/* eslint-disable no-return-await */
import axios from 'axios';
import {API_URL} from '../../env.js';

export const checkEmailApi = async (email) => {
  return await axios.post(`${API_URL}/admin/authen/check_email`, email, {
    headers: {'content-type': 'application/json'},
  });
};

export const adminLoginApi = async (data) => {
  return await axios.post(`${API_URL}/admin/authen/admin_login`, data, {
    headers: {'content-type': 'application/json'},
  });
};

export const currentUserApi = async (uid) => {
  return await axios.get(`${API_URL}/admin/authen/current?uid=${uid}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('token')}`,
      'content-type': 'application/json',
    },
  });
};
