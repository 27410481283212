import React, { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { compareRender } from '../../../functions/fn';
import { Modal, Tooltip, Typography, Button } from 'antd'
import { ButtonTheme } from '../../../components/buttons';
import CanvasDraw from "react-canvas-draw";
import { uploadImg } from '../../../utils/auto-admin/api';

const { Text, Title, Paragraph } = Typography

export const ModalSignature = React.memo(({ item, control }) => {
    const [visibleModal, setVisibleModal] = useState(false)
    const [url, setUrl] = useState(null)
    const [imageBase64, setImageBase64] = useState(null)
    const canvas = useRef(null)


    useEffect(async () => {
        if (JSON.stringify(item.passValue) !== '{}' && item.passValue) {
            setUrl(item.passValue)

            if (item.passValue.signatureImage === null) {
                setUrl(null)
            } else if (item.passValue.signatureImage !== null && item.passValue.edit) {
                const data = await fetch(item.passValue?.signatureImage)
                const blob = await data.blob();
                const reader = new FileReader();
                const base64 = ''
                if (data.status === 200) {
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        const base64data = reader.result
                        setImageBase64(base64data)
                    }
                }
            }
        }
    }, [item.passValue])

    const contentOtherTel = (
        <Paragraph style={{ color: 'white' }}>Click เพื่อให้ลูกค้าเซ็นสัญญารับซื้อสินค้ามือสอง</Paragraph>
    );

    const onSave = async () => {
        const base64 = canvas.current.getDataURL('png', false, 'white')
        const imageUrl = await uploadImg(base64, item?.imageCat);
        let imageUrlCut = imageUrl.split("?")
        setUrl(imageUrlCut[0])
        setImageBase64(base64)
        item.onChangeCustom(imageUrlCut[0], item.name)
        setVisibleModal(false)
    }

    const onClear = () => {
        canvas.current.clear()
    }

    const openModal = () => {
        setVisibleModal(true)
    }

    const onClose = () => {
        setVisibleModal(false)
    }

    const onCloseMain = () => {
        onClose();
    };

    return (
        <>
            <Controller
                render={() => (
                    <>
                        <Tooltip
                            title={contentOtherTel}
                        >
                            {url === null ?
                                <ButtonTheme
                                    useFor="CUSTOM"
                                    style={{ color: '#4B4B4B', borderColor: '#d9d9d9', backgroundColor: '#f5f5f5', borderRadius: '10px', marginLeft: '0px', width: '100%', height: '70%' }}
                                    bgcolor="white"
                                    title=" "
                                    onClick={() => openModal()} />
                                :
                                <Button
                                    style={{ color: '#4B4B4B', borderColor: '#d9d9d9', backgroundColor: '#f5f5f5', borderRadius: '10px', marginLeft: '0px', width: '100%', height: '70%' }}
                                    bgcolor="white"
                                    title=" "
                                    onClick={() => openModal()} >
                                    <img src={imageBase64} alt="image" border="0" width={'60%'} height={'60%'} ></img>
                                </Button>
                            }
                        </Tooltip>
                        <Modal title={'ลงลายมือชื่อ'}
                            open={visibleModal}
                            width={1000}
                            onCancel={onCloseMain}
                            footer={null}
                            destroyOnClose={true}
                            maskClosable={false}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                <CanvasDraw
                                    ref={canvas}
                                    brushRadius={2}
                                    backgroundColor='#f5f5f5'
                                    hideGrid={true}
                                    style={{
                                        width: '100%',
                                        boxShadow:
                                            "0 13px 27px -5px rgba(50, 50, 93, 0.25),    0 8px 16px -8px rgba(0, 0, 0, 0.3)"
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: 20,
                                }}>
                                <ButtonTheme useFor="CONFIRM" title="บันทึก" onClick={() => onSave()} />
                                <ButtonTheme useFor="CLEAR" onClick={() => onClear()} />
                            </div>
                        </Modal>
                    </>
                )
                }
                name="TextField"
                control={control}
                rules={item.rules}
            />
        </>

    );
}
    , (prev, next) =>
        compareRender(prev, next) &&
        JSON.parse(JSON.stringify(prev.item)) ===
        JSON.parse(JSON.stringify(next.item)) &&
        compareRender(prev, next, 'item')
)
