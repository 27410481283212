import {useEffect, useState, useReducer} from 'react';
import {axiosInstance} from '../../oauth';
import {API_REPORT_SERVICE} from '../../env';

export const useGetExportOneTrackingTaskDaily = () => {
  const [params, setParams] = useState(null);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [needRefresh, refresh] = useReducer((s) => !s, false);

  useEffect(() => {
    if (params === null) return;
    let abort = false;
    fetchReport(params);
    return () => {
      abort = true;
    };

    async function fetchReport(params) {
      setLoading(true);

      try {
        const response = await axiosInstance.get(`${API_REPORT_SERVICE}/npl/export_tracking_task_daily${params}`);

        if (response?.data) setResult(response?.data);
      } catch (err) {
        if (!abort) setError(err);
      } finally {
        if (!abort) {
          setLoading(false);
          setParams(null);
        }
      }
    }
  }, [params, needRefresh]);

  return [result, loading, error, setParams, refresh];
};
