// /* eslint no-use-before-define: 0 */
import React, {useState, useEffect} from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import {Modal, Card, Spin} from 'antd';
import {BookOutlined} from '@ant-design/icons';
import {PageLayout} from '../../../components/pageLayout/PageLayout';
import {GET, POST, GET_HISTORY_LOCATION} from '../../../services/index';
import moment from 'moment';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {CSVLink} from 'react-csv';
import {ButtonTheme} from '../../../components/buttons';
import {CheckLocationHistoryColumn} from '../columns';
import {useGetOptions} from '../hook/useGetOptions';
import {CHECK_LOCATION_HISTORY_SEARCH_FORM} from '../form';

const CheckLocationHistoryPage = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [defaultSearch, setDefaultSearch] = useState({});
  const [dataExport, setDataExport] = useState([]);
  const {optionsTracker} = useGetOptions();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('IDtrackers')) {
      const start_date = params.get('startDate');
      const end_date = params.get('startDate');
      const IDtrackers = params.get('IDtrackers');
      setDefaultSearch({start_date, end_date, IDtrackers});
      onGetData({start_date, end_date, IDtrackers});
    }
  }, []);

  const onGetData = async (params) => {
    try {
      setLoading(true);
      const payload = {
        IDtrackers: params?.IDtrackers || defaultSearch?.IDtrackers || '',
        end_date: params?.end_date && moment(params?.end_date, 'DD/MM/YYYY').format('YYYY-MM-DD') || defaultSearch?.start_date && moment(defaultSearch?.start_date, 'DD/MM/YYYY').format('YYYY-MM-DD') || '',
        start_date: params?.start_date && moment(params?.start_date, 'DD/MM/YYYY').format('YYYY-MM-DD') || defaultSearch?.end_date && moment(defaultSearch?.end_date, 'DD/MM/YYYY').format('YYYY-MM-DD')||'',
      };
      const res = await POST(GET_HISTORY_LOCATION, payload);

      const {success, data} = res;
      if (success) {
        setDataSource(
          data.map((el, index) => {
            return {...el, no: index + 1};
          }),
        );
        const exports = data.map((el)=>{
          return {
            'วันที่ติดตาม':el.created_date,
            'ชื่อผู้ติดตาม':el.tracker_name,
            'เลขที่สัญญา':el.contract_no,
            'ชื่อลูกค้า':el.customer_name,
            'Location ลูกค้า':el.tracker_location.name,
            'เวลากดเช็ค':el.created_time,
            'Location ผู้ติดตาม':el.created_time.name,
            'รวมระยะห่าง (เมตร)':el.distance,

          }
        })
        setDataExport(exports)
        setLoading(false);
      }
    } catch (error) {
      Modal.error({title: error?.message});
    }
  };

  const onClearCustom = (setValue, formSearch) => {
    formSearch.forEach((val) => setValue(val.name, null));
    setDefaultSearch({});
  }

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'รายงานประวัติการ Check Location',
              icon: <BookOutlined />,
              spanSearch: 24,
              formSearch: CHECK_LOCATION_HISTORY_SEARCH_FORM({options: {trackers: optionsTracker}}),
              onSearch: (data) => onGetData(data),
              dataSearchDefault: defaultSearch,
              onClearCustom: onClearCustom,
            }}
            tableLayout={{
              columns: CheckLocationHistoryColumn(),
              dataSource: dataSource,
              extraContent: (
                <ContainerButton right>
                  <CSVLink data={dataExport} filename={'รายงานประวัติการ Check Location'}>
                    <ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด Excel'} />
                  </CSVLink>
                </ContainerButton>
              ),
            }}
          />
        </Spin>
      </Card>
    </Wrapper>
  );
};

export default CheckLocationHistoryPage;
