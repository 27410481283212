import axios from 'axios';
import { API_URL } from '../../env.js';

export const getContracts = async (params) => {

    try {
        return await axios.get(`${API_URL}/admin/contract/${params}`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
        });
    } catch (error) {
        return error;
    }
};

export const getContractPeriods = async (contractNumber) => {
    try {
        return await axios.get(`${API_URL}/admin/contract/${contractNumber}/periods`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
        });
    } catch (error) {
        return error;
    }
};


export const exportContract = async (contractNumber) => {
    try {
        return await axios.post(`${API_URL}/admin/contract/tradesys/${contractNumber}/export`, {}, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/octet-stream',
            },
            responseType: 'blob'
        });
    } catch (error) {
        return error;
    }
};


export const exportReciveContract = async (data) => {
    try {
        return await axios.post(`${API_URL}/admin/contract/tradesys/refinPackageExport`, data, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
            responseType: 'blob'
        });
    } catch (error) {
        console.error({ error })
        return error;
    }
};

export const getContractList = async (params) => {
    try {
        return await axios.get(`${API_URL}/admin/contract/tradesys/${params}`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
        });
    } catch (error) {
        return error;
    }
};

export const getContractInfo = async (contractNumber) => {
    try {
        return await axios.get(`${API_URL}/admin/contract/tradesys/${contractNumber}`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
        });
    } catch (error) {
        return error;
    }
};


export const updateContractInfo = async (contractNumber, data) => {
    try {
        return await axios.put(`${API_URL}/admin/contract/${contractNumber}/update`, data, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
        });
    } catch (error) {
        return error;
    }
};

export const updateContractGradeInfo = async (contractNumber, data) => {
    try {
        return await axios.put(`${API_URL}/admin/contract/${contractNumber}/update_grade`, data, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
        });
    } catch (error) {
        return error;
    }
};

export const terminatedContract = async (contractNumber, data) => {
    try {

        if (data.type === 'REFINPACKAGE') {
            // return await axios.post(`${API_URL}/admin/contract/terminated/refinPackage`, { contract_no_main: data.contract_no_main, contract_no_additional: data.contract_no_additional, remarkTerminated: data.remarkTerminated }, {
            //     headers: {
            //         authorization: `Bearer ${localStorage.getItem('token')}`,
            //         'content-type': 'application/json',
            //     },
            // });
            return await axios.post(`${API_URL}/admin/contract/terminated/refinPackage-withtransaction`, { contract_no_main: data.contract_no_main, contract_no_additional: data.contract_no_additional, remarkTerminated: data.remarkTerminated }, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem('token')}`,
                    'content-type': 'application/json',
                },
            });
        } else {
            // return await axios.post(`${API_URL}/admin/contract/${contractNumber}/terminated`, { remarkTerminated: data.remarkTerminated }, {
            //     headers: {
            //         authorization: `Bearer ${localStorage.getItem('token')}`,
            //         'content-type': 'application/json',
            //     },
            // });
            return await axios.post(`${API_URL}/admin/contract/${contractNumber}/terminated-withtransaction`, { remarkTerminated: data.remarkTerminated }, {
              headers: {
                  authorization: `Bearer ${localStorage.getItem('token')}`,
                  'content-type': 'application/json',
              },
            });  
        }

    } catch (error) {
        return error;
    }
};

export const impoundedContract = async (contractNumber) => {
    try {
        return await axios.post(`${API_URL}/admin/contract/${contractNumber}/impounded`, {}, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
        });
    } catch (error) {
        return error;
    }
};

export const getContractSummary = async (contractList, date) => {

    let params = contractList.map(c => `contract_num_list=${c}`).join("&");
    if (date) params += `&now_date=${date}`;
    try {
        return await axios.get(`${API_URL}/admin/contract/summary?${params}`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
        });
    } catch (error) {
        return error;
    }
};

export const exportContractTerminated = async (contractNumber, misTaskId) => {
    try {
        return await axios.post(`${API_URL}/admin/contract/${contractNumber}/export/terminated`, { contract_pdf_mistake_id: misTaskId }, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
            responseType: 'blob'
        });
    } catch (error) {
        return error;
    }
};

export const getPeriods = async (contract_num_list, payment_id, paid_date) => {

    let params = [`contract_num_list=${encodeURIComponent(contract_num_list)}`, `payment_id=${encodeURIComponent(payment_id)}`, `paid_date=${encodeURIComponent(paid_date)}`].join("&");
    try {
        return await axios.get(`${API_URL}/admin/contract/periods?${params}`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
        });
    } catch (error) {
        return error;
    }
};