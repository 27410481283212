import React, { useEffect } from 'react';
import { Input, Space, Button, Select } from 'antd';
import { useContract } from '../../../hook/useContract';
import { Column, InfoLabel, RowFilter } from './styled-component';
import { useReceiptPaymentDispatch, useReceiptPaymentState } from '../hooks/useReceiptPayment';
import * as logic from './logic';

const FilterView = () => {
  const { contractList, dispatchGetContracts } = useContract();
  const { receiptFilterState } = useReceiptPaymentState();
  const { dispatchUpdateFilter, dispatchClearFilter, dispatchClearAmountContract, dispatchClearCheckRedeemData, dispatchClearUpdateRemark, dispatchClearGetCouponDetail } = useReceiptPaymentDispatch();

  //For get Contract No from URL params from Unlock Icloud Accounting
  const params = new URLSearchParams(window.location.search);
  const getContractNo = params.get('contractNo')
  const filter = { ...receiptFilterState };
  filter['contractNo'] = getContractNo;

  useEffect(() => {
    dispatchUpdateFilter(filter)
  }, [getContractNo])

  const onChangeFilter = (key, value) => {
    if (!key) {
      return;
    }

    const newFilter = { ...(receiptFilterState && receiptFilterState) };
    newFilter[key] = value;
    dispatchUpdateFilter(newFilter);
  };

  const onClearFilter = () => {
    const params = logic.createParams('', '', '');
    dispatchGetContracts(params);
    dispatchClearFilter();
    dispatchClearAmountContract();
    dispatchClearCheckRedeemData();
    dispatchClearUpdateRemark();
    dispatchClearGetCouponDetail();
    if (window.location.href.includes('?')) {
      window.history.pushState({}, null, window.location.href.split('?')[0]);
    }
  };

  const onSubmitFilter = () => {
    onChangeFilter('contractNo', '');
    const params = logic.createParams(receiptFilterState?.idCard, receiptFilterState?.name, receiptFilterState?.tel);
    dispatchGetContracts(params);
    dispatchClearCheckRedeemData();
    dispatchClearUpdateRemark();
    dispatchClearGetCouponDetail();
  };

  return (
    <RowFilter>
      <Column edgeInsetsRight={20}>
        <InfoLabel>เลขบัตรประชาชน</InfoLabel>
        <Input
          id="thai-id"
          placeholder="กรอกเลขบัตรประชาชน"
          type="text"
          value={receiptFilterState?.idCard || ''}
          onChange={(e) => onChangeFilter('idCard', e.target.value)}
        />
      </Column>
      <Column edgeInsetsRight={20}>
        <InfoLabel>ชื่อลูกค้า</InfoLabel>
        <Input
          id="customer-name"
          placeholder="กรอกชื่อลูกค้า"
          type="text"
          value={receiptFilterState.name || ''}
          onChange={(e) => onChangeFilter('name', e.target.value)}
        />
      </Column>
      <Column edgeInsetsRight={20}>
        <InfoLabel>เบอร์โทร</InfoLabel>
        <Input
          id="tel"
          placeholder="กรอกเบอร์โทร"
          type="text"
          value={receiptFilterState?.tel || ''}
          onChange={(e) => onChangeFilter('tel', e.target.value)}
        />
      </Column>
      <Column edgeInsetsRight={40}>
        <InfoLabel>เลขที่สัญญา</InfoLabel>
        <Select
          style={{ width: 160 }}
          placeholder="เลือกเลขที่สัญญา"
          value={receiptFilterState?.contractNo || undefined}
          showSearch={true}
          onChange={(value) => onChangeFilter('contractNo', value)}>
          {Array.isArray(contractList) &&
            contractList.length &&
            contractList.map((contract) => (
              <Select.Option value={contract.contract_no} key={contract.contract_no}>
                {contract.contract_no}
              </Select.Option>
            ))}
        </Select>
      </Column>
      <Space>
        <Button type="primary" onClick={onSubmitFilter}>
          กรองสัญญา
        </Button>
        <Button onClick={onClearFilter}>ล้าง</Button>
      </Space>
    </RowFilter>
  );
};

FilterView.propTypes = {};

export default FilterView;
