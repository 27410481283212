import * as TYPES from './type';

const initialFilterState = {
  idCard: '',
  name: '',
  tel: '',
  contractNo: '',
};

export const receiptPaymentUpdateFilterReducer = (state = initialFilterState, { type, payload }) => {
  switch (type) {
    case TYPES.RECEIPT_PAYMENT_UPDATE_FILTER_REQ:
      return { ...state };
    case TYPES.RECEIPT_PAYMENT_UPDATE_FILTER_SUCCESS:
      return { ...state, ...(payload && payload) };
    case TYPES.RECEIPT_PAYMENT_UPDATE_FILTER_CLEAR:
      return { ...initialFilterState };
    default:
      return state;
  }
};

const initialPaidParamsState = {
  transaction_date: '',
  paid_amount: '',
  contract_amount: '',
  paid_type: '',
  channel: 'CASH',
  additional_charges: null,
  slip_id: '',
};

export const receiptPaymentPaidParamsReducer = (state = initialPaidParamsState, { type, payload }) => {
  switch (type) {
    case TYPES.RECEIPT_PAYMENT_UPDATE_PAID_PARAMS_REQ:
      return { ...state };
    case TYPES.RECEIPT_PAYMENT_UPDATE_PAID_PARAMS_SUCCESS:
      return { ...state, ...(payload && payload) };
    case TYPES.RECEIPT_PAYMENT_UPDATE_PAID_PARAMS_CLEAR:
      return { ...initialPaidParamsState };
    default:
      return state;
  }
};

const initialSummary = {
  loading_info: false,
  error_info: null,
  summary_info: null,
  loading_info_update: false,
  fetch_info_date: null,
  summary_amount: {
    loading: false,
    error: null,
    summary: null,
  },
};

export const receiptPaymentGetSummary = (state = initialSummary, { type, payload }) => {
  switch (type) {
    case TYPES.RECEIPT_PAYMENT_GET_SUMMARY_INFO_REQ:
      return { ...state, loading_info: true, fetch_info_date: payload };
    case TYPES.RECEIPT_PAYMENT_GET_SUMMARY_INFO_SUCCESS:
      return { ...state, loading_info: false, error_info: null, summary_info: payload.data.data };
    case TYPES.RECEIPT_PAYMENT_GET_SUMMARY_INFO_FAIL:
      return { ...state, loading_info: false, error_info: payload };
    case TYPES.RECEIPT_PAYMENT_GET_SUMMARY_INFO_CLEAR:
      return { ...initialSummary };

    case TYPES.RECEIPT_PAYMENT_UPDATE_SUMMARY_INFO_REQ:
      return { ...state, loading_info_update: true, error_info: null, fetch_info_date: payload };
    case TYPES.RECEIPT_PAYMENT_UPDATE_SUMMARY_INFO_SUCCESS:
      return { ...state, loading_info_update: false, summary_info: payload.data.data };
    case TYPES.RECEIPT_PAYMENT_UPDATE_SUMMARY_INFO_FAIL:
      return { ...state, loading_info_update: false, error_info: payload };

    case TYPES.RECEIPT_PAYMENT_GET_SUMMARY_AMOUNT_REQ:
      return { ...state, summary_amount: { ...state.summary_amount, loading: true } };
    case TYPES.RECEIPT_PAYMENT_GET_SUMMARY_AMOUNT_SUCCESS:
      return { ...state, summary_amount: { ...state.summary_amount, loading: false, error: null, summary: payload.data.data } };
    case TYPES.RECEIPT_PAYMENT_GET_SUMMARY_AMOUNT_FAIL:
      return { ...state, summary_amount: { ...state.summary_amount, loading: false, error: payload, summary: null } };
    case TYPES.RECEIPT_PAYMENT_GET_SUMMARY_AMOUNT_CLEAR:
      return { ...state, summary_amount: { ...initialSummary.summary_amount } };

    case TYPES.RECEIPT_PAYMENT_GET_AMOUNT_CONTRACT_REQ:
      return { ...state, loading_info: false }
    case TYPES.RECEIPT_PAYMENT_GET_AMOUNT_CONTRACT_SUCCESS:
      return { ...state, loading_info: false, amount_contract: payload.data.data.amount.remain }
    case TYPES.RECEIPT_PAYMENT_GET_AMOUNT_CONTRACT_FAIL:
      return { ...state, loading_info: false }
    case TYPES.RECEIPT_PAYMENT_GET_AMOUNT_CONTRACT_CLEAR:
      return { ...state, loading_info: false, amount_contract: null }
    default:
      return state;
  }
};

const initialPayInfo = {
  loading: false,
  error: null,
  info: null,
};

export const receiptPaymentPay = (state = initialPayInfo, { type, payload }) => {
  switch (type) {
    case TYPES.RECEIPT_PAYMENT_PAY_REQ:
      return { ...state, loading: true, info: null, error: null };
    case TYPES.RECEIPT_PAYMENT_PAY_SUCCESS:
      return { ...state, loading: false, error: null, info: payload.data.data };
    case TYPES.RECEIPT_PAYMENT_PAY_FAIL:
      return { ...state, loading: false, error: payload, info: null };
    case TYPES.RECEIPT_PAYMENT_PAY_CLEAR:
      return { ...initialPayInfo };
    case TYPES.RECEIPT_PAYMENT_PAY_LOADING_CLEAR:
      return { ...state, loading: false }
    default:
      return state;
  }
};

const initialCheckRedeemInfoState = {
  loading: false,
  error: null,
  info: null,
};

export const receiptPaymentCheckRedeemInfoReducer = (state = initialCheckRedeemInfoState, { type, payload }) => {
  switch (type) {
    case TYPES.RECEIPT_PAYMENT_CHECK_REDEEM_DATA_REQ:
      return { ...state, loading: true, info: null, error: null };
    case TYPES.RECEIPT_PAYMENT_CHECK_REDEEM_DATA_SUCCESS:
      return { ...state, loading: false, error: null, info: payload.data };
    case TYPES.RECEIPT_PAYMENT_CHECK_REDEEM_DATA_FAIL:
      return { ...state, loading: false, error: payload, info: null };
    case TYPES.RECEIPT_PAYMENT_CHECK_REDEEM_DATA_CLEAR:
      return { ...initialCheckRedeemInfoState };
    default:
      return state;
  }
};

const initialUpdateRemarkDeviceReturnState = {
  loading: false,
  error: null,
  info: null,
};

const initialGetCouponDetailState = {
  loading: false,
  error: null,
  info: null,
};

export const receiptPaymentUpdateRemarkDeviceReturnReducer = (state = initialUpdateRemarkDeviceReturnState, { type, payload }) => {
  switch (type) {
    case TYPES.RECEIPT_PAYMENT_UPDATE_DEVICE_RETURN_REMARK_REQ:
      return { ...state, loading: true, info: null, error: null };
    case TYPES.RECEIPT_PAYMENT_UPDATE_DEVICE_RETURN_REMARK_SUCCESS:
      return { ...state, loading: false, error: null, info: payload.data };
    case TYPES.RECEIPT_PAYMENT_UPDATE_DEVICE_RETURN_REMARK_FAIL:
      return { ...state, loading: false, error: payload, info: null };
    case TYPES.RECEIPT_PAYMENT_UPDATE_DEVICE_RETURN_REMARK_CLEAR:
      return { ...initialUpdateRemarkDeviceReturnState };
    default:
      return state;
  }
};

export const receiptPaymentGetCouponDetailReducer = (state = initialGetCouponDetailState, { type, payload }) => {
  switch (type) {
    case TYPES.RECEIPT_PAYMENT_GET_COUPON_DETAIL_REQ:
      return { ...state, loading: true, info: null, error: null };
    case TYPES.RECEIPT_PAYMENT_GET_COUPON_DETAIL_SUCCESS:
      return { ...state, loading: false, error: null, info: payload.data };
    case TYPES.RECEIPT_PAYMENT_GET_COUPON_DETAIL_FAIL:
      return { ...state, loading: false, error: payload, info: null };
    case TYPES.RECEIPT_PAYMENT_GET_COUPON_DETAIL_CLEAR:
      return { ...initialGetCouponDetailState };
    default:
      return state;
  }
};

