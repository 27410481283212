export const ChatconeFollowContractHistoryColumn = () => {
  return [
    {
      title: 'วันที่บันทึก',
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: '15%',
      render: (item) => {
        return item
      }
    },
    {
      title: 'ครั้งที่',
      dataIndex: 'no',
      key: 'no',
      width: '10%',
      align: 'center'
    },
    {
      title: 'รายละเอียด',
      dataIndex: 'text',
      key: 'text',
      width: '50%'
    },
    {
      title: 'ส่งแจ้งเตือนโดย',
      dataIndex: 'createdName',
      key: 'createdName',
      width: '25%'
    }
  ]
}
