import React, {useState, useEffect} from 'react';
import {Modal} from 'antd';
import {useForm} from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../buttons';
import {EditRemark} from '../../../constants/global/actionform';
import {POST, EDIT_REMARK_CUSTOMER_INFO} from '../../../services/index';
import {Icon} from '../../../resources/icon';
import CustomerRemarkLogTable from '../../table/CustomerRemarkLog';
import {useLocation} from 'react-router-dom';
import {useUpdateTagChatconeByProcess} from '../../../hook/chatcone';
import {APP_URL} from '../../../env.js';
import {Spinners} from '../../';

export const EditRemarkCustomerModal = ({title, visible, onClose, data}) => {
  const {
    handleSubmit,
    formState: {errors},
    setValue,
    getValues,
    control,
    clearErrors,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState([]);
  const [logs, setLogs] = useState([]);

  const {pathname} = useLocation();
  const [updateTagChatconeByProcessFn, updateTagChatconeByProcessLoading] = useUpdateTagChatconeByProcess();

  useEffect(() => {
    if (JSON.stringify(data && data.record) !== '{}' && data.record) {
      setRecord(data.record);
      setLogs(data.logs);
    }
  }, [data]);

  const openConfirm = (params) => {
    Modal.confirm({
      title: 'ยืนยัน',
      icon: <Icon.warningAlert />,
      content: 'ยืนยันการแก้ไข หมายเหตุ',
      onOk() {
        onSubmit(params);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const onSubmit = async (params) => {
    setLoading(true);

    try {
      let res = await POST(EDIT_REMARK_CUSTOMER_INFO, {remark: params.remark, id: record._id});
      const {success} = res;

      if (success) {
        const {remark} = params;

        /* CHATCONE: 5.2 UPDATE_REMARK */
        const {chatcone, _id} = record;
        if (chatcone?.social_id) {
          const setInput = {
            chatcone: chatcone,
            processType: 'UPDATE_REMARK',
            userId: _id,
            refId: _id,
            routeFrom: `${APP_URL}${pathname}`,
            data: {
              remark: remark,
            },
          };

          await updateTagChatconeByProcessFn({...setInput});
        }

        Modal.success({
          title: 'บันทึกหมายเหตุสำเร็จ',
          afterClose: () => {
            data.reApi(data.valueSearch);
            onCloseMain();
          },
        });
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const onCloseMain = () => {
    clearErrors();
    setValue('remark', '');
    onClose();
  };

  return (
    <>
      {loading || updateTagChatconeByProcessLoading ? <Spinners /> : null}
      <Modal
        title={title || 'modal'}
        open={visible}
        width={1000}
        onCancel={onCloseMain}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
        style={{border: 'unset'}}>
        <form onSubmit={handleSubmit(openConfirm)} style={{marginTop: '10px'}}>
          <RenderForm
            control={control}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            formList={EditRemark()}
            renderButton={
              <>
                <ContainerButton right>
                  <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" />
                  <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
                </ContainerButton>
              </>
            }
          />
        </form>
        <CustomerRemarkLogTable logs={logs} />
      </Modal>
    </>
  );
};
