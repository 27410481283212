import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  chip: {
    // fontSize: '10px',
  },
  pending: {
    color: '#F5B813',
    backgroundColor: 'rgba(245, 184, 19, 0.15)',
  },
  confirmed: {
    color: '#01B927',
    backgroundColor: 'rgba(1, 185, 39, 0.15)',
  },
  canceled: {
    color: '#E01E01',
    backgroundColor: 'rgba(224, 30, 1, 0.15)',
  },
}));

const ChipStatus = (props) => {
  const { status } = props;
  const classes = useStyles();

  const Status = () => {
    switch (status) {
      case 'CONFIRMED': {
        return <Chip className={`${classes.chip} ${classes.confirmed}`} label="ยืนยันแล้ว" />;
      }
      case 'CANCELED': {
        return <Chip className={`${classes.chip} ${classes.canceled}`} label="ยกเลิกแล้ว" />;
      }
      case 'PENDING': {
        return <Chip className={`${classes.chip} ${classes.pending}`} label="รอยืนยัน" />;
      }
      case 'SUCCESS': {
        return <Chip className={`${classes.chip} ${classes.confirmed}`} label="อัพเดทแล้ว" />;
      }
      case 'AMOUNT_ERROR': {
        return <Chip className={`${classes.chip} ${classes.canceled}`} label="ยอดเงินไม่ถูกต้อง" />;
      }
      case 'ERROR': {
        return <Chip className={`${classes.chip} ${classes.canceled}`} label="ผิดพลาด" />;
      }
      case 'REVIEWED': {
        return <Chip className={`${classes.chip} ${classes.confirmed}`} label="ตรวจสอบแล้ว" />;
      }
      // สถานะตรวจสอบ
      case 'SLIP_PENDING': {
        return <Chip className={`${classes.chip} ${classes.pending}`} label="รอตรวจสอบ" />;
      }
      case 'SLIP_UNSUCCESS': {
        return <Chip className={`${classes.chip} ${classes.canceled}`} label="ไม่ผ่าน" />;
      }
      case 'SLIP_SUCCESS': {
        return <Chip className={`${classes.chip} ${classes.confirmed}`} label="ผ่าน" />;
      }
      case 'SLIP_CANNOT_CHECK': {
        return <Chip className={`${classes.chip}`} label="ไม่สามารถตรวจสอบ" />;
      }
      case '-': {
        return <Chip className={`${classes.chip}`} label="-" />;
      }
      default: {
        return <Chip className={`${classes.chip}`} label="ทั้งหมด" />;
      }
    }
  };

  return <Status />;
};

ChipStatus.propTypes = {
  status: PropTypes.string,
};

ChipStatus.defaultProps = {
  status: 'PENDING',
};

export default ChipStatus;
