import { Button } from 'antd';
import styled from 'styled-components';
import { color } from '../../resources/color';
import { Icon } from '../../resources/icon';

export const ButtonTheme = (props) => {
  return renderButtonType(props);
};

const renderButtonType = (props) => {
  let { useFor, title, onClick, disabled, width, bgColor } = props;
  let stuff = {
    htmlType: props.htmlType,
    style: { ...props.style },
    ...props,
  };

  switch (useFor) {
    case 'TRANFER':
      stuff = {
        ...stuff,
        bgcolor: color.tranfer,
        icon: <Icon.tranfer />,
        type: 'primary',
      };
      break;
    case 'SEND':
      stuff = {
        ...stuff,
        bgcolor: color.sendAx,
        icon: <Icon.send />,
        disabled: disabled,
        type: 'primary',
      };
      break;
    case 'TRACKING':
      stuff = {
        ...stuff,
        bgcolor: color.tracking,
        icon: <Icon.box />,
        type: 'primary',
      };
      break;
    case 'QR_CODE':
      title = title || 'qrcode';
      stuff = {
        ...stuff,
        bgcolor: color.edit,
        icon: <Icon.createQRCode />,
        type: 'primary',
      };
      break;
    case 'SEARCH':
      title = title || 'ค้นหา';
      stuff = {
        ...stuff,
        bgcolor: color.search,
        icon: <Icon.search />,
        type: 'primary',
      };
      break;
    case 'EXPORT':
      title = title || 'ดาวน์โหลดไฟล์';
      stuff = {
        ...stuff,
        bgcolor: color.success,
        icon: <Icon.upload />,
        type: 'primary',
      };
      break;
    case 'NEXT':
      title = title || 'ถัดไป';
      stuff = {
        ...stuff,
        width: width || '50%',
        bgcolor: color.theme,
        icon: <Icon.next />,
        type: 'primary',
      };
      break;
    case 'EDIT':
      title = title || 'แก้ไข';
      stuff = {
        ...stuff,
        bgcolor: color.edit,
        color: '#0F0E0E',
        icon: <Icon.edit />,
        type: 'primary',
      };
      break;
    case 'EDIT_POINT':
      // title = { title };
      stuff = {
        ...stuff,
        bgcolor: color.edit,
        color: '#0F0E0E',
        icon: <Icon.edit />,
        type: 'primary',
      };
      break;
    case 'RANDOM':
      stuff = {
        ...stuff,
        bgcolor: color.sendAx,
        icon: <Icon.random />,
        type: 'primary',
      };
      break;
    case 'REMOVE':
      title = title || 'ลบ';
      stuff = {
        ...stuff,
        bgcolor: color.remove,
        icon: <Icon.remove />,
        type: 'primary',
      };
      break;
    case 'VIEW':
      stuff = {
        ...stuff,
        icon: <Icon.search />,
        type: 'primary',
      };
      break;
    case 'CLEAR':
      title = 'ล้างข้อมูล';
      stuff = {
        ...stuff,
        icon: <Icon.clear />,
        type: 'ghost',
      };
      break;
    case 'DOWNLOAD':
      title = title || 'ดาว์นโหลด';
      stuff = {
        ...stuff,
        bgcolor: color.load,
        icon: <Icon.download />,
        type: 'primary',
      };
      break;
    case 'PRINT':
      title = title || 'พิมพ์';
      stuff = {
        ...stuff,
        bgcolor: color.remove,
        icon: <Icon.print />,
        type: 'primary',
      };
      break;
    case 'SUBMIT':
      title = title || 'บันทึก';
      stuff = {
        ...stuff,
        bgcolor: color.success,
        icon: <Icon.save />,
        type: 'primary',
      };
      break;
    case 'REJECT':
      title = title || 'REJECT';
      stuff = {
        ...stuff,
        bgcolor: color.danger,
        type: 'primary',
      };
      break;
    case 'CONFIRM':
      title = title || 'ยืนยัน';
      stuff = {
        ...stuff,
        bgcolor: color.submit,
        icon: <Icon.confirm />,
        type: 'primary',
      };
      break;
    case 'CANCEL':
      title = title || 'ยกเลิก';
      stuff = {
        ...stuff,
        // icon: <Icon.download />,
        type: 'ghost',
      };
      break;
    case 'LOGIN':
      title = 'เข้าสู่ระบบ';
      stuff = {
        ...stuff,
        bgcolor: color.theme,
        icon: <Icon.login />,
        type: 'primary',
      };
      break;
    case 'LOGOUT':
      stuff = {
        ...stuff,
        bgcolor: color.logout,
        icon: <Icon.login />,
        type: 'primary',
      };
      break;
    case 'FORGET_PASSWORD':
      title = 'ลืมรหัสผ่าน';
      stuff = {
        ...stuff,
        icon: <Icon.forgetPassword />,
        type: 'ghost',
      };
      break;
    case 'BACK':
      title = 'ย้อนกลับ';
      stuff = {
        ...stuff,
        icon: <Icon.back />,
        type: 'ghost',
      };
      break;
    case 'SWITCH':
      stuff = {
        ...stuff,
        icon: <Icon.back />,
        type: 'primary',
      };
      break;
    case 'CREATE':
      title = title || 'เพิ่ม';
      stuff = {
        ...stuff,
        bgcolor: bgColor || color.create,
        icon: <Icon.create />,
        type: 'primary',
      };
      break;
    case 'ADD_TASK':
      title = title || 'เพิ่ม';
      stuff = {
        ...stuff,
        bgcolor: bgColor || color.edit,
        icon: <Icon.addtask />,
        type: 'primary',
      };
      break;

    case 'CREATE_INFO':
      title = title || 'เพิ่ม';
      stuff = {
        ...stuff,
        bgcolor: bgColor || color.create,
        icon: <Icon.manageReport />,
        type: 'primary',
      };
      break;

    case 'ZIP_XML':
      stuff = {
        ...stuff,
        icon: <Icon.download />,
        type: 'primary',
      };
      break;
    case 'RESEND':
      stuff = {
        ...stuff,
        icon: <Icon.reAct />,
        type: 'ghost',
      };
      break;
    case 'REQ_PASSWORD':
      stuff = {
        ...stuff,
        icon: <Icon.forgetPassword />,
        bgcolor: color.search,
        type: 'primary',
      };
      break;
    case 'RE_CHECK':
      stuff = {
        ...stuff,
        icon: <Icon.recheck />,
        bgcolor: color.edit,
        type: 'primary',
      };
      break;
    case 'REGISTER':
      stuff = {
        ...stuff,
        icon: <Icon.register />,
        bgcolor: color.submit,
        type: 'primary',
      };
      break;
    case 'CLOSE':
      title = title || 'ปิด';
      stuff = {
        ...stuff,
        bgcolor: color.theme,
        type: 'primary',
      };
      break;
    case 'CONTACT':
      title = title;
      stuff = {
        ...stuff,
        bgcolor: color.on_process,
        disabled: disabled,
        type: 'primary',
      };
      break;
    case 'UNLOCK':
      title = title;
      stuff = {
        ...stuff,
        bgcolor: color.edit,
        type: 'primary',
      };
      break;
    case 'CUSTOM':
      title = title;
      stuff = {
        ...stuff,
        // bgcolor: color.create,
        type: 'primary',
      };
      break;
    case 'VERIFY':
      title = title || 'VERIFY';
      stuff = {
        ...stuff,
        icon: <Icon.clock />,
        bgcolor: color.edit,
        type: 'primary',
      };
      break;
    case 'SEND_NOTI':
      title = title || 'SEND';
      stuff = {
        ...stuff,
        icon: <Icon.bell />,
        bgcolor: color.on_process,
        type: 'primary',
      };
      break;
  }

  if (useFor) {
    if (useFor === 'IMPORT') {
      return (
        <ButtonStyle shape="round" {...stuff} size={stuff.size || 'middle'}>
          {title}
          <input
            className="btnUpload"
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={(e) => onClick(e)}
          />
        </ButtonStyle>
      );
    } else {
      return (
        <ButtonStyle {...stuff} size={stuff.size || 'middle'}>
          {title}
        </ButtonStyle>
      );
    }
  }
};

const ButtonStyle = styled(Button)`
  align-items: center;
  margin: 5px;
  background-color: ${(props) => props.bgcolor};
  border-color: ${(props) => props.bgcolor};
  color: ${(props) => props.color};
  :hover,
  :focus,
  :active {
    background-color: ${(props) => props.bgcolor};
    color: ${(props) => props.color};
    border-color: ${(props) => props.bgcolor};
    opacity: ${(props) => (props.bgcolor ? '0.5' : 'unset')};
  }
`;
