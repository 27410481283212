import React from 'react';
import { compareRender } from '../../functions/fn';
import { Controller } from 'react-hook-form';
import { Checkbox, Collapse, Row, Col } from 'antd';
const { Panel } = Collapse;
const CheckboxGroup = Checkbox.Group;

export const CheckboxTheme = React.memo(({ item, control }) => {
  const { options, onChangeCustom } = item;

  return (
    <div style={{ marginTop: '12px' }}>
      <Controller
        name={item.name}
        control={control}
        rules={item.rules}
        value={item.value}
        defaultValue={item.defaultValue}
        render={({ value, onChange }) =>
          options.map((opt, i) => (
            <Checkbox
              key={`CheckboxTheme_${i}`}
              // checked={value}
              disabled={item.disabled}
              onChange={(e) => (onChangeCustom ? onChangeCustom(e.target.checked, item.name) : onChange)}>
              {/* onChange={(e) => onChange(e.target.checked)}> */}
              {opt.label}
            </Checkbox>
          ))
        }
      />
    </div>
  );
}, compareRender);

export const CheckboxGroupTheme = React.memo(({ item, control }) => {
  const { data, onChangeCustom, name, defaultValue, rules } = item;

  const convertHead = (value) => {
    let headName = '';
    switch (value) {
      case 'Manage NPL':
        headName = 'Tracking';
        break;
      // case 'BaanChiang':
      //   headName = 'Interface บ้านเชียง';
      //   break;
      default:
        headName = value;
        break;
    }
    return headName;
  };

  return (
    <>
      <Row gutter={[24, 24]} justify="center">
        {data &&
          data.map((el, s) => {
            return (
              <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 6 }} lg={{ span: 6 }} key={s}>
                <Collapse defaultActiveKey={['1']} bordered={false} key={s}>
                  <Panel header={el.head} key="1">
                    <Controller
                      // name={el.head === 'Manage NPL' ? 'Tracking' : el.head}
                      name={convertHead(el.head)}
                      control={control}
                      rules={rules}
                      defaultValue={
                        defaultValue
                          ? el.head === 'Tools'
                            ? defaultValue.Tools
                            : el.head === 'Tradesys'
                              ? defaultValue.Tradesys
                              : el.head === 'Account'
                                ? defaultValue.Account
                                : el.head === 'Ufriend'
                                  ? defaultValue.Ufriend
                                  : el.head === 'Manage NPL'
                                    ? defaultValue.Tracking
                                    : el.head === 'Stock'
                                      ? defaultValue.Stock
                                      : el.head === 'Products'
                                        ? defaultValue.Products
                                        : el.head === 'StockNonSerial'
                                          ? defaultValue.StockNonSerial
                                          : el.head === 'BaanChiang'
                                            ? defaultValue.BaanChiang
                                            : ''
                          : ''
                      }
                      render={({ field: { onChange, value } }) => (
                        <Checkbox.Group
                          key={`CheckboxTheme_${s}`}
                          style={{ marginLeft: '8px' }}
                          value={value}
                          options={el.menu}
                          // disabled={item.disabled}
                          onChange={(e) => (onChangeCustom ? onChangeCustom(e, convertHead(el.head)) : onChange)}
                        />
                      )}
                    />
                  </Panel>
                </Collapse>
              </Col>
            );
          })}
      </Row>
    </>
  );
}, compareRender);

export const CheckboxOption = React.memo(({ item, control }) => {
  const { options, onChangeCustom } = item;

  return (
    <div style={{ marginTop: '12px' }}>
      <Controller
        name={item.name}
        control={control}
        defaultValue={item.defaultValue || []}
        render={({ field: { onChange, value } }) => (
          options.map((opt, i) => (
            <Checkbox
              key={`CheckboxNewTheme_${i}`}
              checked={value.includes(opt.value)}
              disabled={item.disabled}
              onChange={(e) => {
                const isChecked = e.target.checked;
                const newValue = isChecked
                  ? [...value, opt.value]
                  : value.filter(v => v !== opt.value);

                if (onChangeCustom) {
                  onChangeCustom(newValue, item.name);
                } else {
                  onChange(newValue);
                }
              }}
            >
              {opt.label}
            </Checkbox>
          ))
        )}
      />
    </div>
  );
});


export const CheckboxDefault = React.memo(({ item, control }) => {
  const { options, onChangeCustom } = item;

  return (
    <div style={{ marginTop: '12px' }}>
      <Controller
        name={item.name}
        control={control}
        rules={item.rules}
        value={item.value}
        defaultValue={item.defaultValue}
        render={({ field: { value, onChange } }) => {
          return options.map((opt, i) => (
            <Checkbox
              key={`CheckboxTheme_${i}`}
              checked={value}
              disabled={item.disabled}
              onChange={(e) => (onChangeCustom ? onChangeCustom(e.target.checked, item.name) : onChange)}>
              {/* onChange={(e) => onChange(e.target.checked)}> */}
              {opt.label}
            </Checkbox>
          ))
        }
        }
      />
    </div>
  );
}, compareRender);