import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from 'antd';
import {LinkOutlined, DeleteOutlined, EditOutlined, SaveOutlined, CloseOutlined} from '@ant-design/icons';
import {IconWrapper} from './styled-component';

export const IconView = (props) => {
  const {name, onClick, tooltipLabel} = props;

  switch (name) {
    case 'delete':
      return (
        <Tooltip title="ยกเลิกรายการ" placement="bottom">
          <IconWrapper onClick={onClick}>
            <DeleteOutlined style={{fontSize: 20, color: 'red'}} />
          </IconWrapper>
        </Tooltip>
      );
    case 'link':
      return (
        <Tooltip title="ดูไฟล์แนบ" placement="bottom">
          <IconWrapper onClick={onClick}>
            <LinkOutlined style={{fontSize: 20}} />
          </IconWrapper>
        </Tooltip>
      );
    case 'edit':
      return (
        <Tooltip title={tooltipLabel || 'แก้ไข'} placement="bottom">
          <IconWrapper onClick={onClick}>
            <EditOutlined style={{fontSize: 20}} />
          </IconWrapper>
        </Tooltip>
      );
    case 'save':
      return (
        <Tooltip title={tooltipLabel || 'บันทึก'} placement="bottom">
          <IconWrapper onClick={onClick}>
            <SaveOutlined style={{fontSize: 20}} />
          </IconWrapper>
        </Tooltip>
      );
    case 'cancel':
      return (
        <Tooltip title={tooltipLabel || 'ยกเลิก'} placement="bottom">
          <IconWrapper onClick={onClick}>
            <CloseOutlined style={{fontSize: 20}} />
          </IconWrapper>
        </Tooltip>
      );
    default:
      return null;
  }
};

IconView.propTypes = {
  onClick: PropTypes.func.isRequired,
  tooltipLabel: PropTypes.string,
  name: PropTypes.oneOf(['delete', 'link', 'edit', 'save', 'cancel']).isRequired,
};

IconView.defaultProps = {
  tooltipLabel: '',
};
