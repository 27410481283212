import { Col, Modal, Row, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Icon } from '../../../resources/icon';
import { ContainerButton } from '../../../styles/styledComponentGlobal';

import { useForm } from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import { FORM_PRODUCT_DOWN_PAYMENT } from '../../../constants/global/actionform';
import { optionsRoundPayment } from '../../../screens/monitor/monitor-customer/hook/useGetOptions';
import {
  CALCULATE_AMOUNT_DOWN_PAYMENT,
  CALCULATE_MONTH_DOWN_PAYMENT,
  DOWN_PAYMENT_UPDATE_PRODUCT,
  GET,
  GET_DOWN_PAYMENT,
  GET_PRODUCT_COLOR_DOWNPAYMENT,
  GET_PRODUCT_MODEL_DOWNPAYMENT,
  GET_PRODUCT_STORAGE_DOWNPAYMENT,
  POST,
} from '../../../services/index';
import { ButtonTheme } from '../../buttons';

export const DownPaymentProductModal = ({ title, visible, onClose, data }) => {
  const [loading, setLoading] = useState(false);

  const [downpaymentNo, setDownpaymentNo] = useState('');
  const [optionsProducts, setOptionsProducts] = useState([]);
  const [optionsStorage, setOptionsStorage] = useState([]);
  const [optionsColor, setOptionsColor] = useState([]);
  const [optionsPeriodPayment, setOptionsPeriodPayment] = useState([]);
  const [model, setModel] = useState(null);
  const [storage, setStorage] = useState(null);
  const [color, setColor] = useState(null);
  const [round, setRound] = useState(null);
  const [periodNumber, setPeriodNumber] = useState(null);
  const [downPaymentCalc, setDownPaymentCalc] = useState(null);
  const [roundAmount, setRoundAmount] = useState(null);
  const [downPayment, setDownPayment] = useState(null);
  const [deposit, setDeposit] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const [downAmount, setDownAmount] = useState(null)

  const type = 'DOWNPAYMENT';
  const {
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
    setError,
  } = useForm();

  const { Text, Title, Paragraph } = Typography;

  const clearAll = () => {
    setModel('');
    setStorage('');
    setColor('');
    setRound('');
    setPeriodNumber('');
    setDownPaymentCalc('');
    setRoundAmount('');
    setDownPayment('');
    setDeposit('');
    setDownpaymentNo('');
    setOptionsProducts([]);
    setOptionsStorage([]);
    setOptionsColor([]);
    setOptionsPeriodPayment([]);
    setDisabled(false);
    setIsClose(true);
  };

  useEffect(async () => {
    if (JSON.stringify(data && data.record[0]) !== '{}' && data.record[0]) {
      const { downpayment_no, model, storage, color, period, id_card, down_payment_type } = data.record[0];
      const dataValue = Object.keys(data.record);
      dataValue.forEach((val) => setValue(val, data.record[val]));
      setDownpaymentNo(downpayment_no);
      setModel(model?.name);
      setStorage(storage?.name);
      setColor(color?.name);
      setIsClose(false);

      if (down_payment_type === 'รายเดือน') {
        setRound('MONTH');
        setValue('round_payment', 'MONTH');
      } else if (down_payment_type === 'รายสัปดาห์') {
        setRound('WEEK');
        setValue('round_payment', 'WEEK');
      } else {
        setRound(null);
      }

      setValue('model', model?.name);
      setValue('storage', storage?.name);
      setValue('color', color?.name);

      let periodValue = period.split(' ')[0] || null;
      setPeriodNumber(periodValue);
      setValue('period_payment', periodValue);

      await onFetchDownPayment({ id_card: id_card });
      await onFetchOptionsProducts(type);
      await onFetchOptionsStorage(type, model?.code);
      await onFetchOptionsColor(type, model?.code, storage?.code);
    }
  }, [data]);

  useEffect(async () => {
    if (downPayment) {
      const { model, storage, color } = downPayment.product;
      const { round_payment, round_amount, time_payment, paid_amount, amount } = downPayment.deposit[0];
      setDeposit(downPayment.deposit[0]);
      setRound(round_payment);
      setPeriodNumber(time_payment);

      await onFetchOptionsPeriodPayment({
        type: type,
        product: {
          model: model,
          storage: storage,
          color: color,
        },
        round_payment: round_payment,
      });

      setDownAmount(amount)
      setDownPaymentCalc(amount - paid_amount);
      setRoundAmount(round_amount);
      setDisabled(paid_amount < Number(round_amount) * (time_payment - 1) ? true : false);
    }
  }, [downPayment]);

  useEffect(() => {
    if (!(model && storage && color && round && periodNumber) && isClose) {
      onClose();
    }
  }, [model, storage, color, round, periodNumber, isClose]);
  const onCloseMain = () => {
    const dataValue = ['model', 'storage', 'color', 'round_payment', 'period_payment', 'disabledFiled'];
    dataValue.forEach((val) => setValue(val, ''));
    clearAll();
  };

  const onSubmit = async (params) => {
    setLoading(true);
    try {
      const payload = {
        product: {
          color: params.color,
          model: params.model,
          storage: params.storage,
        },
        // price_down: deposit.amount, //เงินดาวน์ทั้งหมด
        price_down: downAmount, //เงินดาวน์ทั้งหมด =>
        round_payment: params.round_payment, //รายปีหรือเดือน
        time_payment: params.period_payment, //จำนวนออม
        // round_amount: deposit.round_amount, //จำนวนต่อรอบ
        round_amount: roundAmount.toString(), //จำนวนต่อรอบ =>
        // remain_amount: deposit.remain_amount, //คงเหลือ
        remain_amount: downPaymentCalc, //คงเหลือ => 
        downpayment_no: downpaymentNo, // เลขที่ออมดาวน์
      };

      const res = await POST(DOWN_PAYMENT_UPDATE_PRODUCT, payload);
      const { message } = res;
      Modal.success({
        title: message,
        afterClose: () => {
          onCloseMain();
        },
      });
    } catch (error) {
      Modal.error({ title: error?.message });
    } finally {
      setLoading(false);
    }
  };

  const onConfirm = (params) => {
    Modal.confirm({
      title: 'ยืนยัน',
      icon: <Icon.warningAlert />,
      content: 'ยืนยันการแก้ไขสินค้า',
      onOk() {
        onSubmit(params);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const onFetchDownPayment = async (body) => {
    const { data } = await POST(GET_DOWN_PAYMENT, body);
    setDownPayment(data[0]);
  };

  const onFetchDataCalcPayment = async (body) => {
    const { data } = await POST(CALCULATE_AMOUNT_DOWN_PAYMENT, body);

    setDownAmount(data.downPayment)
    setDownPaymentCalc(data.downPayment - deposit.paid_amount);
    setRoundAmount(data.round_amount);
  };

  const onFetchOptionsPeriodPayment = async (body) => {
    const res = await POST(CALCULATE_MONTH_DOWN_PAYMENT, body);
    const period = res.MaxMonth.map((el) => {
      return {
        value: el,
        label: el.toString(),
      };
    });
    setOptionsPeriodPayment(period);
  };

  const onFetchOptionsProducts = async (type) => {
    const res = await GET(GET_PRODUCT_MODEL_DOWNPAYMENT(type));
    const model = res.model.map((el) => {
      return {
        value: el.label,
        label: el.label,
        image: el.image,
        product_master_code: el.product_master_code
      };
    });
    setOptionsProducts(model);
  };
  const onFetchOptionsStorage = async (type, model) => {
    let res = await GET(GET_PRODUCT_STORAGE_DOWNPAYMENT(type, model));
    const storage = res.storage.map((el) => {
      return {
        value: el.label,
        label: el.label,
        storage_code: el.storage_code
      };
    });
    setOptionsStorage(storage);
  };

  const onFetchOptionsColor = async (type, model, storage) => {
    let res = await GET(GET_PRODUCT_COLOR_DOWNPAYMENT(type, model, storage));
    const color = res.color.map((el) => {
      return {
        value: el.label,
        label: el.label,
        image: el.image,
        color_code: el?.color_code
      };
    });
    setOptionsColor(color);
  };

  const handleProductModelCode = (value) => {
    const filtered = optionsProducts.filter((el) => el.value === value)
    return filtered[0]?.product_master_code || ''
  }

  const handleProductStorageCode = (value) => {
    const filtered = optionsStorage.filter((el) => el.value === value)
    return filtered[0]?.storage_code || ''
  }

  const onChange = async (value, ref) => {
    switch (ref) {
      case 'model':
        const modelValue = handleProductModelCode(value)
        onFetchOptionsStorage(type, modelValue);
        setValue(ref, value);
        setOptionsColor([]);
        setValue('storage', null);
        setValue('color', null);
        setValue('round_payment', null);
        setValue('period_payment', null);

        setModel(getValues('model'));
        setStorage(getValues('storage'));
        setColor(getValues('color'));
        setRound(getValues('round_payment'));
        setPeriodNumber(getValues('period_payment'));
        setDownAmount('-')
        setRoundAmount('-')
        break;
      case 'storage':
        const modelValues = handleProductModelCode(getValues('model'))
        const storageValue = handleProductStorageCode(value)
        onFetchOptionsColor(type, modelValues, storageValue);
        setValue(ref, value);
        setValue('color', '');
        setValue('round_payment', '');
        setValue('period_payment', '');

        setModel(getValues('model'));
        setStorage(getValues('storage'));
        setColor(getValues('color'));
        setRound(getValues('round_payment'));
        setRoundAmount('-');
        setDownAmount('-')
        setPeriodNumber(getValues('period_payment'));
        break;
      case 'color':
        // onFetchDataCalcPayment({
        //   product: {
        //     color: value,
        //     model: getValues('model'),
        //     storage: getValues('storage'),
        //   },
        //   type: type,
        //   round_payment: getValues('round_payment') || 'WEEK',
        //   max_month: getValues('period_payment') || 1,
        // });

        setValue(ref, value);
        setValue('round_payment', '');
        setValue('period_payment', '');

        setModel(getValues('model'));
        setStorage(getValues('storage'));
        // setColor(getValues('color'));
        setColor(value);
        setRound(getValues('round_payment'));
        setPeriodNumber(getValues('period_payment'));
        break;
      case 'round_payment':
        // onFetchDataCalcPayment({
        //   product: {
        //     color: getValues('color'),
        //     model: getValues('model'),
        //     storage: getValues('storage'),
        //   },
        //   type: type,
        //   round_payment: value,
        //   max_month: Number(getValues('period_payment')),
        // });

        onFetchOptionsPeriodPayment({
          type: type,
          product: {
            color: getValues('color'),
            model: getValues('model'),
            storage: getValues('storage'),
          },
          round_payment: value
        });

        setValue(ref, value);
        setValue('period_payment', '');

        setModel(getValues('model'));
        setStorage(getValues('storage'));
        setColor(getValues('color'));
        setRound(getValues('round_payment'));
        setPeriodNumber(getValues('period_payment'));
        break;
      case 'period_payment':
        setValue(ref, value);
        onFetchDataCalcPayment({
          product: {
            color: getValues('color'),
            model: getValues('model'),
            storage: getValues('storage'),
          },
          type: type,
          round_payment: getValues('round_payment'),
          max_month: Number(value),
        });

        setModel(getValues('model'));
        setStorage(getValues('storage'));
        setColor(getValues('color'));
        setRound(getValues('round_payment'));
        setPeriodNumber(getValues('period_payment'));
        break;

      default:
        break;
    }
  };

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('th-TH').format(amount);
  };

  return (
    <>
      <Modal
        title={title || 'modal'}
        open={visible}
        width={500}
        onCancel={onCloseMain}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
        style={{ border: 'unset' }}>
        <form onSubmit={handleSubmit(onConfirm)}>
          <RenderForm
            control={control}
            errors={errors}
            clearErrors={clearErrors}
            formList={FORM_PRODUCT_DOWN_PAYMENT({
              product: {
                model: model,
                storage: storage,
                color: color,
                round_payment: round,
                period_payment: periodNumber,
                disabledFiled: disabled,
              },
              options: { optionsColor, optionsStorage, optionsProducts, optionsPeriodPayment, optionsRoundPayment },
              fn: { onChange },
            })}
            setValue={setValue}
            getValues={getValues}
          />
          <Row style={{ alignItems: 'left', display: 'flex', justifyContent: 'left', marginBottom: 10, paddingInline: 20 }}>
            <Spin spinning={loading} tip={'กำลังดึงข้อมูล'}></Spin>
          </Row>
          <Row style={{ alignItems: 'left', display: 'flex', justifyContent: 'left', marginBottom: 10, paddingInline: 20 }}>
            <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} style={{ display: 'flex', justifyContent: 'left' }}>
              {/* <Text strong>เงินดาวน์</Text>:&thinsp;{deposit && deposit?.amount === '' ? 0 : formatAmount(deposit?.amount)} บาท */}
              <Text strong>เงินดาวน์</Text>:&thinsp;{downAmount === null ? 0 : downAmount === '-' ? '-' : formatAmount(downAmount)} บาท
            </Col>
          </Row>
          <Row style={{ alignItems: 'left', display: 'flex', justifyContent: 'left', marginBottom: 10, paddingInline: 20 }}>
            <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} style={{ display: 'flex', justifyContent: 'left' }}>
              <Text strong>ยอดออมต่องวด</Text>:&thinsp;{roundAmount && roundAmount === null ? 0 : roundAmount === '-' ? '-' : formatAmount(roundAmount)} บาท
            </Col>
          </Row>
          <Row style={{ alignItems: 'left', display: 'flex', justifyContent: 'left', marginBottom: 10, paddingInline: 20 }}>
            <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} style={{ display: 'flex', justifyContent: 'left' }}>
              <Text strong>ชำระแล้ว</Text>:&thinsp;{deposit && deposit?.paid_amount === '' ? 0 : formatAmount(deposit?.paid_amount)} บาท
            </Col>
          </Row>
          <Row style={{ alignItems: 'left', display: 'flex', justifyContent: 'left', marginBottom: 10, paddingInline: 20 }}>
            <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} style={{ display: 'flex', justifyContent: 'left' }}>
              <Text strong>ยอดออมคงเหลือที่ต้องออม</Text>&thinsp;:{deposit && deposit?.paid_amount === '' ? 0 : formatAmount(downPaymentCalc)} บาท
            </Col>
          </Row>
          <ContainerButton right>
            <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" loading={loading} />
            <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
          </ContainerButton>
        </form>
      </Modal>
    </>
  );
};
