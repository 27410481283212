/* eslint-disable no-unused-vars */
import React, {useEffect} from 'react';
import Wrapper from '../../views/wrapper/Wrapper';

const Dashboard = () => {
  return (
    <>
      <Wrapper>
        <h1>Dashboard</h1>
      </Wrapper>
    </>
  );
};

export default Dashboard;
