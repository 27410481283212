import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../buttons';
import { RecheckRefinanceForm } from '../../../constants/global/actionform';
import { GET, POST, APPROVE_CONTACT_ONLINE, GET_LOOKUP_REFINANCE, GET_CONFIRM_REFINANCE, CANCEL_LOOKUP } from '../../../services/index';
import { socketContext } from '../../../utils/socketProvider';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { auth } from '../../../utils/firebase';
import { useAuthState } from '../../../utils/hook';
import { convertStrToFormat } from '../../../functions/fn';
import { useLocation } from 'react-router-dom';
import { APP_URL } from '../../../env'
import { Spinners } from '../../../components';
import { useUpdateTagChatconeByProcess } from '../../../hook/chatcone';

export const RecheckRefinanceModal = ({ title, visible, onClose, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
  } = useForm();

  const { user } = useAuthState(auth);
  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState({});
  const [requiredRemark, setRequiredRemark] = useState(false);
  const [statusReject, setStatusReject] = useState(false);

  const { pathname } = useLocation()
  const [updateTagChatconeByProcessFn, updateTagChatconeByProcessLoading] = useUpdateTagChatconeByProcess()

  useEffect(() => {
    onSetValue();
    setRecord(data.record);
  }, [data.record]);

  const onSetValue = () => {
    for (var key in data.record) {
      setValue(key, data.record[key]);
    }
  };

  const onCloseMain = () => {
    setStatusReject(false);
    clearErrors();
    onClose();
  };

  const onConfirm = (params) => {
    if (statusReject) {
      onConfirmRefinance(params, '');
    } else {
      // Modal.confirm({
      //   title: 'ยืนยันการทำรายการ',
      //   icon: <ExclamationCircleOutlined />,
      //   content: '',
      //   onOk() {
      onSubmit(params);
      //   },
      //   okText: 'ยืนยัน',
      //   cancelText: 'ยกเลิก',
      // });
    }
  };

  const onSubmit = async (params) => {
    setLoading(true);
    const obj = {
      contractNo: params.contract_no,
      // caseFailed:false
    };

    try {
      let res = await POST(GET_LOOKUP_REFINANCE, obj);
      const { success, data } = res;
      if (success) {
        let content = (
          <p>
            {`ชื่อบัญชี: ${data.result.receivingAccountName}`}
            <br />
            {`เลขที่บัญชี: ${data.result.receivingAccountNumber}`}
            <br />
            {`จำนวนเงิน: ${convertStrToFormat(data.result.lease_amount, 'money')}`}
            <br />
            <span style={{ color: '#dc3545' }}>{`หลังจากกดยืนยันแล้วจะทำการโอนเงินทันที`}</span>
          </p>
        );
        Modal.confirm({
          title: 'ยืนยันการโอนเงิน',
          icon: <ExclamationCircleOutlined />,
          content: content,
          onOk() {
            onConfirmRefinance(params, data.result.txnReferenceNo);
          },
          okText: 'ยืนยัน',
          onCancel() {
            onCancelLookUp(data.result?.txnReferenceNo);
          },
          cancelText: 'ยกเลิก'
        });
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onConfirmRefinance = async (params, ref) => {
    setLoading(true);
    try {
      const { contract_no, remark , user} = params;
      const obj = {
        contractNo: contract_no,
        statusApprove: statusReject ? 'N' : 'Y',
        remark: remark,
        userLogin: user.email,
        txnReferenceNo: ref,
        // caseFailed: true
      };

      let res = await POST(GET_CONFIRM_REFINANCE, obj);
      const { success, message } = res;
      if (success) {
        if(!statusReject && user?.chatcone?.social_id) {
          /* CHATCONE: 6.1 สร้างสัญญาสำเร็จ (E-contract) REFINANCE */
          const setInput = {
            chatcone: user?.chatcone,
            refId: contract_no,
            processType: 'COMPLETE_CONTRACT',
            routeFrom: `${APP_URL}${pathname}`,
          }
          
          await updateTagChatconeByProcessFn({...setInput});
        }

        Modal.success({
          title: statusReject ? 'ไม่อนุมัติรายการสำเร็จ' : message,
          afterClose: () => {
            onCloseMain();
            data.reApi({});
          },
        });
      }

    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onCancelLookUp = async (ref) => {
    setLoading(true);
    try {
      const obj = {
        txnReferenceNo: ref,
      };
      let res = await POST(CANCEL_LOOKUP, obj);
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            onCloseMain();
            data.reApi({});
          },
        });
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message || err || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const onChangeCustom = (value, ref) => {
    setValue(ref, value);
    if (value === 'N') {
      setRequiredRemark(true);
    } else {
      setRequiredRemark(false);
    }
  };

  return (
    <Modal title={title || 'modal'} open={visible} onCancel={onCloseMain} width={860} footer={null} destroyOnClose={true} maskClosable={false}>
      {(updateTagChatconeByProcessLoading) ? <Spinners /> : null}
      <form onSubmit={handleSubmit(onConfirm)}>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          formList={RecheckRefinanceForm({ data: record, requiredRemark, onChangeCustom })}
          renderButton={
            <>
              <ContainerButton right>
                <ButtonTheme useFor="CONFIRM" title="อนุมัติจ่ายเงิน" htmlType="submit" loading={loading}/>
                <ButtonTheme useFor="REJECT" title="ไม่อนุมัติ" htmlType="submit" onClick={() => setStatusReject(true)} />
              </ContainerButton>
            </>
          }
        />
      </form>
    </Modal>
  );
};
