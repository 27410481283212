import dayjs from 'dayjs';
export const calAppointMentDiffTime = (date1, date2) => {
  if (!dayjs(date1).isValid() || !dayjs(date2).isValid()) {
    return;
  }
  
  const timeScript = 30;
  const time1 = dayjs(date1);
  const time2 = dayjs(date2);

  const differenceInHours = time2.diff(time1, 'hour');
  const differenceInMinutes = time2.diff(time1, 'minute');
  const modMinutes = differenceInMinutes % 60;

  if (modMinutes <= timeScript && !differenceInHours) {
    /* กรณี น้อยกว่าเท่ากับ 30 นาที ให้เป็น default 1 ชั่วโมง */
    return {hours: 1, minutes: 0};
  }

  if (modMinutes > timeScript) {
    /* กรณี นาทีเลยมา มากกว่า 30 นาที ให้เป็น + 1 ชั่วโมง */
    return {hours: differenceInHours + 1, minutes: 0};
  }

  if (modMinutes > 0 && modMinutes <= timeScript) {
    /* กรณี นาทีเลยมา แต่ไม่มากกว่า 30 นาที ให้แสดง นาที 30 */
    return {hours: differenceInHours, minutes: timeScript};
  }

  return {hours: differenceInHours, minutes: 0};
};
