// import { useState } from 'react';
import Wrapper from '../../views/wrapper/Wrapper';
import { Joi, AutoAdmin } from 'joi-auto-admin';
import { notification } from '../../utils/auto-admin/schema';
// import { Modal as ModalAnt } from 'antd';
import { getNotificationList } from '../../utils/auto-admin/api';
// import Loading from '../../components/loading/Loading';
// import Modal from '../../components/modal';

const Notification = () => {
    // const [loading, setLoading] = useState(false);

    // const onClick = async (rowData) => {
    //     setLoading(true);
    //     await resendNotification([rowData._id]);
    //     setLoading(false);
    //     ModalAnt.success({
    //         content: (
    //             <div>แจ้งเตือนเรียบร้อย</div>
    //         )
    //     });
    // };

    const props = {
        title: 'รายการแจ้งเตือน',
        getMany: getNotificationList,
        schema: notification,
        rowButtons: [
            // { label: 'แจ้งเตือนอีกครั้ง', onClick },
        ],
        disableExcelDownload: true,
        disableExcelUpload: true,
        querySchema: Joi.object({
            id_card: Joi.string().min(3).label('บัตรปชช'),
            name: Joi.string().min(3).label('ชื่อลูกค้า'),
            tel: Joi.string().min(3).label('เบอร์โทรศัพท์'),
            channel: Joi.string().label('ช่องทาง').valid("APP", "SMS").meta({ validLabel: ["APP", "SMS"], allowClear: true }),
            status: Joi.string().label('สถานะ').valid("PENDING", "SENT", "ERROR").meta({ validLabel: ["รอส่ง", "แจ้งเตือนแล้ว", "พบข้อผิดพลาด"], allowClear: true }),
            system_only: Joi.string().label('ผู้แจ้ง').valid("true", "false").meta({ validLabel: ["เฉพาะระบบ", "ทั้งหมด"], allowClear: true }),
        }),
    };

    return (
        <Wrapper>
            <center style={{ maxWidth: 1440, margin: 'auto' }}>
                <AutoAdmin {...props} />
                {/* <Modal open={loading} noButton heightAuto style={{ backgroundColor: 'unset', boxShadow: 'unset' }}>
                    <Loading />
                </Modal> */}
            </center>
        </Wrapper>
    );
};

export default Notification;