
import React, { useState, useEffect } from 'react';
import { Modal, Table } from 'antd';
import { useFormikContext } from 'formik';
import { PackageDetailColumn } from '../../constants/global/columnTableForm'
import { GET, GET_PACKAGE_DETAIL_BY_ID } from '../../services'
import _ from 'lodash';


const ModalPackageDetail = (props) => {


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [title, setTitle] = useState('');
  const ctx = useFormikContext();
  const pacId = _.get(ctx.values, 'contract.contract.package');
  const price_package = _.get(ctx.values, 'contract.contract.price_package');
  const branch = _.get(ctx.values, 'contract.branch');


  useEffect(() => {
    getpackageDetail(pacId, branch)
  }, [pacId, price_package, branch])


  const showModal = () => {
    setIsModalVisible(true);
  };
  const getpackageDetail = async (id, branch) => {
    try {
      const res = await GET(GET_PACKAGE_DETAIL_BY_ID(id, branch))
      const { result, success } = res
      if (success) {
        let price = 0
        result.detail.map((el) => {
          price = price + (el.cost * el.qty)
        })
        ctx.setFieldValue('contract.contract.price_package', price);
        setDataSource(result.detail)
        setTitle(result.detail[0].package_name)

      }

    } catch (err) {
      Modal.error({
        title: err.message
      })
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div>  <p className='text-link' onClick={showModal} >{pacId && "คลิกดูรายละเอียด"}</p></div>
      <Modal title={`${title}`} open={isModalVisible} onCancel={handleCancel} width={720} footer={null}>
        <Table dataSource={dataSource} columns={PackageDetailColumn()} />;
      </Modal>
    </>
  );
};
export default ModalPackageDetail;