import { useEffect, useState } from 'react';
import lodashGet from 'lodash/get';
import { Typography, Skeleton, Divider } from 'antd';
import { SmileOutlined, FrownOutlined } from '@ant-design/icons';
import { Grid } from '@material-ui/core';
import { Div, Background, InfoWrapper, NotVisibleDataWrapper } from './styled-component';
import { useContract } from '../../hook/useContract';
import Loading from '../../components/loading/Loading';
import Modal from '../../components/modal';
import { auth } from '../../utils/firebase';
import { useAuthState } from '../../utils/hook';
import { useReceiptPaymentState, useReceiptPaymentDispatch } from './hooks/useReceiptPayment';
import Wrapper from '../../views/wrapper/Wrapper';
import PayInfoView from './views/pay-info-view';
import PayCalView from './views/pay-cal-view';
import TableView from './views/table-view';
import FilterView from './views/filter-view';
import ContractStatusView from './views/contract-status-view';
import OtherPaymentTable from './views/other-payment-table';
import { ContainerButton } from '../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../components/buttons';
import { OtherFeeModal } from './modal/OtherFeeModal';
import * as logic from './views/logic';

const Payment = () => {
  const [visibleOtherFee, setVisibleOtherFee] = useState(false);
  const [otherPaymentData, setOtherPaymentData] = useState([]);
  const { dispatchGetContracts, dispatchClearContractPeriods, loadingContractList, contractList } = useContract();
  const { receiptFilterState, receiptSummary, receiptPayInfo, receiptCheckRedeemInfo } = useReceiptPaymentState();
  const { dispatchGetSummaryInfo, dispatchClearSummaryInfo, dispatchGetSummaryAmount, dispatchClearPaidParams, dispatchClearAmountContract, dispatchClearGetCouponDetail } =
    useReceiptPaymentDispatch();
  const { user } = useAuthState(auth);

  const onRequestInitCalPaidValue = () => {
    const isNotFetch = receiptSummary?.summary_amount?.loading || receiptSummary?.summary_amount?.error;
    if (isNotFetch) {
      return;
    }
    const params = {
      transaction_date: logic.getDateQueryFormat(),
      paid_amount: 0,
    };
    dispatchClearPaidParams();
    dispatchGetSummaryAmount(receiptFilterState?.contractNo, params, true);
  };

  useEffect(() => {
    // dispatchGetContracts('');
    dispatchClearContractPeriods()
  }, []);


  useEffect(() => {
    if (receiptSummary?.summary_info?.otherPayment) {
      setOtherPaymentData(receiptSummary?.summary_info?.otherPayment || [])
    } else {
      setOtherPaymentData([])
    }
  }, [receiptSummary?.summary_info?.otherPayment])


  useEffect(() => {
    if (receiptFilterState?.contractNo) {
      const params = {
        transaction_date: logic.getCurrentDate(true),
      };
      dispatchGetSummaryInfo(receiptFilterState.contractNo, params);

      onRequestInitCalPaidValue();
    } else {
      dispatchClearSummaryInfo();
      dispatchClearPaidParams();
    }
    dispatchClearAmountContract();
    dispatchClearGetCouponDetail()
  }, [receiptFilterState?.contractNo]);

  const onGetData = () => {
    const params = {
      transaction_date: logic.getCurrentDate(true),
    };
    dispatchGetSummaryInfo(receiptFilterState.contractNo, params)
    onRequestInitCalPaidValue();
  }

  return (
    <Wrapper>
      <Modal
        open={receiptSummary?.loading_info_update || receiptPayInfo?.loading || receiptCheckRedeemInfo.loading || false}
        noButton
        heightAuto
        style={{ backgroundColor: 'unset', boxShadow: 'unset' }}>
        <Loading />
      </Modal>
      <Typography.Title level={3}>จ่ายค่างวด</Typography.Title>
      <Div>
        <Grid container item>
          <Background>
            <FilterView />
            <InfoWrapper>
              {(loadingContractList || lodashGet(receiptSummary, 'loading_info', false)) && <Skeleton active />}
              {!loadingContractList && lodashGet(receiptFilterState, 'contractNo') ? (
                <>
                  {!lodashGet(receiptSummary, 'loading_info', false) && (
                    <>
                      <PayInfoView />
                      <TableView user={user} />
                      <Divider />
                      {receiptSummary?.summary_info?.contract?.status === 'ACTIVE' && <ContainerButton right>
                        <ButtonTheme useFor="CREATE" title={'เพิ่มรายการค่าใช้จ่ายอื่นๆ'} onClick={() => setVisibleOtherFee(true)} />
                      </ContainerButton>}
                      <OtherPaymentTable data={otherPaymentData} reApi={onGetData} />
                      {/* <OtherPaymentTable data={receiptSummary?.summary_info?.otherPayment || []} reApi={onGetData} /> */}
                      {lodashGet(receiptSummary, 'summary_info.contract.status', null) === logic.enumContractStatus.active ? (
                        <PayCalView user={user} />
                      ) : (
                        <ContractStatusView status={lodashGet(receiptSummary, 'summary_info.contract.status', null)} />
                      )}
                    </>
                  )}
                </>
              ) : (
                <NotVisibleDataWrapper>
                  {!loadingContractList && lodashGet(contractList, '[0]') && !lodashGet(receiptFilterState, 'contractNo') && (
                    <>
                      <SmileOutlined style={{ fontSize: 40, marginBottom: '1rem', color: 'gray' }} />
                      <Typography.Title level={4}>กรุณาเลือกสัญญา</Typography.Title>
                    </>
                  )}
                  {!loadingContractList && !lodashGet(contractList, '[0]') && (
                    <>
                      <FrownOutlined style={{ fontSize: 40, marginBottom: '1rem', color: 'gray' }} />
                      <Typography.Title level={4}>ไม่พบข้อมูล</Typography.Title>
                    </>
                  )}
                </NotVisibleDataWrapper>
              )}
            </InfoWrapper>
          </Background>
        </Grid>
      </Div>
      {visibleOtherFee && (
        <OtherFeeModal
          visible={visibleOtherFee}
          onClose={() => setVisibleOtherFee(false)}
          title={'รายการค่าใช้จ่ายอื่นๆ'}
          data={{ contract_no: receiptFilterState?.contractNo }}
          reApi={() => onGetData()}
        />
      )}
    </Wrapper>
  );
};

export default Payment;
