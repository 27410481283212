// /* eslint no-use-before-define: 0 */
import React, {useState, useEffect, useRef, useMemo} from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import {Modal, Card, Spin} from 'antd';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../../components/buttons';
import {FileOutlined} from '@ant-design/icons';
import {PageLayout} from '../../../components/pageLayout/PageLayout';
import {GET, GET_RESERVE_REPORT, GET_RESERVE_REPORT_EXPORT, GET_OPTION_BRANCH, POST} from '../../../services/index';
import {CSVLink} from 'react-csv';
import moment from 'moment';
import {convertStrToFormat} from '../../../functions/fn';
import {ReserveProductStockReportColumn} from '../column';
import {ReserveReportStockSearchForm} from '../form';
import {optionProductHandPick} from '../utils/constants';

const ReserveProductStockReportScreen = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [optionBranch, setOptionBranch] = useState([]);

  const [branchState, setBranchState] = useState(null);
  const [productNameState, setProductNameState] = useState(null);
  const [nameState, setNameState] = useState(null);
  const [adminNameState, setAdminNameState] = useState(null);
  const [createDateStart, setCreateDateStart] = useState(null);
  const [createDateEnd, setCreateDateEnd] = useState(null);
  const [appointmentDateStartState, setAppointmentDateStart] = useState(null);
  const [appointmentDateEndState, setAppointmentDateEnd] = useState(null);

  const pageRef = useRef(1);
  const sizeRef = useRef(10);
  const [totalSize, setTotalSize] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [visibleModal, setVisibleModal] = useState(false);

  const [filter, setFilter] = useState({
    branch: '',
    item_name: '',
    user_name: '',
    admin_name: '',
    create_date_start: '',
    create_date_end: '',
    appointment_date_start: '',
    appointment_date_end: '',
    product_hand: '',
    user_remark: '',
  });

  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
  });

  const filterMemo = useMemo(() => {
    const data = {...filter};
    data.page = data?.page || 1;
    data.size = data?.size || 10;

    pageRef.current = data.page;
    sizeRef.current = data.size;

    data.branch = data?.branch || '';
    data.item_name = data?.item_name || '';
    data.user_name = data?.user_name || '';
    data.admin_name = data?.admin_name || '';
    data.create_date_start = data.create_date_start ? moment(data.create_date_start).format('YYYY-MM-DD') : '';
    data.create_date_end = data.create_date_end ? moment(data.create_date_end).format('YYYY-MM-DD') : '';
    data.appointment_date_start = data.appointment_date_start ? moment(data.appointment_date_start).format('YYYY-MM-DD') : '';
    data.appointment_date_end = data.appointment_date_end ? moment(data.appointment_date_end).format('YYYY-MM-DD') : '';
    data.product_hand = data?.product_hand || '';
    data.user_remark = data?.user_remark || '';

    setPagination({
      ...pagination,
      page: 1,
    });
    return data;
  }, [filter]);
  const paginationMemo = useMemo(() => {
    const data = {...pagination};

    data.page = data.page || 1;
    data.size = data.size || 10;
    return data;
  }, [pagination]);

  useEffect(() => {
    getOptionBranch();
  }, []);

  useEffect(() => {
    getReserveProductStockReport(filterMemo, paginationMemo);
  }, [filterMemo, paginationMemo]);

  const actionFilter = (data) => {
    setFilter(data);
  };

  const getOptionBranch = async () => {
    try {
      const res = await GET(GET_OPTION_BRANCH);
      const {success, result} = res;
      if (success) {
        const branchFilter = result.filter((el) => el.value !== 'IT' && el.value !== 'QC' && el.value !== 'Service' && el.value !== 'Store');

        setOptionBranch(branchFilter);
      }
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
    }
  };

  const getReserveProductStockReport = async (filterd, paginate) => {
    setLoading(true);

    try {
      let obj = {
        ...filterd,
        ...paginate,
      };

      const res = await POST(GET_RESERVE_REPORT, obj);
      const {success, data, totalItem, totalPage} = res;
      if (success) {
        setTotalPage(totalPage);
        setTotalSize(totalItem);

        const result = data.map((el, i) => {
          return {
            rowNo: i + 1,
            ...el,
            // userCreate: el.user_create?.name,
          };
        });

        setDataSource(result);
      }
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const convertBranchName = (value) => {
    let branch_name = optionBranch.filter((el) => el.value === value);
    return branch_name[0].label;
  };

  const fetchDataForExport = async () => {
    setLoading(true);

    const {page, size, ...obj} = filterMemo;

    try {
      const res = await POST(GET_RESERVE_REPORT_EXPORT, obj);
      const {success, data} = res;

      if (success) {
        const result = data.map((el, i) => {
          return {
            วันที่นัดทำสัญญา: el.appointment_date !== '' ? moment(el.appointment_date).format('DD/MM/YYYY') : '',
            เวลา: el.appointment_date !== '' ? moment(el.appointment_date).format('HH:mm') : '',
            'ชื่อ - นามสกุล': el.user_name || '',
            ชื่อสินค้า: el.item_name || '',
            ลักษณะสินค้า: el?.product_hand || '',
            ผู้นัดทำสัญญา: el.admin_name || '',
            วันที่กดนัดทำสัญญา: el.create_date !== '' ? moment(el.create_date).format('DD/MM/YYYY HH:mm') : '',
            สาขา: (el.branch && convertBranchName(el.branch)) || '',
            'หมายเหตุ(สำหรับลูกค้า)': el.user_remark || '',
            'หมายเหตุ(สำหรับภายในทีม)': el.admin_remark || '',
          };
        });
        setDataExport(result);
      }
    } catch (err) {
      const {message, error} = err;

      Modal.error({
        title: message || '',
        content: error || '',
      });
    } finally {
      setLoading(false);
      setVisibleModal(true);
    }
  };

  const onClose = () => {
    setVisibleModal(false);
  };

  const onClearCustom = (setValue, formSearch) => {
    formSearch.map((val) => {
      return setValue(val.name, null);
    });

    pageRef.current = 1;
    sizeRef.current = 10;
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'รายงานการจองสินค้าในระบบ',
              icon: <FileOutlined />,
              spanSearch: 24,
              formJustify: 'left',
              formSearch: ReserveReportStockSearchForm({options: {optionBranch, optionProductHandPick}}),
              onSearch: (data) => actionFilter(data),
              onClearCustom,
            }}
            tableLayout={{
              columns: ReserveProductStockReportColumn({options: {optionBranch}}),
              dataSource: dataSource,
              extraContent: (
                <ContainerButton right>
                  <ButtonTheme useFor="DOWNLOAD" title={'EXPORT'} onClick={() => fetchDataForExport()} />
                  {/* <CSVLink data={dataExport} filename={"รายงานปรับปรุงสต็อก"}><ButtonTheme useFor="DOWNLOAD" title={'EXPORT'} /></CSVLink> */}
                </ContainerButton>
              ),
              pagination: {
                showSizeChanger: true,
                current: pagination.page,
                pageSize: pagination.size,
                onChange: (p, s) => setPagination({page: p, size: s}),
                // onChange: (p, s) => onPageSizeChangeCustom(p, s),
                pageSizeOptions: ['10', '20', '50', '100', '200', '300'],
                total: totalSize || 0,
                showTotal: (totalSize, range) => `${convertStrToFormat(totalSize, 'number-comma')} รายการ`,
                defaultPageSize: pagination.size,
                defaultCurrent: 1,
              },
            }}
          />
        </Spin>
      </Card>
      <Modal
        title={'ต้องการดาวน์โหลด Excel?'}
        open={visibleModal}
        width={550}
        onCancel={onClose}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}>
        <ContainerButton right>
          <CSVLink data={dataExport} filename={'รายงานการจองสินค้าในระบบ'}>
            <ButtonTheme useFor="CUSTOM" title={'ตกลง'} onClick={() => onClose()} />
          </CSVLink>
          <ButtonTheme useFor="CANCEL" onClick={() => onClose()} />
        </ContainerButton>
      </Modal>
    </Wrapper>
  );
};

export default ReserveProductStockReportScreen;
