// /* eslint no-use-before-define: 0 */
import React, { useState, useEffect } from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import { Modal, Card, Spin, Row, Space } from 'antd';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../../components/buttons';
import { AuditOutlined } from '@ant-design/icons';
import { PageLayout } from '../../../components/pageLayout/PageLayout';
import { ReportCountStockSearchForm } from '../../../constants/global/SearchForm';
import { ReportCountStockColumn } from '../../../constants/global/columnTableForm';
import { PUT, GET_REPORT_COUNT_STOCK, GET, GET_BRANCH } from '../../../services/index';
import { CSVLink } from "react-csv";
import { auth } from '../../../utils/firebase';
import { useCurrentState } from '../../../utils/hook';
import { buildArrayOfObjectWithNoDulplicateKey, convertStrToFormat } from '../../../functions/fn';
import { color } from '../../../resources/color';
import moment from 'moment/moment';


const CountStockReport = () => {

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataExport, setDataExport] = useState([])
  const [optionBranch, setOptionBranch] = useState([])
  const { user } = useCurrentState(auth);

  let optionDocNo = []
  let optionUserCreate = []

  useEffect(() => {
    getOption()
    getOptionBranch()
    getReportCountStock({ startDateCheckStock: '', endDateCheckStock: '', checkStockDocumentNo: '', userCreateCheckStock: '' })
  }, []);

  const getOption = async () => {
    try {
      const res = await PUT(GET_REPORT_COUNT_STOCK, { startDateCheckStock: '', endDateCheckStock: '', documentNo: '', userCreate: '' });

      const { success, data } = res;
      if (success) {

        buildArrayOfObjectWithNoDulplicateKey('documentNo', data).map((val, i) => {
          optionDocNo.push({
            value: val.documentNo,
            label: val.documentNo
          })
        });

        buildArrayOfObjectWithNoDulplicateKey('userCreate', data).map((val, i) => {
          optionUserCreate.push({
            value: val.userCreate,
            label: val.userCreate
          })
        });

        return optionDocNo, optionUserCreate
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  }

  const getOptionBranch = async () => {
    try {
      const res = await GET(GET_BRANCH);
      const { success, result } = res;
      if (success) {

        const option = result.branch.map((el) => {
          return {
            label: el.label,
            value: el.value
          }
        })

        setOptionBranch(option)
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  const getReportCountStock = async (value) => {

    let prepareStartDate = value?.startDateCheckStock || '';
    if (prepareStartDate !== '') {
      prepareStartDate = convertStrToFormat(prepareStartDate, 'dateISO-set-time-0')
    }

    let prepareEndDate = value?.endDateCheckStock || '';
    if (prepareEndDate !== '') {
      prepareEndDate = convertStrToFormat(prepareEndDate, 'dateISO-set-time-0')
    }

    value.documentNo = value?.documentNo || '';
    value.userCreate = value?.userCreate || '';

    const { startDateCheckStock, endDateCheckStock, documentNo, userCreate } = value

    setLoading(true);
    try {
      const res = await PUT(GET_REPORT_COUNT_STOCK, { startDateCheckStock: prepareStartDate, endDateCheckStock: prepareEndDate, documentNo: documentNo, userCreate: userCreate });

      const { success, data } = res;
      if (success) {
        const resultData = data.map((val, i) => {
          return {
            key: i + 1,
            ...val,
          };
        });

        const dataEx = data.map((el, i) => {
          return {
            'สาขา': el.branch,
            'เลขที่เอกสาร': el.documentNo,
            'ผู้สร้างเอกสาร': el.userCreate,
            'วันที่สร้าง': moment.utc(el.createDate).utcOffset(0, true).format('YYYY-MM-DD HH:mm') || '',
          };
        });

        setDataSource(resultData);
        setDataExport(dataEx)
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'รายงานนับสต็อก',
              icon: <AuditOutlined />,
              spanSearch: 24,
              formSearch: ReportCountStockSearchForm({
                option: { optionDocNo, optionUserCreate }
              }),
              onSearch: (data) => getReportCountStock(data),
            }}
            tableLayout={{
              columns: ReportCountStockColumn({ option: { optionBranch } }),
              dataSource: dataSource,
              extraContent: (
                <ContainerButton right>
                  <CSVLink data={dataExport} filename={"รายงานนับสต็อก"}><ButtonTheme useFor="DOWNLOAD" title={'EXPORT'} /></CSVLink>
                </ContainerButton>
              ),
            }}
          />
        </Spin>
      </Card>
    </Wrapper>
  );
};

export default CountStockReport;
