/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableRow,
  TablePagination,
  TableFooter,
  TableContainer,
  TableCell,
  TableHead,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
} from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Wrapper from '../../views/wrapper/Wrapper';
import { useTransaction } from '../../hook/useTransaction';
import { Div, Background, Button } from './styled-component';
import Loading from '../../components/loading/Loading';
import Modal from '../../components/modal';
import TransactionItem from './TransactionItem';
import { auth } from '../../utils/firebase';
import { useAuthState } from '../../utils/hook';

const useStyles = makeStyles({
  select: {
    '& #filter-status:focus, & #transaction-status:focus': {
      background: 'none',
    },
  },
  icon_btn: {
    cursor: 'pointer',
  },
  pagination_btn: {
    '& button:focus': {
      outline: 0,
    },
  },
  input_label: {
    fontSize: '8px',
  },
});

const createParams = (userIdCardParam, nameParam, telParam, pageParam, sizeParam, startDate, createdDate, statusUpdateTradesys) => {
  let params = `?size=${sizeParam}&page=${pageParam}`;
  if (userIdCardParam !== null && userIdCardParam !== '') {
    params += `&user_id_card=${userIdCardParam}`;
  }
  if (nameParam !== null && nameParam !== '') {
    params += `&name=${nameParam}`;
  }
  if (telParam !== null && telParam !== '') {
    params += `&tel=${telParam}`;
  }
  if (statusUpdateTradesys) {
    params += `&status_update_tradesys=${statusUpdateTradesys}`;
  }
  // if (startDate) {
  //   params += `&start_date=${startDate.format("YYYY-MM-DD")}`;
  // }
  // if (createdDate) {
  //   params += `&created_date=${createdDate.format("YYYY-MM-DD")}`;
  // }
  return params;
};

const COLUMNS = 9;

const translateStatus = (status) => {
  switch (status) {
    case 'CONFIRMED':
      return 'ยืนยันแล้ว';
    case 'CANCELED':
      return 'ยกเลิกแล้ว';
    case 'PENDING':
      return 'รอยืนยัน';
    case 'SUCCESS':
      return 'อัพเดทแล้ว';
    case 'AMOUNT_ERROR':
      return 'ยอดเงินไม่ถูกต้อง';
    case 'ERROR':
      return 'ผิดพลาด';
    case 'REVIEWED':
      return 'ตรวจสอบแล้ว';
    default:
      return 'ทั้งหมด';
  }
};

const Transactions = () => {
  const { defaultPage, defaultSize, error, loading, transactions, dispatchGet, totalItem } = useTransaction();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [statusUpdateTradesys, setStatusUpdateTradesys] = useState('AMOUNT_ERROR');
  const [userIdCard, setUserIdCard] = useState('');
  const [name, setName] = useState('');
  const [tel, setTel] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [urlSlip, setUrlSlip] = useState(null);
  const [isEditTable, setIsEditTable] = useState(false);
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterCreatedDate, setFilterCreatedDate] = useState(null);
  const [isClearActive, setIsClearActive] = useState(false);
  const classes = useStyles();
  const { user } = useAuthState(auth);

  useEffect(() => {
    dispatchGet(`?size=${defaultSize}&page=${defaultPage}&status_update_tradesys=${statusUpdateTradesys}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setIsEditTable(false);
    const params = createParams(userIdCard, name, tel, newPage + 1, size, filterStartDate, filterCreatedDate, statusUpdateTradesys);
    dispatchGet(params);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(0);
    setIsEditTable(false);
    const params = createParams(userIdCard, name, tel, 1, event.target.value, filterStartDate, filterCreatedDate, statusUpdateTradesys);
    dispatchGet(params);
  };

  const filterStatusUpdateTradesys = (event) => {
    setStatusUpdateTradesys(event.target.value);
    setPage(0);
    setIsEditTable(false);
    const params = createParams(userIdCard, name, tel, 1, size, filterStartDate, filterCreatedDate, event.target.value);
    dispatchGet(params);
  };

  const filterUser = (event) => {
    setUserIdCard(event.target.value);
    if (event.target.value !== '') {
      setIsClearActive(true);
    }
  };
  const filterName = (event) => {
    setName(event.target.value);
    if (event.target.value !== '') {
      setIsClearActive(true);
    }
  };
  const filterTel = (event) => {
    setTel(event.target.value);
    if (event.target.value !== '') {
      setIsClearActive(true);
    }
  };

  const search = () => {
    setPage(0);
    setIsEditTable(false);
    const params = createParams(userIdCard, name, tel, 1, size, filterStartDate, filterCreatedDate, statusUpdateTradesys);
    dispatchGet(params);
  };

  const showSlip = (url) => {
    setOpenModal(true);
    setUrlSlip(url);
  };

  const onFilterStartDateChange = (date) => {
    setFilterStartDate(date);
    setIsEditTable(false);
    if (date) {
      setIsClearActive(true);
    }
    const params = createParams(userIdCard, name, tel, 1, size, date, filterCreatedDate, statusUpdateTradesys);
    dispatchGet(params);
  };

  const onFilterCreatedDateChange = (date) => {
    setFilterCreatedDate(date);
    setIsEditTable(false);
    if (date) {
      setIsClearActive(true);
    }
    const params = createParams(userIdCard, name, tel, 1, size, filterStartDate, date, statusUpdateTradesys);
    dispatchGet(params);
  };

  const clearFilter = () => {
    const params = createParams('ALL', null, null, null, 1, size, null, null);
    dispatchGet(params);

    setUserIdCard('');
    setName('');
    setTel('');
    setFilterStartDate(null);
    setFilterCreatedDate(null);
    setIsEditTable(false);
    setIsClearActive(false);
  };

  return (
    <Wrapper>
      <h1>รายการชำระ</h1>
      <Div>
        <Grid container item>
          <Background>
            <Div style={{ marginBottom: '3rem', justifyContent: 'space-between' }}>
              <div>
                {/* <KeyboardDatePicker
                  style={{ marginRight: '2rem' }}
                  variant="inline"
                  format="DD/MM/yyyy"
                  id="date-picker-inline"
                  label="วันที่ชำระ"
                  value={filterStartDate}
                  onChange={onFilterStartDateChange}
                />
                <KeyboardDatePicker
                  style={{ marginRight: '2rem' }}
                  variant="inline"
                  format="DD/MM/yyyy"
                  id="date-picker-inline"
                  label="วันที่ทำรายการ"
                  value={filterCreatedDate}
                  onChange={onFilterCreatedDateChange}
                /> */}
                <FormControl style={{ marginRight: '2rem' }}>
                  <InputLabel id="filter-status-label">สถานะอัพเดทรายการ</InputLabel>
                  <Select className={classes.select} labelId="filter-status-label" id="filter-status" value={statusUpdateTradesys} onChange={filterStatusUpdateTradesys}>
                    <MenuItem value="SUCCESS">{translateStatus('SUCCESS')}</MenuItem>
                    <MenuItem value="REVIEWED">{translateStatus('REVIEWED')}</MenuItem>
                    <MenuItem value="CANCELED">{translateStatus('CANCELED')}</MenuItem>
                    <MenuItem value="AMOUNT_ERROR">{translateStatus('AMOUNT_ERROR')}</MenuItem>
                    <MenuItem value="ERROR">{translateStatus('ERROR')}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Div>
            <Div style={{ marginBottom: '3rem' }}>
              <TextField style={{ marginRight: '10px' }} label="เลขบัตรประชาชน" onChange={filterUser} value={userIdCard} />
              <TextField style={{ marginRight: '10px' }} label="ชื่อลูกค้า" onChange={filterName} value={name} />
              <TextField label="เบอร์โทร." onChange={filterTel} value={tel} />
              <div>
                <Button className="search" type="button" onClick={() => search()} style={{ marginLeft: '2rem' }}>
                  ค้นหา
                </Button>
                <Button className="clear" type="button" onClick={() => clearFilter()} disabled={!isClearActive}>
                  ล้าง
                </Button>
              </div>
            </Div>
            <Modal open={openModal} setOpen={setOpenModal} noButton heightAuto>
              {urlSlip ? <img width="400px" src={urlSlip} alt="" /> : <p>image cannot load</p>}
            </Modal>

            <TableContainer>
              <Table style={{ overflow: 'hidden' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>เลขบัตรประชาชน</TableCell>
                    <TableCell>ชื่อ</TableCell>
                    {/* <TableCell>วันที่ชำระ</TableCell>
                    <TableCell>วันที่ทำรายการ</TableCell> */}
                    <TableCell>จำนวนเงิน(บาท)</TableCell>
                    <TableCell>สลิป</TableCell>
                    <TableCell align="center">สถานะ</TableCell>
                    <TableCell align="center">สถานะอัพเดทรายการ</TableCell>
                    <TableCell align="left" colSpan={isEditTable ? COLUMNS - 5 : COLUMNS - 6}>
                      หมายเหตุ
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.length === 0 && (
                    <TableRow>
                      <TableCell align="center" colSpan={COLUMNS}>
                        ไม่พบข้อมูล
                      </TableCell>
                    </TableRow>
                  )}
                  {transactions.length > 0 &&
                    transactions.map((item, index) => (
                      <TransactionItem
                        key={item._id}
                        id={item._id}
                        idCard={item.user.id_card}
                        name={item.user.general.fullname}
                        // transactionDate={item.transaction_date}
                        // createdDate={item.created_date}
                        totalAmount={item.total_amount}
                        slipUrl={item.slip_url}
                        canUpdate={item.can_update}
                        onClickSlip={showSlip}
                        status={item.status}
                        statusLabel={translateStatus(item.status)}
                        remark={item.remark}
                        onEditTable={setIsEditTable}
                        filterStatus={statusUpdateTradesys}
                        filterUser={userIdCard}
                        page={page}
                        size={size}
                        filterStartDate={filterStartDate}
                        updatedBy={user.displayName}
                        statusUpdateTradesys={item.status_update_tradesys}
                        statusUpdateTradesysLabel={translateStatus(item.status_update_tradesys)}
                      />
                    ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      className={classes.pagination_btn}
                      rowsPerPageOptions={[10, 20, 30, 40, 100]}
                      colSpan={isEditTable ? COLUMNS + 1 : COLUMNS}
                      count={totalItem}
                      rowsPerPage={size}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            {loading && <Loading />}
          </Background>
        </Grid>
      </Div>
    </Wrapper >
  );
};

export default Transactions;
