import * as TYPES from '../types/product';

const initialState = {
    loading: false,
    error: null,
    size: 10,
    page: 1,
    products: [],
    totalItem: 0,
    totalPage: 0,
}

const initialStateInfo = {
    loading: false,
    error: null,
    productInfo: null
}

export const productReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.LIST_PRODUCT_REQ:
            return { ...state, loading: true };
        case TYPES.LIST_PRODUCT_SUCCESS:
            return { ...state, loading: false, products: payload.data.data.products, totalItem: payload.data.data.total_item, totalPage: payload.data.data.total_page };
        case TYPES.LIST_PRODUCT_FAIL:
            return { ...state, loading: false, error: payload };
        default:
            return state;
    }
};

export const productInfoReducer = (state = initialStateInfo, { type, payload }) => {
    switch (type) {
        case TYPES.GET_PRODUCT_REQ || TYPES.UPDATE_PRODUCT_REQ:
            return { ...state, loading: true };
        case TYPES.GET_PRODUCT_SUCCESS:
            return { ...state, loading: false, productInfo: payload.data.data, error: null };
        case TYPES.GET_PRODUCT_FAIL:
            return { ...state, loading: false, error: payload };
        case TYPES.UPDATE_PRODUCT_SUCCESS:
            return { ...state, loading: false, productInfo: payload.data.data, error: null };
        case TYPES.UPDATE_PRODUCT_FAIL:
            return { ...state, loading: false, error: payload };
        default:
            return state;
    }
};