import { message } from 'antd';
import Swal from 'sweetalert2';

const SWAL_COLOR = '#1890ff';

export const desTroyMessage = (key) => {
  if (!key) {
    return;
  }
  message.destroy(key);
};

export const setMessageLoading = (key, content = 'Loading') => {
  if (!key) {
    return;
  }
  message.loading({ content, key });
};

export const setMessageSuccess = (key, content = 'Success') => {
  if (!key) {
    return;
  }
  setTimeout(() => {
    message.success({ content, key, duration: 2 });
  }, 500);
};

export const setMessageError = (key, content = 'Failed') => {
  if (!key) {
    return;
  }
  setTimeout(() => {
    message.error({ content, key, duration: 2 });
  }, 500);
};

export const setFocusAlert = (message, buttonTitle) => {
  Swal.fire({
    title: message,
    confirmButtonText: buttonTitle || 'ตกลง',
    confirmButtonColor: SWAL_COLOR,
  });
};

export const setAlertConfirmDeleteTrans = ({ confirmFn, cancelFn, goToFn }) => {
  Swal.fire({
    title: 'ลูกค้ายังไม่ได้ถูกล็อคการชำระ ต้องการดำเนินการต่อหรือไม่?',
    showCancelButton: true,
    showDenyButton: true,
    confirmButtonText: 'ทำรายการต่อ',
    cancelButtonText: 'ไปหน้าลูกค้า',
    denyButtonText: 'ยกเลิก',
    confirmButtonColor: SWAL_COLOR,
  }).then((res) => {
    if (res.isConfirmed) {
      if (typeof confirmFn === 'function') {
        confirmFn();
      }
    }
    if (res.dismiss === 'cancel') {
      if (typeof goToFn === 'function') {
        goToFn();
      }
    }
    if (res.isDenied) {
      if (typeof confirmFn === 'function') {
        cancelFn();
      }
    }
  });
};

export const setFocusAlertWithIcon = ({ message, buttonTitle, icon }) => {
  Swal.fire({
    icon: icon || 'success',
    text: message,
    confirmButtonText: buttonTitle || 'ตกลง',
    confirmButtonColor: SWAL_COLOR,
  });
};

export const setAlertConfirmAutoCheckSlip = ({ confirmFn, message }) => {
  Swal.fire({
    title: message,
    text: "คุณต้องการยืนยันบันทึกรายการชำระหรือไม่?",
    icon: "warning",
    width: 800,
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "ยืนยัน",
    cancelButtonText: 'ยกเลิก',
  }).then((result) => {
    if (result.isConfirmed) {
      if (typeof confirmFn === 'function') {
        confirmFn();
      }
    }
  });
}
