export const CHECK_EMAIL_REQ = "CHECK_EMAIL_REQ";
export const CHECK_EMAIL_SUCCESS = "CHECK_EMAIL_SUCCESS";
export const CHECK_EMAIL_FAIL = "CHECK_EMAIL_FAIL";

export const LOGIN_REQ = "LOGIN_REQ";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const VERIFY_USER_REQ = "VERIFY_USER_REQ";
export const VERIFY_USER_SCCESS = "VERIFY_USER_SCCESS";
export const VERIFY_USER_FAIL = "VERIFY_USER_FAIL";

export const LOGOUT_REQ = "LOGOUT_REQ";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const AUTH_ERROR = "AUTH_ERROR";
