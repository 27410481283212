export const convertStatusENtoTH = (str, useFor) => {
  switch (useFor) {
    case 'contract':
      switch (str) {
        case CONTRACT_STATUS.active.en:
          return CONTRACT_STATUS.active.th;
        case CONTRACT_STATUS.terminated.en:
          return CONTRACT_STATUS.terminated.th;
        case CONTRACT_STATUS.impounded.en:
          return CONTRACT_STATUS.impounded.th;
        case CONTRACT_STATUS.completed.en:
          return CONTRACT_STATUS.completed.th;
        case CONTRACT_STATUS.notice_of_termination.en:
          return CONTRACT_STATUS.notice_of_termination.th;
        default:
      }
      break;
    case 'tracking':
      switch (str) {
        case TRACKING_STATUS.normal.en:
          return TRACKING_STATUS.normal.th;
        case TRACKING_STATUS.notice_of_termination.en:
          return TRACKING_STATUS.notice_of_termination.th;
        case TRACKING_STATUS.field_collection.en:
          return TRACKING_STATUS.field_collection.th;
        case TRACKING_STATUS.hold.en:
          return TRACKING_STATUS.hold.th;
        case TRACKING_STATUS.closed.en:
          return TRACKING_STATUS.closed.th;
        case TRACKING_STATUS.sue.en:
          return TRACKING_STATUS.sue.th;
        case TRACKING_STATUS.baddebt.en:
          return TRACKING_STATUS.baddebt.th;
        case TRACKING_STATUS.cancel.en:
          return TRACKING_STATUS.cancel.th;
        case TRACKING_STATUS.finished.en:
          return TRACKING_STATUS.finished.th;
        default:
      }
      break;
    case 'sending':
      switch (str) {
        case SENDING_STATUS.notSend.en:
          return SENDING_STATUS.notSend.th;
        case SENDING_STATUS.sending.en:
          return SENDING_STATUS.sending.th;
        case SENDING_STATUS.arrived.en:
          return SENDING_STATUS.arrived.th;
        case SENDING_STATUS.failed.en:
          return SENDING_STATUS.failed.th;
        case SENDING_STATUS.returning.en:
          return SENDING_STATUS.returning.th;
        case SENDING_STATUS.return_success.en:
          return SENDING_STATUS.return_success.th;
        case SENDING_STATUS.wait_sending.en:
          return SENDING_STATUS.wait_sending.th;
        default:
      }
      break;
    case 'trackingRecord':
      switch (str) {
        case TRACKING_RECORD_STATUS.close.en:
          return TRACKING_RECORD_STATUS.close.th;
        case TRACKING_RECORD_STATUS.return.en:
          return TRACKING_RECORD_STATUS.return.th;
        case TRACKING_RECORD_STATUS.appointment.en:
          return TRACKING_RECORD_STATUS.appointment.th;
        case TRACKING_RECORD_STATUS.not_tracked.en:
          return TRACKING_RECORD_STATUS.not_tracked.th;
        case TRACKING_RECORD_STATUS.not_found.en:
          return TRACKING_RECORD_STATUS.not_found.th;
        case TRACKING_RECORD_STATUS.finished.en:
          return TRACKING_RECORD_STATUS.finished.th;
        case TRACKING_RECORD_STATUS.other.en:
          return TRACKING_RECORD_STATUS.other.th;
        default:
      }
      break;
    case 'jobType':
      switch (str) {
        case JOB_TYPE.online.en:
          return JOB_TYPE.online.th;
        case JOB_TYPE.offline.en:
          return JOB_TYPE.offline.th;
        case JOB_TYPE.change_pass.en:
          return JOB_TYPE.change_pass.th;
        case JOB_TYPE.others.en:
          return JOB_TYPE.others.th;
        default:
      }
      break;
    case 'status':
      switch (str) {
        case STATUS.special.en:
          return STATUS.special.th;
        case STATUS.normal.en:
          return STATUS.normal.th;
        default:
      }
      break;
    case 'terminateContract':
      switch (str) {
        case TERMINATE_CONTRACT.sale_phone.en:
          return TERMINATE_CONTRACT.sale_phone.th;
        case TERMINATE_CONTRACT.change_password.en:
          return TERMINATE_CONTRACT.change_password.th;
        case TERMINATE_CONTRACT.other.en:
          return TERMINATE_CONTRACT.other.th;
        default:
          return 'ผิดเงื่อนไขเกิน 60 วัน';
      }
      break;
    default:
  }
};

/*---------------------------------------Status------------------------------------------*/

/*-------สถานะสัญญา-------*/

export const CONTRACT_STATUS = {
  active: {
    en: 'ACTIVE',
    th: 'กำลังใช้งาน',
    // th_short : 'กำลังใช้งาน'
  },
  terminated: {
    en: 'TERMINATED',
    th: 'สัญญาถูกยกเลิก',
    // th_short : 'สัญญาถูกยกเลิก'
  },
  impounded: {
    en: 'IMPOUNDED',
    th: 'สัญญาถูกยึด',
    // th_short : 'สัญญาถูกยึด'
  },
  completed: {
    en: 'COMPLETED',
    th: 'ปิดสัญญาเรียบร้อย',
    th_short: 'ปิดสัญญา',
  },
  notice_of_termination: {
    en: 'NOTICE_OF_TERMINATION',
    th: 'ออกหนังสือบอกเลิกสัญญา',
    th_short: 'กำลังใช้งาน',
  },
};

/*-------สถานะการติดตาม-------*/

export const TRACKING_STATUS = {
  normal: {
    en: 'NORMAL',
    th: 'สถานะปกติ',
  },
  notice_of_termination: {
    en: 'NOTICE_OF_TERMINATION',
    th: 'ออกหนังสือบอกเลิกสัญญา',
    // th_short : 'ออกหนังสือ'
  },
  field_collection: {
    en: 'FIELD_COLLECTION',
    th: 'ภาคสนามกำลังติดตาม',
    th_short: 'กำลังติดตาม',
  },
  hold: {
    en: 'HOLD',
    th: 'คืนเครื่อง',
  },
  closed: {
    en: 'CLOSED',
    th: 'ปิดสัญญา',
  },
  sue: {
    en: 'SUE',
    th: 'ส่งฟ้อง',
  },
  baddebt: {
    en: 'BADDEBT',
    th: 'หนี้สูญ',
  },
  cancel: {
    en: 'CANCEL',
    th: 'ยกเลิก',
  },
  finished: {
    en: 'FINISHED',
    th: 'ปิดเคส',
  },
};

/*-------สถานะการจัดส่งหนังสือบอกเลิกสัญญา-------*/

export const SENDING_STATUS = {
  notSend: {
    en: 'NOT_SEND',
    th: 'ยังไม่ได้จัดส่ง',
  },
  sending: {
    en: 'SENDING',
    th: 'กำลังจัดส่ง',
  },
  arrived: {
    en: 'ARRIVED',
    th: 'จัดส่งเสร็จสิ้น',
  },
  failed: {
    en: 'FAILED',
    th: 'การจัดส่งไม่สำเร็จ',
    th_short: 'จัดส่งไม่สำเร็จ',
  },
  returning: {
    en: 'RETURNING',
    th: 'กำลังจัดส่งคืน',
  },
  return_success: {
    en: 'RETURN_SUCCESS',
    th: 'จัดส่งคืนสำเร็จ',
  },
  wait_sending: {
    en: 'WAIT_SENDING',
    th: 'รอจัดส่ง',
  },
};

/*-------สถานะการติดตามของภาคสนามแต่ละครั้ง-------*/

export const TRACKING_RECORD_STATUS = {
  close: {
    en: 'CLOSE',
    th: 'ปิดสัญญา',
  },
  return: {
    en: 'RETURN',
    th: 'คืนเครื่อง',
  },
  appointment: {
    en: 'APPOINTMENT',
    th: 'นัดหมาย',
  },
  not_tracked: {
    en: 'NOT_TRACKED',
    th: 'ไม่สามารถติดตามได้',
  },
  not_found: {
    en: 'NOT_FOUND',
    th: 'ไม่พบลูกค้า',
  },
  finished: {
    en: 'FINISHED',
    th: 'ปิดเคส',
  },
  other: {
    en: 'OTHER',
    th: 'อื่นๆ',
  },
};

/*-------ประเภทหนังสือยกเลิกสัญญา-------*/
export const TERMINATE_CONTRACT = {
  sale_phone: {
    en: 'SALE_PHONE',
    th: 'ผิดเงื่อนไขนำเครื่องไปขาย',
  },
  change_password: {
    en: 'CHANGE_PASSWORD',
    th: 'ผิดเงื่อนไขเปลี่ยนรหัส',
  },
  other: {
    en: 'OTHER',
    th: 'ผิดเงื่อนไขอื่นๆ',
  },
};

/*-------ประเภทงาน-------*/

export const JOB_TYPE = {
  online: {
    en: 'ONLINE',
    th: 'ออนไลน์',
  },
  offline: {
    en: 'OFFLINE',
    th: 'ออฟไลน์',
  },
  change_pass: {
    en: 'CHANGE_PASS',
    th: 'เปลี่ยนรหัส',
  },
  others: {
    en: 'OTHERS',
    th: 'อื่นๆ',
  },
};

/*-------รูปแบบงาน-------*/

export const STATUS = {
  special: {
    en: 'SPECIAL',
    th: 'งานพิเศษ',
  },
  normal: {
    en: 'NORMAL',
    th: 'งานทั่วไป',
  },
};
