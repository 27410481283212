export const RECEIPT_PAYMENT_UPDATE_FILTER_REQ = '@RECEIPT_PAYMENT/UPDATE_FILTER_REQ';
export const RECEIPT_PAYMENT_UPDATE_FILTER_SUCCESS = '@RECEIPT_PAYMENT/UPDATE_FILTER_SUCCESS';
export const RECEIPT_PAYMENT_UPDATE_FILTER_CLEAR = '@RECEIPT_PAYMENT/UPDATE_FILTER_CLEAR';

export const RECEIPT_PAYMENT_UPDATE_PAID_PARAMS_REQ = '@RECEIPT_PAYMENT/UPDATE_PAID_PARAMS_REQ';
export const RECEIPT_PAYMENT_UPDATE_PAID_PARAMS_SUCCESS = '@RECEIPT_PAYMENT/UPDATE_PAID_PARAMS_SUCCESS';
export const RECEIPT_PAYMENT_UPDATE_PAID_PARAMS_CLEAR = '@RECEIPT_PAYMENT/UPDATE_PAID_PARAMS_CLEAR';

export const RECEIPT_PAYMENT_GET_SUMMARY_INFO_REQ = '@RECEIPT_PAYMENT/GET_SUMMARY_INFO_REQ';
export const RECEIPT_PAYMENT_GET_SUMMARY_INFO_SUCCESS = '@RECEIPT_PAYMENT/GET_SUMMARY_INFO_SUCCESS';
export const RECEIPT_PAYMENT_GET_SUMMARY_INFO_FAIL = '@RECEIPT_PAYMENT/GET_SUMMARY_INFO_FAIL';
export const RECEIPT_PAYMENT_GET_SUMMARY_INFO_CLEAR = '@RECEIPT_PAYMENT/GET_SUMMARY_INFO_CLEAR';
export const RECEIPT_PAYMENT_UPDATE_SUMMARY_INFO_REQ = '@RECEIPT_PAYMENT/UPDATE_SUMMARY_INFO_REQ';
export const RECEIPT_PAYMENT_UPDATE_SUMMARY_INFO_SUCCESS = '@RECEIPT_PAYMENT/UPDATE_SUMMARY_INFO_SUCCESS';
export const RECEIPT_PAYMENT_UPDATE_SUMMARY_INFO_FAIL = '@RECEIPT_PAYMENT/UPDATE_SUMMARY_INFO_FAIL';

export const RECEIPT_PAYMENT_GET_SUMMARY_AMOUNT_REQ = '@RECEIPT_PAYMENT/GET_SUMMARY_AMOUNT_REQ';
export const RECEIPT_PAYMENT_GET_SUMMARY_AMOUNT_SUCCESS = '@RECEIPT_PAYMENT/GET_SUMMARY_AMOUNT_SUCCESS';
export const RECEIPT_PAYMENT_GET_SUMMARY_AMOUNT_FAIL = '@RECEIPT_PAYMENT/GET_SUMMARY_AMOUNT_FAIL';
export const RECEIPT_PAYMENT_GET_SUMMARY_AMOUNT_CLEAR = '@RECEIPT_PAYMENT/GET_SUMMARY_AMOUNT_CLEAR';

export const RECEIPT_PAYMENT_PAY_REQ = '@RECEIPT_PAYMENT/PAY_REQ';
export const RECEIPT_PAYMENT_PAY_SUCCESS = '@RECEIPT_PAYMENT/PAY_SUCCESS';
export const RECEIPT_PAYMENT_PAY_FAIL = '@RECEIPT_PAYMENT/PAY_FAIL';
export const RECEIPT_PAYMENT_PAY_CLEAR = '@RECEIPT_PAYMENT/PAY_CLEAR';
export const RECEIPT_PAYMENT_PAY_LOADING_CLEAR = '@RECEIPT_PAYMENT/PAY_LOADING_CLEAR';

export const RECEIPT_PAYMENT_GET_AMOUNT_CONTRACT_REQ = '@RECEIPT_PAYMENT/GET_AMOUNT_CONTRACT_REQ';
export const RECEIPT_PAYMENT_GET_AMOUNT_CONTRACT_SUCCESS = '@RECEIPT_PAYMENT/GET_AMOUNT_CONTRACT_SUCCESS';
export const RECEIPT_PAYMENT_GET_AMOUNT_CONTRACT_FAIL = '@RECEIPT_PAYMENT/GET_AMOUNT_CONTRACT_FAIL';
export const RECEIPT_PAYMENT_GET_AMOUNT_CONTRACT_CLEAR = '@RECEIPT_PAYMENT/GET_AMOUNT_CONTRACT_CLEAR';

export const RECEIPT_PAYMENT_CHECK_REDEEM_DATA_REQ = '@RECEIPT_PAYMENT/CHECK_REDEEM_DATA_REQ';
export const RECEIPT_PAYMENT_CHECK_REDEEM_DATA_SUCCESS = '@RECEIPT_PAYMENT/CHECK_REDEEM_DATA_SUCCESS';
export const RECEIPT_PAYMENT_CHECK_REDEEM_DATA_FAIL = '@RECEIPT_PAYMENT/CHECK_REDEEM_DATA_FAIL';
export const RECEIPT_PAYMENT_CHECK_REDEEM_DATA_CLEAR = '@RECEIPT_PAYMENT/CHECK_REDEEM_DATA_CLEAR';

export const RECEIPT_PAYMENT_UPDATE_DEVICE_RETURN_REMARK_REQ = '@RECEIPT_PAYMENT/UPDATE_DEVICE_RETURN_REMARK_REQ';
export const RECEIPT_PAYMENT_UPDATE_DEVICE_RETURN_REMARK_SUCCESS = '@RECEIPT_PAYMENT/UPDATE_DEVICE_RETURN_REMARK_SUCCESS';
export const RECEIPT_PAYMENT_UPDATE_DEVICE_RETURN_REMARK_FAIL = '@RECEIPT_PAYMENT/UPDATE_DEVICE_RETURN_REMARK_FAIL';
export const RECEIPT_PAYMENT_UPDATE_DEVICE_RETURN_REMARK_CLEAR = '@RECEIPT_PAYMENT/UPDATE_DEVICE_RETURN_REMARK_CLEAR';

export const RECEIPT_PAYMENT_GET_COUPON_DETAIL_REQ = '@RECEIPT_PAYMENT/GET_COUPON_DETAIL_REQ';
export const RECEIPT_PAYMENT_GET_COUPON_DETAIL_SUCCESS = '@RECEIPT_PAYMENT/GET_COUPON_DETAIL_SUCCESS';
export const RECEIPT_PAYMENT_GET_COUPON_DETAIL_FAIL = '@RECEIPT_PAYMENT/GET_COUPON_DETAIL_FAIL';
export const RECEIPT_PAYMENT_GET_COUPON_DETAIL_CLEAR = '@RECEIPT_PAYMENT/GET_COUPON_DETAIL_CLEAR';