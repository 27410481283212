import React from 'react';
import { Input } from 'antd';

export const EditableCellReciveStockNonSerial = ({
  title, editable, children, dataIndex, record, handleChange, dataOption, ...restProps
  }) => {


    const renderInputType = (dataIndex) => {

      if (dataIndex === "qty") {
        return <Input  defaultValue={record && record[dataIndex] || ''} type={dataIndex === "qty" ? "NUMBER" : "TEXT"} 
        style={{ margin: '5px 0px', borderRadius: '10px',textAlign:'right' }} onChange={(e) => handleChange(e.target.value, dataIndex,record.key)} />;
      } else {
        return <p>{children}</p>;
      }
     
    }  
  
    
    return <td {...restProps}>
      {editable? renderInputType(dataIndex):children} 
      
      </td>;
  };