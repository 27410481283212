import axios from 'axios';
import { API_URL } from '../../env.js';

export const getBranch = async () => {
    try {
        return await axios.get(`${API_URL}/admin/authen/branchAdmin/options`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
        });
    } catch (error) {
        return error;
    }
};

export const getBranchSpecific = async () => {
    try {
        return await axios.get(`${API_URL}/admin/authen/branchDashboard/options`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
        });
    } catch (error) {
        return error;
    }
};