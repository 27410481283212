// /* eslint no-use-before-define: 0 */
import React, {useState, useEffect} from 'react';
import Wrapper from '../../views/wrapper/Wrapper';
import {Modal, Card, Spin,Row,Table} from 'antd';
import {ContainerButton} from '../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../components/buttons';
import {PageLayout} from '../../components/pageLayout/PageLayout';
import {PackageColumn,PackageDetailColumn} from '../../constants/global/columnTableForm';
import {GET,GET_OPTION_PACKAGE_BY_KEY,GET_PRODUCT_PACKAGES_DETAIL,FECTH_UPDATE_PACKAGE} from '../../services/index';
import TableForm from "../../components/table";
import {TextSmall} from '../../components/forms/text';
import {useHistory} from 'react-router-dom';
import {convertStrToFormat} from '../../functions/fn'
import {AddPackagesProductModal} from '../../components/modal/ManageProducts/AddPackagesByProductModal'
import {EditPackagesProductModal} from '../../components/modal/ManageProducts/EditPackagesByProductModal'


const ManagePackage = () => {
  const history = useHistory();
  const contractList = history.location.state;
  const [visibleAdd,setVisibleAdd] = useState(false)
  const [visibleEdit,setVisibleEdit] = useState(false)
  const [dataSource, setDataSource] = useState([]);
  const [defaultValue,setDefaultValue] = useState({})
  const [optionPeriods, setOptionPeriods] = useState([]);
  const [optionPackages, setOptionPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  //const [packageName, setPackagename] = useState('');

  useEffect(() => {
    getProductPackageDetail(contractList?.item_code)
    getOptionPeriods()
    getOptionPackages()
  }, []);

  const getOptionPeriods = async()=>{
    try {
        const res = await GET(GET_OPTION_PACKAGE_BY_KEY("refinance_installments"))
        const {success, result} = res;
        if(success){
          setOptionPeriods(result?.refinance_installments)
        }
    } catch (err) {
    const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  }
 

  const getOptionPackages = async()=>{
    try {
        const res = await GET(GET_OPTION_PACKAGE_BY_KEY("packages"))
        const {success, result} = res;
        if(success){
          setOptionPackages(result?.packages)
        }
    } catch (err) {
    const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  }


  const getProductPackageDetail = async (code) => {
    setLoading(true);
    try {
      
      let res = await GET(GET_PRODUCT_PACKAGES_DETAIL(code));
      const {success, result} = res;
      if (success) {
        let resultData = []
        let packageId = []
          result.productPackage.forEach((val, i) => {
            resultData.push({key: i,...val})
            packageId.push(val.package_id)
        });
        //setPackagename(result.productPackage[0].product_name)
        //setPackageIdDefault(packageId)
        setDataSource(resultData);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const onClickModal = (ref,record)=>{
  
    setDefaultValue(record)
      if(ref === 'edit'){
        setVisibleEdit(true)
      }
  }

  const updateDataCurrent =async()=>{
    try {
      const res = await GET(FECTH_UPDATE_PACKAGE)
      const {success, message} = res;
      if(success){
        Modal.success({
					title: message,
					afterClose: () => {
						getProductPackageDetail(contractList?.item_code)
					},
				});
      }
  } catch (err) {
  const {message, error} = err;
    Modal.error({
      title: message,
      content: error || '',
    });
  }

  }

  return (
    <Wrapper>
      <Spin tip="Loading..." spinning={loading}>
      <Card>
        <Row>
          <ButtonTheme useFor="BACK" onClick={() => history.goBack()}></ButtonTheme>
        </Row>
          <PageLayout
            tableLayout={{
              title: contractList?.item_name,
              columns: PackageColumn(onClickModal),
              dataSource: dataSource,
              expandable: {
                expandedRowRender: (record) => (
                  <>
                  <TableForm
                    title={"รายการสินค้าในแพ็คเก็จ"}
                    style={{ margin: "4vh"}}
                    noPagination={true}
                    columns={PackageDetailColumn()}
                    dataSource={record.detail}
                    scroll="100%"
                    summary={(data)=>{
                      let total = 0
                      data.forEach(({cost,qty})=>{
                        total += cost*qty
                      })
                      return (
                        <>
                        <Table.Summary.Row >
                            <Table.Summary.Cell index={0} colSpan={3} >
                              <TextSmall text={"รวม"} color="#28a745" align="right"/>
                              </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} >
                              <TextSmall text={convertStrToFormat(total,'money')} color="#28a745" align="right"/>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                        </>
                      )
                    }}
                  />
                  </>
                  
                ),
              },
              extraContent: (
                <ContainerButton right>
                  <ButtonTheme useFor="RANDOM" title={'ดึงข้อมูลใหม่'} onClick={()=>updateDataCurrent()}/>
                   <ButtonTheme useFor="CREATE" title={'เพิ่มแพคเก็จ'} onClick={()=>setVisibleAdd(true)}/>
                </ContainerButton>
              ),
            }}
          />
      </Card>
      </Spin>
      <AddPackagesProductModal title="เพิ่มแพคเก็จ" visible={visibleAdd} onClose={()=>setVisibleAdd(false)} data={{options:{optionPeriods,optionPackages},value:{product_code:contractList?.item_code},reApi: getProductPackageDetail}}/>
      <EditPackagesProductModal title="แก้ไขแพคเก็จ" visible={visibleEdit} onClose={()=>setVisibleEdit(false)} data={{options:{optionPeriods,optionPackages},value :defaultValue,reApi: getProductPackageDetail}}/>
    </Wrapper>
  );
};

export default ManagePackage;
