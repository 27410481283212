// /* eslint no-use-before-define: 0 */
import React, {useRef, useEffect, useState} from 'react';
import {ButtonTheme} from '../../components/buttons';
import TemplatePrintPick from './template/templatePrintPick';
import ReactToPrint from 'react-to-print';
import Wrapper from '../../views/wrapper/Wrapper';
import {Card, Modal, Spin} from 'antd';
import {useLocation} from 'react-router-dom';
import TemplatePrintCheckStock from './template/templatePrintCheckStock';
import TemplatePrintPickNonSerial from './template/templatePrintPickNonSerial';
import TemplatePrintProductPick from './template/templatePrintProductPick';
import moment from 'moment';
import {GET_STOCK_AND_RESERVATION, GET_STOCK_RESERVATION, POST} from '../../services';

const PrintPickFormScreen = () => {
  const location = useLocation();
  const [dataSource, setDataSource] = useState({});
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataHead, setDataHead] = useState({});
  const componentRef = useRef();
  const [templateState, setTemplate] = useState('');
  let template = '';

  const params = new URLSearchParams(window.location.search);
  const pageParams = params.get('page');
  const branchFromParams = params.get('branchFrom');
  const branchToParams = params.get('branchTo');
  const dateStartParams = params.get('dateStart');
  const dateEndParams = params.get('dateEnd');
  const remarkParams = params.get('remark');
  const productHandParams = params.get('productHand');

  useEffect(() => {
    if (pageParams === 'productsPick') {
      getStock({
        branchFrom: branchFromParams,
        branchTo: branchToParams,
        startDateTime: dateStartParams,
        endDateTime: dateEndParams,
        productHand: productHandParams,
      });
    } else {
      if (location.state) {
        const {data} = location.state;
        setDataSource(data);
      }
    }
  }, []);

  const getStock = async (data) => {
    setLoading(true);
    if (data.branchTo === 'all') {
      data.branchTo = '';
    }

    const {branchFrom, branchTo, startDateTime, endDateTime, productHand} = data;
    const obj = {
      branchFrom: branchFrom || 'CENTER',
      branchTo: branchTo,
      startDateTime: moment(startDateTime).format('YYYY-MM-DD'),
      endDateTime: moment(endDateTime).format('YYYY-MM-DD'),
      productHand: productHand || '',
    };

    try {
      let res = await POST(GET_STOCK_AND_RESERVATION, obj);
      const {success, result} = res;
      if (success) {
        const dataSet = result.stockReserve.items.map((el, i) => {
          return {
            rowNo: i + 1,
            ...el,
          };
        });
        const count = result.stockReserve?.count;

        // const branch = result.stockReserve.branch.map((item, index) => ({
        //   label: item.value,
        //   branch: item.code,
        //   key: index,
        // }));
        // const { branch_from_name, branch_to_name, document_date_start, document_date_end } = result.stockReserve.header

        // setItems(dataSet)
        // setDataHead(result.stockReserve.header)
        setDataSource({
          items: dataSet,
          page: 'productPick',
          ...result.stockReserve.header,
          remark: remarkParams,
          amount: count || dataSet.length,
          // branch: branch,
        });
      }
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
      if (window.location.href.includes('?')) {
        window.history.pushState({}, null, window.location.href.split('?')[0]);
      }
    }
  };

  useEffect(() => {
    if (JSON.stringify(dataSource) !== '{}') {
      switch (dataSource.page) {
        case 'checkstock':
          template = <TemplatePrintCheckStock ref={componentRef} data={dataSource} />;
          break;
        case 'pickNonSerial':
          template = <TemplatePrintPickNonSerial ref={componentRef} data={dataSource} />;
          break;
        case 'productPick':
          template = <TemplatePrintProductPick ref={componentRef} data={dataSource} />;
          break;
        case 'printPick':
          template = <TemplatePrintPick ref={componentRef} data={dataSource} />;
          break;
        default:
          template = <></>;
          break;
      }
      setTemplate(template);
    }
  }, [dataSource]);

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <ReactToPrint trigger={() => <ButtonTheme useFor="CONFIRM" title={'พิมพ์เอกสาร'} />} content={() => componentRef.current} />

          {/* {template} */}
          {templateState}
        </Spin>
      </Card>
    </Wrapper>
  );
};

export default PrintPickFormScreen;
