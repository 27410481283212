import styled from 'styled-components';

export const Div = styled.div`
  display: flex;
  min-width: 1000px;
  // height: calc(100vh - 105px);
`;

export const Background = styled.div`
  background-color: ${(props) => (props.color ? props.color : 'white')};
  padding: 25px;
  border-radius: 0;
  width: 100%;
  height: 100%;
`;

export const Table = styled.table`
  width: 100%;
  & tr {
    height: 3rem;
  }
`;

export const Th = styled.th`
  text-align: ${(props) => (props.left ? 'left' : 'center')};
  border-right: ${(props) => (props.border_right ? '1px solid black' : '0')};
  border-bottom: ${(props) => (props.border_bottom ? '1px solid black' : '0')};
  background-color: ${(props) => (props.highlight ? '#ddd' : 'none')};
`;
export const Td = styled.td`
  text-align: ${(props) => (props.left ? 'left' : 'center')};
  border-right: ${(props) => (props.border_right ? '1px solid black' : '0')};
  background-color: ${(props) => (props.highlight ? '#ddd' : 'none')};
`;

export const WrapTable = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

export const HeaderInfo = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const FormUpdateWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Button = styled.button`
  padding: 5px 15px 5px 15px;
  border: 0;
  border-radius: 3px;
  margin-right: 10px;
  background-color: transparent;
  &:focus {
    outline: 0;
  }

  &.pay-btn {
    background-color: #3443eb;
    color: #fff;
  }

  &.pay-btn:hover {
    background-color: #303ed9;
  }

  &.pay-btn:disabled {
    background-color: #ddd;
    border: 0;
    color: #999;
  }

  &.filter-btn {
    border: 1px solid #3443eb;
    color: #3443eb;
  }

  &.clear {
    border: 1px solid #3443eb;
    color: #3443eb;
  }

  &.clear:disabled {
    background-color: #ddd;
    border: 0;
    color: #999;
  }
`;

export const InputFile = styled.input`
  &[type='file']::-webkit-file-upload-button {
    background-color: #ddd;
    border: 0;
    padding: 3px 10px;
    cursor: pointer;
  }
`;

export const FilterItemWrapper = styled.div`
  margin-right: 1.5rem;
  ${(props) => props.extraRight && `margin-right: 2rem`};
`;

export const InfoWrapper = styled.div`
  margin: 2rem 0 0 0;
`;

export const NotVisibleDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 290px);
  justify-content: center;
  align-items: center;
`;
