import React, {useState, useEffect, useMemo, useRef} from 'react';
import moment from 'moment';
import Wrapper from '../../../views/wrapper/Wrapper';
import {Modal, Card, Spin, Row, Space} from 'antd';
import {Icon} from '../../../resources/icon';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../../components/buttons';
import {SettingOutlined} from '@ant-design/icons';
import {PageLayout} from '../../../components/pageLayout/PageLayout';
import {ManageAccountDashboardSearchForm} from '../../../constants/global/SearchForm';
import {ManageAccountColumn} from '../../../constants/global/columnTableForm';
import {
  GET,
  POST,
  GET_ACCOUNT_MAIL,
  GET_ICLOUD_FILTER_OPTION,
  GET_CHECK_MAIL_STATUS_ICLOUD,
  GET_CHANGE_MAIL_STATUS_ICLOUD,
} from '../../../services/index';
import {EditAccountModal} from '../../../components/modal/ManageAccount/EditAccountModal';
import {ManageIcloudModal} from '../../../components/modal/ManageAccount/ManageIcloudModal';
import {ChangeContractIcloudModal} from '../../../components/modal/ManageAccount/ChangeContractIcloudModal';
import {convertStrToFormat} from '../../../functions/fn';
import {useGetReportManageAccountExcel} from './api';

const ManageAccount = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleManage, setVisibleManage] = useState(false);
  const [visibleExport, setVisibleExport] = useState(false);
  const [visibleChangeContract, setVisibleChangeContract] = useState(false);
  const [title, setTitle] = useState('');
  const [valueDefault, setValueDefault] = useState({});
  const [totalSize, setTotalSize] = useState(0);
  const [hideTable, setHideTable] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [icloudStatusOption, setIcloudStatusOption] = useState([]);
  const [mailIcloudCheck, setMailIcloudCheck] = useState([]);
  const [paginations, setPaginations] = useState({
    page: 1,
    size: 10,
  });
  const [filterDt, setFilterDt] = useState({
    tel: '',
    mail: '',
    status: '',
    icloud_status: '',
    createUser: '',
    startDate: '',
    endDate: '',
  });
  const isInitialRender = useRef(true);

  const [resultReport, loadingReport, errorReport, setInputReport, reportRefecth] = useGetReportManageAccountExcel();

  const memoizedData = useMemo(() => {
    const data = {...filterDt};

    data.tel = data.tel || '';
    data.mail = data.mail || '';
    data.status = data.status || '';
    data.icloud_status = data.icloud_status || '';
    data.createUser = data.createUser || '';

    data.startDate = data.startDate ? moment(data?.startDate).format('YYYY-MM-DD') : '';
    data.endDate = data.endDate ? moment(data?.endDate).format('YYYY-MM-DD') : '';
    setPaginations({
      ...paginations,
      page: 1,
    });
    return data;
  }, [filterDt]);

  const memoizedPageData = useMemo(() => {
    const data = {...paginations};

    data.page = data.page || 1;
    data.size = data.size || 10;
    return data;
  }, [paginations]);

  useEffect(() => {
    getFilterOption();
  }, []);

  useEffect(() => {
    if (isInitialRender.current) {
      // Skip the effect on the initial render
      isInitialRender.current = false;
    } else {
      if (memoizedData) getDataAccountMail(memoizedData);
    }
  }, [memoizedData, memoizedPageData]);

  useEffect(() => {
    if (resultReport?.message === 'success' && resultReport?.url) {
      setTimeout(() => {
        window.open(resultReport?.url, '_blank', 'noopener,noreferrer');
        onCloseExport();
        reportRefecth();
      }, 1000);
    }
    if (!loadingReport) setLoading(false);
  }, [loadingReport]);

  const getDataAccountMail = async (data) => {
    setLoading(true);
    try {
      const {page, size} = paginations;
      const res = await POST(GET_ACCOUNT_MAIL(page, size), data);
      const {success, result, total_item} = res;
      if (success) {
        setTotalSize(total_item);

        const resultData = result.map((val, i) => {
          return {
            ...val,
            key: val?._id,
            rowNo: val.no,
            fullName: `${val.firstName} ${val.lastName}`,
          };
        });
        setHideTable(false);
        setDataSource(resultData);
        setSelectedRowKeys([]);
        setLoading(false);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
      setLoading(false);
    }
  };

  const getFilterOption = async () => {
    setLoading(true);
    try {
      const res = await GET(GET_ICLOUD_FILTER_OPTION);
      const {success, icloud_status} = res;
      if (success) {
        setLoading(false);
        setIcloudStatusOption(icloud_status);
      }
    } catch (err) {
      const {error} = err;
      Modal.error({
        title: error || '',
      });
      setLoading(false);
    }
  };

  const openModal = (ref, record) => {
    if (ref === 'edit') {
      setTitle('แก้ไขบัญชี');
      setValueDefault(record);
      setVisibleEdit(true);

      if (record.telMail) {
        // setConfirmUseTel(true);
      }
    } else if (ref === 'manageIcloud') {
      setTitle('จัดการไอคลาวน์');
      setVisibleManage(true);
      setValueDefault(record);
    } else if (ref === 'changeContract') {
      setTitle('ระบุสัญญา');
      setVisibleChangeContract(true);
      setValueDefault(record);
    }
  };

  const closeModal = (ref) => {
    if (ref === 'edit') {
      setVisibleEdit(false);
    } else if (ref === 'manageIcloud') {
      setVisibleManage(false);
    } else if (ref === 'changeContract') {
      setVisibleChangeContract(false);
    }
  };

  const onClearCustom = (setValue, formSearch) => {
    formSearch.forEach((val) => setValue(val.name, null));
    setFilterDt({tel: '', mail: '', status: '', icloud_status: '', createUser: '', startDate: '', endDate: ''});
    setPaginations({...paginations});
  };

  const createParams = (value) => {
    let params = [];
    let result = '';

    Object.keys(value).forEach((key) => {
      if (value[key]) {
        params.push(key + '=' + encodeURIComponent(value[key]));
      }
    });

    if (params.length !== 0) {
      result += params.join('&');
    }

    return result;
  };

  const onExportExcelReport = async () => {
    setLoading(true);
    const obj = {
      tel: filterDt?.tel,
      mail: filterDt?.mail,
      status: filterDt?.status,
      icloud_status: filterDt?.icloud_status,
      createUser: filterDt?.createUser,
      startDate: filterDt?.startDate,
      endDate: filterDt?.endDate,
    };
    const params = createParams(obj);
    await setInputReport(params.length ? `?${params}` : params);
    onCloseExport();
  };

  const onConfirmClose = () => {
    Modal.confirm({
      title: 'แจ้งเตือน',
      icon: <Icon.warningAlert />,
      content: `โปรดยืนยันการปิดใช้งาน iCloud`,
      onOk() {
        closeIcloudAccount(selectedRowKeys);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const recheckIcloudAccount = async () => {
    setLoading(true);
    try {
      const mailIcloudList = mailIcloudCheck.map((dt) => ({
        mail: dt.mail,
        contractNo: dt?.contractNo,
      }));

      let res = await POST(GET_CHECK_MAIL_STATUS_ICLOUD(), {email: mailIcloudList});
      const {success} = res;

      if (success) {
        Modal.info({
          title: 'แจ้งเตือน',
          icon: <Icon.warningAlert />,
          content: `ดึงข้อมูลใหม่สำเร็จ`,
          onOk() {
            getDataAccountMail(memoizedData);
          },
          okText: 'ยืนยัน',
        });
      }
    } catch (err) {
      const {error} = err;
      Modal.error({
        title: 'แจ้งเตือน',
        icon: <Icon.warningAlert />,
        content: `ดึงข้อมูลไม่สำเร็จ ${error?.message || ''}`,
        onOk() {
          getDataAccountMail(memoizedData);
        },
        okText: 'ยืนยัน',
      });
    }
  };

  const closeIcloudAccount = async () => {
    setLoading(true);
    try {
      const mailIcloudList = mailIcloudCheck.map((dt) => ({
        mail: dt.mail,
        contractNo: dt.contractNo,
      }));
      const res = await POST(GET_CHANGE_MAIL_STATUS_ICLOUD(), {email: mailIcloudList});
      const {success} = res;
      if (success) {
        Modal.info({
          title: 'แจ้งเตือน',
          icon: <Icon.warningAlert />,
          content: `ปิดใช้งาน iCloud สำเร็จ`,
          onOk() {
            getDataAccountMail(memoizedData);
          },
          okText: 'ยืนยัน',
        });
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message || '',
        content: error || '',
      });
    }
  };

  const onCloseExport = () => setVisibleExport(false);

  const actionSave = async () => {
    getDataAccountMail(memoizedData);
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'จัดการข้อมูลบัญชี',
              icon: <SettingOutlined />,
              spanSearch: 24,
              formSearch: ManageAccountDashboardSearchForm({option: {icloudStatusOption}}),
              onSearch: (data) => setFilterDt(data),
              onClearCustom: onClearCustom,
              formJustify: 'left',
            }}
            tableLayout={{
              columns: ManageAccountColumn({openModal}),
              dataSource: dataSource,
              hidden: hideTable,
              extraContent: (
                <Row
                  style={{
                    width: '100%',
                  }}
                  align="bottom"
                  justify="space-between">
                  <Space>
                    <ButtonTheme
                      useFor="CONTACT"
                      title={'ดึงข้อมูลใหม่'}
                      icon={<Icon.reAct />}
                      disabled={!selectedRowKeys.length > 0}
                      onClick={() => recheckIcloudAccount(selectedRowKeys)}
                    />
                    <ButtonTheme
                      useFor="REJECT"
                      title={'ปิดใช้งาน'}
                      icon={<Icon.close />}
                      disabled={!selectedRowKeys.length > 0}
                      onClick={onConfirmClose}
                    />
                  </Space>
                  <Space>
                    <ContainerButton right>
                      <ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด Excel'} onClick={() => setVisibleExport(true)} />
                    </ContainerButton>
                  </Space>
                </Row>
              ),
              pagination: {
                showSizeChanger: true,
                current: paginations.page,
                pageSize: paginations.size,
                onChange: (p, s) => setPaginations({page: p, size: s}),
                pageSizeOptions: ['10', '20', '30', '40', '100'],
                total: totalSize || 0,
                showTotal: (totalSize, range) => `รายการทั้งหมด ${convertStrToFormat(totalSize, 'number-comma')} รายการ`,
                defaultPageSize: paginations.page,
                defaultCurrent: 1,
              },
              rowSelection: {
                selectedRowKeys,
                onChange: (keys, record) => {
                  setSelectedRowKeys(keys);
                  setMailIcloudCheck(record);
                },
                getCheckboxProps: (record) => ({
                  disabled: ['INACTIVE', 'CLOSED'].includes(record?.status),
                }),
              },
            }}
          />
        </Spin>
      </Card>
      <EditAccountModal visible={visibleEdit} title={title} onClose={closeModal} data={{reApi: actionSave, record: valueDefault}} />
      <ManageIcloudModal visible={visibleManage} title={title} onClose={closeModal} data={{reApi: actionSave, record: valueDefault}} />
      {visibleChangeContract && (
        <ChangeContractIcloudModal
          visible={visibleChangeContract}
          title={title}
          onClose={closeModal}
          data={{reApi: actionSave, record: valueDefault}}
        />
      )}
      <Modal
        title={'ต้องการดาวน์โหลด Excel?'}
        open={visibleExport}
        width={550}
        onCancel={onCloseExport}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}>
        <ContainerButton right>
          <ButtonTheme useFor="CUSTOM" title={'ตกลง'} onClick={(data) => onExportExcelReport(data)} />
          <ButtonTheme useFor="CANCEL" onClick={() => onCloseExport()} />
        </ContainerButton>
      </Modal>
    </Wrapper>
  );
};

export default ManageAccount;
