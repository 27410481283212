export const ConfirmAppointmentContractColumn = () => {
  return [
    {
      title: 'วันที่บันทึก',
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: '20%',
      render: (item) => {
        return item
      }
    },
    {
      title: 'ครั้งที่',
      dataIndex: 'no',
      key: 'no',
      width: '10%',
      align: 'center'
    },
    {
      title: 'วันที่ทำสัญญา',
      dataIndex: 'appointDate',
      key: 'appointDate',
      width: '20%',
      render: (item) => {
        return item
      }
    },
    {
      title: 'เวลา',
      dataIndex: 'appointTime',
      key: 'appointTime',
      width: '10%',
      render: (item) => {
        return item
      }
    },
    {
      title: 'สาขาที่นัด',
      dataIndex: 'branchName',
      key: 'branchName',
      width: '15%'
    },
    {
      title: 'บันทึกโดย',
      dataIndex: 'createdName',
      key: 'createdName',
      width: '25%'
    }
  ]
}
