// /* eslint no-use-before-define: 0 */
import React, {useState, useEffect, useRef, useContext} from 'react';
import Wrapper from '../../../../views/wrapper/Wrapper';
import {useHistory} from 'react-router-dom';
import {Modal, Card, Row, Col, Input, Alert, Space} from 'antd';
import {ButtonTheme} from '../../../../components/buttons';
import {PlusCircleOutlined} from '@ant-design/icons';
import {TextLarge, TextSmall} from '../../../../components/forms/text';
import {useForm} from 'react-hook-form';
import {ContainerButton} from '../../../../styles/styledComponentGlobal';
import {TableLayout} from '../../../../components/pageLayout/TableLayout';
import {TrackingNoColumn} from '../columns';
import {POST, GET_INFO_FROM_TRACKING, UPDATE_TRACKING_ACKNOWLEDGMENT} from '../../../../services';
import ResultUploadTrackingNoModal from '../../../../components/modal/TrackingNo/ResultUploadTrackingNoModal';
import {Icon} from '../../../../resources/icon';
import { toastContext } from '../../../../utils/toastProvider';
import { toast } from 'react-toastify';

const AddAcknowledgementTrackingNo = () => {
  const history = useHistory();
  const {setValue, getValues, resetField, register} = useForm();
  const ContractNoRef = useRef(null);
  const [trackingNoList, setTrackingNoList] = useState([]);
  const [resultTrackingList, setResultTrackingList] = useState([]);
  const [contractNo, setContractNo] = useState('');
  const [visibleResultTracking, setVisibleResultTracking] = useState(false);
  const [config] = useContext(toastContext);

  useEffect(() => {
    ContractNoRef.current.focus();
  }, []);

  const onAddTrackingNo = async () => {
    try {
      const { ContractNo } = getValues();
      
      // Filter TrackingNo based on ContractNo
      const filterTrackingNo = trackingNoList?.filter(item => item?.ContractNo === ContractNo)?.map((item)=> item.TrackingNo);

      const payload = { contract_no: ContractNo, tracking_no: filterTrackingNo };
      const res = await POST(GET_INFO_FROM_TRACKING, payload);
      const {success, data} = res;
      
      if (success) {
        setTrackingNoList(
          [...trackingNoList, {ContractNo: data?.contract_no, fullName: data?.name, TrackingNo: data?.tracking_no}].map((el, index) => {
            return {...el, key: index + 1};
          }),
        );
        
        handleClear()
        toast.success('เพิ่ม Tracking No สำเร็จ', config);
      }
    } catch (error) {
      Modal.error({title: error?.message});
    }
  };

  const handleKeyUp = (e, name) => {
    if (e.key === 'Enter') {
      if (name === 'ContractNo') {
        if (!contractNo) {
          return Modal.error({title: 'กรุณากรอกเลข Contract No'});
        }
        onAddTrackingNo();
      }
    }
  };

  const onChange = (e, name) => {
    if (name === 'ContractNo') {
      setContractNo(e.target.value);
      setValue('ContractNo', e.target.value);
    }
  };

  const onRemove = (record) => {
    const dataFilter = trackingNoList.filter((item) => item.key !== record?.key);
    setTrackingNoList(dataFilter);
  };

  const onConfirmRemove = (record) => {
    Modal.confirm({
      title: 'ยืนยัน',
      icon: <Icon.warningAlert />,
      content: 'ต้องการลบรายการนี้หรือไม่?',
      onOk() {
        onRemove(record);
      },
      okText: 'ลบ',
      cancelText: 'ยกเลิก',
    });
  };

  const onSubmit = async () => {
    try {
      let tracking_no = [];
      trackingNoList.forEach((el) => {
        tracking_no.push(el.TrackingNo);
      });
      const payload = {tracking_no};

      const res = await POST(UPDATE_TRACKING_ACKNOWLEDGMENT, payload);
      const {success, message} = res;
      if (success) {
        Modal.success({title: message});
        setTrackingNoList([]);
      }
    } catch (error) {
      Modal.error({title: error?.message});
    }
  };

  const onCloseModal = () => {
    setVisibleResultTracking(false);
    setTrackingNoList([]);
  };

  const onConfirm = () => {
    Modal.confirm({
      title: 'ยืนยัน',
      icon: <Icon.warningAlert />,
      content: 'ยืนยันการบันทึกข้อมูล',
      onOk() {
        onSubmit();
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };
  
  const handleClear = () => {
    resetField('ContractNo');
    setValue('ContractNo', '');
    setContractNo('');
    ContractNoRef.current.focus();
  };

  return (
    <Wrapper>
      <ContainerButton left style={{marginTop: '20px'}}>
        <ButtonTheme useFor="BACK" onClick={() => history.goBack()} />
      </ContainerButton>
      <Card
        title={
          <div style={{display: 'flex', alignItems: 'center', color: '#0052BA'}}>
            {<PlusCircleOutlined />}
            &nbsp;
            <TextLarge text={'เพิ่มเลขที่ใบตอบรับจากไปรษณีย์'} color={'#0052BA'} />
          </div>
        }>
        <Space
          direction="vertical"
          style={{
            width: '100%',
          }}>
          <Alert message="* หลังจากสแกนบาร์โค้ดแล้ว Enter เพื่อเพิ่ม Contract No." type="warning" showIcon />
        </Space>

        <Row style={{width: '100%', marginTop: '20px', paddingLeft: '8px'}} gutter={[16, 16]}>
          <Col xs={{span: 24}} md={{span: 24}} xl={{span: 12}} lg={{span: 12}}>
            <TextSmall bold={true} text={'สแกนบาร์โค้ดหรือกรอกข้อมูล Contract No.'} />
            <Input
              placeholder="ContractNo"
              name="ContractNo"
              ref={ContractNoRef}
              onPressEnter={(e) => handleKeyUp(e, 'ContractNo')}
              onChange={(e) => onChange(e, 'ContractNo')}
              value={contractNo}
              style={{
                marginBottom: '5px'
              }}
            />
          </Col>
          <Col
            xs={{span: 24}}
            md={{span: 24}}
            xl={{span: 2}}
            lg={{span: 2}}
            style={{
              display: 'flex',
              alignItems: 'flex-end',
            }}>
            <ButtonTheme useFor="CLEAR" onClick={() => handleClear()} />
          </Col>
        </Row>

        {trackingNoList.length > 0 && (
          <>
            <TableLayout props={{columns: TrackingNoColumn(onConfirmRemove), dataSource: trackingNoList, title: 'แสดงรายการ'}} />
            <ContainerButton center style={{marginTop: '20px'}}>
              <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" onClick={() => onConfirm()} />
              <ButtonTheme useFor="CANCEL" onClick={() => history.goBack()}/>
            </ContainerButton>
          </>
        )}
      </Card>
      {visibleResultTracking && <ResultUploadTrackingNoModal data={resultTrackingList} visible={visibleResultTracking} onClose={onCloseModal} />}
    </Wrapper>
  );
};

export default AddAcknowledgementTrackingNo;
