import React, { useState, useEffect } from 'react';
import { Modal, Divider, Select, Tag, Spin, Typography } from 'antd';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../buttons';
import { GET, PUT, GET_CONTRACT_NO_DATA_MANAGE_ACCOUNT, GET_CONTRACT_NO_OPTION_MANAGE_ACCOUNT, EDIT_CONTRACT_NO_MANAGE_ACCOUNT } from '../../../services/index';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { color } from '../../../resources/color';

const { Text } = Typography;

export const ChangeContractIcloudModal = ({ title, visible, onClose, data }) => {

  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState({});
  const [selectedContract, setSelectedContract] = useState('');
  const [contractInfo, setContractInfo] = useState(null);
  const [contractNumberOption, setContractNumberOption] = useState([]);
  const [errorSelect, setErrorSelect] = useState(false);
  //const dataLogin = JSON.parse(localStorage.getItem('dataLogin'))[0];

  useEffect(async () => {
    await getContractNumberOption()
  }, [])

  useEffect(() => {
    setRecord(data.record);
  }, [data.record]);

  const onCloseMain = () => {
    setErrorSelect(false)
    setSelectedContract('')
    setContractInfo(null)
    setContractNumberOption([])
    onClose('changeContract');
  };
  const onChangeCustom = async (value, ref) => {
    if (ref === 'selecteContract') {
      setErrorSelect(false)
      setSelectedContract(value)
      await getContractInfo(value)
    }
  };

  const getContractNumberOption = async () => {
    setLoading(true)
    try {
      let res = await GET(GET_CONTRACT_NO_OPTION_MANAGE_ACCOUNT);
      const { success, result } = res;
      if (success) {
        let data = result.map((el) => {
          return {
            value: el.contract_no,
            label: el.contract_no,
          }
        })
        setContractNumberOption(data)
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }
  }

  const getContractInfo = async (value) => {
    setLoading(true)
    try {
      let res = await GET(GET_CONTRACT_NO_DATA_MANAGE_ACCOUNT(value));
      const { success, result } = res;
      if (success) {
        setContractInfo(result)
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }
  }

  const onSubmit = async () => {

    setLoading(true);
    const obj = {
      icloud_email: record.mail,
    };

    try {
      let res = await PUT(EDIT_CONTRACT_NO_MANAGE_ACCOUNT(selectedContract), obj);
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            onCloseMain();
            setSelectedContract('')
            setContractInfo(null)
            data.reApi();
          },
        });
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onConfirm = () => {
    if (selectedContract !== '') {
      Modal.confirm({
        title: 'ยืนยันเปลี่ยนสัญญากับไอคลาวน์',
        icon: <ExclamationCircleOutlined />,
        content: 'ต้องการยืนยันเปลี่ยนสัญญากับไอคลาวน์นี้ ใช่หรือไม่',
        onOk() {
          onSubmit();
        },
        okText: 'ยืนยัน',
        cancelText: 'ยกเลิก',
      });
    } else {
      setErrorSelect(true)
      // Modal.error({
      //   content: 'กรุณาระบุสัญญาที่ต้องการเปลียนกับไอคลาวน์นี',
      // });
    }
  };

  const tagStatusColor = (value) => {
    switch (value) {
      case 'WAIT_SIGNATURE':
        return color.edit
      case 'ACTIVE':
        return color.on_process;
      case 'WAIT_ADMIN_CONFIRM':
        return color.manage;
      case 'WAIT_WAIT_FINANCE_APPROVE':
        return color.tranfer
    }
  }

  const tagStatusLabel = (value) => {
    switch (value) {
      case 'WAIT_SIGNATURE':
        return 'รอเซ็นสัญญา'
      case 'ACTIVE':
        return 'กำลังใช้งาน';
      case 'WAIT_ADMIN_CONFIRM':
        return 'รอเจ้าหน้าที่อนุมัติ';
      case 'WAIT_WAIT_FINANCE_APPROVE':
        return 'รอการเงินอนุมัติ'
    }
  }

  return (
    <Modal title={title || 'modal'} open={visible} onCancel={onCloseMain} width={480} footer={null} destroyOnClose={true} maskClosable={false}>
      <Select
        style={{ width: '100%' }}
        showSearch
        placeholder={`กรุณาเลือกเลขที่สัญญา`}
        onChange={(e) => onChangeCustom(e, 'selecteContract')}
        options={contractNumberOption}
      >
      </Select>
      {selectedContract === '' && errorSelect ? <div style={{ marginBlock: 10 }}>
        <ExclamationCircleOutlined style={{ color: 'red', marginRight: 5 }} />
        <Text type="danger">กรุณาระบุสัญญาที่ต้องการเปลียนกับไอคลาวน์นี</Text>
      </div> : <></>}
      {
        (selectedContract !== '') ?
          <Spin tip={'Loading...'} spinning={loading}>
            <Divider />
            <p><b>ชื่อลูกค้า : </b> {contractInfo?.customer_name}</p>
            <p><b>สถานะ : </b><Tag color={tagStatusColor(contractInfo?.status)}>{tagStatusLabel(contractInfo?.status)}</Tag></p>
            <p><b>ชื่อสินค้า : </b>{contractInfo?.products_name}</p>
            <p><b>SN : </b>{contractInfo?.serialNumber}</p>
          </Spin>
          : <></>
      }
      <ContainerButton right sty>
        <ButtonTheme useFor="SUBMIT" title="บันทึก" onClick={() => onConfirm()} />
      </ContainerButton>
    </Modal>
  );
};
