import * as TYPES from '../types/product';
import * as API from '../api/product';

export const getProducts = (params) => async (dispatch) => {
    dispatch({ type: TYPES.LIST_PRODUCT_REQ });
    try {
        const response = await API.getProducts(params);
        if (response.status === 200) {
            dispatch({ type: TYPES.LIST_PRODUCT_SUCCESS, payload: response });
        } else {
            dispatch({ type: TYPES.LIST_PRODUCT_FAIL, payload: response });
        }
    } catch (error) {
        dispatch({ type: TYPES.LIST_PRODUCT_FAIL, payload: error });
    }
};

export const getProductInfo = (code) => async (dispatch) => {
    dispatch({ type: TYPES.GET_PRODUCT_REQ });
    try {
        const response = await API.getProductInfo(code);
        if (response.status === 200) {
            dispatch({ type: TYPES.GET_PRODUCT_SUCCESS, payload: response });
        } else {
            dispatch({ type: TYPES.GET_PRODUCT_FAIL, payload: response });
        }
    } catch (error) {
        dispatch({ type: TYPES.GET_PRODUCT_FAIL, payload: error });
    }
};

export const updateProductInfo = (code, body) => async (dispatch) => {
    dispatch({ type: TYPES.UPDATE_PRODUCT_REQ });
    try {
        const response = await API.updateProduct(code, body);
        if (response.status === 200) {
            dispatch({ type: TYPES.UPDATE_PRODUCT_SUCCESS, payload: response });
        } else {
            dispatch({ type: TYPES.UPDATE_PRODUCT_FAIL, payload: response });
        }
    } catch (error) {
        dispatch({ type: TYPES.UPDATE_PRODUCT_FAIL, payload: error });
    }
};