import { Modal, Table } from 'antd';
import moment from 'moment';

export const BlacklistCustomerModal = (props) => {
    const { openUserBlacklist, onClose, fbBlacklist } = props;
    return (
        <Modal
            width={1300}
            open={openUserBlacklist} footer={null} onCancel={onClose}
        >
            {fbBlacklist && Array.isArray(fbBlacklist.blacklist) &&
                <Table
                    scroll={{ x: 240 }}
                    tableLayout="fixed"
                    columns={[
                        {
                            title: '#',
                            dataIndex: 'no',
                            width: '5%',
                            render: (record, text, index) => {
                                return index + 1
                            }
                        },
                        {
                            title: 'ลิงก์',
                            dataIndex: 'url',
                            width: '50%',
                            render: (text, record) => {
                                const urlLink = record.source ? record.link : record.ref
                                if (Array.isArray(urlLink) && urlLink[0] && urlLink[0].url) {
                                    return <div>{urlLink.map((l, i) => {
                                        return l.url.map(linkR => {
                                            return <div key={`${linkR}_${i}`}><a target="_blank" rel="noreferrer" href={!linkR.includes('https') ? `https://${linkR.trim()}` : linkR} >{linkR}</a></div>
                                        })
                                    })}</div>
                                }

                                return <div>{urlLink && urlLink.map((l, i) => {
                                    let linkR = l
                                    return linkR && <div key={`${linkR}_${i}`}><a target="_blank" rel="noreferrer" href={!linkR.includes('https') ? `https://${linkR.trim()}` : linkR} >{linkR}</a></div>
                                })}</div>
                            },
                        },
                        {
                            title: 'ประเภท',
                            dataIndex: 'source',
                        },
                        {
                            title: 'แหล่งที่มา',
                            dataIndex: 'runBy',
                        },
                        {
                            title: 'วันที่ตรวจสอบ',
                            dataIndex: 'create_date',
                            render: (text, record) => {
                                return moment(text).format('YYYY-MM-DD HH:mm')
                            },
                        },
                    ]}
                    dataSource={fbBlacklist.blacklist}
                />
            }
        </Modal>
    )
}

