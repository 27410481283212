export const optionOtherFee = [
    {
        value:"TRACKING_COST",
        label:"ค่าติดตามภาคสนาม"
    },
    {
        value:"STORAGE_COST",
        label:"ค่าเก็บรักษา"
    },
    {
        value:"OTHER",
        label:"อื่นๆ"
    }
]
