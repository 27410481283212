import React, {useEffect, useState} from 'react';
import {Col, Form, Row, Modal} from 'antd';
import _ from 'lodash';
import {SelectMDMProvider, SelectMDMSite, SelectTypeLock} from '../../../../components/inputfromapi';
import {getAdminMDMDefault} from '../../../../apiv2';
import {updateContractTypeLock} from './api';
import {Spinners} from '../../../../components';

const ModalUpdateTypeLock = (props) => {
  const formName = 'modalUpdateTypeLock';
  const filterMaster = {is_visible: true, is_active: true};
  const {visible, onClose, modalData, onRefresh} = props;

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [defaultDataKey, setDefaultDataKey] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({...modalData});
    setData({...modalData});
  }, [modalData]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await getAdminMDMDefault();
      
      if (res) {
        const MDMDefaultKey = _.keyBy(res, 'type_lock');
        setDefaultDataKey(MDMDefaultKey);
      }
      setLoading(false);
    })();
  }, []);

  const onFinish = async (values) => {
    
    try {
      setLoading(true);
      const setInput = {
        contract_no: modalData?.contract_no ? modalData?.contract_no : '',
        type_lock: values?.type_lock ? values?.type_lock : '',
        mdm_provider_code: values?.mdm_provider_code ? values?.mdm_provider_code : '',
        mdm_site_code: values?.mdm_site_code ? values?.mdm_site_code : '',
      };

      const response = await updateContractTypeLock({...setInput});

      if (response) {
        Modal.success({
          type: 'success',
          title: 'บันทึกข้อมูลสำเร็จ',
          onOk() {
            onUpdateComplete();
          },
        });
      }
    } catch (error) {
      Modal.error({
        title: 'ไม่สามารถบันทึกข้อมูลได้',
        content: error?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onUpdateComplete = () => {
    onRefresh();
    onCancel();
  };

  const onCancel = () => {
    onClose();
    form.resetFields();
  };

  const handleTypeLockChange = (value) => {
    form.setFieldsValue({
      type_lock: value,
      mdm_provider_code: defaultDataKey[value]?.mdm_provider_code,
      mdm_site_code: defaultDataKey[value]?.mdm_site_code,
    });

    setData((prev) => {
      return {...prev, type_lock: value};
    });
  };
  return (
    <>
      {loading && <Spinners />}
      <Form
        form={form}
        name={formName}
        autoComplete="off"
        scrollToFirstError={true}
        labelCol={{offset: 2, xs: 8, md: 8, lg: 8, xl: 8, xxl: 8}}
        wrapperCol={{xs: 12, md: 12, lg: 12, xl: 12, xxl: 12}}
        onFinish={(value) => {
          Modal.confirm({
            title: `ยืนยันการบันทึกข้อมูล ?`,
            onOk() {
              onFinish(value);
            },
            onCancel() {},
            okText: 'ยืนยัน',
            cancelText: 'ยกลิก',
          });
        }}>
        <Modal open={visible} onOk={form.submit} onCancel={onCancel} title={'แก้ไขรูปแบบการล็อกเครื่อง'} okText="ยืนยัน" cancelText="ยกลิก">
          <Row justify="center">
            <Col {...{xs: 24, md: 24, lg: 24, xl: 24, xxl: 24}}>
              <Row justify="center">
                <Col {...{xs: 24, md: 24, lg: 24, xl: 24, xxl: 24}}>
                  <Form.Item name={'type_lock'} label={'รูปแบบการล็อกเครื่อง'} rules={[{required: true, message: 'กรุณาระบุ'}]}>
                    <SelectTypeLock
                      placeholder={'เลือกรูปแบบการล็อกเครื่อง'}
                      handleChange={(e) => {
                        handleTypeLockChange(e?.value);
                      }}
                      filter={filterMaster}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="center">
                <Col {...{xs: 24, md: 24, lg: 24, xl: 24, xxl: 24}}>
                  <Form.Item
                    name={'mdm_provider_code'}
                    label={'ผู้ให้บริการ'}
                    rules={[{required: data?.type_lock !== 'ICLOUD', message: 'กรุณาระบุ'}]}
                    hidden={data?.type_lock === 'ICLOUD'}>
                    <SelectMDMProvider
                      placeholder={'เลือกผู้ให้บริการ'}
                      handleChange={(e) => {
                        form.setFieldValue('mdm_provider_code', e?.value);
                      }}
                      filter={filterMaster}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="center">
                <Col {...{xs: 24, md: 24, lg: 24, xl: 24, xxl: 24}}>
                  <Form.Item
                    name={'mdm_site_code'}
                    label={'Site MDM'}
                    rules={[{required: data?.type_lock !== 'ICLOUD', message: 'กรุณาระบุ'}]}
                    hidden={data?.type_lock === 'ICLOUD'}>
                    <SelectMDMSite
                      placeholder={'เลือกSite MDM'}
                      handleChange={(e) => {
                        form.setFieldValue('mdm_site_code', e?.value);
                      }}
                      filter={filterMaster}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal>
      </Form>
    </>
  );
};

export default ModalUpdateTypeLock;
