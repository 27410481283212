// /* eslint no-use-before-define: 0 */
import React, {useState, useEffect, useRef, useContext} from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import {useHistory} from 'react-router-dom';
import {Modal, Card, Row, Col, Input, Alert, Space} from 'antd';
import {ButtonTheme} from '../../../components/buttons';
import {PlusCircleOutlined} from '@ant-design/icons';
import {TextLarge, TextSmall} from '../../../components/forms/text';
import {useForm} from 'react-hook-form';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {TableLayout} from '../../../components/pageLayout/TableLayout';
import {TrackingNoColumn} from './columns';
import {POST, GET_NAME_BY_CONTRACT, UPLOAD_TRACKING} from '../../../services';
import ResultUploadTrackingNoModal from '../../../components/modal/TrackingNo/ResultUploadTrackingNoModal';
import { Icon } from '../../../resources/icon';
import { toastContext } from '../../../utils/toastProvider';
import { toast } from 'react-toastify';

const AddTrackingNo = () => {
  const history = useHistory();
  const {setValue, getValues, resetField, register} = useForm();
  const ContractNoRef = useRef(null);
  const TrackingNoRef = useRef(null);
  const [trackingNoList, setTrackingNoList] = useState([]);
  const [resultTrackingList, setResultTrackingList] = useState([]);
  const [contractNo, setContractNo] = useState('');
  const [trackingNo, setTrackingNo] = useState('');
  const [loading, setLoading] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [visibleResultTracking, setVisibleResultTracking] = useState(false);
  const [config] = useContext(toastContext);

  useEffect(() => {
    ContractNoRef.current.focus();
  }, []);

  const onAddTrackingNo = async () => {
    try {
      const {ContractNo, TrackingNo} = getValues();
      const payload = {contract_no: ContractNo, tracking_no: TrackingNo};
      const res = await POST(GET_NAME_BY_CONTRACT, payload);
      const {success, data} = res;
      if (success) {
        setTrackingNoList(
          [...trackingNoList, {ContractNo: ContractNo, fullName: data?.name, TrackingNo}].map((el, index) => {
            return {...el, key: index + 1};
          }),
        );
        
        handleClear()
        toast.success('เพิ่ม Tracking No สำเร็จ', config);
      }
    } catch (error) {
      Modal.error({title: error?.message});
    }
  };

  const handleKeyUp = (e, name) => {
    if (e.key === 'Enter') {
      if (name === 'ContractNo') {
        // if (trackingNoList.filter((item) => item.ContractNo === contractNo).length > 0) {
        //   return Modal.error({title: 'มีเลขที่สัญญานี้แล้ว'});
        // }
        TrackingNoRef.current.focus();
      } else if (name === 'TrackingNo') {
        if (!trackingNo) {
          return Modal.error({title: 'กรุณากรอกเลข Tracking No'});
        }
        onAddTrackingNo();
      }
    }
  };

  const onChange = (e, name) => {
    if (name === 'ContractNo') {
      setValue('ContractNo', e.target.value);
      setContractNo(e.target.value);
      setDisabledBtn(false);
    } else if (name === 'TrackingNo') {
      setTrackingNo(e.target.value);
      setValue('TrackingNo', e.target.value);
    }
  };

  const onRemove = (record) => {
    const dataFilter = trackingNoList.filter((item) => item.key !== record?.key);
    setTrackingNoList(dataFilter);
  };

  const onConfirmRemove = (record) => {
    Modal.confirm({
      title: 'ยืนยัน',
      icon: <Icon.warningAlert />,
      content: 'ต้องการลบรายการนี้หรือไม่?',
      onOk() {
        onRemove(record);
      },
      okText: 'ลบ',
      cancelText: 'ยกเลิก',
    });
  };

  const onSubmit = async () => {
    try {
      setLoading(true)
      const formattedData = trackingNoList?.map((item)=>{
        return {
        contractNo : item.ContractNo,
        trackingNo : item.TrackingNo
        }
      })
      const res = await POST(UPLOAD_TRACKING, {contract: formattedData});
      const {success} = res;
      if (success) {
        setTrackingNoList([]);
        Modal.success({
					title: 'บันทึกสำเร็จ',
					afterClose: () => {
						// onCloseMain();
						// data.reApi({});
					},
				});       
      }
    } catch (error) {
      Modal.error({title: error?.message});
    }finally{
      setLoading(false)
    }
  };

  const onCloseModal = () => {
    setVisibleResultTracking(false);
    setTrackingNoList([]);
  };

  const onConfirm = ()=>{
    Modal.confirm({
      title: "ยืนยัน",
      icon: <Icon.warningAlert />,
      content: "ยืนยันการบันทึกข้อมูล",
      onOk() {
        onSubmit();
      },
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
    });
  }
  
  const handleClear = () => {
    resetField('ContractNo');
    resetField('TrackingNo');
    setValue('ContractNo', '');
    setValue('TrackingNo', '');
    setContractNo('');
    setTrackingNo('');
    setDisabledBtn(true);
    ContractNoRef.current.focus();
  };
  
  return (
    <Wrapper>
      <Card
        title={
          <div style={{display: 'flex', alignItems: 'center', color: '#0052BA'}}>
            {<PlusCircleOutlined />}
            &nbsp;
            <TextLarge text={'เพิ่ม Tracking No'} color={'#0052BA'} />
          </div>
        }>
        <Space
          direction="vertical"
          style={{
            width: '100%',
          }}>
          <Alert message="* หลังจากสแกนบาร์โค้ดแล้ว Enter เพื่อไปช่องถัดไป หรือ เพิ่ม Tracking No." type="warning" showIcon />
        </Space>

        <Row style={{width: '100%', marginTop: '20px', paddingLeft: '8px'}} gutter={[16, 16]}>
          <Col xs={{span: 24}} md={{span: 24}} xl={{span: 11}} lg={{span: 11}}>
            <TextSmall bold={true} text={'สแกนบาร์โค้ดหรือกรอกข้อมูลเลขที่สัญญา'} />
            <Input
              placeholder="เลขที่สัญญา"
              name="ContractNo"
              ref={ContractNoRef}
              onPressEnter={(e) => handleKeyUp(e, 'ContractNo')}
              onChange={(e) => onChange(e, 'ContractNo')}
              value={contractNo}
            />
          </Col>
          <Col xs={{span: 24}} md={{span: 24}} xl={{span: 11}} lg={{span: 11}}>
            <TextSmall bold={true} text={'สแกนบาร์โค้ดหรือกรอกข้อมูล Tracking No.'} />
            <Input
              placeholder="TrackingNo"
              name="TrackingNo"
              ref={TrackingNoRef}
              onPressEnter={(e) => handleKeyUp(e, 'TrackingNo')}
              onChange={(e) => onChange(e, 'TrackingNo')}
              disabled={disabledBtn}
              value={trackingNo}
              style={{
                marginBottom: '5px'
              }}
            />
          </Col>
          <Col
            xs={{span: 24}}
            md={{span: 24}}
            xl={{span: 2}}
            lg={{span: 2}}
            style={{
              display: 'flex',
              alignItems: 'flex-end',
            }}>
            <ButtonTheme useFor="CLEAR" onClick={() => handleClear()} />
          </Col>
        </Row>

        {trackingNoList.length > 0 && (
          <>
            <TableLayout props={{columns: TrackingNoColumn(onConfirmRemove), dataSource: trackingNoList, title: 'แสดงรายการ'}} />
            <ContainerButton center style={{marginTop: '20px'}}>
             
              <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" onClick={() => onConfirm()} loading={loading}/>
              <ButtonTheme useFor="CANCEL" onClick={() => history.goBack()}/>
            </ContainerButton>
          </>
        )}
      </Card>
      {visibleResultTracking && <ResultUploadTrackingNoModal data={resultTrackingList} visible={visibleResultTracking} onClose={onCloseModal} />}
    </Wrapper>
  );
};

export default AddTrackingNo;
