import React, {useState, useEffect} from 'react';
import {Modal, Col, Form, Row, Input, Select} from 'antd';
import {ContainerButton} from '../../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../../../components/buttons';
import {Icon} from '../../../../resources/icon';
import {useGetHistorySocialId, useSaveSocialId} from '../../../../hook/chatcone';
import {SelectChatconeSocial} from '../../../../components/inputfromapi';
import {DatatableLog, Spinners} from '../../../../components';
import {TextSmall} from '../../../../components/forms/text';
import {EditSocialIdAndTagsHistoryColumn} from '../columns/EditSocialIdAndTagsHistoryColumn';
import {APP_URL} from '../../../../env';
import {useLocation} from 'react-router-dom';

export const EditSocailIdAndTagsModal = ({title, visible, onClose, data}) => {
  const onCloseMain = () => {
    data.reApi(data.valueSearch);
    onClose();
  };

  const {pathname} = useLocation();
  const [dataBefore, setDataBefore] = useState(null);
  const [saveSocialIdFn, saveSocialIdLoading] = useSaveSocialId({ishowModal: true, onCloseMain: onCloseMain});
  const [getHistorySocialIdFn, getHistorySocialIdData, getHistorySocialIdLoading] = useGetHistorySocialId();

  useEffect(() => {
    const tmpData = {social_id: data?.chatcone?.social_id, channel_id: data?.chatcone?.channel_id, tag: data?.chatcone?.tag};

    if (visible && data?.refId) {
      getHistorySocialIdFn({refId: data?.refId, refName: data?.refName});
    }

    form.setFieldsValue(tmpData);
    setDataBefore(tmpData);
  }, [data?.refId, visible]);

  const onFinish = async (params) => {
    const setInput = {
      ...params,
      refId: data?.refId,
      refName: data?.refName,
      dataBefore: dataBefore,
      processType: data?.processType,
      routeFrom: `${APP_URL}${pathname}`,
    };

    const response = await saveSocialIdFn(setInput);

    if (response) {
      Modal.success({
        title: 'บันทึกข้อมูลสำเร็จ',
        afterClose: () => {
          onCloseMain();
        },
      });
    }
  };

  const RESPONSIVE_VERTICAL = {xs: 24, md: 24, lg: 12, xl: 12, xxl: 12};
  const RESPONSIVE_VERTICAL_FULL = {xs: 24, md: 24, lg: 24, xl: 24, xxl: 24};

  const [form] = Form.useForm();
  return (
    <>
      {(saveSocialIdLoading || getHistorySocialIdLoading) && <Spinners />}
      <Modal
        title={title || 'modal'}
        open={visible}
        width={1600}
        onCancel={onCloseMain}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
        style={{border: 'unset'}}>
        <Form
          form={form}
          scrollToFirstError={true}
          autoComplete="off"
          labelAlign="left"
          layout="vertical"
          onFinish={(value) => {
            Modal.confirm({
              title: 'ยืนยัน',
              icon: <Icon.warningAlert />,
              content: 'ยืนยันการบันทึก',
              onOk() {
                onFinish(value);
              },
              okText: 'ยืนยัน',
              cancelText: 'ยกเลิก',
            });
          }}>
          <Row gutter={[12, 0]}>
            <Col {...RESPONSIVE_VERTICAL}>
              <Form.Item label={<TextSmall bold={true} text={'Channel'} />} name={'channel_id'}>
                <SelectChatconeSocial
                  handleChange={(e) => {
                    form.setFieldValue('channel_id', e?.value);
                  }}
                  defaultValue={data?.chatcone?.channel_id}
                />
              </Form.Item>
            </Col>
            <Col {...RESPONSIVE_VERTICAL}>
              <Form.Item label={<TextSmall bold={true} text={'Social id'} />} name={'social_id'}>
                <Input style={{borderRadius: '10px'}} defaultValue={data?.chatcone?.social_id} />
              </Form.Item>
            </Col>
            <Col {...RESPONSIVE_VERTICAL_FULL}>
              <Form.Item label={<TextSmall bold={true} text={'Tag'} />} name={'tag'}>
                <Select mode="tags" style={{width: '100%'}} 
                  tokenSeparators={[' ']}
                  options={[]}
                  />
              </Form.Item>
            </Col>
          </Row>

          <ContainerButton right>
            <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" loading={saveSocialIdLoading} />
            <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
          </ContainerButton>
        </Form>

        <DatatableLog
          style={{marginTop: '20px'}}
          columns={EditSocialIdAndTagsHistoryColumn()}
          data={getHistorySocialIdData}
          pagination={{pageSize: 5}}
          showDivider={false}
        />
      </Modal>
    </>
  );
};
