import { Select } from 'formik-antd';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FetchRefinanceProductCode, getProductList } from './api';
import _ from 'lodash'

const ProductSearch = (props) => {
	const [choices, setChoices] = useState([]);
	const [keyValueProduct, setKeyValueProduct] = useState({});

	useEffect(() => {
		(async () => {
			var products = await getProductList();
      
      const filterProducts = products.filter((a) => /g..2/i.test(a.code) && !/ยกเลิก/i.test(a.name)).map((a) => ({ label: `[${a.code}] ${a.name} (${a.plu})`, value: a.code , name: a.name }))
			setChoices(filterProducts);
			setKeyValueProduct(_.keyBy(filterProducts, 'value'))
		})();
	}, []);

  const filterOption = (input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

	return <Select {...props} showSearch optionFilterProp="label" options={choices} filterOption={filterOption} onChange={(e) => FetchRefinanceProductCode(e, keyValueProduct[e]?.name)}></Select>;
};

ProductSearch.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ProductSearch;
