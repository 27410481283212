import * as TYPES from '../types/import';

const initialState = {
    loading: false,
    error: null,
    response: null
};

export const importDataReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.IMPORT_DATA_REQ:
            return { ...state, loading: true, response: null };
        case TYPES.IMPORT_DATA_SUCCESS:
            return { ...state, loading: false, error: null, response: payload.data.message };
        case TYPES.IMPORT_DATA_FAIL:
            return { ...state, loading: false, error: payload, response: null };
        default:
            return state;
    }
}