import React,{useEffect} from 'react';
import { Input,Select ,InputNumber } from 'antd';
import {Controller, useForm} from 'react-hook-form';

const { Option } = Select;

export const EditableCellStockNonSerial = ({
  title, editable, children, dataIndex, record, handleChange, dataOption, ...restProps
  }) => {

    const {
      setValue,
      control,
    } = useForm();

    useEffect(()=>{
      if(['cost','stock'].includes(dataIndex)){
        setValue(dataIndex,record && record[dataIndex])
      }
    })

   const onCustomChange = (value,dataIndex,key)=>{
      setValue(dataIndex,value)
      handleChange(value,dataIndex,key)
    }

    

    const renderInputType = (dataIndex) => {

      if (['item_code','branch'].includes(dataIndex)) {
        return (
          <>
          <Select
            style={{ width: '100%' }}
            showSearch
            //allowClear
            name={dataIndex}
            placeholder={`กรุณาเลือก ${title}`}
            optionFilterProp="children"
            onChange={(e) => handleChange(e, dataIndex,record.key)}
            value={record && record[dataIndex] || null}

          >
            {dataOption.map((el, k) => {
              return <Option value={el.value} key={`option_${k}`}>{el.label}</Option>;
            })}
  
          </Select>
          {record && record.errors &&  <p style={{color:'#dc3545',fontSize:'12px',padding:'6px',marginBottom:'0px'}}>{record.errors}</p>}
          </>
        );
      } else if (['cost','stock'].includes(dataIndex)) {

        return (<Controller
          control={control}
          name={dataIndex}
          render={({field: {value}}) => (
            <Input
              type={['cost','stock'].includes(dataIndex) ? "NUMBER" : "TEXT"} 
              name={dataIndex}
              value={value}
              onChange={(e) => onCustomChange(e.target.value, dataIndex,record.key)}
              style={{margin: '5px 0px', borderRadius: '10px'}}
            />
          )}
        />)
        // return <input  defaultValue ={record && record[dataIndex] ||''}  type={['cost','stock'].includes(dataIndex) ? "NUMBER" : "TEXT"} 
        // className="style-input" onChange={(e) => handleChange(e.target.value, dataIndex,record.key)} />;
      } else {
        return <p>{children}</p>;
      }
     
    }  

    return <td {...restProps}>{editable? renderInputType(dataIndex):children}</td>;
  };