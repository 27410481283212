import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { compareRender } from '../../../functions/fn';

export const SyncIDcard = React.memo(({ item, control }) => {
  // export const SyncIDcard = ({ item, control }) => {
  return (
    <>
      <Controller
        render={() => (
          <>
            {item.passValue && <li style={item.passValue.status === 'success' ? { color: '#73d13d' } : item.passValue.status === 'failed' ? { color: '#ff4d4f' } : { color: '#213BD1' }}>{item.passValue.text}</li>}
          </>
        )
        }
        name="TextField"
        control={control}
        rules={item.rules}
      />
    </>

  );
}
  , (prev, next) =>
    compareRender(prev, next) &&
    JSON.parse(JSON.stringify(prev.item)) ===
    JSON.parse(JSON.stringify(next.item)) &&
    compareRender(prev, next, 'item')
)

// export default CustomerInfo;
