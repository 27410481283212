import { API_ADMIN_SERVICE, API_AUTHEN_SERVICE, APP_API_KEY } from "../env";
import { GET_MASTER_DATA, GET_MDM_SETTING_DEFAULT_LIST } from "./graphql/Query";
import { axiosInstance, decodeUserAdmin } from "../oauth";

export const getAdminMasterData = async (master_code) => {
  try {
    const userAdminV2 = await decodeUserAdmin();
    if (!userAdminV2) return;

    const { accessToken, clientKey } = userAdminV2;

    const response = await axiosInstance.post(
      API_ADMIN_SERVICE,
      {
        query: GET_MASTER_DATA,
        variables: {
          input: {
            master_code: master_code,
            is_active: true,
            is_visible: true,
          },
        },
      },
      {
        headers: {
          Authorization: accessToken,
          'x-api-key': APP_API_KEY,
          'client-key': clientKey,
        },
      },
    );

    return response?.data?.data?.getMasterData;
  } catch (error) {
    return null;
  }
};

export const getAdminMDMDefault = async () => {
  try {
    const userAdminV2 = await decodeUserAdmin();
    if (!userAdminV2) return;

    const { accessToken, clientKey } = userAdminV2;

    const response = await axiosInstance.post(
      API_ADMIN_SERVICE,
      {
        query: GET_MDM_SETTING_DEFAULT_LIST,
        variables: {
          input: {
            filters: {},
            pagination: {
              limit: 10,
              page: 1,
            },
          },
        },
      },
      {
        headers: {
          Authorization: accessToken,
          'x-api-key': APP_API_KEY,
          'client-key': clientKey,
        },
      },
    );

    return response?.data?.data?.getMDMSettingDefaultList?.result;
  } catch (error) {
    return null;
  }
};

export const logout = async () => {
  const userAdminV2 = await decodeUserAdmin();
  if (!userAdminV2) return;

  const { accessToken, clientKey } = userAdminV2;
  const response = await axiosInstance.post(
    API_AUTHEN_SERVICE,
    {
      query: `
        mutation RevokeUserSessionByKey{
         revokeUserSessionByKey
        }
      `,
      variables: {
        input: {
          clientKey: clientKey
        },
      },
    },
    {
      headers: {
        Authorization: accessToken,
        'x-api-key': APP_API_KEY,
        'client-key': clientKey,
      },
    },
  );
  return response;
};