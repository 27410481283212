import moment from 'moment';
export const CHECK_LOCATION_HISTORY_SEARCH_FORM = ({options}) => {
  return [
    {
      key: '1',
      span: 6,
      name: 'start_date',
      label: 'เริ่มวันที่ค้นหา',
      placeholder: 'เลือกวันที่เริ่มต้น',
      type: 'DATE_PICKER',
      format:'DD/MM/YYYY',
      // defaultValue: data?.start_date && moment(data?.start_date, 'DD/MM/YYYY') ||null,
    },
    {
      key: '2',
      span: 6,
      name: 'end_date',
      label: 'ถึงวันที่',
      placeholder: 'เลือกวันที่สิ้นสุด',
      type: 'DATE_PICKER',
      format:'DD/MM/YYYY',
      //defaultValue: data?.start_date && moment(data?.start_date, 'DD/MM/YYYY') || null,
    },
    {
      key: '3',
      span: 6,
      name: 'IDtrackers',
      label: 'ชื่อผู้ติดตาม',
      placeholder: 'เลือกชื่อผู้ติดตาม',
      type: 'SELECT',
      options: options?.trackers,
      allowClear:true,
      //defaultValue: data?.IDtrackers ||null,
    },
  ];
};


