import React, {useState, useEffect} from 'react';
import {Modal, Table, Image} from 'antd';
import {useForm} from 'react-hook-form';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../buttons';
import {Icon} from '../../../resources/icon';
import RenderForm from '../../../constants/global/RenderForm';
import {UpdateOtherInfoForm} from '../../../screens/customer2/form/index';
import {OtherInfoColumn} from '../../../screens/customer2/columns';
import {GET, POST, GET_ETC_DATA_INFO, ADD_ETC_DATA_INFO} from '../../../services';
import moment from 'moment/moment';
import {TextSmall} from '../../forms/text';

export const UpdateOtherInfo = ({title, visible, onClose, id, contractNo}) => {
  const {
    handleSubmit,
    formState: {errors},
    setValue,
    setError,
    getValues,
    control,
  } = useForm();
  const [type, setType] = useState('');
  const [visibleImages, setVisibleImages] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [imageUploadListTemp, setImageUploadListTemp] = useState([]); // ใข้แสดงผล
  const [etcDataInfo, setEtcDataInfo] = useState([]);

  useEffect(() => {
    if (id) {
      onGetDataInfo(id);
    }
  }, [id]);

  const onGetDataInfo = async (id) => {
    try {
      const res = await GET(GET_ETC_DATA_INFO(id));
      const {success, data} = res;
      if (success) {
        setEtcDataInfo(
          data.map((el) => {
            return {
              createDate: moment(el.create_date).format('DD/MM/YYYY HH:mm'),
              image: el.image.map((vl) => {
                return vl.url;
              }),
              type: el.type,
              remark: el.remark,
              createBy: el.user_crate,
            };
          }),
        );
      }
    } catch (error) {
      Modal.error({title: 'เกิดข้อผิดพลาด', content: error?.message});
    }
  };
  
  const onSubmit = async (params) => {
    try {

      const fileUploadList = imageUploadListTemp?.map((el) => {
        return {fileName: el.response?.file?.fileName, mimeType: el.response?.file?.mimetype};
      });

      const payload = {contract: contractNo, type: params?.type, remark: params?.remark, image: fileUploadList};
      const res = await POST(`${ADD_ETC_DATA_INFO}${id}`, payload);
      const {success, message} = res;
      if (success) {
        Modal.success({title: message});
        onGetDataInfo(id)
        setValue('type','')
        setValue('remark','')
        setImageUploadListTemp([])
      }      
    } catch (error) {
      Modal.error({title: 'เกิดข้อผิดพลาด', content: error?.message});
    }
  };

  const onConfirm = (params) => {
    Modal.confirm({
      title: 'ยืนยัน',
      icon: <Icon.warningAlert />,
      content: 'ยืนยันการเพิ่มข้อมูลอื่นๆ',
      onOk() {
        onSubmit(params);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const onPreview = (record) => {
    setVisibleImages(true);
    setImageList(record?.image);
  };

  const onUploadImage = async (files) => {
    setImageUploadListTemp(files?.fileList);
  };

const onCloseModal = ()=>{
  setValue('type','')
  setValue('remark','')
  setImageUploadListTemp([])
  onClose()
}

  return (
    <Modal title={title} open={visible} width={920} onCancel={onCloseModal} footer={null} destroyOnClose={true} maskClosable={false}>
      <div style={{padding: '20px'}}>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          setError={setError}
          formList={UpdateOtherInfoForm({
            data: {type},
            onUploadImage,
            onChange: (value) => {
              setType(value);
            },
            imageUploadListTemp
          })}
        />
        <ContainerButton right>
          <ButtonTheme useFor="SUBMIT" onClick={handleSubmit((data) => onConfirm(data))} />
          <ButtonTheme useFor="CANCEL" onClick={onCloseModal} />
        </ContainerButton>
        <TextSmall text="รายละเอียด" />
        <Table columns={OtherInfoColumn(onPreview)} dataSource={etcDataInfo} pagination={true} />
        <div
          style={{
            display: 'none',
          }}>
          <Image.PreviewGroup
            preview={{
              visible: visibleImages,
              onVisibleChange: (vis) => setVisibleImages(vis),
            }}>
            {imageList.map((item, index) => (
              <Image
                key={index}
                preview={{
                  visible: false,
                }}
                height={100}
                src={item}
                onClick={() => setVisibleImages(true)}
              />
            ))}
          </Image.PreviewGroup>
        </div>
      </div>
    </Modal>
  );
};
