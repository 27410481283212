export const dataCreateAccount = {
  fistName: 'paweena',
  lastName: 'chomsaun',
  birthDate: '1991-07-13',
  telIcloud: '09712569366',
  passIcloud: '12345',
  passPin: '7894',
  email: 'pawecho',
  passEmail: '12345',
  telEmail: '0895623477',
};

export const dataManageAccount = [
  {
    rowNo: 1,
    email: 'paweena.cho@dplusonline.net',
    fullName: 'ปวีณา ชมสวน',
    tel: '0971257422',
    createDate: '2022-05-01',
    contractDate: '2022-05-04',
    docNo: 'doc123456789',
    statusAccount: 'SUCCESS',
    statusAccountName: 'พร้อมใช้งาน',
  },
  {
    rowNo: 2,
    email: 'paweena2.cho@dplusonline.net',
    fullName: 'ปวีณา2 ชมสวน',
    tel: '0971257423',
    createDate: '2022-05-01',
    contractDate: '2022-05-04',
    docNo: 'doc123456789',
    statusAccount: 'WAIT',
    statusAccountName: 'รอจัดการ',
  },
  {
    rowNo: 3,
    email: 'paweena.cho@dplusonline.net',
    fullName: 'ปวีณา ชมสวน',
    tel: '0971257422',
    createDate: '2022-05-01',
    contractDate: '2022-05-04',
    docNo: 'doc123456789',
    statusAccount: 'ON PROCESS',
    statusAccountName: 'กำลังใช้งาน',
  },
];

export const dataManageIcloudAccount = [
  {
    rowNo: 1,
    mail: 'paweena.cho@dplusonline.net',
    fullName: 'ปวีณา ชมสวน',
    itemName: 'Iphone 13 Pro Max',
    telMail: '0971257422',
    telIcloud: '0971257422',
    contractNo: '2022-05-04',
    passIcloud: '@25Wk895',
    pin: '1235',
  },
  {
    rowNo: 2,
    mail: 'paweena.cho@dplusonline.net',
    fullName: 'ปวีณา ชมสวน2',
    itemName: 'Iphone 13 Pro Max',
    telMail: '0971257422',
    telIcloud: '0971257422',
    contractNo: '2022-05-04',
    passIcloud: '@25Wk8#$',
    pin: '7896',
  },
  {
    rowNo: 3,
    mail: 'paweena.cho@dplusonline.net',
    fullName: 'ปวีณา ชมสวน2',
    itemName: 'Iphone 13 Pro Max',
    telMail: '0971257422',
    telIcloud: '0971257422',
    contractNo: '2022-05-04',
    passIcloud: '@25Wk8#$',
    pin: '7896',
  },
];
