// /* eslint no-use-before-define: 0 */
import React, { useState, useEffect } from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import { Modal, Card, Spin } from 'antd';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../../components/buttons';
import { AuditOutlined } from '@ant-design/icons';
import { PageLayout } from '../../../components/pageLayout/PageLayout';
import { ReportFollowTrackSearchForm } from '../../../constants/global/SearchForm';
import { ReportFollowTrackColumn } from '../../../constants/global/columnTableForm';
import { GET, GET_BRANCH, GET_TRACK_DAILY_REPORT } from '../../../services/index';
import { CSVLink } from "react-csv";
import { auth } from '../../../utils/firebase';
import { useCurrentState } from '../../../utils/hook';
import { exportDefault } from "../../../functions/fn"
import { followTrackerdata, optionTrackerMock } from "../dataMock"
import moment from 'moment';


const FollowTrack = () => {

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataSearch, setDataSearch] = useState({ action_by: '', startDate: '', endDate: '' });
  const [dataExport, setDataExport] = useState([])
  const { user } = useCurrentState(auth);

  useEffect(() => {
    getReportTrackDaily(dataSearch)
  }, []);


  const getReportTrackDaily = async (value) => {


    setDataSearch({
      action_by: value.action_by || '', startDate: value.startDate ? moment(value.startDate).format('YYYY-MM-DD') : '',
      endDate: value.startDate ? moment(value.endDate).format('YYYY-MM-DD') : ''
    })
    setLoading(true);
    try {

      let params = `?`;
      if (value.action_by) {
        params += `action_by=${value.action_by}&`;
      }

      if (value.startDate) {
        params += `start_date=${moment(value.startDate).format('YYYY-MM-DD')}&`;
      }

      if (value.endDate) {
        params += `end_date=${moment(value.endDate).format('YYYY-MM-DD')}`;
      }



      let res = await GET(`${GET_TRACK_DAILY_REPORT}${params}`);
      const { status, data } = res;
      if (status) {

        if (data.length > 0) {
          const dataEx = data.map((el, i) => {
            return {
              'แอดมินผู้ติดตาม': el._id?.action_by,
              'วันที่ติดตาม': el._id?.date,
              'เกินกำหนด': el._id?.stage,
              'จำนวนลูกค้า': el.customer_count,
              'ยอดรวมค้างชำระ': el.sum_amount,
            };
          });

          const report = data.map((el, i) => {
            return {
              actionBy: el._id?.action_by,
              aging: el._id?.stage,
              stageNo: el._id?.stageNo,
              qty: el.customer_count,
              amount: el.sum_amount,
              date: el._id?.date,
            };
          });
          const dataExportDefault = exportDefault(['แอดมินผู้ติดตาม', 'วันที่ติดตาม', 'เกินกำหนด', 'จำนวนลูกค้า', 'ยอดรวมค้างชำระ'])
          setDataExport(dataEx.length > 0 && dataEx || dataExportDefault)
          setDataSource(report);

        } else {
          setDataSource([]);
        }

      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'บันทึกติดตามงานรายคน',
              icon: <AuditOutlined />,
              spanSearch: 24,
              formSearch: ReportFollowTrackSearchForm(dataSearch),
              onSearch: (data) => getReportTrackDaily(data),
            }}
            tableLayout={{
              columns: ReportFollowTrackColumn(),
              dataSource: dataSource,
              extraContent: (
                <ContainerButton right>
                  <CSVLink data={dataExport} filename={"รายงานบันทึกติดตามงานรายคน"}><ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด'} /></CSVLink>
                </ContainerButton>
              ),
            }}
          />
        </Spin>
      </Card>
    </Wrapper>
  );
};

export default FollowTrack;
