export const CONTRACT_LIST_REQ = "CONTRACT_LIST_REQ";
export const CONTRACT_LIST_SUCCESS = "CONTRACT_LIST_SUCCESS";
export const CONTRACT_LIST_FAIL = "CONTRACT_LIST_FAIL";

export const CONTRACT_PERIOD_LIST_REQ = "CONTRACT_PERIOD_LIST_REQ";
export const CONTRACT_PERIOD_LIST_SUCCESS = "CONTRACT_PERIOD_LIST_SUCCESS";
export const CONTRACT_PERIOD_LIST_FAIL = "CONTRACT_PERIOD_LIST_FAIL";
export const CONTRACT_PERIOD_LIST_CLEAR = "CONTRACT_PERIOD_LIST_CLEAR";

export const CONTRACT_EXPORT_REQ = "CONTRACT_EXPORT_REQ";
export const CONTRACT_EXPORT_SUCCESS = "CONTRACT_EXPORT_SUCCESS";
export const CONTRACT_EXPORT_FAIL = "CONTRACT_EXPORT_FAIL";

export const CONTRACT_RECIVE_EXPORT_REQ = "CONTRACT_RECIVE_EXPORT_REQ";
export const CONTRACT_RECIVE_EXPORT_SUCCESS = "CONTRACT_RECIVE_EXPORT_SUCCESS";
export const CONTRACT_RECIVE_EXPORT_FAIL = "CONTRACT_RECIVE_EXPORT_FAIL";

export const CONTRACT_LIST_TRADESYS_REQ = "CONTRACT_LIST_TRADESYS_REQ";
export const CONTRACT_LIST_TRADESYS_SUCCESS = "CONTRACT_LIST_TRADESYS_SUCCESS";
export const CONTRACT_LIST_TRADESYS_FAIL = "CONTRACT_LIST_TRADESYS_FAIL";

export const CONTRACT_INFO_REQ = "CONTRACT_INFO_REQ";
export const CONTRACT_INFO_SUCCESS = "CONTRACT_INFO_SUCCESS";
export const CONTRACT_INFO_FAIL = "CONTRACT_INFO_FAIL";

export const CONTRACT_UPDATE_REQ = "CONTRACT_UPDATE_REQ";
export const CONTRACT_UPDATE_SUCCESS = "CONTRACT_UPDATE_SUCCESS";
export const CONTRACT_UPDATE_FAIL = "CONTRACT_UPDATE_FAIL";

export const CONTRACT_GRADE_UPDATE_REQ = "CONTRACT_GRADE_UPDATE_REQ";
export const CONTRACT_GRADE_UPDATE_SUCCESS = "CONTRACT_GRADE_UPDATE_SUCCESS";
export const CONTRACT_GRADE_UPDATE_FAIL = "CONTRACT_GRADE_UPDATE_FAIL";

export const CONTRACT_TERMINATED_REQ = "CONTRACT_TERMINATED_REQ";
export const CONTRACT_TERMINATED_SUCCESS = "CONTRACT_TERMINATED_SUCCESS";
export const CONTRACT_TERMINATED_FAIL = "CONTRACT_TERMINATED_FAIL";

export const CONTRACT_IMPOUNDED_REQ = "CONTRACT_IMPOUNDED_REQ";
export const CONTRACT_IMPOUNDED_SUCCESS = "CONTRACT_IMPOUNDED_SUCCESS";
export const CONTRACT_IMPOUNDED_FAIL = "CONTRACT_IMPOUNDED_FAIL";

export const CONTRACT_EXPORT_TERMINATED_REQ = "CONTRACT_EXPORT_TERMINATED_REQ";
export const CONTRACT_EXPORT_TERMINATED_SUCCESS = "CONTRACT_EXPORT_TERMINATED_SUCCESS";
export const CONTRACT_EXPORT_TERMINATED_FAIL = "CONTRACT_EXPORT_FAIL";