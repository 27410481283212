/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {Button} from '@material-ui/core';
import {useHistory} from 'react-router-dom';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  redirect() {
    this.setState({hasError: false});
    window.history.back();
    // window = 'www.google.com';
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <center>
            <h1>Something went wrong.</h1> <br />
            <Button variant="contained" color="primary" onClick={this.redirect}>
              Go Back
            </Button>
          </center>
        </>
      );
    }

    return this.props.children;
  }
}
