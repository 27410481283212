import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import Wrapper from '../../views/wrapper/Wrapper';
import {Modal, Card, Spin} from 'antd';
import {BookOutlined} from '@ant-design/icons';
import {PageLayout} from '../../components/pageLayout/PageLayout';
import {POST, GET_RETURN_STORE, ACCEPT_REDEEM_DEVICE} from '../../services/index';
import {ContainerButton} from '../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../components/buttons';
import {CSVLink} from 'react-csv';
import moment from 'moment';
import {DEVICE_RETURN_SEARCH_FORM} from './form';
import {DeviceReturnColumn} from './columns';
import {useGetOptions} from '../report_tracking/hook/useGetOptions';
import {optionDeviceReturnStatus} from './constant';
import {EditRemarkModal} from './modal/EditRemarkModal';
import {RedeemPeriodModal} from './modal/RedeemPeriodModal';
import {DeviceRecheckModal} from './modal/DeviceRecheckModal';
import {Icon} from '../../resources/icon';
import {useGetExportReturnDeviceToStore} from './api';

const DeviceReturnScreen = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [visibleRemark, setVisibleRemark] = useState(false);
  const [visibleRedeemPeriod, setVisibleRedeemPeriod] = useState(false);
  const [visibleDeviceRecheck, setVisibleDeviceRecheck] = useState(false);
  const [visibleReportModal, setVisibleReportModal] = useState(false);
  const [defaultValue, setDefaultValue] = useState({});
  const [paramsUrl, setParamsUrl] = useState(null);

  const {optionsBranch, optionsTracker} = useGetOptions();

  const menu_user = JSON.parse(localStorage.getItem('menu_user') || []);
  const permissions = menu_user?.length > 0 && menu_user.filter((el) => el.head === 'Manage NPL')[0]?.menu;
  const perAction = (permissions.length > 0 && permissions.filter((el) => el.menu_code === 'M5009')[0].actions) || [];
  const permissionButtonRedeemCloseContract = perAction.includes('approve') || false;

  const [resultReport, loadingReport, errorReport, setInputReport] = useGetExportReturnDeviceToStore();

  useEffect(() => {
    if (resultReport?.message === 'success' && resultReport?.url) {
      setTimeout(() => {
        window.open(resultReport?.url, '_blank', 'noopener,noreferrer');
        closeModal('report');
      }, 1000);
    }
  }, [resultReport]);

  useEffect(() => {
    if (errorReport) {
      const message = errorReport?.response?.data?.errors;

      Modal.error({title: 'ดึงข้อมูลไม่สำเร็จ', content: message});
      closeModal('report');
    }
  }, [errorReport]);

  useEffect(() => {
    onGetData({});
    setParamsUrl({});
  }, []);

  const onGetData = async (params) => {
    try {
      setLoading(true);
      const payload = {
        trackerID: params?.trackerID || '',
        status: params?.status || '',
        contract_no: params?.contract_no || '',
        cus_tel: params?.cus_tel || '',
        cus_name: params?.cus_name || '',
        end_date: params?.end_date ? moment(params?.end_date).format('YYYY-MM-DD') : '',
        start_date: params?.start_date ? moment(params?.start_date).format('YYYY-MM-DD') : '',
      };

      setParamsUrl(payload);

      const res = await POST(GET_RETURN_STORE, payload);
      const {success, data} = res;
      if (success) {
        setDataSource(data.map((el, index) => ({...el, no: index + 1})));
        setLoading(false);
      }
    } catch (error) {
      Modal.error({title: error?.message});
    }
  };

  const onOpenModal = (record, ref) => {
    setDefaultValue(record);
    if (ref === 'REMARK') {
      return setVisibleRemark(true);
    }

    if (ref === 'REDEEM') {
      return setVisibleRedeemPeriod(true);
    }

    if (ref === 'RECHECK') {
      return setVisibleDeviceRecheck(true);
    }
  };

  const openModal = (ref) => {
    switch (ref) {
      case 'report':
        setVisibleReportModal(true);
        break;
      default:
        break;
    }
  };

  const closeModal = (ref) => {
    switch (ref) {
      case 'report':
        setVisibleReportModal(false);
        break;
      default:
        break;
    }
  };

  const onReturnCustomer = async (record) => {
    try {
      const payload = {
        contract_no: record?.contract_no || '',
      };
      const res = await POST(ACCEPT_REDEEM_DEVICE, payload);
      const {success, message} = res;
      if (success) {
        Modal.success({title: message});
        onGetData({});
      }
    } catch (error) {
      Modal.error({title: error?.message});
    }
  };

  const onConfirmReturn = (record) => {
    Modal.confirm({
      title: 'ยืนยัน',
      icon: <Icon.warningAlert />,
      content: 'ยืนยันการไถ่ถอนเครื่อง?',
      onOk() {
        onReturnCustomer(record);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const onExportExcelReport = async () => {
    const params = paramsUrl
      ? Object.entries(paramsUrl)
          .filter(([key, value]) => value !== '' && value !== null)
          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
          .join('&')
      : null;

    params !== null && (await setInputReport(`?${params}`));
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading || loadingReport}>
          <PageLayout
            searchLayout={{
              title: 'รายงานแสดงรายการคืนเครื่อง',
              icon: <BookOutlined />,
              spanSearch: 24,
              formSearch: DEVICE_RETURN_SEARCH_FORM({
                options: {branch: optionsBranch, trackers: optionsTracker, deviceReturn: optionDeviceReturnStatus},
              }),
              onSearch: (data) => onGetData(data),
            }}
            tableLayout={{
              columns: DeviceReturnColumn(onOpenModal, onConfirmReturn, permissionButtonRedeemCloseContract),
              dataSource: dataSource,
              extraContent: (
                <ContainerButton right>
                  {/* <CSVLink data={dataExport} filename={'รายงานแสดงรายการยึดเครื่อง'}>
                    <ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด Excel'} />
                  </CSVLink> */}
                  <ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด Excel'} onClick={() => openModal('report')} />
                </ContainerButton>
              ),
            }}
          />
        </Spin>
      </Card>
      {visibleRemark && (
        <EditRemarkModal visible={visibleRemark} title={'หมายเหตุ'} onClose={() => setVisibleRemark(false)} data={defaultValue} reApi={onGetData} />
      )}
      {visibleRedeemPeriod && (
        <RedeemPeriodModal
          visible={visibleRedeemPeriod}
          title={'เลื่อนระยะเวลาการไถ่ถอน'}
          onClose={() => setVisibleRedeemPeriod(false)}
          data={defaultValue}
          reApi={onGetData}
        />
      )}
      {visibleDeviceRecheck && (
        <DeviceRecheckModal
          visible={visibleDeviceRecheck}
          title={'ตรวจสอบรับเครื่อง'}
          onClose={() => setVisibleDeviceRecheck(false)}
          data={defaultValue}
          reApi={onGetData}
        />
      )}

      <Modal
        title={'ต้องการดาวน์โหลด Excel?'}
        open={visibleReportModal}
        width={550}
        onCancel={() => closeModal('report')}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}>
        <ContainerButton right>
          <ButtonTheme useFor="CUSTOM" title={'ตกลง'} onClick={async () => await onExportExcelReport()} />
          <ButtonTheme useFor="CANCEL" onClick={() => closeModal('report')} />
        </ContainerButton>
      </Modal>
    </Wrapper>
  );
};

export default DeviceReturnScreen;
