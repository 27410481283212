import axios from 'axios';
import qs from 'querystring';
import { API_URL } from '../../env.js';

export const getPaymentSummaryInfo = async (contractNumber, params) => {

  try {
    const urlParams = `?${qs.stringify(params)}`;
    return await axios.get(`${API_URL}/admin/contract/${contractNumber}/summary${urlParams}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json',
      },
    });
  } catch (error) {
    return error;
  }
};

export const getPaymentSummaryAmount = async (contractNumber, body) => {

  try {
    return await axios.post(`${API_URL}/admin/contract/${contractNumber}/summary/amount`, body, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json',
      },
    });
  } catch (error) {
    return error;
  }
};

export const payByContract = async (contractNumber, body) => {
  try {
    return await axios.post(`${API_URL}/admin/payment/contract/${contractNumber}/pay`, body, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json',
      },
    });
  } catch (error) {
    return error;
  }
};

export const getAmountContract = async (contractNumber, body) => {
  try {
    return await axios.post(`${API_URL}/admin/contract/${contractNumber}/close`, body, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json',
      },
    })
  } catch (error) {
    return error;
  }
}

export const checkRedeemStatusData = async (payload) => {
  try {
    return await axios.post(`${API_URL}/admin/contract/checkRedeemDataForRecieve`, { contractNumber: payload.contract_no, type: payload.paid_type }, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json',
      },
    });
  } catch (error) {
    return error;
  }
};

export const updateRemarkDeviceReturn = async (payload) => {
  try {
    return await axios.post(`${API_URL}/admin/tracking-task/edit-remark `, { contract_no: payload.contract_no, remark: payload.remark }, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json',
      },
    });
  } catch (error) {
    return error;
  }
};

export const getCouponData = async (payload) => {
  try {
    // payload.product_code = product
    return await axios.post(`${API_URL}/admin/contract/checkCoupon`, payload, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json',
      },
    })
  } catch (error) {
    return error
  }


}