// /* eslint no-use-before-define: 0 */
import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Wrapper from '../../views/wrapper/Wrapper';
import {Modal, Card, Spin, Row, Col, Input} from 'antd';
import {SettingOutlined} from '@ant-design/icons';
import {SearchLayout} from '../../components/pageLayout/SearchLayout';
import {TableLayout} from '../../components/pageLayout/TableLayout';
import {PickNoSearchForm} from '../../constants/global/SearchForm';
import RenderForm from '../../constants/global/RenderForm';
import {LineProductsPickColumn} from '../../constants/global/columnTableForm';
import {CreatePickModal} from '../../components/modal/Stock/CreatePickModal';
import {ContainerButton} from '../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../components/buttons';
import {
  POST,
  GET,
  PUT,
  GET_JOURNAL_ITEM,
  GET_ITEM_BY_SN,
  UPDATE_JOURNAL_ITEMS,
  CONFIRM_DOC,
  GET_OPTION_BRANCH,
  DELETE_PICK_DOC,
} from '../../services';
import {FORM_SN} from '../../constants/global/actionform';
import moment from 'moment';
import {useAuthState, useCurrentState} from '../../utils/hook';
import {auth} from '../../utils/firebase';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import _ from 'lodash';
import {Icon} from '../../resources/icon';

const CreatePick = () => {
  let timeout = null;
  const [loading, setLoading] = useState(false);
  const [itemsPick, setItemPick] = useState(0);
  const [dataSearch, setDataSearch] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [disabledBtnConfirm, setDisabledBtnConfirm] = useState(false);
  const [visibleCreatePickModal, setVisibleCreatePickModal] = useState(false);
  const [optionBranch, setOptionBranch] = useState([]);
  const [branchGetItem, setBranchGetItem] = useState('');
  const [dataOrder, setDataOrder] = useState([]);
  const [line, setLine] = useState([]);
  const [remarkDefault, setRemark] = useState('');
  const [userCreate, setUserCreate] = useState('');
  const [userUpdate, setUserUpdate] = useState('');
  const [docNo, setDocNo] = useState('');
  const {user} = useAuthState(auth);
  const {user: user2} = useCurrentState(auth);
  const location = useLocation();
  const history = useHistory();
  const {
    setValue,
    getValues,
    control,
    formState: {errors},
    clearErrors,
  } = useForm();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const docNoParams = params.get('document_no');
    // if (location.state) {
    //   const { data } = location.state;
    //   getJournalItems(data.document_no)
    // }
    if (docNoParams) {
      getJournalItems(docNoParams);
    }
    getOptionBranch();
  }, []);

  const getOptionBranch = async () => {
    try {
      setLoading(true);
      let res = await GET(GET_OPTION_BRANCH);
      const {success, result} = res;
      if (success) {
        setOptionBranch(result);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const getJournalItems = async (docNo) => {
    setLoading(true);
    if (window.location.href.includes('?')) {
      window.history.pushState({}, null, window.location.href.split('?')[0]);
    }
    try {
      const res = await PUT(GET_JOURNAL_ITEM, {documentNo: docNo});
      const {success, result} = res;
      if (success) {
        const {
          branch_from,
          branch_from_name,
          branch_to_name,
          document_date_start,
          document_date_end,
          document_no,
          remark,
          user_create,
          user_update,
          create_date,
          update_date,
        } = result.journalItems;
        const dataSet = [
          {label: 'Pick No.', value: document_no},
          {label: 'สาขาต้นทาง', value: branch_from_name},
          {label: 'สาขาปลายทาง', value: branch_to_name},
          {
            label: 'วันที่ลูกค้าจอง',
            value: `${moment(document_date_start).format('YYYY-MM-DD HH:mm')} - ${moment(document_date_end).format('YYYY-MM-DD HH:mm')}`,
          },
        ];

        const dataSetSearch = location.state ? dataSet : dataSet.slice(0, -1);

        const data = result.journalItems.journal_line.map((el, i) => {
          return {
            serial_number: el.serial_number || '',
            _id: el._id,
            line_no: +el.line_no,
            item_code: el.item_code,
            item_name: el.item_name,
            qty: el.qty,
            status: el.serial_number && el.status === 'PICK' ? 'RELEASE' : el.status,
            type: el.type,
            appleStatus: el.appleStatus,
            serial_status: el.serial_status,
            remark: el.remark,
          };
        });
        const filterData = data.filter((el) => el.serial_number === '');
        if (filterData.length > 0) {
          setDisabledBtnConfirm(true);
        }
        setBranchGetItem(branch_from);
        setRemark(remark);
        setDocNo(document_no);
        setUserCreate(`${user_create.name} ${moment(create_date).format('YYYY-MM-DD HH:mm')}`);
        setUserUpdate(`${user_update.name || ''} ${update_date ? moment(update_date).format('YYYY-MM-DD HH:mm') : ''}`);
        setDataSearch(dataSetSearch);
        setDataSource(data);
        setDocumentData(result.journalItems);
        // setDataOrder(data)

        setItemPick(result.journalItems.journal_line.filter((e) => e.type === 'ORDER').length);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const onChange = (value, ref) => {
    setDisabledBtnConfirm(true);
    if (ref === 'sn') {
      setValue(ref, value);
      getItemBySn();
    } else if (ref === 'status') {
      let data = _.cloneDeep(dataSource);
      data.map((el) => {
        if (value.line_no === el.line_no && ['RELEASE', 'PICK'].includes(value.status)) {
          el.status = 'CANCEL';
        } else if (value.line_no === el.line_no && value.status === 'CANCEL') {
          el.status = value.serial_number === '' ? 'PICK' : 'RELEASE';
        }
      });

      setDataSource(data);
    } else if (ref === 'remark') {
      setRemark(value);
    }
  };

  const getItemBySn = async () => {
    const sn = getValues('sn');

    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(async () => {
      if (sn !== '') {
        try {
          const res = await PUT(GET_ITEM_BY_SN, {sn: sn, branch: branchGetItem});
          const {success, result} = res;
          if (success) {
            let dataDefault = dataOrder;
            let data = [];
            let dataline = line;
            const {item_code, serial_number, item_name, appleStatus, serial_status, remark} = result.item;
            const filterSerial = dataSource.filter((el) => el.serial_number === serial_number);
            if (filterSerial.length > 0) {
              Modal.error({
                title: 'S/N ซ้ำ',
              });
            }
            const filterCheck = dataSource.filter((el) => el.item_code === item_code && el.serial_number === '');
            if (filterCheck.length > 0) {
              for (let i = 0; i < dataSource.length; i++) {
                if (
                  dataSource[i].item_code === item_code &&
                  dataSource[i].serial_number === '' &&
                  dataSource[i].type === 'ORDER' &&
                  filterSerial.length === 0
                ) {
                  dataline.push(dataSource[i].line_no || i + 1);
                  dataDefault.push({
                    _id: dataSource[i]._id,
                    line_no: dataSource[i].line_no || i + 1,
                    type: dataSource[i].type,
                    status: 'RELEASE',
                    item_name: item_name,
                    item_code: item_code,
                    serial_number: serial_number,
                    appleStatus: appleStatus,
                    serial_status: serial_status,
                    remark: remark,
                  });
                  break;
                }
              }
            } else if (filterSerial.length === 0) {
              dataSource.sort((a, b) => a.line_no - b.line_no);
              data.push({
                appleStatus,
                item_code,
                serial_number,
                status: 'RELEASE',
                serial_status,
                type: 'ADD',
                item_name,
                remark,
                qty: 1,
                line_no: dataSource.length !== 0 ? dataSource[dataSource.length - 1].line_no + 1 : 0 + 1,
              });
            }

            const dataGroup = [...new Map(dataDefault.map((item) => [item['serial_number'], item])).values()];

            const dataFilter = dataSource.filter((e) => !dataline.includes(e.line_no));

            dataGroup.sort((a, b) => a.line_no - b.line_no);
            dataFilter.sort((a, b) => a.line_no - b.line_no);
            data.sort((a, b) => a.line_no - b.line_no);

            setDataSource([...dataGroup, ...dataFilter, ...data]);
            //setItemTotal(dataSource.length)
          }
        } catch (err) {
          const {message, error} = err;
          Modal.error({
            title: message,
            content: error || '',
          });
        } finally {
          setValue('sn', '');
        }
      }
    }, 300);
  };

  const updateJournalItems = async () => {
    setLoading(true);

    const dataSend = {
      documentNo: docNo,
      remark: remarkDefault,
      userLogin: user.email,
      items: dataSource,
    };

    try {
      const res = await POST(UPDATE_JOURNAL_ITEMS, dataSend);
      const {success, message} = res;
      if (success) {
        Modal.success({
          title: message,
        });
        setDisabledBtnConfirm(false);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const onConfirm = async () => {
    const data = {
      documentNo: docNo,
      userLogin: user.email,
      // items:dataSource
    };

    try {
      const res = await POST(CONFIRM_DOC, data);
      const {success, result} = res;

      if (success) {
        history.push({
          pathname: '/print-pick',
          state: {data: {...result.export, page: 'printPick'}},
        });
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  const onConfirmCancelPickDoc = () => {
    Modal.confirm({
      title: 'ยืนยัน',
      icon: <Icon.warningAlert />,
      content: `ยกเลิกใบจัดสินค้า เลขที่เอกสาร ${docNo}`,
      onOk() {
        onCancelPickDoc();
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const onCancelPickDoc = async () => {
    const data = {
      documentNo: docNo,
      // userLogin: user.email,
    };

    try {
      const res = await POST(DELETE_PICK_DOC, data);
      const {success, message} = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            getJournalItems(documentData.document_no);
          },
        });
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  const detailUser = [
    {label: 'ผู้สร้างเอกสาร', value: userCreate},
    {label: 'แก้ไขล่าสุดโดย', value: userUpdate},
  ];
  const formSn = (
    <RenderForm
      control={control}
      setValue={setValue}
      getValues={getValues}
      clearErrors={clearErrors}
      errors={errors}
      formList={FORM_SN({fn: {onChange}})}
    />
  );

  const countProd = [
    {label: 'S/N', value: formSn},
    {label: 'จำนวนที่ยิงแล้ว', value: dataSource.filter((e) => e.serial_number !== '').length},
    {label: 'จำนวนที่จัดเพิ่ม', value: dataSource.filter((e) => e.type === 'ADD').length},
    {label: 'จำนวนที่ต้องจัด', value: itemsPick},
  ];

  const countProducts = location.state ? countProd : countProd.slice(0, -1);

  return (
    <Wrapper>
      <Card>
        <SearchLayout
          props={{
            title: 'สร้างใบจัดสินค้า',
            icon: <SettingOutlined />,
            spanSearch: 24,
            formSearch: PickNoSearchForm({
              data: {docNo: (location && location.state && location.state.data && location.state.data.document_no) || ''},
            }),
            onSearch: (data) => getJournalItems(data.document_no),
            extraContent: <ButtonTheme useFor="CREATE" title={'สร้างใบจัดสินค้า'} onClick={() => setVisibleCreatePickModal(true)} />,
          }}
        />
        <Spin tip="Loading..." spinning={loading}>
          {dataSearch.length > 0 ? (
            <div style={{marginTop: '20px', padding: '12px'}}>
              <Row gutter={[16, 16]} style={{marginBottom: '12px', fontSize: '16px'}} justify="left">
                {dataSearch.map((el, k) => {
                  return (
                    <Col xs={{span: 24}} md={{span: 24}} xl={{span: k === 3 ? 8 : 4}} lg={{span: k === 3 ? 8 : 4}} key={k}>
                      <span style={{fontWeight: '800'}}>{`${el.label} : `}</span>
                      <br />
                      {`${el.value}`}
                    </Col>
                  );
                })}
              </Row>
              <Row gutter={[16, 16]} justify="left">
                <Col xs={{span: 24}} md={{span: 24}} xl={{span: 22}} lg={{span: 22}}>
                  <Input.TextArea
                    maxLength={500}
                    rows={5}
                    placeholder="ระบุข้อความไม่เกิน 500 ตัวอักษร"
                    value={remarkDefault}
                    onChange={(e) => onChange(e.target.value, 'remark')}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]} justify="left" style={{marginTop: '12px'}}>
                {/* <Input placeholder="กรอกหรือยิงบาร์โค้ด" type="text" onChange={(e)=>getItemBySn(e.target.value)}  autoFocus={true} allowClear/> */}
                {detailUser.map((val, i) => {
                  return (
                    <Col xs={{span: 24}} md={{span: 24}} xl={{span: 8}} lg={{span: 8}} key={i}>
                      <span style={{fontWeight: '800'}}>{`${val.label} : `}</span>
                      {`${val.value}`}
                    </Col>
                  );
                })}
              </Row>
              <Row gutter={[16, 16]} justify="center" className="bg-scan-products">
                {countProducts.map((val, s) => {
                  return (
                    <Col xs={{span: 24}} md={{span: 24}} xl={{span: 6}} lg={{span: 6}} key={s}>
                      <span style={{fontWeight: '800'}}>{`${val.label} : `}</span>
                      <br />
                      {s > 0 && val.value > 0 ? `${val.value} ชิ้น` : val.value}
                    </Col>
                  );
                })}
              </Row>
              <TableLayout
                props={{
                  title: 'รายการสินค้า',
                  columns: LineProductsPickColumn({onChange}),
                  dataSource: dataSource,
                  footerContent: (
                    <ContainerButton center>
                      {documentData && documentData?.status !== 'CANCEL' ? (
                        <>
                          <ButtonTheme useFor="SUBMIT" title={'บันทึก'} onClick={() => updateJournalItems()} loading={loading} />
                          <ButtonTheme
                            useFor="CONFIRM"
                            title={'คอนเฟิร์ม'}
                            onClick={() => onConfirm()}
                            disabled={disabledBtnConfirm || dataSource.length === 0}
                          />
                          <ButtonTheme useFor="REJECT" title={'ยกเลิก'} onClick={() => onConfirmCancelPickDoc()} />
                        </>
                      ) : (
                        <></>
                      )}
                    </ContainerButton>
                  ),
                }}
              />
            </div>
          ) : null}
        </Spin>
      </Card>
      <CreatePickModal
        visible={visibleCreatePickModal}
        title={''}
        onClose={() => setVisibleCreatePickModal(false)}
        options={{branch: optionBranch}}
        data={{branchDefault: user2 && user2.branch}}
        onCallApi={getJournalItems}
        type={'serial'}
      />
    </Wrapper>
  );
};

export default CreatePick;
