import {ButtonTheme} from '../../../components/buttons';
import moment from 'moment/moment';
import {Tag} from 'antd';
import {color} from '../../../resources/color';

export const MistakeTerminateContractColumn = (onAddTaskToTracker, onExportPdf) => {
  return [
    {
      title: '#',
      dataIndex: 'no',
      width: '50px',
      align: 'center',
    },
    {
      title: 'วันที่ออกหนังสือ',
      dataIndex: 'terminated_date',
      width: '120px',
      align: 'center',
      render: (text, _record) => {
        return text ? moment(text).format('DD/MM/YYYY') : '';
      },
    },
    {
      title: 'ประเภทหนังสือยกเลิกสัญญา',
      dataIndex: 'condition_th',
      width: '300px',
      align: 'left',
    },
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contract_no',
      width: '150px',
      align: 'left',
    },
    {
      title: 'ชื่อ-นามสกุล',
      dataIndex: 'user_name',
      width: '200px',
      align: 'left',
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return record?.status_th;
      },
    },

    {
      title: 'ผู้ออกหนังสือ',
      dataIndex: 'user_create_terminated',
      width: '300px',
      align: 'center',
    },
    {
      title: '',
      dataIndex: 'action',
      width: '200px',
      align: 'center',
      fixed: 'right',
      render: (_text, record) => {
        return <ButtonTheme useFor="DOWNLOAD" title="ดาวน์โหลดหนังสือ" onClick={() => onExportPdf(record)} />;
      },
    },
    {
      title: 'ภาคสนาม',
      dataIndex: 'trackingtask_status',
      width: '200px',
      align: 'center',
      fixed: 'right',
      render: (text, record) => {
        let colorStyled = '';
        switch (text) {
          case 'NORMAL':
          case 'CLOSED':
          case 'FINISHED':
            colorStyled = 'success';
            break;
          case 'NOTICE_OF_TERMINATION':
            colorStyled = 'processing';
            break;
          case 'FIELD_COLLECTION':
            colorStyled = 'warning';
            break;
          case 'HOLD':
          case 'SUE':
          case 'BADDEBT':
          case 'CANCEL':
            colorStyled = 'error';
            break;
        }

        if (text === 'WAIT_ASSIGN') {
          return <ButtonTheme useFor="CONTACT" title={record?.trackingtask_status_th} onClick={() => onAddTaskToTracker(record)} />;
        }
        return <Tag color={colorStyled}>{record?.trackingtask_status_th}</Tag>;
      },
    },
  ];
};
