import moment from 'moment';
import lodashGet from 'lodash/get';
// import lodashOrderBy from 'lodash/orderBy';
import * as number from '../../../utils/number';

const DATE_FORMAT = 'DD/MM/YYYY';
const QUERY_FORMAT = 'YYYY-MM-DD';

export const enumPaidChannel = {
  cash: 'CASH',
  transfer: 'TRANSFER',
};

export const enumTypeStatus = {
  contract: 'CONTRACT',
  redeemCloseContract: 'REDEEM_CLOSED_CONTRACT',
  paid: 'PAID'
}

export const enumEditFee = {
  overdue: 'CHARGE_OVERDUED',
  warning: 'CHARGE_WARNING',
};

export const enumPaidStatus = {
  overdue: 'OVERDUED',
  completed: 'COMPLETED',
  pending: 'PENDING',
  soon: 'SOON',
  confirm: 'CONFIRMED',
};

export const enumContractStatus = {
  active: 'ACTIVE',
  terminated: 'TERMINATED',
  impounded: 'IMPOUNDED',
  completed: 'COMPLETED',
};

export const getContractStatusLabel = (status) => {
  switch (status) {
    case enumContractStatus.active:
      return 'ACTIVE';
    case enumContractStatus.terminated:
      return 'สัญญานี้ถูกยกเลิก';
    case enumContractStatus.impounded:
      return 'สัญญานี้ถูกยึด';
    case enumContractStatus.completed:
      return 'สัญญานี้จ่ายครบแล้ว';
    default:
      return '';
  }
};

export const renderThaiId = (id) => {
  if (!id) {
    return '-';
  }
  const cleaned = ('' + id).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}-${match[4]}-${match[5]}`;
  }
  return '-';
};

export const renderNumber = (value) => {
  if (!value) {
    return '0.00';
  }
  return number.formatNumber(value);
};

export const countOverduePeriod = (payments) => {
  if (!Array.isArray(payments)) {
    return 0;
  }
  return payments.filter((item) => item.status === enumPaidStatus.overdue).length;
};

export const checkIsDisabledClear = (filterValue) => {
  if (typeof filterValue !== 'object') {
    return false;
  }
  const { idCard, name, tel } = filterValue;
  return idCard || name || tel;
};

export const createParams = (userIdCard, name, tel) => {
  let params = '?';
  if (userIdCard !== undefined && userIdCard !== null && userIdCard !== '') {
    params += `id_card=${userIdCard}&`;
  }
  if (name !== undefined && name !== null && name !== '') {
    params += `name=${name}&`;
  }
  if (tel !== undefined && tel !== null && tel !== '') {
    params += `tel=${tel}&`;
  }
  return params;
};

// const findLastPaidPeriod = (data) => {
//   const paidPeriodList = data.filter((item) => Array.isArray(item.transactions) && item.transactions.length);
//   const orderedPaidList = lodashOrderBy(paidPeriodList, 'period');
//   const lastPaidPeriod = lodashGet(orderedPaidList[orderedPaidList.length - 1], 'period');
//   return lastPaidPeriod;
// };

export const createTableData = (data) => {
  if (!Array.isArray(data)) {
    return [];
  }
  // const enableDeletePeriod = findLastPaidPeriod(data);
  const ans = [];
  const payment = [...data];
  payment.forEach((item) => {
    let cols = {};
    cols.id = item._id;
    cols.period = item.period;
    cols.is_transaction = false;
    cols.is_has_child = lodashGet(item, 'transactions.length') ? true : false;
    cols.is_paid = item.status === enumPaidStatus.completed;
    cols.is_remain = item.status === enumPaidStatus.overdue;
    cols.col_1 = item.period;
    cols.col_2 = item.paid_date;
    cols.col_3 = item.amount;
    cols.col_4 = item.capital_amount;
    cols.col_5 = item.interest_amount;
    cols.col_6 = item.overdays;
    cols.col_7 = item.charge_overdued;
    cols.col_8 = item.charge_warning;
    cols.col_9 = '-';
    cols.col_10 = item.remain_amount;
    cols.col_11 = item.remain_capital_amount;
    cols.col_12 = item.remain_interest_amount;
    ans.push(cols);

    if (Array.isArray(item.transactions)) {
      //  const lastRow = item.transactions.length - 1;
      item.transactions.forEach((paidItem, paidIndex) => {
        cols = {};
        cols.slip_url = paidItem.slip_url;
        cols.type = paidItem.type;
        cols.trans_id = paidItem._id;
        cols.period = item.period;
        cols.is_transaction = true;
        cols.is_enable_delete = true; // lastRow === paidIndex && item.period === enableDeletePeriod;
        cols.col_1 = paidItem.status === enumPaidStatus.confirm ? 'PAID' : 'UNCONFIRMED';
        cols.col_2 = paidItem.transaction_date;
        cols.col_3 = paidItem.receipt_no;
        cols.col_4 = lodashGet(paidItem, 'transaction_info.capital_amount', 0);
        cols.col_5 = lodashGet(paidItem, 'transaction_info.interest_amount', 0);
        cols.col_6 = lodashGet(paidItem, 'overdays', 0);
        cols.col_7 = lodashGet(paidItem, 'transaction_info.overdued_amount', 0);
        cols.col_8 = lodashGet(paidItem, 'transaction_info.warning_amount', 0);
        cols.col_9 = lodashGet(paidItem, 'total_amount', 0);
        ans.push(cols);
      });
    }
  });
  return ans;
};

export const createTableSummary = (data) => {
  const row_1 = {
    col_1: lodashGet(data, 'total.capital_amount', 0),
    col_2: lodashGet(data, 'paid.capital_amount', 0),
    col_3: lodashGet(data, 'remain.capital_amount', 0),
  };
  const row_2 = {
    col_1: lodashGet(data, 'total.interest_amount', 0),
    col_2: lodashGet(data, 'paid.interest_amount', 0),
    col_3: lodashGet(data, 'remain.interest_amount', 0),
  };
  const row_3 = {
    col_1: lodashGet(data, 'total.overdued_amount', 0),
    col_2: lodashGet(data, 'paid.overdued_amount', 0),
    col_3: lodashGet(data, 'remain.overdued_amount', 0),
  };
  const row_4 = {
    col_1: lodashGet(data, 'total.warning_amount', 0),
    col_2: lodashGet(data, 'paid.warning_amount', 0),
    col_3: lodashGet(data, 'remain.warning_amount', 0),
  };
  const row_5 = {
    col_1: lodashGet(data, 'total.amount', 0),
    col_2: lodashGet(data, 'paid.amount', 0),
    col_3: lodashGet(data, 'remain.amount', 0),
  };
  const row_6 = {
    col_1: lodashGet(data, 'total.discount_closed_contract', 0),
    col_2: lodashGet(data, 'paid.discount_closed_contract', 0),
    col_3: lodashGet(data, 'remain.discount_closed_contract', 0),
  }
  const row_7 = {
    col_1: lodashGet(data, 'total.other_payment', 0),
    col_2: lodashGet(data, 'paid.other_payment', 0),
    col_3: lodashGet(data, 'remain.other_payment', 0),
  }
  const ans = { row_1, row_2, row_3, row_4, row_5, row_6, row_7 };
  return ans;
};

export const getCurrentDate = (query) => {
  const format = query ? QUERY_FORMAT : DATE_FORMAT;
  const date = moment().format(format);
  return date;
};

export const getDateQueryFormat = (d) => {
  const initDate = d || new Date();
  const date = moment(initDate).format(QUERY_FORMAT);
  return date;
};

export const getDateLabel = (date) => {
  try {
    return moment(date).format(DATE_FORMAT);
  } catch (error) {
    return 'วันที่ไม่ถูกต้อง';
  }
};

export const transAdditionalCharges = (charges, period, type, amount) => {
  if (!Array.isArray(charges)) {
    return [{ period, type, amount }];
  }
  const newCharges = [...charges];
  const findCharged = newCharges.findIndex((item) => item.period === period && item.type === type);
  if (findCharged < 0) {
    newCharges.push({ period, type, amount });
    return newCharges;
  }
  newCharges[findCharged] = { period, type, amount };
  return newCharges;
};

export const getPreChangeCharges = (charges, period, type, defaultValue) => {
  if (!Array.isArray(charges)) {
    return number.formatComma(defaultValue, 0);
  }
  const findCharged = charges.find((item) => item.period === period && item.type === type);
  if (!findCharged) {
    return number.formatComma(defaultValue, 0);
  }
  const ans = number.formatComma(lodashGet(findCharged, 'amount', 0), 0);
  return ans;
};

export const transAmountToNumber = (amount) => {
  if (!amount) {
    return 0;
  }

  return amount

  // const numAmount = '' + amount.replace(/,/g, '');
  // const isValidNumber = /^[0-9]*$/.test(numAmount);
  // if (!isValidNumber) {
  //   return 0;
  // }
  // return parseInt(numAmount, 10);
};
