import moment from 'moment';
import noImage from '../../../assets/images/no_image.jpeg';
import { API_URL } from '../../../env.js';

export const SignSecondHandSearchForm = ({options}) => {
  return [
    {
      key: '1',
      span: 8,
      name: 'docNo',
      label: 'เลขที่เอกสาร',
      placeholder: 'กรุณากรอกเลขที่เอกสาร',
      type: 'INPUT',
      allowClear: true,
      value: '',
    },
  ];
};

export const BuySecondHandReportSearchForm = ({options}) => {
  return [
    {
      key: '1',
      span: 4,
      name: 'branch',
      label: 'สาขา',
      placeholder: 'กรุณาเลือกสาขา',
      type: 'SELECT',
      options: options.optionBranch,
      allowClear: true,
      value: '',
    },
    {
      key: '2',
      span: 4,
      name: 'start_date',
      label: 'เริ่มวันที่',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
      value: '',
    },
    {
      key: '3',
      span: 4,
      name: 'end_date',
      label: 'ถึงวันที่',
      placeholder: 'เลือกวันที่',
      type: 'DATE_PICKER',
      value: '',
    },
    {
      key: '4',
      span: 4,
      name: 'tel',
      label: 'เบอร์โทรศัพท์',
      placeholder: 'กรุณากรอกเบอร์โทรศัพท์',
      type: 'INPUT',
      allowClear: true,
      value: '',
    },
    {
      key: '5',
      span: 5,
      name: 'name',
      label: 'ชือลูกค้า',
      placeholder: 'กรุณากรอกชือลูกค้า',
      type: 'INPUT',
      allowClear: true,
      value: '',
    },
    {
      key: '6',
      span: 5,
      name: 'product_name',
      label: 'ชือสินค้า',
      placeholder: 'กรุณากรอกชื่อสินค้า',
      type: 'SELECT',
      allowClear: true,
      options: options.optionProduct,
      value: '',
    },
    {
      key: '7',
      span: 4,
      name: 'serial_number',
      label: 'S/N',
      placeholder: 'เลือกตัวเลือกค้นหา',
      type: 'INPUT',
      allowClear: true,
      value: '',
    },
    {
      key: '7',
      span: 4,
      name: 'status',
      label: 'สถานะสินค้า',
      placeholder: 'เลือกสถานะสินค้า',
      type: 'SELECT',
      options: options.optionStatus,
      allowClear: true,
      // defaultValue: defaultSearch.status,
      value: '',
    },
  ];
};

export const SecondHandApproveSearchForm = () => {
  return [
    {
      key: '1',
      span: 4,
      name: 'name',
      label: 'ชื่อ-นามสกุลลูกค้า',
      placeholder: 'กรุณากรอกชื่อลูกค้า',
      type: 'INPUT',
      value: '',
    },
    {
      key: '2',
      span: 3,
      name: 'tel',
      label: 'เบอร์โทรศัพท์',
      placeholder: 'กรุณากรอกเบอร์',
      type: 'INPUT',
      value: '',
    },
  ];
};


export const SecondHandVerifyForm = ({data}) => {
  const {book_bank_image, profile_image, rename_image} = data;

  return [
    {
      key: '1',
      span: 11,
      name: 'document_no',
      label: 'เลขที่เอกสาร',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '2',
      span: 11,
      name: 'name',
      label: 'ชื่อลูกค้า',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '3',
      span: 22,
      name: 'product_name',
      label: 'ชื่อสินค้า',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '4',
      span: 22,
      name: 'approve_paid_amount',
      label: 'วงเงินที่อนุมัติ',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '5',
      span: 11,
      name: 'book_bank_no',
      label: 'เลขที่บัญชี',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '6',
      span: 11,
      name: 'book_bank_name',
      label: 'ชื่อบัญชี',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '7',
      span: 11,
      name: 'bank_name',
      label: 'ธนาคาร',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '8',
      span: 11,
      name: 'book_bank_branch',
      label: 'สาขา',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '9',
      span: 22,
      name: 'user_created_email',
      label: 'พนักงานที่รับซื้อ',
      type: 'INPUT',
      disabled: true,
    },
    {
      key: '10',
      span: 7,
      name: 'book_bank_image',
      label: 'รูป bookbank',
      type: 'VIEWIMAGE',
      disabled: true,
      defaultValue: book_bank_image !== '-' ? book_bank_image : noImage,
    },
    {
      key: '11',
      label: '',
      span: 1,
    },
    {
      key: '12',
      span: 7,
      name: 'profile_image',
      label: 'รูปคู่บัตรประชาชน',
      type: 'VIEWIMAGE',
      disabled: true,
      defaultValue: profile_image !== '-' ? profile_image : noImage,
    },
    {
      key: '13',
      label: '',
      span: 1,
    },
    {
      key: '14',
      span: 7,
      name: 'rename_image',
      label: 'รูปใบเปลี่ยนชื่อ',
      type: 'VIEWIMAGE',
      disabled: true,
      defaultValue: rename_image !== '-' ? rename_image : noImage,
    },
    {
      key: '15',
      span: 22,
      align: 'right',
      name: 'remark',
      label: 'หมายเหตุ',
      type: 'INPUT_AREA',
      rows: 2,
    },
  ];
};

export const AddNewRecordSecondHandForm = ({fn, data, options}) => {
  const {idCardImageList, bookBankImage, changeNameImageList, enabledChangeName, insuranceStatus, mobileImageList, documentImageList} = data;

  return [
    {
      key: '1',
      span: 10,
      name: 'branch',
      label: 'สาขา:',
      type: 'SELECT',
      options: options.optionBranch,
      onChangeCustom: (e) => fn.handleChange(e, 'branch'),
      placeholder: 'เลือกสาขาที่รับซื้อ',
      rules: {
        required: true,
      },
      // disabled: true,
    },
    {
      key: '2',
      span: 10,
      name: 'product',
      label: 'ชื่อสินค้า:',
      type: 'SELECT',
      options: options.optionProduct,
      placeholder: 'เลือกชื่อสินค้า',
      onChangeCustom: (e) => fn.handleChange(e, 'product'),
      rules: {
        required: true,
      },
      // disabled: true,
    },
    {
      key: '3',
      span: 10,
      name: 'serial_number',
      label: 'S/N:',
      placeholder: 'กรุณากรอก S/N',
      onChangeCustom: (e) => fn.handleChange(e, 'serial_number'),
      type: 'INPUT',
      rules: {
        required: true,
      },
      // disabled: true,
    },
    {
      key: '4',
      span: 10,
      name: 'imei',
      label: 'IMEI:',
      placeholder: 'กรุณากรอกหมายเลขเครื่อง IMEI',
      type: 'INPUT',
      onChangeCustom: (e) => fn.handleChange(e, 'imei'),
      rules: {
        required: true,
      },
      // disabled: true,
    },
    {
      key: '5',
      span: 10,
      name: 'condition',
      label: 'สภาพเครื่อง:',
      type: 'SELECT',
      options: options.optionCondition,
      onChangeCustom: (e) => fn.handleChange(e, 'condition'),
      placeholder: 'กรุณาเลือกสภาพเครื่อง',
      rules: {
        required: true,
      },
      // disabled: true,
    },
    {
      key: '6',
      span: insuranceStatus === null || insuranceStatus === 'NO' ? 10 : 4,
      name: 'insurance_status',
      label: 'ประกัน:',
      type: 'SELECT',
      placeholder: 'กรุณาเลือกสถานะประกันของเครื่อง',
      options: [
        {label: 'มี', value: 'YES'},
        {label: 'ไม่มี', value: 'NO'},
      ],
      onChangeCustom: (e) => fn.handleChange(e, 'insurance_status'),
      rules: {
        required: true,
      },
      // disabled: true,
    },
    {
      key: '7',
      span: insuranceStatus === null || insuranceStatus === 'NO' ? 0 : 6,
      name: 'insurance_date',
      label: 'วันที่หมดประกัน:',
      placeholder: 'กรุณากรอกวันที่หมดประกัน',
      type: 'DATE_PICKER',
      onChange: (e) => fn.handleChange(e, 'insurance_date'),
      hidden: insuranceStatus === null || insuranceStatus === 'NO',
      rules: {
        required: insuranceStatus === null || insuranceStatus === 'NO' ? false : true,
      },
      // disabled: true,
    },
    {
      key: '8',
      span: 10,
      name: 'highest_approve_amount',
      label: 'วงเงินสูงสุดที่จะได้รับ:',
      type: 'INPUT',
      // onChangeCustom: (e) => fn.handleChange(e, 'highest_approve_amount'),
      disabled: true,
    },
    {
      key: '9',
      span: 10,
      name: 'approve_paid_amount',
      label: 'วงเงินที่อนุมัติ:',
      placeholder: 'กรุณากรอกวงเงินที่อนุมัติ',
      type: 'INPUT',
      onChangeCustom: (e) => fn.handleChange(e, 'approve_paid_amount'),
      // disabled: true,
    },
    {
      key: '10',
      span: 20,
      name: 'id_card_image',
      label: 'อัพโหลดรูปคู่บัตรประชาชน:',
      type: 'FILE_UPLOAD_WITH_HEADER',
      oneLine: true,
      accept: 'image/*',
      onUpload: fn.handleChange,
      fileList: idCardImageList,
      // onRemove: fn.handleRemove,
      urlUpload: `${API_URL}/admin/contract/getUrlUpload?`,
      imageCat: 'secondHand_idCard',
      // disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '11',
      span: 6,
      name: 'id_card',
      label: '',
      type: 'INPUT',
      onChangeCustom: (e) => fn.handleChange(e, 'id_card'),
      placeholder: 'เลขบัตรประชาชน',
      value: '',
      hiddenRequired: true,
      rules: {
        required: true,
      },
    },
    {
      key: '12',
      span: 6,
      name: 'name',
      label: '',
      type: 'INPUT',
      onChangeCustom: (e) => fn.handleChange(e, 'name'),
      placeholder: 'ชื่อ-นามสกุล',
      value: '',
      hiddenRequired: true,
      rules: {
        required: true,
      },
    },
    {
      key: '13',
      span: 6,
      name: 'tel',
      label: '',
      type: 'INPUT',
      onChangeCustom: (e) => fn.handleChange(e, 'tel'),
      placeholder: 'เบอร์โทรศัพท์',
      value: '',
      hiddenRequired: true,
      rules: {
        required: true,
      },
    },
    {
      key: '14',
      span: 20,
      name: 'book_bank_image',
      label: 'อัพโหลดรูปหน้า Book Bank:',
      type: 'FILE_UPLOAD_WITH_HEADER',
      oneLine: true,
      accept: 'image/*',
      onUpload: fn.handleChange,
      fileList: bookBankImage,
      // onRemove: handleRemove,
      urlUpload: `${API_URL}/admin/contract/getUrlUpload?`,
      imageCat: 'secondHand_bookBank',
      // disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '15',
      span: 9,
      name: 'book_bank_code',
      label: '',
      type: 'SELECT',
      options: options.bankOptions,
      onChangeCustom: (e) => fn.handleChange(e, 'book_bank_code'),
      placeholder: 'กรุณาเลือกธนาคาร',
      value: '',
      hiddenRequired: true,
      disabled: data.disabled,
      rules: {
        required: true,
      },
    },
    {
      key: '16',
      span: 9,
      name: 'book_bank_branch',
      label: '',
      type: 'INPUT',
      onChangeCustom: (e) => fn.handleChange(e, 'book_bank_branch'),
      placeholder: 'กรุณากรอกชื่อสาขาธนาคาร',
      value: '',
      hiddenRequired: true,
      disabled: data.disabled,
      rules: {
        required: true,
      },
    },
    {
      key: '17',
      span: 9,
      name: 'book_bank_name',
      label: '',
      type: 'INPUT',
      onChangeCustom: (e) => fn.handleChange(e, 'book_bank_name'),
      placeholder: 'กรุณากรอกชื่อบัญชี',
      value: '',
      hiddenRequired: true,
      disabled: data.disabled,
      rules: {
        required: true,
      },
    },
    {
      key: '18',
      span: 9,
      name: 'book_bank_number',
      label: '',
      type: 'INPUT',
      onChangeCustom: (e) => fn.handleChange(e, 'book_bank_number'),
      placeholder: 'กรุณากรอกเลขที่บัญชี',
      value: '',
      hiddenRequired: true,
      disabled: data.disabled,
      rules: {
        required: true,
      },
    },
    {
      key: '19',
      span: 20,
      name: 'mobile_image',
      label: 'อัพโหลดรูปเครื่อง:',
      type: 'FILE_UPLOAD_WITH_HEADER',
      oneLine: true,
      accept: 'image/*',
      onUpload: fn.handleChange,
      fileList: mobileImageList,
      onPreview: fn.onPreview,
      maxCount: 10,
      multiple: true,
      // onRemove: handleRemove,
      urlUpload: `${API_URL}/admin/contract/getUrlUpload?`,
      imageCat: 'secondHand_mobilePhoto',
      // disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '20',
      span: 20,
      name: 'document_image',
      label: 'อัพโหลดรูปเอกสาร:',
      type: 'FILE_UPLOAD_WITH_HEADER',
      oneLine: true,
      accept: 'image/*',
      onUpload: fn.handleChange,
      fileList: documentImageList,
      onPreview: fn.onPreview,
      maxCount: 10,
      multiple: true,
      // onRemove: handleRemove,
      urlUpload: `${API_URL}/admin/contract/getUrlUpload?`,
      imageCat: 'secondHand_documentPhoto',
      // disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '21',
      span: 20,
      name: 'change_name_image',
      label: 'อัพโหลดรูปใบเปลี่ยนชื่อ:',
      type: 'FILE_UPLOAD_WITH_HEADER',
      oneLine: true,
      accept: 'image/*',
      onUpload: fn.handleChange,
      fileList: changeNameImageList,
      // onRemove: handleRemove,
      urlUpload: `${API_URL}/admin/contract/getUrlUpload?`,
      imageCat: 'downPayment_changeName',
      // disabled: true,
      rules: {
        required: enabledChangeName,
      },
    },
    {
      key: '22',
      span: 20,
      name: 'remark',
      label: 'หมายเหตุ:',
      type: 'INPUT_AREA',
      maxLength: 1500,
      rows: 4,
      onChangeCustom: (e) => fn.handleChange(e, 'remark'),
    },
    // {
    //   key: '21',
    //   span: 10,
    //   align: 'right',
    //   name: 'signature_image',
    //   label: 'ลงลายมือชื่อ:',
    //   component: 'MODAL_SIGNATURE',
    //   onChangeCustom: fn.handleChange,
    //   imageCat: 'secondHand_signature',
    //   passValue: {signatureImage: signatureImage, edit: false},
    //   rules: {
    //     required: true,
    //   },
    // },
  ];
};

export const SecondHandDetailForm = () => {
  return [
    {
      key: '1',
      span: 16,
      name: 'id_card',
      label: 'เลขบัตรประชาชน',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      span: 16,
      name: 'name',
      label: 'ชื่อ-นามสกุล',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '3',
      span: 16,
      name: 'tel',
      label: 'เบอร์โทรศัพท์',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '4',
      span: 16,
      name: 'status',
      label: 'สถานะ',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '5',
      span: 16,
      name: 'product_name',
      label: 'สินค้า',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '6',
      span: 16,
      name: 'serial_no',
      label: 'Serial Number',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '7',
      span: 16,
      name: 'imei',
      label: 'IMEI',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '8',
      span: 16,
      name: 'condition',
      label: 'สภาพเครื่อง',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '9',
      span: 16,
      name: 'insurance_end_date',
      label: 'ประกันหมดอายุ',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '10',
      span: 16,
      name: 'approve_paid_amount',
      label: 'วงเงินที่อนุมัติ',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '11',
      span: 16,
      name: 'approve_date',
      label: 'วันที่อนุมัติ',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '11',
      span: 16,
      name: 'terminate_date',
      label: 'วันที่ยกเลิก',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '12',
      span: 16,
      name: 'branch',
      label: 'สาขาที่รับซื้อ',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
    // {
    //   key: '13',
    //   span: 12,
    //   name: 'remark',
    //   label: 'หมายเหตุ',
    //   type: 'INPUT',
    //   disabled: true,
    //   rules: {
    //     required: true,
    //   },
    // },
  ];
};

export const SecondHandDetailForm2 = () => {
  return [
    {
      key: '1',
      span: 24,
      name: 'remark',
      label: 'หมายเหตุ',
      type: 'INPUT',
      disabled: true,
      rules: {
        required: true,
      },
    },
  ];
};

export const EditRecordSecondHandForm = ({fn, data, onPreview, options}) => {
  const {idCardImageList, bookBankImage, changeNameImageList, enabledChangeName, insuranceStatus, signatureImage, mobileImageList, documentImageList} = data;

  return [
    {
      key: '1',
      span: 10,
      name: 'branch',
      label: 'สาขา:',
      type: 'SELECT',
      options: options.optionBranch,
      onChangeCustom: (e) => fn.handleChange(e, 'branch'),
      placeholder: 'เลือกสาขาที่รับซื้อ',
      disabled: true,
      rules: {
        required: true,
      },
      // disabled: true,
    },
    {
      key: '2',
      span: 10,
      name: 'product',
      label: 'ชื่อสินค้า:',
      type: 'SELECT',
      options: options.optionProduct,
      placeholder: 'เลือกชื่อสินค้า',
      onChangeCustom: (e) => fn.handleChange(e, 'product'),
      rules: {
        required: true,
      },
      // disabled: true,
    },
    {
      key: '3',
      span: 10,
      name: 'serial_number',
      label: 'S/N:',
      placeholder: 'กรุณากรอก S/N',
      onChangeCustom: (e) => fn.handleChange(e, 'serial_number'),
      type: 'INPUT',
      rules: {
        required: true,
      },
      // disabled: true,
    },
    {
      key: '4',
      span: 10,
      name: 'imei',
      label: 'IMEI:',
      placeholder: 'กรุณากรอกหมายเลขเครื่อง IMEI',
      type: 'INPUT',
      onChangeCustom: (e) => fn.handleChange(e, 'imei'),
      rules: {
        required: true,
      },
      // disabled: true,
    },
    {
      key: '5',
      span: 10,
      name: 'condition',
      label: 'สภาพเครื่อง:',
      type: 'SELECT',
      options: options.optionCondition,
      onChangeCustom: (e) => fn.handleChange(e, 'condition'),
      placeholder: 'กรุณาเลือกสภาพเครื่อง',
      rules: {
        required: true,
      },
      // disabled: true,
    },
    {
      key: '6',
      span: insuranceStatus === null || insuranceStatus === 'NO' ? 10 : 4,
      name: 'insurance_status',
      label: 'ประกัน:',
      type: 'SELECT',
      placeholder: 'กรุณาเลือกสถานะประกันของเครื่อง',
      options: [
        {label: 'มี', value: 'YES'},
        {label: 'ไม่มี', value: 'NO'},
      ],
      onChangeCustom: (e) => fn.handleChange(e, 'insurance_status'),
      rules: {
        required: true,
      },
      // disabled: true,
    },
    {
      key: '7',
      span: insuranceStatus === null || insuranceStatus === 'NO' ? 0 : 6,
      name: 'insurance_date',
      label: 'วันที่หมดประกัน:',
      placeholder: 'กรุณากรอกวันที่หมดประกัน',
      type: 'DATE_PICKER',
      onChange: (e) => fn.handleChange(e, 'insurance_date'),
      hidden: insuranceStatus === null || insuranceStatus === 'NO',
      rules: {
        required: insuranceStatus === null || insuranceStatus === 'NO' ? false : true,
      },
      // disabled: true,
    },
    {
      key: '8',
      span: 10,
      name: 'highest_approve_amount',
      label: 'วงเงินสูงสุดที่จะได้รับ:',
      type: 'INPUT',
      // onChangeCustom: (e) => fn.handleChange(e, 'highest_approve_amount'),
      disabled: true,
    },
    {
      key: '9',
      span: 10,
      name: 'approve_paid_amount',
      label: 'วงเงินที่อนุมัติ:',
      placeholder: 'กรุณากรอกวงเงินที่อนุมัติ',
      type: 'INPUT',
      onChangeCustom: (e) => fn.handleChange(e, 'approve_paid_amount'),
      // disabled: true,
    },
    {
      key: '10',
      span: 20,
      name: 'id_card_image',
      label: 'อัพโหลดรูปคู่บัตรประชาชน:',
      type: 'FILE_UPLOAD_WITH_HEADER',
      oneLine: true,
      accept: 'image/*',
      onUpload: fn.handleChange,
      fileList: idCardImageList,
      onPreview: fn.onPreview,
      // onRemove: handleRemove,
      urlUpload: `${API_URL}/admin/contract/getUrlUpload?`,
      imageCat: 'secondHand_idCard',
      // disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '11',
      span: 6,
      name: 'id_card',
      label: '',
      type: 'INPUT',
      onChangeCustom: (e) => fn.handleChange(e, 'id_card'),
      placeholder: 'เลขบัตรประชาชน',
      value: '',
      hiddenRequired: true,
      rules: {
        required: true,
      },
    },
    {
      key: '12',
      span: 6,
      name: 'name',
      label: '',
      type: 'INPUT',
      onChangeCustom: (e) => fn.handleChange(e, 'name'),
      placeholder: 'ชื่อ-นามสกุล',
      value: '',
      hiddenRequired: true,
      rules: {
        required: true,
      },
    },
    {
      key: '13',
      span: 6,
      name: 'tel',
      label: '',
      type: 'INPUT',
      onChangeCustom: (e) => fn.handleChange(e, 'tel'),
      placeholder: 'เบอร์โทรศัพท์',
      value: '',
      hiddenRequired: true,
      rules: {
        required: true,
      },
    },
    {
      key: '14',
      span: 20,
      name: 'book_bank_image',
      label: 'อัพโหลดรูปหน้า Book Bank:',
      type: 'FILE_UPLOAD_WITH_HEADER',
      oneLine: true,
      accept: 'image/*',
      onUpload: fn.handleChange,
      fileList: bookBankImage,
      onPreview: onPreview,
      // onRemove: handleRemove,
      urlUpload: `${API_URL}/admin/contract/getUrlUpload?`,
      imageCat: 'secondHand_bookBank',
      // disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '15',
      span: 9,
      name: 'book_bank_code',
      label: '',
      type: 'SELECT',
      options: options.bankOptions,
      onChangeCustom: (e) => fn.handleChange(e, 'book_bank_code'),
      placeholder: 'กรุณาเลือกธนาคาร',
      value: '',
      hiddenRequired: true,
      disabled: data.disabled,
      rules: {
        required: true,
      },
    },
    {
      key: '16',
      span: 9,
      name: 'book_bank_branch',
      label: '',
      type: 'INPUT',
      onChangeCustom: (e) => fn.handleChange(e, 'book_bank_branch'),
      placeholder: 'กรุณากรอกชื่อสาขาธนาคาร',
      value: '',
      hiddenRequired: true,
      disabled: data.disabled,
      rules: {
        required: true,
      },
    },
    {
      key: '17',
      span: 9,
      name: 'book_bank_name',
      label: '',
      type: 'INPUT',
      onChangeCustom: (e) => fn.handleChange(e, 'book_bank_name'),
      placeholder: 'กรุณากรอกชื่อบัญชี',
      value: '',
      hiddenRequired: true,
      disabled: data.disabled,
      rules: {
        required: true,
      },
    },
    {
      key: '18',
      span: 9,
      name: 'book_bank_number',
      label: '',
      type: 'INPUT',
      onChangeCustom: (e) => fn.handleChange(e, 'book_bank_number'),
      placeholder: 'กรุณากรอกเลขที่บัญชี',
      value: '',
      hiddenRequired: true,
      disabled: data.disabled,
      rules: {
        required: true,
      },
    },
    {
      key: '19',
      span: 20,
      name: 'mobile_image',
      label: 'อัพโหลดรูปเครื่อง:',
      type: 'FILE_UPLOAD_WITH_HEADER',
      oneLine: true,
      accept: 'image/*',
      onUpload: fn.handleChange,
      fileList: mobileImageList,
      onPreview: onPreview,
      maxCount: 10,
      multiple: true,
      // onRemove: handleRemove,
      urlUpload: `${API_URL}/admin/contract/getUrlUpload?`,
      imageCat: 'secondHand_mobilePhoto',
      // disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '20',
      span: 20,
      name: 'document_image',
      label: 'อัพโหลดรูปเอกสาร:',
      type: 'FILE_UPLOAD_WITH_HEADER',
      oneLine: true,
      accept: 'image/*',
      onUpload: fn.handleChange,
      fileList: documentImageList,
      onPreview: onPreview,
      maxCount: 10,
      multiple: true,
      // onRemove: handleRemove,
      urlUpload: `${API_URL}/admin/contract/getUrlUpload?`,
      imageCat: 'secondHand_documentPhoto',
      // disabled: true,
      rules: {
        required: true,
      },
    },
    {
      key: '21',
      span: 20,
      name: 'change_name_image',
      label: 'อัพโหลดรูปใบเปลี่ยนชื่อ:',
      type: 'FILE_UPLOAD_WITH_HEADER',
      oneLine: true,
      accept: 'image/*',
      onUpload: fn.handleChange,
      fileList: changeNameImageList,
      onPreview: onPreview,
      // onRemove: handleRemove,
      urlUpload: `${API_URL}/admin/contract/getUrlUpload?`,
      imageCat: 'downPayment_changeName',
      // disabled: true,
      rules: {
        required: enabledChangeName,
      },
    },
    {
      key: '22',
      span: 10,
      name: 'remark',
      label: 'หมายเหตุ:',
      type: 'INPUT_AREA',
      maxLength: 1500,
      rows: 8,
      onChangeCustom: (e) => fn.handleChange(e, 'remark'),
    },
    {
      key: '23',
      span: 10,
      align: 'right',
      name: 'signature_image',
      label: 'ลงลายมือชื่อ:',
      type: 'VIEWIMAGE',
      width: '70%',
      center: true,
      defaultValue: signatureImage !== '-' ? signatureImage : noImage,
      // component: 'MODAL_SIGNATURE',
      // onChangeCustom: fn.handleChange,
      // imageCat: 'secondHand_signature',
      // passValue: {signatureImage: signatureImage, edit: true},
    },
  ];
};

export const AddNewProductAndPriceSecondHandForm = ({options}) => {
  return [
    {
      key: '1',
      span: 24,
      name: 'model',
      label: 'สินค้า',
      type: 'SELECT',
      options: options.optionProductModelAll,
      placeholder: 'เลือกชื่อสินค้า',
      rules: {
        required: true,
      },
    },
    {
      key: '2',
      span: 12,
      name: 'storage',
      label: 'ความจุ',
      placeholder: 'กรุณาเลือกความจุ',
      type: 'SELECT',
      options: options.optionProductStorageAll,
      rules: {
        required: true,
      },
    },
    {
      key: '3',
      span: 12,
      name: 'color',
      label: 'สี',
      placeholder: 'กรุณากรอกชื่อสี',
      type: 'SELECT',
      options: options.optionProductColorAll,
      rules: {
        required: true,
      },
    },
    {
      key: '4',
      span: 24,
      name: 'price_condition_1',
      label: 'Condition 1',
      type: 'INPUT',
      // rules: {
      //   required: true,
      // },
    },
    {
      key: '5',
      span: 24,
      name: 'price_condition_2',
      label: 'Condition 2',
      type: 'INPUT',
      // rules: {
      //   required: true,
      // },
    },
    {
      key: '6',
      span: 24,
      name: 'price_condition_3',
      label: 'Condition 3',
      type: 'INPUT',
      // rules: {
      //   required: true,
      // },
    },
    {
      key: '7',
      span: 24,
      name: 'price_condition_4',
      label: 'Condition 4',
      type: 'INPUT',
      // rules: {
      //   required: true,
      // },
    },
  ];
};

export const SignSecondHandDetailForm = () => {
  return [
    {
      key: '1',
      span: 16,
      name: 'document_no',
      label: 'เลขที่เอกสาร',
    },
    {
      key: '2',
      span: 16,
      name: 'name',
      label: 'ชื่อ-นามสกุล',
    },
    {
      key: '3',
      span: 16,
      name: 'product_name',
      label: 'สินค้า',
    },
    {
      key: '4',
      span: 16,
      name: 'serial_no',
      label: 'Serial Number',
    },
    {
      key: '5',
      span: 16,
      name: 'approve_paid_amount',
      label: 'วงเงินที่อนุมัติ',
    },
  ];
};

export const EditRemarkSecondHandForm = () => {
  return [
    {
      key: '1',
      span: 24,
      name: 'remark',
      label: 'หมายเหตุ',
      type: 'INPUT_AREA',
      rows: 10,
      maxLength: 1500,
      rules: {
        required: true,
      },
    },
  ];
};