import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../../components/buttons';
import { DEVICE_RECHECK_FORM } from '../form';
import { POST, GET_DETAIL_RECEIVE_DEVICE, ADD_CHECK_RECEIVE_DEVICE } from '../../../services';
import { Icon } from '../../../resources/icon';
import moment from 'moment';
import { optionProductCondition } from '../constant';
import TrackingHistoryModal from '../../../components/modal/TrackingTask/TrackingHistoryModal';

export const DeviceRecheckModal = ({ title, visible, onClose, data, reApi }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [visibleHistory, setVisibleHistory] = useState(false);
  const [dataResult, setDataResult] = useState({});
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    if (data && data?.contract_no) {
      onGetData(data?.contract_no);
    }
  }, [data]);

  const onGetData = async (contractNo) => {
    try {
      const res = await POST(GET_DETAIL_RECEIVE_DEVICE, { contract_no: contractNo });
      const { success, data } = res;
      if (success) {
        setDataResult(data[0]);
        const { contract_no, name, tel, product_name, sn, hold_date, tracker_name, remark } = data[0];
        setValue('name', name);
        setValue('tel', tel);
        setValue('contract_no', contract_no);
        setValue('product_name', product_name);
        setValue('hold_date', moment(hold_date, 'YYYY-MM-DD'));
        setValue('sn', sn);
        setValue('tracker_name', tracker_name);
        setValue('remark', remark);
      }
      setImageList([data[0]?.product_picture?.phoneFront, data[0]?.product_picture?.phoneBack, data[0]?.product_picture?.phoneSerial]);
    } catch (error) {
      Modal.error({ title: error?.message });
    }
  };

  const openConfirm = (params) => {
    Modal.confirm({
      title: 'ยืนยัน',
      icon: <Icon.warningAlert />,
      content: 'ยืนยันตรวจสอบรับเครื่อง?',
      onOk() {
        onSubmit(params);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const onSubmit = async (params) => {
    setLoading(true);
    try {
      const payload = { contract_no: params?.contract_no, product_condition: params?.product_condition, remark: params?.remark };
      const res = await POST(ADD_CHECK_RECEIVE_DEVICE, payload);
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            reApi();
            onClose();
          },
        });
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal title={title || 'modal'} open={visible} width={960} onCancel={onClose} footer={null} destroyOnClose={true} maskClosable={false}>
      <form onSubmit={handleSubmit(openConfirm)}>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          formList={DEVICE_RECHECK_FORM({
            images: {
              customer: dataResult?.cus_picture,
              product: imageList,
            },
            options: {
              productCondition: optionProductCondition,
            },
          })}
          renderButton={
            <>
              <ContainerButton right>
                <ButtonTheme useFor="VIEW" title="ประวัติการติดตาม" onClick={() => setVisibleHistory(true)} />
                <ButtonTheme useFor="CONFIRM" title="บันทึก" htmlType="submit" loading={loading} />
                <ButtonTheme useFor="CANCEL" onClick={() => onClose()} />
              </ContainerButton>
            </>
          }
        />
      </form>
      {visibleHistory && <TrackingHistoryModal visible={visibleHistory} onClose={() => setVisibleHistory(false)} data={dataResult} />}
    </Modal>
  );
};
