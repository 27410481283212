import React, { useState, useEffect } from 'react';
import { CardTheme } from '../card/CardTheme';
import { useForm } from 'react-hook-form';
import RenderForm from '../../constants/global/RenderForm';
import { TextLarge } from '../forms/text';

export const InfoLayout = ({ props }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
  } = useForm();
  const { title, formInfo, icon, extra, defaultValue, color, renderButton, onSubmit } = props;

  useEffect(() => {
    if (defaultValue) {
      formInfo.forEach((val) => {
        setValue(val.name, defaultValue[val.name] || '');
      });
    }
  }, [defaultValue]);

  return (
    <>
      <CardTheme
        title={
          <div style={{ display: 'flex', alignItems: 'center', color: '#0052BA' }}>
            {icon}
            &nbsp;
            <TextLarge text={title} color={color} />
          </div>
        }
        extra={extra}
        content={
          <formRenderForm onSubmit={handleSubmit(onSubmit)}>
            <RenderForm control={control} setValue={setValue} getValues={getValues} errors={errors} formList={formInfo} renderButton={renderButton} />
          </formRenderForm>
        }
      />
    </>
  );
};
