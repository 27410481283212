import {BookOutlined} from '@ant-design/icons';
import {Card, Col, Modal, Row, Spin} from 'antd';
import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {ButtonTheme} from '../../components/buttons';
import {PageLayout} from '../../components/pageLayout/PageLayout';
import {DownPaymentSearchForm} from '../../constants/global/SearchForm';
import {DownPaymentColumn} from '../../constants/global/columnTableForm';
import {convertStrToFormat} from '../../functions/fn';
import {GET, GET_REPORT_DOWN_PAYMENT, GET_REPORT_DOWN_PAYMENT_COUNT_HEADER} from '../../services/index';
import {ContainerButton} from '../../styles/styledComponentGlobal';
import Wrapper from '../../views/wrapper/Wrapper';
import {useGetExportSavingBalanceDownPaymentExcel} from './api';

const ReportDownPaymentScreen = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const pageRef = useRef(1);
  const sizeRef = useRef(10);
  const [totalSize, setTotalSize] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [idCardState, setIdCardState] = useState(null);
  const [nameState, setNameState] = useState(null);
  const [telState, setTelState] = useState(null);
  const [startDateState, setStartDateState] = useState(null);
  const [endDateState, setEndDateState] = useState(null);
  const [statusState, setStatuseState] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [params, setParams] = useState(null);
  const [summaryList, setSummarayList] = useState([
    {label: 'จำนวนลูกค้า', value: 0, unit: 'คน', bg: 'rgb(129 140 248)', color: 'rgb(46 16 101)'},
    {label: 'ยอดออมรวม', value: 0, unit: 'บาท', bg: 'rgb(254 249 195)', color: 'rgb(217 119 6)'},
  ]);

  const [dataSearch, setDataSearch] = useState({
    IsDownPayment: true,
    page: pageRef.current,
    size: sizeRef.current,
    user_id_card: '',
    name: '',
    tel: '',
    start_date: '',
    end_date: '',
  });

  const [resultReport, loadingReport, errorReport, setInputReport] = useGetExportSavingBalanceDownPaymentExcel();

  useEffect(() => {
    if (resultReport?.message === 'success' && resultReport?.url) {
      setTimeout(() => {
        window.open(resultReport?.url, '_blank', 'noopener,noreferrer');
        onClose();
      }, 1000);
    }
  }, [resultReport]);

  useEffect(() => {
    if (errorReport) {
      const message = errorReport?.response?.data?.errors;

      Modal.error({title: 'ดึงข้อมูลไม่สำเร็จ', content: message});
      onClose();
    }
  }, [errorReport]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const idCardParams = params.get('user_id_card');
    const telParams = params.get('tel');
    const nameParams = params.get('name');
    const startDateParams = params.get('start_date');
    const endDateParams = params.get('end_date');

    if (idCardParams) {
      setDataSearch({
        IsDownPayment: true,
        page: pageRef.current,
        size: sizeRef.current,
        userIdCard: idCardParams,
        userName: nameParams,
        userTel: telParams,
        user_id_card: idCardParams,
        name: nameParams,
        tel: telParams,
        start_date: startDateParams || '',
        end_date: endDateParams || '',
      });

      getDownPaymenteData({
        page: pageRef.current,
        size: sizeRef.current,
        user_id_card: idCardParams,
        name: nameParams,
        tel: telParams,
        start_date: startDateParams || '',
        end_date: endDateParams || '',
      });
    } else {
      setDataSearch({
        IsDownPayment: true,
        page: pageRef.current,
        size: sizeRef.current,
        userIdCard: '',
        userTel: '',
        userName: '',
        user_id_card: '',
        name: '',
        tel: '',
        start_date: startDateParams || '',
        end_date: endDateParams || '',
      });

      getDownPaymenteData({
        page: pageRef.current,
        size: sizeRef.current,
        user_id_card: '',
        name: '',
        tel: '',
        start_date: startDateParams || '',
        end_date: endDateParams || '',
      });
    }
  }, []);

  const createParams = (value) => {
    let params = [];
    let result = '';

    Object.keys(value).forEach((key) => {
      if (value[key]) {
        if (key === 'start_date') {
          params.push('start_date=' + convertStrToFormat(value.start_date, 'dateAD'));
        } else if (key === 'end_date') {
          if (value[key]) {
            params.push('end_date=' + convertStrToFormat(value.end_date, 'dateAD'));
          } else if (!value[key]) {
            params.push('end_date=' + convertStrToFormat(new Date(), 'dateAD'));
          }
        } else {
          params.push(key + '=' + value[key]);
        }
      }
    });

    if (params.length !== 0) {
      result += params.join('&');
    }
    return result;
  };

  const getDownPaymenteData = async (value) => {
    setDataSource([]);

    value.page = value?.page || 1;
    value.size = value?.size || 10;

    pageRef.current = value.page;
    sizeRef.current = value.size;

    value.user_id_card = value.user_id_card || '';
    value.name = value.name || '';
    value.tel = value.tel || '';
    value.start_date = value.start_date || '';
    value.end_date = value.end_date || '';
    // value.startDate = value.startDate ? moment(value.startDate).format('YYYY-MM-DD') : ''
    // value.endDate = value.endDate ? moment(value.endDate).format('YYYY-MM-DD') : ''
    setLoading(true);
    let params = createParams(value);
    setParams(params);

    const {name, tel, user_id_card, start_date, end_date, status} = value;

    setIdCardState(user_id_card);
    setNameState(name);
    setTelState(tel);
    setStartDateState(start_date);
    setEndDateState(end_date);
    setStatuseState(status);

    try {
      const res = await GET(GET_REPORT_DOWN_PAYMENT(params));
      const {success, data, total_item, total_page} = res;

      if (success) {
        setTotalSize(total_item);
        setTotalPage(total_page);
        const result = data.map((el) => {
          return {
            key: el?._id,
            date: el?.transaction_date ? moment(el?.transaction_date).format('DD/MM/YYYY HH:mm') : '',
            name: el?.user?.general?.fullname || '',
            user_id_card: el?.user?.id_card || '',
            tel: el?.user?.tel || '',
            pay_from_qr_name: el?.payment_tqr_notification?.from_name || '',
            ...el,
          };
        });
        setDataSource(result);
      }
    } catch (err) {
      const {error} = err;
      Modal.error({
        title: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(async () => {
    let summary = [
      {label: 'จำนวนลูกค้า', value: 0, unit: 'คน', bg: 'rgb(129 140 248)', color: 'rgb(46 16 101)'},
      {label: 'ยอดออมรวม', value: 0, unit: 'บาท', bg: 'rgb(254 249 195)', color: 'rgb(217 119 6)'},
    ];

    if (dataSource.length > 0) {
      const res = await GET(GET_REPORT_DOWN_PAYMENT_COUNT_HEADER(params));
      const {success, header} = res;
      if (success === true) {
        summary = [
          {
            label: 'จำนวนลูกค้า',
            value: Number(header.total_user).toLocaleString('en-US'),
            unit: 'คน',
            bg: 'rgb(129 140 248)',
            color: 'rgb(46 16 101)',
          },
          {
            label: 'ยอดออมรวม',
            value: Number(header.total_amount_dairy).toLocaleString('en-US', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
            unit: 'บาท',
            bg: 'rgb(254 249 195)',
            color: 'rgb(217 119 6)',
          },
        ];
      }
    }
    setSummarayList(summary);
  }, [dataSource]);

  const onPageSizeChangeCustom = (pageData, sizeData) => {
    const sizeChange = sizeRef.current !== sizeData;
    if (sizeChange) {
      // setPageState(1)
      pageRef.current = 1;
    } else {
      // setPageState(pageData)
      pageRef.current = pageData;
    }
    sizeRef.current = sizeData;

    let id_card = idCardState;
    let name = nameState;
    let tel = telState;
    let start_date = startDateState;
    let end_date = endDateState;
    let status = statusState;

    const params = {
      page: pageRef.current,
      size: sizeRef.current,
      user_id_card: id_card || '',
      name: name || '',
      tel: tel || '',
      start_date: start_date,
      end_date: end_date,
      status: status || '',
    };

    getDownPaymenteData(params);
  };

  const onExportExcelReport = async () => {
    const obj = {
      page: totalPage,
      size: totalSize,
      user_id_card: idCardState,
      name: nameState,
      tel: telState,
      start_date: startDateState,
      end_date: endDateState,
    };

    const params = createParams(obj);

    await setInputReport(params.length ? `?${params}` : params);
  };

  const onClearCustom = (setValue, formSearch) => {
    formSearch.map((val) => setValue(val.name, null));

    setDataSearch({
      IsDownPayment: true,
      page: pageRef.current,
      size: sizeRef.current,
      user_id_card: '',
      name: '',
      tel: '',
      start_date: '',
      end_date: '',
    });

    setIdCardState('');
    setNameState('');
    setTelState('');
    setStartDateState('');
    setEndDateState('');
    setStatuseState('');
    pageRef.current = 1;
    sizeRef.current = 10;
  };

  const onClose = () => setVisibleModal(false);

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading || loadingReport}>
          <PageLayout
            searchLayout={{
              title: 'รายงานยอดออมดาวน์',
              icon: <BookOutlined />,
              spanSearch: 24,
              formSearch: DownPaymentSearchForm(dataSearch),
              onSearch: (data) => getDownPaymenteData(data),
              dataSearchDefault: dataSearch,
              onClearCustom,
            }}
            tableLayout={{
              columns: DownPaymentColumn(),
              dataSource: dataSource,
              extraContent: (
                <>
                  <ContainerButton left>
                    <Row>
                      {summaryList.map((item, index) => {
                        return (
                          <Col key={index}>
                            <Card
                              style={{backgroundColor: item.bg, color: item.color, borderColor: item.color}}
                              className="card-summary"
                              size="small">
                              <span>{`${item.label} : `}</span>
                              <span style={{fontSize: '22px'}}>{`${item.value} ${item.unit}`}</span>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  </ContainerButton>
                  <ContainerButton right>
                    <ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด Excel'} onClick={() => setVisibleModal(true)} />
                  </ContainerButton>
                </>
              ),
              rowKey: (record) => record.key,
              pagination: {
                showSizeChanger: true,
                current: pageRef.current,
                pageSize: sizeRef.current,
                onChange: (p, s) => onPageSizeChangeCustom(p, s),
                pageSizeOptions: ['10', '20', '30', '40', '100', '200', '300'],
                total: totalSize || 0,
                showTotal: (totalSize, range) => `รายการทั้งหมด ${convertStrToFormat(totalSize, 'number-comma')} รายการ`,
                defaultPageSize: sizeRef.current,
                defaultCurrent: 1,
              },
            }}
          />
        </Spin>
      </Card>
      <Modal
        title={'ต้องการดาวน์โหลด Excel?'}
        open={visibleModal}
        width={550}
        onCancel={onClose}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}>
        <ContainerButton right>
          <ButtonTheme useFor="CUSTOM" title={'ตกลง'} onClick={async () => await onExportExcelReport()} />
          <ButtonTheme useFor="CANCEL" onClick={() => onClose()} />
        </ContainerButton>
      </Modal>
    </Wrapper>
  );
};

export default ReportDownPaymentScreen;
