import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Table, Divider  } from 'antd'
import _ from 'lodash'
import Spinners from '../spinners/Spinners'
import { DatatableLogStyle } from './DatatableLogStyle'

const DatatableLog = ({
  rowKey,
  data,
  isLoading,
  page,
  pageSize,
  total,
  className,
  columns,
  style,
  pagination,
  paginationCustom,
  onChangePagination,
  showSizeChanger,
  pageSizeOptions,
  handleTableChange: handleChange = () => {},
  showDivider = true,
  ...otherProps
}) => {
  const [isDataSources, setIsDataSources] = useState([])

  useEffect(() => {
    !_.isEmpty(data) ? setIsDataSources(data) : setIsDataSources([])
  }, [data])

  const handleTableChange = (pagination, filters, sorter, extra) => handleChange({ pagination, filters, sorter, extra })

  return (
    <DatatableLogStyle className={`table-containers`} style={{ marginTop: showDivider? '50px': '0px'}}>
      {isLoading && <Spinners />}

      {showDivider && (
        <>
          <Divider style={{background: 'black'}} />
          <div style={{fontSize: '18px', marginBottom: '10px'}}>ประวัติการส่งแจ้งเตือน</div>
        </>
      )}
      <Table
        {...otherProps}
        rowKey={rowKey || 'id'}
        style={style}
        className={className}
        columns={columns}
        dataSource={[...isDataSources]}
        pagination={pagination ? {...pagination} : false}
        showSorterTooltip={false}
        onChange={handleTableChange}
      />
    </DatatableLogStyle>
  );
}

export default DatatableLog

DatatableLog.propTypes = {
  handleTableChange: PropTypes.func,
  onChangePagination: PropTypes.func,
  showSizeChanger: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  pageSizeOptions: PropTypes.array
}

DatatableLog.defaultProps = {
  pageSizeOptions: ['1', '10', '20', '50', '100']
}
