/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import HeaderView from '../../components/header/Header';
import MenuView from '../../components/menu/Menu';
import ContentComp from '../../components/content/Content';
import { useContext } from 'react';
// import { socketContext } from '../../utils/socketProvider';
import { useDispatch } from 'react-redux';
import { adminLogout } from '../../redux/actions/auth';

const Wrapper = (props) => {
  // const { socket, userData } = useContext(socketContext)
  const dispatch = useDispatch();

  /** listen socket "force-logout" from server */
  // socket.on('force-logout', (messageNew) => {
  // 	console.log('userData.email === messageNew.email', userData.email, messageNew.email)
  // 	if (userData.email === messageNew.email) {
  // 		if (messageNew.message === 'ok') {
  // 			dispatch(adminLogout())
  // 		}
  // 	}
  // })

  return (
    <>
      <HeaderView />
      <MenuView />
      <ContentComp>{props.children}</ContentComp>
    </>
  );
};

export default Wrapper;
