import moment from 'moment';
import {TextSmall} from '../../../components/forms/text';
import {renderSearchColumn} from '../../../constants/global/renderSearchColumn';
import {Tag, Space, Select, Typography} from 'antd';
import {createRef} from 'react';
import {color} from '../../../resources/color';
import {ButtonTheme} from '../../../components';

const {Text} = Typography;

export const StockProductsPickMainColumn = () => {
  return [
    {
      title: 'สาขา',
      dataIndex: 'branch',
      key: 'branch',
      render: (text, record) => {
        return record.label;
      },
    },
  ];
};

export const StockProductsPickPrintColumn = () => {
  return [
    {
      title: 'NO',
      dataIndex: 'rowNo',
      width: '30px',
      align: 'center',
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'product_name',
      width: '300px',
      align: 'left',
    },
    // {
    //   title: 'ลักษณะสินค้า',
    //   dataIndex: 'productHand',
    //   width: '80px',
    //   align: 'center',
    // },
    {
      title: 'จำนวนจอง',
      dataIndex: 'countReserve',
      width: '80px',
      align: 'right',
    },
    {
      title: 'จำนวนสต็อก',
      dataIndex: 'countStock',
      width: '80px',
      align: 'right',
    },
    {
      title: 'จำนวนที่ต้องหยิบ',
      dataIndex: 'countPick',
      width: '80px',
      align: 'right',
    },
  ];
};

export const StockProductsPickColumn = () => {
  return [
    {
      title: 'NO',
      dataIndex: 'rowNo',
      width: '40px',
      align: 'center',
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'product_name',
      width: '150px',
      align: 'left',
    },
    {
      title: 'ลักษณะสินค้า',
      dataIndex: 'productHand',
      width: '100px',
      align: 'center',
    },
    {
      title: 'จำนวนจอง',
      dataIndex: 'countReserve',
      width: '80px',
      align: 'right',
    },
    {
      title: 'จำนวนสต็อก',
      dataIndex: 'countStock',
      width: '80px',
      align: 'right',
    },
    {
      title: 'จำนวนที่ต้องหยิบ',
      dataIndex: 'countPick',
      width: '80px',
      align: 'right',
      render: (text, record) => {
        return <TextSmall text={text <= 0 ? `${text} (ไม่ต้องจัด)` : text} />;
      },
    },
  ];
};

export const PickReportColumn = ({options, redirectToPrintPick}) => {
  const searchInput = createRef();
  return [
    {
      title: 'No',
      dataIndex: 'no',
      width: '40px',
      align: 'center',
    },
    {
      title: 'เลขที่เอกสาร',
      dataIndex: 'document_no',
      width: '100px',
      align: 'left',
      ...renderSearchColumn('document_no', searchInput),
    },
    {
      title: 'ผู้สร้าง',
      dataIndex: 'user_create',
      width: '120px',
      align: 'left',
      ...renderSearchColumn('user_create', searchInput),
    },
    {
      title: 'วันที่สร้าง',
      dataIndex: 'create_date',
      width: '100px',
      align: 'left',
      render: (text) => {
        return (text && moment(text).format('YYYY-MM-DD HH:mm')) || '';
      },
      sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),
    },
    {
      title: 'ปลายทาง',
      dataIndex: 'branch_to',
      width: '120px',
      align: 'left',
      filters: options.optionBranch,
      onFilter: (value, record) => record.branch_to.indexOf(value) === 0,
      render: (text, record) => {
        return record.branch_to_name.branch_name;
      },
    },
    {
      title: 'ผู้รับ',
      dataIndex: 'user_update',
      width: '120px',
      align: 'left',
      ...renderSearchColumn('user_update', searchInput),
      render: (text, record) => {
        return record.status === 'RECEIVE' ? text : '';
      },
    },
    {
      title: 'วันที่รับ',
      dataIndex: 'date_receive',
      width: '100px',
      align: 'left',
      render: (text) => {
        return (text && moment(text).format('YYYY-MM-DD HH:mm')) || '';
      },
      sorter: (a, b) => moment(a.date_receive).unix() - moment(b.date_receive).unix(),
    },
    {
      title: 'สถานะ',
      dataIndex: 'status_name',
      width: '80px',
      align: 'center',
      filters: options.optionJournalStatus,
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (text, record) => {
        return (
          <Tag
            color={
              record.status === 'PICK'
                ? color.on_process
                : record.status === 'RECEIVE'
                ? color.success
                : record.status === 'CANCEL'
                ? color.danger
                : color.edit
            }>
            {text}
          </Tag>
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'center',
      width: '80px',
      render: (text, record) => {
        return (
          (record.status === 'RECEIVE' || record.status === 'PACK') && (
            <ButtonTheme useFor="VIEW" title="เปิดเอกสาร" onClick={() => redirectToPrintPick(record)} />
          )
        );
      },
    },
  ];
};

export const CheckStockColumn = ({option, onChangeSelectedAppleStatus}) => {
  const tagColor = (data) => {
    let tag = '';
    switch (!!data) {
      case data === 'READY':
        tag = '#61c264';
        break;
      case data === 'NOT_READY':
        tag = '#d41717';
        break;
      default:
        tag = color.gray;
        break;
    }
    return tag;
  };

  return [
    {
      title: 'Line No.',
      dataIndex: 'line_no',
      width: '40px',
      align: 'center',
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'item_name',
      width: '150px',
      align: 'left',
    },
    {
      title: 'S/N',
      dataIndex: 'serial_number',
      width: '100px',
      align: 'center',
    },
    {
      title: 'สถานะ',
      dataIndex: 'statusText',
      width: '80px',
      align: 'center',
      render: (text, record) => {
        return <Tag color={record.status == 'OVER' ? color.on_process : record.status == 'COMPLETE' ? color.success : color.remove}>{text}</Tag>;
      },
    },
    {
      title: 'Serial Status',
      dataIndex: 'serial_status',
      width: '80px',
      align: 'center',
      render: (text, record) => {
        return record.serial_status === null || record.serial_status === undefined || record.serial_status === '' ? (
          <Text>{'-'}</Text>
        ) : (
          <Tag color={tagColor(record.serial_status)}>
            {record.serial_status === 'READY' ? 'พร้อมขาย' : record.serial_status === 'NOT_READY' ? 'ไม่พร้อมขาย' : record.serial_status}
          </Tag>
        );
      },
    },
    {
      title: 'Apple Status',
      dataIndex: 'appleStatus',
      key: 'appleStatus',
      align: 'center',
      width: '80px',
      render: (text, record) => {
        return (
          <Space size="middle">
            {text === 0 ? null : (
              <Select
                className="manageDEP"
                style={
                  record.appleStatus === 'TBD'
                    ? {width: 150, color: '#d41717'}
                    : record.appleStatus === 'DEP'
                    ? {width: 150, color: '#61c264'}
                    : {width: 150, color: '#0052BA'}
                }
                onChange={(e) => onChangeSelectedAppleStatus(e, record.index)}
                options={option.optionAppleStatus}
                disabled={!!record.serial_number === false}
                // defaultValue={record.appleStatus}
                value={record.appleStatus}
              />
            )}
          </Space>
        );
      },
    },
    {
      title: 'เช็ค Apple Status',
      dataIndex: 'isSameAppleStatus',
      width: '80px',
      align: 'center',
      render: (text, record) => {
        let result = '';
        if (record.isSameAppleStatus === false) {
          result = 'ไม่ตรง';
        } else if (record.isSameAppleStatus === true) {
          result = 'ตรง';
        }
        return <Tag color={record.isSameAppleStatus === true ? color.success : color.danger}>{result}</Tag>;
      },
    },
  ];
};

export const ReserveProductStockReportColumn = ({options}) => {
  return [
    {
      title: 'วันที่นัดทำสัญญา',
      dataIndex: 'appointment_date',
      width: '150px',
      align: 'center',
      render: (text, record) => {
        return (record && moment(record.appointment_date).format('DD/MM/YYYY')) || '';
      },
    },
    {
      title: 'เวลา',
      dataIndex: 'time',
      width: '20px',
      align: 'center',
      render: (text, record) => {
        return (record && moment(record.appointment_date).format('HH:mm')) || '';
      },
    },
    {
      title: 'ชื่อ - นามสกุล',
      dataIndex: 'user_name',
      width: '150px',
      align: 'left',
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'item_name',
      width: '280px',
      align: 'left',
    },
    {
      title: 'ลักษณะสินค้า',
      dataIndex: 'product_hand',
      width: '120px',
      align: 'center',
    },
    {
      title: 'ผู้นัดทำสัญญา',
      dataIndex: 'admin_name',
      width: '150px',
      align: 'left',
    },
    {
      title: 'วันที่กดนัดทำสัญญา',
      dataIndex: 'create_date',
      width: '150px',
      align: 'center',
      render: (text, record) => {
        return (record && moment(record.create_date).format('DD/MM/YYYY HH:mm')) || '';
      },
    },
    {
      title: 'สาขา',
      dataIndex: 'branch',
      width: '100px',
      align: 'center',
      render: (text, record) => {
        let branch_name = options.optionBranch.filter((el) => el.value === record.branch);
        return branch_name[0]?.label || '';
      },
    },
    {
      title: 'หมายเหตุ(สำหรับเจ้าหน้าที่)',
      dataIndex: 'user_remark',
      width: '200px',
      align: 'right',
    },
    {
      title: 'หมายเหตุ(สำหรับภายในทีม)',
      dataIndex: 'admin_remark',
      width: '200px',
      align: 'right',
    },
  ];
};
