import axios from 'axios';
import { API_URL } from '../env.js';


export const getConsiderDataExport = async (params) => {

  return await axios.get(`${API_URL}/admin/consider/export_excel${params}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('token')}`,
      'content-type': 'application/json',
    },
  }).catch(function (error) {
    if (error.response) {
      return error.response
    }
  })
}

export const getTrackingNo = async (params) => {

  try {
    return await axios.get(`${API_URL}/admin/notice-of-termination-of-contract${params}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json',
      },
    });
  } catch (error) {
    return error;
  }
};