import * as TYPES from '../types/customer';

const initialState = {
    loading: false,
    error: null,
    size: 10,
    page: 1,
    customers: [],
    totalItem: 0,
    totalPage: 0
};

const initialStateInfo = {
    loading: false,
    error: null,
    customerInfo: null
};

export const customersReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.GET_LIST_CUSTOMER_REQ:
            return { ...state, loading: true };
        case TYPES.GET_LIST_CUSTOMER_SUCCESS:
            return { ...state, loading: false, error: null, customers: payload.data.data.customers, totalItem: payload.data.data.total_item, totalPage: payload.data.data.total_page };
        case TYPES.GET_LIST_CUSTOMER_FAIL:
            return { ...state, loading: false, error: payload };
        default:
            return state;
    }
};
export const customerInfoReducer = (state = initialStateInfo, { type, payload }) => {
    switch (type) {
        case TYPES.GET_CUSTOMER_INFO_REQ:
            return { ...state, loading: true };
        case TYPES.GET_CUSTOMER_INFO_SUCCESS:
            return { ...state, loading: false, error: null, customerInfo: payload.data.data };
        case TYPES.GET_CUSTOMER_INFO_FAIL:
            return { ...state, loading: false, error: payload };
        default:
            return state;
    }
};