export const optionCondition = [
    {
        value:"CHANGE_PASSWORD",
        label:"ผิดเงื่อนไขเปลี่ยนรหัส"
    },
    {
        value:"SALE_PHONE",
        label:"ผิดเงื่อนไขนำเครื่องไปขาย"
    },
    {
        value:"OTHER",
        label:"ผิดเงื่อนไขอื่นๆ"
    }
]

export const optionTrackStatus = [
    {
        value:"YES",
        label:"ติดตามแล้วเกิน 60+"
    },
    {
        value:"NO",
        label:"ยังไม่ถูกติดตามเกิน 60+"
    },
]
