export const LIST_TRANSACTION_REQ = "LIST_TRANSACTION_REQ";
export const LIST_TRANSACTION_SUCCESS = "LIST_TRANSACTION_SUCCESS";
export const LIST_TRANSACTION_FAIL = "LIST_TRANSACTION_FAIL";

export const GET_TRANSACTION_REQ = "GET_TRANSACTION_REQ";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_FAIL = "GET_TRANSACTION_FAIL"

export const UPDATE_TRANSACTION_REQ = "UPDATE_TRANSACTION_REQ";
export const UPDATE_TRANSACTION_SUCCESS = "UPDATE_TRANSACTION_SUCCESS";
export const UPDATE_TRANSACTION_FAIL = "UPDATE_TRANSACTION_FAIL";

export const EXPORT_TRANSACTION_REQ = "EXPORT_TRANSACTION_REQ";
export const EXPORT_TRANSACTION_SUCCESS = "EXPORT_TRANSACTION_SUCCESS";
export const EXPORT_TRANSACTION_FAIL = "EXPORT_TRANSACTION_FAIL";

export const UPDATE_TRANSACTION_INFO_REQ = "UPDATE_TRANSACTION_INFO_REQ";
export const UPDATE_TRANSACTION_INFO_SUCCESS = "UPDATE_TRANSACTION_INFO_SUCCESS";
export const UPDATE_TRANSACTION_INFO_FAIL = "UPDATE_TRANSACTION_INFO_FAIL";