// /* eslint no-use-before-define: 0 */
import React, {useState, useEffect} from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import {Modal, Card, Spin} from 'antd';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../../components/buttons';
import {AuditOutlined} from '@ant-design/icons';
import {PageLayout} from '../../../components/pageLayout/PageLayout';
import {ReportMonitorStockNonSerialSearchForm} from '../../../constants/global/SearchForm';
import {ReportMonitorStockNonSerialColumn} from '../../../constants/global/columnTableForm';
import {GET, GET_BRANCH,GET_MONITOR_STOCK_NON_SERIAL_REPORT} from '../../../services/index';
import { CSVLink } from "react-csv";
import {auth} from '../../../utils/firebase';
import {useCurrentState} from '../../../utils/hook';
import {exportDefault} from "../../../functions/fn"



const MonitorStockNonSerial = () => {

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [optionBranch,setOptionBranch] = useState([])
  const [dataExport,setDataExport] = useState([])
  const {user} = useCurrentState(auth);
  const [branchDefault,setBranchDefault] = useState("")

  useEffect(() => {
    if(user && user.branch){
        setBranchDefault(user.branch ==="CENTER"?"":user.branch)
        getReportMonitorStock({branch:user.branch ==="CENTER"?"":user.branch,product:""});
        getOptionBranch()
    }
    
  }, [user]);


  const getOptionBranch = async () => {
    try {
      const res = await GET(GET_BRANCH);
      const {success, result} = res;
      if (success) {

        const option = result.branch

        const optionCheck = user.branch==="CENTER"?option:option.filter((el)=>{
            if(el.value === user.branch){
                return {
                    label:el.label,
                    value:el.value
                }
            }
        })

       
        setOptionBranch([{value:"",label:"ทุกสาขา"},...optionCheck]);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };



  const getReportMonitorStock = async (data) => {
    setLoading(true);
    try {
      
      let res = await GET(GET_MONITOR_STOCK_NON_SERIAL_REPORT(data.branch || "",data.product || ""));
      const {success, result} = res;
      if (success) {
        const dataEx = result.report.map((el, i) => {
          return {
            'สาขา':el.branch_name,
            'รหัสสินค้า':el.item_code,
            'ชื่อสินค้า':el.item_name,
            'จำนวน':el.stock,
            'ระหว่างส่ง':el.inTransit,
            'คงเหลือ':el.remaining,
          };
        });
        const dataExportDefault = exportDefault(['สาขา','รหัสสินค้า','ชื่อสินค้า','จำนวน','ระหว่างส่ง','คงเหลือ'])
        setDataExport(dataEx.length > 0 && dataEx || dataExportDefault)
        setDataSource(result.report);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'รายงานติดตามสต็อกสินค้า Non Serial',
              icon: <AuditOutlined />,
              spanSearch: 24,
              formSearch: ReportMonitorStockNonSerialSearchForm({option:{optionBranch},branchDefault}),
              onSearch: (data) => getReportMonitorStock(data),
            }}
            tableLayout={{
              columns: ReportMonitorStockNonSerialColumn(),
              dataSource: dataSource,
              extraContent: (
                <ContainerButton right>
                    <CSVLink data={dataExport} filename={"รายงานติดตามสต็อกสินค้า Non Serial"}><ButtonTheme useFor="DOWNLOAD" title={'EXPORT'} /></CSVLink>
                </ContainerButton>
              ),
            }}
          />
        </Spin>
      </Card>
    </Wrapper>
  );
};

export default MonitorStockNonSerial;
