// /* eslint no-use-before-define: 0 */
import React, { useState, useEffect } from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import { Modal, Card, Spin, Row, Space } from 'antd';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../../components/buttons';
import { AuditOutlined } from '@ant-design/icons';
import { PageLayout } from '../../../components/pageLayout/PageLayout';
import { ReportBuyStockSearchForm } from '../../../constants/global/SearchForm';
import { ReportBuyStockColumn } from '../../../constants/global/columnTableForm';
import { PUT, GET_REPORT_BUY_STOCK } from '../../../services/index';
import { CSVLink } from "react-csv";
import { auth } from '../../../utils/firebase';
import { useCurrentState } from '../../../utils/hook';
import { buildArrayOfObjectWithNoDulplicateKey, convertStrToFormat } from '../../../functions/fn';
import { color } from '../../../resources/color';
import moment from 'moment/moment';


const BuyStockReport = () => {

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataExport, setDataExport] = useState([])
  const { user } = useCurrentState(auth);
  let optionDocNo = []
  let optionVendor = []

  useEffect(() => {
    getOption()
    getReportBuyStock({ startDatePurchase: '', endDatePurchase: '', docNo: '', vendor: '' })
  }, []);

  const getOption = async () => {
    try {
      const res = await PUT(GET_REPORT_BUY_STOCK, { startDatePurchase: '', endDatePurchase: '', docNo: '', vendor: '' });

      const { success, data } = res;
      if (success) {

        buildArrayOfObjectWithNoDulplicateKey('docNo', data).map((val, i) => {
          optionDocNo.push({
            value: val.docNo,
            label: val.docNo
          })
        });

        buildArrayOfObjectWithNoDulplicateKey('vendor', data).map((val, i) => {
          optionVendor.push({
            value: val.vendor,
            label: val.vendor
          })
        });

        return optionDocNo, optionVendor
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  }

  const getReportBuyStock = async (value) => {
    let prepareStartDate = value?.startDatePurchase || '';
    if (prepareStartDate !== '') {
      prepareStartDate = convertStrToFormat(prepareStartDate, 'dateISO-set-time-0')
    }

    let prepareEndDate = value?.endDatePurchase || '';
    if (prepareEndDate !== '') {
      prepareEndDate = convertStrToFormat(prepareEndDate, 'dateISO-set-time-0')
    }

    value.startDatePurchase = prepareStartDate || '';
    value.endDatePurchase = prepareEndDate || '';
    value.docNo = value?.docNo || '';
    value.vendor = value?.vendor || '';

    const { startDatePurchase, endDatePurchase, docNo, vendor } = value
    setLoading(true);
    try {
      const res = await PUT(GET_REPORT_BUY_STOCK, { startDatePurchase: startDatePurchase, endDatePurchase: endDatePurchase, docNo: docNo, vendor: vendor });
      const { success, data } = res;
      if (success) {
        const resultData = data.map((val, i) => {
          return {
            key: i + 1,
            ...val,
          };
        });

        const dataEx = data.map((el, i) => {
          return {
            'วันที่สั่งซื้อ': el.purchaseDate,
            'เลขที่ใบสั่งซื้อ': el.docNo,
            'มูลค่าการสั่งซื้อ': el.sumCost,
            'รหัส Vendor': el.vendor || '',
            'ชื่อ Vendor': el.name,
          };
        });

        setDataSource(resultData);
        setDataExport(dataEx)
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'รายงานใบสั่งซื้อ',
              icon: <AuditOutlined />,
              spanSearch: 24,
              formSearch: ReportBuyStockSearchForm({ option: { optionDocNo, optionVendor } }),
              onSearch: (data) => getReportBuyStock(data),
            }}
            tableLayout={{
              columns: ReportBuyStockColumn(),
              dataSource: dataSource,
              extraContent: (
                <ContainerButton right>
                  <CSVLink data={dataExport} filename={"รายงานใบสั่งซื้อ"}><ButtonTheme useFor="DOWNLOAD" title={'EXPORT'} /></CSVLink>
                </ContainerButton>
              ),
            }}
          />
        </Spin>
      </Card>
    </Wrapper>
  );
};

export default BuyStockReport;
