import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../buttons';
import { EditTelephoneForm } from '../../../constants/global/actionform';
import { GET, POST, EDIT_TELEPHONE } from '../../../services/index';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { auth } from '../../../utils/firebase';
import { useAuthState } from '../../../utils/hook';
import moment from 'moment';

export const EditTelephoneModal = ({ title, visible, onClose, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
  } = useForm();

  const { user } = useAuthState(auth);
  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState({});
  // const valueChange = useRef({ status: null, isEmailRegis: null })

  useEffect(() => {

    if (JSON.stringify(data.record) != '{}') {
      const dataValue = Object.keys(data.record);

      dataValue.forEach((val) => val === "expireDate" ? setValue(val, data.record[val] ? moment(data.record[val]) : null) : setValue(val, data.record[val]));
      setRecord(data.record);
    }
  }, [data]);

  const onCloseMain = () => {
    clearErrors();
    setRecord({});
    onClose();
  };

  const onConfirm = (params) => {
    Modal.confirm({
      title: 'ยืนยันแก้ไขข้อมูล',
      icon: <ExclamationCircleOutlined />,
      content: 'ต้องการแก้ไขข้อมูล ใช่หรือไม่',
      onOk() {
        onSubmit(params);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const onChangeCustom = (value, ref) => {
    if (ref === 'status') {
      setValue('status', value ? 'ACTIVE' : 'INACTIVE');
    } else if (ref === 'isUseiCloud') {
      setValue('isUseiCloud', value);
    } else if (ref === 'isEmailRegis') {
      setValue('isEmailRegis', value);
    }
  };

  const onSubmit = async (params) => {

    const { expireDate, tel, _id, status, remark, isUseiCloud, isEmailRegis } = params;

    setLoading(true);
    let obj = {
      userLogin: user.email,
      _id,
      status: status,
      isUseiCloud: isUseiCloud === undefined ? false : isUseiCloud,
      isEmailRegis: isEmailRegis === undefined ? false : isEmailRegis,
      remark: remark === undefined ? '' : remark,
      expireDate: !!expireDate ? moment(expireDate).format('YYYY-MM-DD') : null,
    };

    try {
      let res = await POST(EDIT_TELEPHONE, obj);
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            onCloseMain();
            data.reApi({});
          },
        });
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }
  };



  return (
    <Modal title={title || 'modal'} open={visible} onCancel={onCloseMain} width={500} footer={null} destroyOnClose={true} maskClosable={false}>
      <form onSubmit={handleSubmit(onConfirm)}>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          formList={EditTelephoneForm({
            data: record,
            onChangeCustom,
          })}
          renderButton={
            <>
              <ContainerButton right>
                <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" loading={loading} />
                <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
              </ContainerButton>
            </>
          }
        />
      </form>
    </Modal>
  );
};
