export const TRACKING_TASK_SEARCH_FORM = ({options}) => {
  return [
    {
      key: '1',
      span: 6,
      name: 'start_date',
      label: 'เริ่มวันที่ค้นหา',
      placeholder: 'เลือกวันที่เริ่มต้น',
      type: 'DATE_PICKER',
    },
    {
      key: '2',
      span: 6,
      name: 'end_date',
      label: 'ถึงวันที่',
      placeholder: 'เลือกวันที่สิ้นสุด',
      type: 'DATE_PICKER',
    },
    {
      key: '3',
      span: 6,
      name: 'track_status',
      label: 'ประเภทการติดตาม',
      placeholder: 'เลือกประเภทการติดตาม',
      type: 'SELECT',
      options: options?.trackingType,
      allowClear: true,
      value: '',
    },
    {
      key: '4',
      span: 6,
      name: 'contract_no',
      label: 'เลขที่สัญญา',
      type: 'INPUT',
      placeholder: 'กรอกเลขที่สัญญา',
    },
    {
      key: '5',
      span: 6,
      name: 'terminate_contract_status',
      label: 'ประเภทหนังสือ',
      placeholder: 'เลือกประเภทหนังสือ',
      type: 'SELECT',
      options: options?.condition,
      allowClear: true,
      value: '',
    },
    {
      key: '6',
      span: 6,
      name: 'sort_by',
      label: 'จัดเรียงโดย',
      placeholder: 'เลือก',
      type: 'SELECT',
      options: options?.sort,
      allowClear: true,
      value: '',
    },
  ];
};

export const CHANGE_TRACKER_FORM = ({options, onChange, data}) => {
  return [
    {
      key: '0',
      span: 24,
      name: 'jobType',
      label: 'ประเภทงาน',
      placeholder: 'เลือกประเภทงาน',
      type: 'SELECT',
      options: options?.contractType,
      onChangeCustom: onChange,
      allowClear: true,
      value: '',
    },
    {
      key: '3',
      span: 24,
      name: 'jobTypeRemark',
      label: '',
      placeholder: 'กรอกโปรดระบุ',
      type: 'INPUT',
      hidden: data?.type !== 'OTHERS',
      rules: {
        required: data?.type === 'OTHERS',
      },
    },
    {
      key: '1',
      span: 24,
      name: 'tracker_id',
      label: 'ผู้ติดตาม',
      placeholder: 'เลือกผู้ติดตาม',
      type: 'SELECT',
      options: options?.trackers,
      allowClear: true,
      value: '',
    },
    {
      key: '2',
      span: 24,
      name: 'tracking_fee',
      label: 'ค่าติดตาม',
      placeholder: 'กรอกค่าติดตาม',
      type: 'INPUT_PRICE',
      rules: {
        required: true,
      },
    },
  ];
};
