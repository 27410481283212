import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../buttons';
import { ViewIcloudForm } from '../../../constants/global/actionform';
import { RandomIcloudAccountModal } from './RandomIcloudAccountModal';
import { GET, POST, EDIT_ACCOUNT, RANDOM_TEL } from '../../../services/index';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { auth } from '../../../utils/firebase';
import { useAuthState } from '../../../utils/hook';
import { checkPassword } from '../../../functions/fn';
// import {useCountRandom} from '../../../hook/useCountRandom';

export const ViewIcloudModal = ({ title, visible, onClose, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
    setError,
  } = useForm();

  const [record, setRecord] = useState({});
  const [visibleRandom, setVisibleRandom] = useState(false);
  // const {countRandom, addCountRandom} = useCountRandom();

  useEffect(() => {
    if (JSON.stringify(data.record) != '{}') {
      const dataValue = Object.keys(data.record);
      dataValue.forEach((val) => setValue(val, data.record[val]));
      setRecord(data.record);
    }
  }, [data]);

  const onCloseMain = () => {
    // addCountRandom(0);
    // localStorage.setItem('countRandomLocal', '0');
    clearErrors();
    onClose('view');
  };

  const onSetValue = (data) => {
    record.mail = data.mail;
    record.telIcloud = data.telIcloud;
    record.passIcloud = data.passIcloud;
    record.passMail = data.passMail;
    record.pin = data.pin;
  };

  return (
    <>
      {' '}
      <Modal title={title || 'modal'} open={visible} onCancel={onCloseMain} width={720} footer={null} destroyOnClose={true} maskClosable={false}>
        <form onSubmit={handleSubmit()}>
          <RenderForm
            control={control}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            formList={ViewIcloudForm()}
            renderButton={
              <>
                <ContainerButton right>
                  <ButtonTheme useFor="RANDOM" title="สุ่มไอคลาวน์ใหม่" onClick={() => setVisibleRandom(true)} />
                  <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
                </ContainerButton>
              </>
            }
          />
        </form>
      </Modal>
      <RandomIcloudAccountModal
        visible={visibleRandom}
        title={'สุ่มบัญชีไอคลาวน์ใหม่'}
        onClose={() => setVisibleRandom(false)}
        data={{ reApi: data.reApi, record, onSet: onSetValue }}
      />
    </>
  );
};
