import React, {useEffect} from 'react';
import {Modal} from 'antd';
import {ContainerButton} from '../../../../styles/styledComponentGlobal';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {useLocation} from 'react-router-dom';
import {useSendChatconeByProcess, useGetHistory} from '../../../../hook/chatcone';
import {ButtonTheme, DatatableLog, Spinners} from '../../../../components';
import {ChatconeFollowContractHistoryColumn} from '../columns/ChatconeFollowContractHistoryColumn';
import {APP_URL} from '../../../../env.js';

export const ChatconeFollowContractModal = ({title, content, visible, onClose, input}) => {
  const {pathname} = useLocation();
  const {registerId: ref_id, chatcone} = input;
  const [getChatconeHistoryFn, getChatconeHistoryData, getChatconeHistoryLoading] = useGetHistory();
  const [sendChatconeByProcessFn, sendChatconeByProcessLoading] = useSendChatconeByProcess();

  useEffect(() => {
    if (ref_id && visible) getChatconeHistoryFn({ref_id: ref_id, type: 'FOLLOW_CONTRACT'});
  }, [ref_id, visible]);

  const openConfirm = () => {
    Modal.confirm({
      title: 'ยืนยันการส่งแจ้งเตือน',
      icon: <ExclamationCircleOutlined />,
      content: 'ต้องการส่งแจ้งเตือนติดตามนัดลูกค้าทำสัญญา?',
      onOk() {
        onSendChatconeFn();
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  /* CHATCONE: 3 FOLLOW_CONTRACT */
  const onSendChatconeFn = async () => {
    const setInput = {
      chatcone: chatcone,
      processType: 'FOLLOW_CONTRACT',
      registerId: ref_id,
      refId: ref_id,
      routeFrom: `${APP_URL}${pathname}`,
    };

    const result = await sendChatconeByProcessFn({...setInput});

    if (result?.data?.success) onClose();
  };

  return (
    <>
      {getChatconeHistoryLoading || sendChatconeByProcessLoading ? <Spinners /> : null}
      <Modal
        title={
          <>
            <ExclamationCircleOutlined style={{color: '#faad14'}} /> {title || ''}
          </>
        }
        open={visible}
        width={840}
        onCancel={onClose}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}>
        <div style={{textAlign: 'center', fontSize: '20px', marginBottom: '10px'}}>{content || ''}</div>
        <ContainerButton center>
          <ButtonTheme useFor="SEND_NOTI" title="ส่งแจ้งเตือนไปยังแชท" onClick={() => openConfirm()} />
        </ContainerButton>

        <DatatableLog
          style={{marginTop: '20px'}}
          columns={ChatconeFollowContractHistoryColumn()}
          data={getChatconeHistoryData}
          pagination={{pageSize: 5}}
        />
      </Modal>
    </>
  );
};
