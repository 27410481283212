import PropTypes from 'prop-types';
import { Table, Td, Th } from './styled-component';

const CustomerRemarkLogTable = (props) => {
  const { logs } = props;
  logs.sort((a, b) => Number(new Date(b.date_create)) - Number(new Date(a.date_create)))
  return (
    <>
      <Table style={{ width: '100%', alignSelf: 'center', marginBlock: '3%' }}>
        <thead>
          <tr>
            <Th>วันที่บันทึก</Th>
            <Th>หมายเหตุ</Th>
            <Th>บันทึกโดย</Th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log) => (
            <tr key={log?._id}>
              <Td style={{ width: '15%' }}>{new Intl.DateTimeFormat('th-TH', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(log?.date_create))}</Td>
              <Td>{log?.remark}</Td>
              <Td style={{ width: '30%' }}>{log?.user_create}</Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

CustomerRemarkLogTable.propTypes = {
  logs: PropTypes.array,
};

CustomerRemarkLogTable.defaultProps = {
  logs: [],
};

export default CustomerRemarkLogTable;
