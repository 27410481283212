
import styled from 'styled-components'

export const SpinnersStyled = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.19);
  z-index: 9000 !important;
  cursor: wait;

  .ant-spin {
    color: #1890ff;
  }

  .spinner-title {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .spinner-title .ant-spin-text {
    margin-top: 30px;
    margin-left: 12px;
    color: black;
  }
`
