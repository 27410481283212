import { useRef, useEffect, } from 'react';
import { useFormikContext } from 'formik';
import { Input } from 'formik-antd';
import { FetchCouponData, sumPrice, sumPriceOther } from './api';
import numeral from 'numeral';

const calculate = (type, periods, totalPrice, down = 0, price_package, discount) => {
  let rate = ''
  if (type === 'REFINANCE') {
    const refinanceStore = JSON.parse(localStorage.getItem('refinancePeriod')) || []
    const refinancePeriodRate = refinanceStore[0]?.refinance_interests  
    rate = refinancePeriodRate.filter((el) => el.period === periods)
  }

  if (!type || !periods || !totalPrice || (!price_package && type === 'REFINPACKAGE')) {
    return {
      // discount: 0,
      interestAmount: 0,
      capitalAmount: 0,
      periodAmount: 0,
      totalAmount: 0,
      totalPrice: 0,
    };
  }
  // เงินดาวน์
  const downAmount = down;
  // ยอดจัดสินเชื่อ
  const capitalAmount = type === 'REFINANCE' || type === 'REFINPACKAGE' ? totalPrice : ((totalPrice - downAmount) - discount);
  // ดอกเบี้ย(ร้อยละ)
  // const interestRate = type === 'REFINANCE' ? 96 : type === 'REFINPACKAGE' ? 15 : 34;
  const interestRate = type === 'REFINANCE' ? rate[0].interest : type === 'REFINPACKAGE' ? 15 : 34;
  // ดอกเบี้ย/เดือน
  const interestPerMonth = (capitalAmount * interestRate) / 100 / 12;
  // ดอกเบี้ยทั้งหมด
  // const interestAmount = interestPerMonth * periods;
  const interestAmount = interestPerMonth * parseInt(periods);
  // เงินผ่อน/เดือน
  // let periodAmount = (interestAmount + capitalAmount) / periods;
  let periodAmount = (interestAmount + parseInt(capitalAmount)) / parseInt(periods);
  periodAmount = periodAmount % 10 !== 0 ? Math.ceil(periodAmount / 10) * 10 : periodAmount;
  // เงินต้น/เดือน
  // const capitalPerMonth = Math.ceil(capitalAmount / periods);
  // ยอดชำระรวม
  // const totalAmount = periodAmount * periods + downAmount;
  const totalAmount = type === 'REFINANCE' ? (periodAmount * periods + downAmount) - discount : periodAmount * periods + downAmount;
  totalPrice = totalPrice - discount

  //ส่วนลด
  const discountAmount = discount

  return {
    discountAmount,
    interestAmount,
    periodAmount,
    totalAmount,
    totalPrice,
    capitalAmount,
  };
};

// perf call api and call func
const AutoCal = (props) => {
  const ctx = useFormikContext();
  const previousValue = useRef(null);
  const { contract } = ctx.values;
  let checkPrice;


  useEffect(() => {
    doUpdate();
  }, [contract, contract?.serial_number]);

  const doUpdate = () => {
    try {

      const promo = contract.contract.promotion_code;
      let price_package = contract.contract.price_package;

      let coupon = FetchCouponData()
      const coupon_discount = coupon.coupon_discount || 0
      // let discount = contract.discount

      /* FGF: 2.ทำสัญญา fgf_commission is set in contract.$discount */
      const discount = parseFloat(contract.$discountValue || 0) + coupon_discount

      ctx.setFieldValue('contract.$discount', numeral(discount).format('0,0'), false);

      var newValue = `${contract.type}|
      ${contract.contract.periods}|
      ${contract.contract.periods_fin}|
      ${contract.contract.down_amount}|
      ${promo}|
      ${promo === 'none' ? sumPrice(contract.serial_number) : sumPriceOther(contract.serial_number, promo)}|
      ${contract.price}|${price_package}|${(contract?.contract?.couponCode !== null || contract?.contract?.couponCode !== undefined) ? contract.contract.couponCode : 'NO'}|${discount}`;

      if (newValue === previousValue.current) return;

      previousValue.current = newValue;

      const price =
        contract.type === 'INSTALLMENT'
          ? promo === 'none'
            ? sumPrice(contract.serial_number)
            : sumPriceOther(contract.serial_number, promo)
          : contract.type === 'REFINPACKAGE' ? contract.price + price_package
            : contract.price;

      const period = contract.type === 'INSTALLMENT' ? contract.contract.periods : contract.contract.periods_fin;

      const data = calculate(contract.type, period || 0, price || 0, contract.contract.down_amount || 0, price_package, discount || 0);
      const { discountAmount, interestAmount, capitalAmount, periodAmount, totalAmount, totalPrice } = data;

      checkPrice = price;
      if (checkPrice === 'สินค้านี้ไม่มีการระบุราคาที่2') {
        ctx.setFieldValue('contract.$discount', '-', false);
        ctx.setFieldValue('contract.$interestAmount', '-', false);
        ctx.setFieldValue('contract.$totalAmount', '-', false);
        ctx.setFieldValue('contract.$periodAmount', '-', false);
        ctx.setFieldValue('contract.$capitalAmount', '-', false);
        ctx.setFieldValue('contract.$totalPrice', '-', false);
      } else {
        ctx.setFieldValue('contract.discountt', numeral(discountAmount).format('0,0'), false);
        ctx.setFieldValue('contract.$interestAmount', numeral(interestAmount).format('0,0'), false);
        ctx.setFieldValue('contract.$totalAmount', numeral(totalAmount).format('0,0'), false);
        ctx.setFieldValue('contract.$periodAmount', numeral(periodAmount).format('0,0'), false);
        ctx.setFieldValue('contract.$capitalAmount', numeral(capitalAmount).format('0,0'), false);
        ctx.setFieldValue('contract.$totalPrice', numeral(totalPrice).format('0,0'), false);
      }
    } catch (e) {
      console.warn('e: ', e)
    }
  };

  return (
    <>
      <Input {...props} />
    </>
  )
};

export default AutoCal;
