import {BookOutlined} from '@ant-design/icons';
import {Card, Modal, Spin} from 'antd';
import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import {ButtonTheme} from '../../components/buttons';
import {PageLayout} from '../../components/pageLayout/PageLayout';
import {DownPaymentReportDailySearchForm} from '../../constants/global/SearchForm';
import {ReportDownPaymentDailyColumn} from '../../constants/global/columnTableForm';
import {convertStrToFormat} from '../../functions/fn';
import {GET, GET_REPORT_DOWN_PAYMENT_DAIRY} from '../../services/index';
import {ContainerButton} from '../../styles/styledComponentGlobal';
import Wrapper from '../../views/wrapper/Wrapper';
import {useGetExportDailyDownPaymentExcel} from './api';

const ReportDownPaymentDailyScreen = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const pageRef = useRef(1);
  const sizeRef = useRef(10);
  const [totalSize, setTotalSize] = useState(0);
  const [, setTotalPage] = useState(0);
  const [startDateState, setStartDateState] = useState(null);
  const [endDateState, setEndDateState] = useState(null);
  const [dataSearch, setDataSearch] = useState({page: pageRef.current, size: sizeRef.current, start_date: '', end_date: ''});
  const [visibleModal, setVisibleModal] = useState(false);

  const [resultReport, loadingReport, errorReport, setInputReport] = useGetExportDailyDownPaymentExcel();

  useEffect(() => {
    if (resultReport?.message === 'success' && resultReport?.url) {
      setTimeout(() => {
        window.open(resultReport?.url, '_blank', 'noopener,noreferrer');
        onClose();
      }, 1000);
    }
  }, [resultReport]);

  useEffect(() => {
    if (errorReport) {
      const message = errorReport?.response?.data?.errors;

      Modal.error({title: 'ดึงข้อมูลไม่สำเร็จ', content: message});
      onClose();
    }
  }, [errorReport]);

  const onExportExcelReport = async () => {
    const obj = {start_date: startDateState, end_date: endDateState};

    const params = createParams(obj);

    await setInputReport(params.length ? `?${params}` : params);
  };

  useEffect(() => {
    setDataSearch({page: pageRef.current, size: sizeRef.current, start_date: '', end_date: ''});
    getReportDownPaymenteDailyData({page: pageRef.current, size: sizeRef.current, start_date: '', end_date: ''});
  }, []);

  const createParams = (value) => {
    let params = [];
    let result = '';

    Object.keys(value).forEach((key) => {
      if (value[key]) {
        if (key === 'start_date') {
          params.push('startDate=' + encodeURIComponent(convertStrToFormat(value.start_date, 'dateAD')));
        } else if (key === 'end_date') {
          if (value[key]) {
            params.push('endDate=' + encodeURIComponent(convertStrToFormat(value.end_date, 'dateAD')));
          } else if (!value[key]) {
            params.push('endDate=' + encodeURIComponent(convertStrToFormat(new Date(), 'dateAD')));
          }
        } else {
          params.push(key + '=' + encodeURIComponent(value[key]));
        }
      }
    });

    if (params.length !== 0) {
      result += params.join('&');
    }
    return result;
  };

  const getReportDownPaymenteDailyData = async (value) => {
    setDataSource([]);

    value.page = value?.page || 1;
    value.size = value?.size || 10;

    pageRef.current = value.page;
    sizeRef.current = value.size;

    value.start_date = value.start_date || '';
    value.end_date = value.end_date || '';

    setLoading(true);
    let params = createParams(value);
    const {start_date, end_date} = value;

    setStartDateState(start_date);
    setEndDateState(end_date);

    try {
      const res = await GET(GET_REPORT_DOWN_PAYMENT_DAIRY(params));
      const {success, result, total_item, total_page} = res;
      if (success) {
        setTotalSize(total_item);
        setTotalPage(total_page);

        const _result = result.map((el) => {
          return {
            key: el?._id,
            date: el?.transaction_date ? moment(el?.transaction_date).format('DD/MM/YYYY') : '',
            ...el,
          };
        });
        setDataSource(_result);
      }
    } catch (err) {
      const {error} = err;
      Modal.error({
        title: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const onPageSizeChangeCustom = (pageData, sizeData) => {
    const sizeChange = sizeRef.current !== sizeData;
    if (sizeChange) {
      pageRef.current = 1;
    } else {
      pageRef.current = pageData;
    }
    sizeRef.current = sizeData;

    let start_date = startDateState;
    let end_date = endDateState;

    const params = {
      page: pageRef.current,
      size: sizeRef.current,
      start_date: start_date,
      end_date: end_date,
    };

    getReportDownPaymenteDailyData(params);
  };

  const onClearCustom = (setValue, formSearch) => {
    formSearch.map((val) => setValue(val.name, null));

    setDataSearch({
      page: pageRef.current,
      size: sizeRef.current,
      start_date: '',
      end_date: '',
    });

    setStartDateState('');
    setEndDateState('');

    pageRef.current = 1;
    sizeRef.current = 10;
  };

  const onClose = () => setVisibleModal(false);

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading || loadingReport}>
          <PageLayout
            searchLayout={{
              title: 'รายงานยอดออมดาวน์รายวัน',
              icon: <BookOutlined />,
              spanSearch: 24,
              formSearch: DownPaymentReportDailySearchForm(dataSearch),
              onSearch: (data) => getReportDownPaymenteDailyData(data),
              dataSearchDefault: dataSearch,
              onClearCustom,
            }}
            tableLayout={{
              columns: ReportDownPaymentDailyColumn(),
              dataSource: dataSource,
              extraContent: (
                <ContainerButton right>
                  <ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด Excel'} onClick={() => setVisibleModal(true)} />
                </ContainerButton>
              ),
              rowKey: (record) => record.key,
              pagination: {
                showSizeChanger: true,
                current: pageRef.current,
                pageSize: sizeRef.current,
                onChange: (p, s) => onPageSizeChangeCustom(p, s),
                pageSizeOptions: ['10', '20', '30', '40', '100', '200', '300'],
                total: totalSize || 0,
                showTotal: (totalSize, range) => `รายการทั้งหมด ${convertStrToFormat(totalSize, 'number-comma')} รายการ`,
                defaultPageSize: sizeRef.current,
                defaultCurrent: 1,
              },
            }}
          />
        </Spin>
      </Card>
      <Modal
        title={'ต้องการดาวน์โหลด Excel?'}
        open={visibleModal}
        width={550}
        onCancel={onClose}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}>
        <ContainerButton right>
          <ButtonTheme useFor="CUSTOM" title={'ตกลง'} onClick={async () => await onExportExcelReport()} />
          <ButtonTheme useFor="CANCEL" onClick={() => onClose()} />
        </ContainerButton>
      </Modal>
    </Wrapper>
  );
};

export default ReportDownPaymentDailyScreen;
