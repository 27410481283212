import styled from 'styled-components';

export const MenuLink = styled.li`
  &:hover a.nav-link {
    background-color: unset !important;
    color: #c2c7d0 !important;
  }

  & a.nav-link:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: #fff !important;
  }
`;

export const AsideResponsive = styled.aside`
  position: fixed!important;
  @media (max-width: 991px) {
    top: unset!important;
  }
`;

export const MenuTitleSection = styled.li`
  color: #c2c7d0;
  font-size: 18px;
`;
