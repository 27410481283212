import React, { useState, useEffect, useRef, useContext } from 'react';
import { Modal, Steps, Typography } from 'antd';
import { useForm } from 'react-hook-form';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../buttons';
import { Icon } from '../../../resources/icon';
import moment from 'moment/moment';
import RenderForm from '../../../constants/global/RenderForm';
import { UpDateCustomerIDcardInfo } from '../../../constants/global/actionform';
import { UpDateCustomerPersonalInfo } from '../../../constants/global/actionform';
import { UpDateCustomerContactInfo } from '../../../constants/global/actionform';
import { updateCustomer } from '../../../utils/auto-admin/api';
import { Button, ButtonWrap } from '../../../utils/auto-admin/styled-component';
import Loading from '../../loading/Loading';
import { getCustomerInfoWP, uploadIdCardPhoto } from '../../../utils/auto-admin/api';
import io from 'socket.io-client';
import EditCustomerContext from '../../../screens/customer2/EditCustomerProvider';
import { getAgeMonth } from '../../../functions/fn';
import { ExclamationCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';


const Step = Steps.Step
const { Text, Link } = Typography;

export const UpdateCustomerInfo = ({ title, visible, onClose, data }) => {

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
    control,
    clearErrors,
  } = useForm();

  const [record, setRecord] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingCardReader, setLoadingCardReader] = useState(false);
  const [errorSync, setErrorSync] = useState('');
  const [successSync, setSuccessSync] = useState('');
  const [step, setStep] = useState(0)
  const [IDcard, setIDcard] = useState('')
  const checkSameName = useRef(false)
  const checkSameTel = useRef(false)
  const [placeholder, setPlaceholder] = useState('ยังไม่ได้ติดต่อเครื่องอ่านบัตร');
  const [successSyncCard, setSuccessSyncCard] = useState({});
  const [pictureIDCard, setPictureIDCard] = useState();
  const [dataIDCard, setDataIDCard] = useState(null);
  const [jobCat, setJobCat] = useState(null);
  const [otherTel, setOtherTel] = useState([])
  const [visibleAlertName, setVisibleAlertName] = useState(false);
  const [visibleAlertIdCard, setVisibleAlerIdCard] = useState(false);
  const [disableBtnIdCard, setDisableBtnIdCard] = useState(false);
  const [visibleAlertPersonal, setVisibleAlertPersonal] = useState(false);
  const [ansData, setAnsData] = useState();

  const ctx = useContext(EditCustomerContext)

  const next = () => {
    setStep(step + 1);
  };

  const prev = () => {
    setStep(step - 1);
  };

  useEffect(() => {
    if (JSON.stringify(data && data.record) !== "{}" && data.record) {
      onSetValue()
      setIDcard(ctx?.dataCustomer?.id_card)
      getDataFromCard()
      setJobCat(ctx?.dataCustomer.job_cat)
      setOtherTel(ctx?.dataCustomer.other_tel || '')
    }
  }, [data.record]);

  const onSetValue = () => {
    if (ctx?.dataCustomer) {

      for (var key in ctx?.dataCustomer) {
        setValue(key, ctx?.dataCustomer[key]);
      }
      if (typeof ctx?.dataCustomer.picture_id_card === undefined || ctx?.dataCustomer.picture_id_card === null) {
        setValue('code', null)
      } else if (!!ctx?.dataCustomer.picture_id_card && ctx?.dataCustomer.picture_id_card.includes('googleapis')) {
        setValue('code', ctx?.dataCustomer.picture_id_card)
        setPictureIDCard(ctx?.dataCustomer.picture_id_card)
      } else if (ctx?.dataCustomer.picture_id_card === ctx?.dataCustomer.code) {
        setValue('code', ctx?.dataCustomer.code)
      } else {
        setValue('code', null)
      }

      const age = getAgeMonth(ctx?.dataCustomer?.dob)
      setValue('age', `${age.years} ปี ${age.months} เดือน`)

      setValue('dob', !!ctx?.dataCustomer?.dob ? moment(ctx?.dataCustomer?.dob) : null)
      setValue('gender', ctx?.dataCustomer?.gender)
      setValue('addressPresent', ctx?.dataCustomer?.address2?.full || '')
      setValue('addressIdCard', ctx?.dataCustomer?.address?.full || '')
      if (ctx?.dataCustomer.job_cat === 'employed') {
        setValue('get_student_loan', false)
      } else {
        if (ctx?.dataCustomer?.get_student_loan === '') {
          setValue('get_student_loan', false)
        } else {
          setValue('get_student_loan', ctx?.dataCustomer?.get_student_loan)
        }
      }

    }

  };

  const getDataFromCard = () => {
    var socket = io('http://localhost:9898');
    socket.on('connect', () => {
      setPlaceholder('นำบัตรใส่เครื่องอ่านเพื่อการกรอกอัตโนมัติ');
      setSuccessSyncCard({ status: 'ready', text: 'นำบัตรใส่เครื่องอ่านเพื่อการกรอกอัตโนมัติ' })
    });
    socket.on('smc-data', async (cardData) => {
      let photoFromCard = cardData?.data?.photo
      let resUploadIdCardPhoto = await uploadIdCardPhoto(photoFromCard);

      let idCard = getValues('id_card')
      let fullname = getValues('fullname')
      try {
        setLoadingCardReader(true)

        setPictureIDCard(resUploadIdCardPhoto)
        setDataIDCard(cardData?.data || null)
        setValue('picture_id_card', resUploadIdCardPhoto)

        // setValue('picture_id_card', resUploadIdCardPhoto)
        setValue('code', resUploadIdCardPhoto)
        setValue('prefix', cardData?.data?.name.prefix)
        // setValue('fullname', cardData?.data?.name.firstname + ' ' + cardData?.data?.name.lastname)
        setValue('gender', cardData?.data?.gender === '1' ? 'm' : 'f')
        setValue('dob', moment(cardData?.data?.dob))
        setValue('addressIdCard', cardData?.data?.address?.full)
        if (fullname && idCard) {
          if (idCard !== cardData?.data?.cid) {
            setVisibleAlerIdCard(true)
            setDisableBtnIdCard(true)
          } else if (fullname !== cardData?.data?.name.firstname + ' ' + cardData?.data?.name.lastname) {
            setVisibleAlertName(true)
          } else {
            setSuccessSyncCard({
              status: 'success',
              text: 'อ่านข้อมูลจากบัตรประชาชนสำเร็จ',
            })
          }
        }



      } catch (err) {
        setSuccessSyncCard({
          status: 'failed',
          text: 'ไม่อ่านข้อมูลจากบัตรประชาชน',
        })
      } finally {
        setLoadingCardReader(false)
      }
    });

    socket.on('connect_error', (error) => {
      console.error(error, 'cannot get data from card error')
      socket.disconnect();
      setPlaceholder('ติดต่อเครื่องอ่านบัตรไม่สำเร็จ');
      // setValue('picture_id_card', ctx?.dataCustomer?.code)
      // setPictureIDCard(ctx?.dataCustomer?.code)
      setSuccessSyncCard({ status: 'failed', text: 'ติดต่อเครื่องอ่านบัตรไม่สำเร็จ' })
    });

    return () => socket.disconnect();
  }

  const syncDataIdcard = () => {
    setValue('fullname', dataIDCard?.name.firstname + ' ' + dataIDCard?.name.lastname)
    setSuccessSyncCard({
      status: 'success',
      text: 'อ่านข้อมูลจากบัตรประชาชนสำเร็จ',
    })
    setVisibleAlertName(false)
  }


  const steps = [
    {
      title: 'ข้อมูลบัตรปชช',
    },
    {
      title: 'ข้อมูลลูกค้า',
    },
    {
      title: 'ผู้ติดต่อได้',
    },
  ]

  const onConfirm = (params,) => {
    Modal.confirm({
      title: "ยืนยัน",
      icon: <Icon.warningAlert />,
      content: "ยืนยันการแก้ข้อมูลลูกค้า",
      onOk() {
        onSubmit(params);
      },
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
    });
  };

  const onSubmit = async (params) => {
    const {
      code,
      picture_id_card,
      prefix,
      fullname,
      gender,
      dob,
      addressIdCard,
      zipcode,
      id_card,
      tel,
      lineId,
      facebook,
      addressPresent,
      addressCheck,
      job_cat,
      career_name,
      position,
      experience,
      salary,
      company_name,
      work_tel,
      work_address,
      degree,
      year,
      college,
      faculty,
      get_student_loan,
      branch,
      block_payment,
      grade_before,
      grade_after,
      person1FirstName,
      person1LastName,
      person1Tel,
      person1Relationship,
      person2FirstName,
      person2LastName,
      person2Tel,
      person2Relationship,
      person3FirstName,
      person3LastName,
      person3Tel,
      person3Relationship,
      chatcone
    } = params;

    switch (true) {
      case (person2FirstName === '' && person3FirstName === ''):
        checkSameName.current = false
        break
      case (person1FirstName !== person2FirstName && person2FirstName !== person3FirstName && person1FirstName !== person3FirstName):
        checkSameName.current = false
        break;
      case (person1FirstName === person2FirstName && person2FirstName === person3FirstName && person1FirstName === person3FirstName):
      case person1FirstName === person2FirstName:
      case person1FirstName === person3FirstName:
      case person2FirstName === person3FirstName:
        checkSameName.current = true
        break;
      default:
        break;
    }

    switch (true) {
      case (person2Tel === '' && person3Tel === ''):
        checkSameTel.current = false
        break
      case (person1Tel !== person2Tel && person2Tel !== person3Tel && person1Tel !== person3Tel):
        checkSameTel.current = false
        break;
      case (person1Tel === person2Tel && person2Tel === person3Tel && person1Tel === person3Tel):
      case person1Tel === person2Tel:
      case person1Tel === person3Tel:
      case person2Tel === person3Tel:
        checkSameTel.current = true
        break;
      default:
        break;
    }

    const formData = {
      customer2: {
        _id: ctx?.dataCustomer._id,
        address: {
          full: addressIdCard,
          zipcode: zipcode
        },
        address2: {
          full: addressCheck === true ? addressIdCard : addressPresent
        },
        block_payment: block_payment === undefined ? false : block_payment,
        code: code,
        contact_points: {
          person1: {
            firstname: person1FirstName,
            lastname: person1LastName,
            tel: person1Tel,
            relationship: person1Relationship,
          },
          person2: {
            firstname: person2FirstName,
            lastname: person2LastName,
            tel: person2Tel,
            relationship: person2Relationship,
          },
          person3: {
            firstname: person3FirstName,
            lastname: person3LastName,
            tel: person3Tel,
            relationship: person3Relationship,
          },
        },
        dob: moment(dob).toDate(),
        education: {
          branch: branch,
          college: college,
          degree: degree,
          faculty: faculty,
          year: year,
          get_student_loan: get_student_loan === undefined ? false : get_student_loan,
        },
        facebook: facebook,
        fullname: fullname,
        gender: gender,
        grade_before: grade_before,
        grade_after: grade_after,
        id_card: id_card,
        job_cat: job_cat,
        line: lineId,
        picture_id_card: picture_id_card || code,
        prefix: prefix,
        tel: tel,
        work: {
          career_name: career_name,
          company_name: company_name,
          experience: experience,
          position: position,
          salary: salary,
          work_tel: work_tel,
          work_address: { full: work_address }
        },
        chatcone: chatcone
      }
    }

    const originalData = {
      customer2: {
        // ...ctx?.dataCustomer,
        _id: ctx?.dataCustomer?._id,
      }
    }

    if (step === 2 && (checkSameName.current === false && checkSameTel.current === false)) {
      setLoading(true);
      try {
        let res = await updateCustomer(formData, originalData);
        const { message } = res;
        if (message === 'update customer success') {
          Modal.success({
            title: 'อัพเดตข้อมูลลูกค้าเสร็จสิ้น',
            afterClose: () => {
              data.reApi(data.valueSearch);
              onCloseMain();
            },
          });
        }
      } catch (err) {
        const { message, error } = err;
        Modal.error({
          title: message,
          content: error || '',
        });
      } finally {
        setLoading(false);
      }
    } else if (step === 2 && (checkSameName.current === true || checkSameTel.current === true)) {
      switch (true) {
        case (person1FirstName === person2FirstName && person2FirstName === person3FirstName && person1FirstName === person3FirstName):
          setError('person1FirstName', { type: "same", message: 'ชื่อจริงทั้งสามชื่อซ้ำกัน' })
          setError('person2FirstName', { type: "same", message: 'ชื่อจริงทั้งสามชื่อซ้ำกัน' })
          setError('person3FirstName', { type: "same", message: 'ชื่อจริงทั้งสามชื่อซ้ำกัน' })
          break;
        case person1FirstName === person2FirstName:
          setError('person1FirstName', { type: "same", message: 'ชื่อจริง1 ซ้ำกับ ชื่อจริง2' })
          setError('person2FirstName', { type: "same", message: 'ชื่อจริง1 ซ้ำกับ ชื่อจริง2' })
          break;
        case person1FirstName === person3FirstName:
          setError('person1FirstName', { type: "same", message: 'ชื่อจริง1 ซ้ำกับ ชื่อจริง3' })
          setError('person3FirstName', { type: "same", message: 'ชื่อจริง1 ซ้ำกับ ชื่อจริง3' })
          break;
        case person2FirstName === person3FirstName:
          setError('person2FirstName', { type: "same", message: 'ชื่อจริง2 ซ้ำกับ ชื่อจริง3' })
          setError('person3FirstName', { type: "same", message: 'ชื่อจริง2 ซ้ำกับ ชื่อจริง3' })
          break;
        default:
          break;
      }

      switch (true) {
        case (person1Tel === person2Tel && person2Tel === person3Tel && person1Tel === person3Tel):
          setError('person1Tel', { type: "same", message: 'เบอร์โทรศัพท์ทั้งสามซ้ำกัน' })
          setError('person2Tel', { type: "same", message: 'เบอร์โทรศัพท์ทั้งสามซ้ำกัน' })
          setError('person3Tel', { type: "same", message: 'เบอร์โทรศัพท์ทั้งสามซ้ำกัน' })
          break;
        case person1Tel === person2Tel:
          setError('person1Tel', { type: "same", message: 'เบอร์โทรศัพท์1 ซ้ำกับ เบอร์โทรศัพท์2' })
          setError('person2Tel', { type: "same", message: 'เบอร์โทรศัพท์1 ซ้ำกับ เบอร์โทรศัพท์2' })
          break;
        case person1Tel === person3Tel:
          setError('person1Tel', { type: "same", message: 'เบอร์โทรศัพท์1 ซ้ำกับ เบอร์โทรศัพท์3' })
          setError('person3Tel', { type: "same", message: 'เบอร์โทรศัพท์1 ซ้ำกับ เบอร์โทรศัพท์3' })
          break;
        case person2Tel === person3Tel:
          setError('person2Tel', { type: "same", message: 'เบอร์โทรศัพท์2 ซ้ำกับ เบอร์โทรศัพท์3' })
          setError('person3Tel', { type: "same", message: 'เบอร์โทรศัพท์2 ซ้ำกับ เบอร์โทรศัพท์3' })
          break;
        default:
          break;
      }
    } else {
      next()
    }
  };

  const onCloseMain = () => {
    setPlaceholder(null)
    setPictureIDCard(null)
    setJobCat(null)
    setSuccessSyncCard({})
    setSuccessSync(null)
    setErrorSync(null)
    clearErrors();
    setStep(0)
    setIDcard(null)
    setDisableBtnIdCard(false)
    onClose();
  };

  const searchByIdCard = () => {
    // const idCard = data.id_card
    if (IDcard !== '') search(IDcard);
  };

  const search = async (searchStr) => {
    try {
      setLoading(true);
      setSuccessSync('');
      setErrorSync('');
      let ans = await getCustomerInfoWP(searchStr)
      let fullname = getValues('fullname')
      setAnsData(ans)
      if (ans?.data?.fname && ans?.data?.lname) {
        if (fullname !== ans?.data?.fname + ' ' + ans?.data?.lname) {
          setVisibleAlertPersonal(true)
        } else {
          onSyncDataPersonal(ans)
        }
      }
    } catch (err) {
      if (err.message === 'customer not found') {
        setErrorSync('ไม่มีข้อมูลลูกค้าจากการลงทะเบียน');
      } else {
        setErrorSync(err.message);

      }
      setSuccessSync('');
    }
    setLoading(false);
  };

  const handleOk = () => {
    onSyncDataPersonal(ansData)
    setVisibleAlertPersonal(false)
  }

  const onSyncDataPersonal = (ans) => {
    setValue('tel', ans?.data.tel)
    setValue('lineId', ans?.data.line)
    setValue('facebook', ans?.data.facebook)
    setValue('addressPresent', ans?.data.address)

    setValue('job_cat', ans?.data.university ? 'student' : 'employed')
    setValue('career_name', ans?.data.occupation)
    setValue('position', ans?.data.position)
    setValue('experience', ans?.data.experience)
    setValue('salary', ans?.data.income)
    setValue('company_name', ans?.data.companyName)
    setValue('work_tel', ans?.data.companyPhone)
    setValue('work_address', ans?.data.companyLocation)
    setValue('degree', ans?.data.degree)
    setValue('year', ans?.data.grade)
    setValue('college', ans?.data.university)
    setValue('faculty', ans?.data.major)
    setValue('branch', ans?.data.bachelor)
    // setValue('grade_before', ctx?.dataCustomer?.grade_before)
    setValue('grade_before', ans?.data?.customer_grade)
    setValue('grade_after', ctx?.dataCustomer?.grade_after)
    setValue('person1FirstName', ans?.data?.fnameRelation)
    setValue('person2FirstName', ans?.data?.fnameRelation2)
    setValue('person3FirstName', ans?.data?.fnameRelation3)
    setValue('person1LastName', ans?.data?.lnameRelation)
    setValue('person2LastName', ans?.data?.lnameRelation2)
    setValue('person3LastName', ans?.data?.lnameRelation3)
    setValue('person1Tel', ans?.data?.telRelation)
    setValue('person2Tel', ans?.data?.telRelation2)
    setValue('person3Tel', ans?.data?.telRelation3)
    setValue('person1Relationship', ans?.data?.relationship)
    setValue('person2Relationship', ans?.data?.relationship2)
    setValue('person3Relationship', ans?.data?.relationship3)
    setValue('chatcone', ans?.data?.chatcone)

    setJobCat(ans?.data.university ? 'student' : 'employed')

    setSuccessSync('ซิงค์ข้อมูลลงทะเบียนสำเร็จ');
  }

  const setAddressPresent = (value) => {
    if (value === true) {
      setValue('addressPresent', ctx?.dataCustomer?.addressIdCard)
    } else if (value === false) {
      setValue('addressPresent', '')
    }
  }

  const onChangeSetValue = (value, key) => {

    setValue(key, value)

    if (key === 'block_payment') {
      if (value === true) {
        setValue('block_payment', true)
      } else {
        setValue('block_payment', false)
      }
    } else if (key === 'job_cat') {
      setJobCat(value)
    } else if (key === 'get_student_loan') {
      if (value === true) {
        setValue('get_student_loan', true)
      } else {
        setValue('get_student_loan', false)
      }
    } else if (key === 'dob') {
      const age = getAgeMonth(value)
      setValue('age', `${age.years} ปี ${age.months} เดือน`)
    }
  }

  return (
    <>
      <Modal title={title} open={visible} width={1200} onCancel={onCloseMain} footer={null} destroyOnClose={true} maskClosable={false}>
        <div style={{ padding: '3%' }}>
          <Steps
            key='updateStep'
            direction='horizontal'
            size="small"
            current={step}
            style={{ marginBottom: '30px' }}
          >
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <form onSubmit={handleSubmit(step === 2 ? onConfirm : onSubmit)}>
            {step === 0 &&
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                setError={setError}
                formList={UpDateCustomerIDcardInfo({ data: pictureIDCard, step: step, placeholder: placeholder, statusSync: successSyncCard, onChangeSetValue })}
              />
            }
            {step === 1 &&
              <>
                <ButtonWrap style={{ marginBlock: '2%' }}>
                  <Button className="search" type="button" htmlType="submit" onClick={() => searchByIdCard()}>
                    ซิงค์บัตรปชช
                  </Button>
                </ButtonWrap>
                {loading && <Loading inline />}
                {errorSync && <li style={{ color: '#ff4d4f' }}>{errorSync}</li>}
                {successSync && <li style={{ color: '#73d13d' }}>{successSync}</li>}
                {(errorSync || successSync) && <br />}
                <RenderForm
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  errors={errors}
                  setError={setError}
                  formList={UpDateCustomerPersonalInfo({ data: jobCat, step: step, setAddressPresent, onChangeSetValue, otherTel: otherTel })}
                />
              </>
            }
            {step === 2 &&
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                setError={setError}
                formList={UpDateCustomerContactInfo({ step: step, onChangeSetValue })}
              />
            }
            <ContainerButton center>
              {(step > 0 && step < 3) &&
                <ButtonTheme useFor='CUSTOM' title="ย้อนกลับ" style={{ color: '#4B4B4B', borderColor: '#A1A1A1' }} bgcolor="white" onClick={() => prev()} />
              }
              {step < 2 &&
                <ButtonTheme useFor='CUSTOM' title="ถัดไป" htmlType="submit" disabled={disableBtnIdCard || false} />
                // <ButtonTheme useFor='CUSTOM' title="ถัดไป" onClick={() => test(record)} />
              }
              {step === 2 &&
                <ButtonTheme useFor='CUSTOM' title="ยืนยัน" htmlType="submit" />
                // <ButtonTheme useFor='CUSTOM' title="ยืนยัน" onClick={() => test(record)} />
              }
            </ContainerButton>
          </form>
        </div>
      </Modal >
      <Modal
        open={visibleAlertName}
        onCancel={() => { setVisibleAlertName(false) }}
        width={450}
        closable={false}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px 0' }}>
          <ExclamationCircleOutlined style={{ color: '#ff4d4f', fontSize: 70 }} />
          <div style={{ paddingTop: 8 }}>
            ชื่อที่อ่านได้จากบัตร ไม่ตรงกับข้อมูลเดิมในระบบ
          </div>
        </div>
        <div style={{ paddingLeft: 16 }}>
          <div >
            ชื่อเดิม {ctx?.dataCustomer?.general?.fullname || ''}
          </div>
          <div >
            ชื่อที่อ่านได้จากบัตร {dataIDCard?.name.firstname + ' ' + dataIDCard?.name.lastname || ''}
          </div>
          <ContainerButton right>
            <ButtonTheme useFor="CUSTOM" title={'ยืนยัน'} onClick={async () => syncDataIdcard()} />
            <ButtonTheme useFor="CANCEL" onClick={() => setVisibleAlertName(false)} />
          </ContainerButton>
        </div>
      </Modal>
      <Modal
        open={visibleAlertIdCard}ƒ
        onCancel={() => { 
          setVisibleAlerIdCard(false)
         }}
        width={450}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px 0' }}>
          <ExclamationCircleOutlined style={{ color: '#ff4d4f', fontSize: 70 }} />
          <div style={{ paddingTop: 8 }}>
            ไม่สามารถทำงานต่อได้เนื่องจากเลขบัตรประชาชนที่อ่านจากบัตร ไม่ตรงกับข้อมูลเดิมในระบบ
          </div>
        </div>
      </Modal>
      <Modal
        open={visibleAlertPersonal}
        onCancel={() => { setVisibleAlertPersonal(false) }}
        width={450}
        closable={false}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px 0' }}>
          <ExclamationCircleOutlined style={{ color: '#ff4d4f', fontSize: 70 }} />
          <div style={{ paddingTop: 8 }}>
            ข้อมูลที่ลงทะเบียนไม่ตรงกับชื่อในบัตรประชาชน
          </div>
          <div>
            ยืนยันการซิงก์ข้อมูล?
          </div>
        </div>
        <div style={{ paddingLeft: 16 }}>
          <div>
            เลขบัตรประชาชน {dataIDCard?.cid || ''}
          </div>
          <div >
            ชื่อที่อ่านได้จากบัตร {dataIDCard?.name.firstname + ' ' + dataIDCard?.name.lastname || ''}
          </div>
          <div >
            ชื่อที่ลงทะเบียน {ansData?.data?.fname + ' ' + ansData?.data?.lname || ''}
          </div>
          <div >
            เบอร์โทร {ansData?.data?.tel}
          </div>
          <ContainerButton right>
            <ButtonTheme useFor="CUSTOM" title={'ยืนยัน'} onClick={async () => handleOk()} />
            <ButtonTheme useFor="CANCEL" onClick={() => setVisibleAlertPersonal(false)} />
          </ContainerButton>
        </div>
      </Modal>
    </>

  );
};
