import styled from 'styled-components';

export const Div = styled.div`
  display: flex;
  min-width: 1000px;
  // height: calc(100vh - 105px);
`;

export const Background = styled.div`
  background-color: ${(props) => (props.color ? props.color : 'white')};
  padding: 25px;
  border-radius: 0;
  width: 100%;
  height: 100%
`;

export const Table = styled.table`
  width: 100%;
  & tr {
    height: 3rem;
  }
`;

export const Th = styled.th`
  text-align: ${(props) => (props.left ? 'left' : 'center')};
  border-right: ${(props) => (props.border_right ? '1px solid black' : '0')};
  border-bottom: ${(props) => (props.border_bottom ? '1px solid black' : '0')};
  background-color: ${(props) => (props.highlight ? '#ddd' : 'none')};
`
export const Td = styled.td`
  text-align: ${(props) => (props.left ? 'left' : 'center')};
  border-right: ${(props) => (props.border_right ? '1px solid black' : '0')};
  background-color: ${(props) => (props.highlight ? '#ddd' : 'none')};
`

export const WrapTable = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

export const HeaderInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const FormUpdateWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Button = styled.button`
  padding: 5px 15px 5px 15px;
  border: 0;
  border-radius: 3px;
  margin-right:10px;
  background-color: transparent;
  &:focus {
    outline: 0;
  }

  &.submit {
    background-color: #3443eb;
    color: #fff;
    height: 80%;
  }

  &.submit:hover {
    background-color: #303ed9;
  }

  &.submit:disabled {
    background-color: #ddd;
    border: 0;
    color: #999;
  }
`

export const TextBold = styled.p`
  & span.bold {
    font-weight: bold;
    margin-bottom: 0;
  }
`;

export const Remark = styled.p`
  font-size: 10px;
  color: #aaa;
`

export const InputFile = styled.input`
  &[type="file"]::-webkit-file-upload-button {
    background-color: #ddd;
    border: 0;
    padding: 3px 10px;
    cursor: pointer;
  }
`

export const InputFileNoText = styled.div`
    height: 32px;
    width: 100px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    background-color: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;

    & input[type="file"] {
      cursor: pointer;
      height: 100%;
      position:absolute;
      top: 0;
      right: 0;
      z-index: 99;
      font-size:50px;
      opacity: 0;
      -moz-opacity: 0;
    }
`

export const TextError = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: #E01E01;
`

export const FileItem = styled.div`
  display: flex;
  margin-bottom: 10px;
  & p {
    margin: 0;
  }
  & button {
    padding: 0;
    margin-left: 10px;
    color: #E01E01;
  }
`