/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Grid, TextField, Snackbar } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import Wrapper from '../../views/wrapper/Wrapper';
import { Div, Background, Button, Alert } from './styled-component';
import { useImport } from '../../hook/useImport';
import Loading from '../../components/loading/Loading';
import Modal from '../../components/modal';

const Import = () => {
    const { loading, error, dispatchImport, dispatchImportProduct } = useImport();
    const [tel, setTel] = useState(null);
    const [helperText, setHelperText] = useState(null);
    const [openSnack, setOpenSnack] = useState(false);
    const regexThaiTelNumberNoHyphen = /0([2]|[6]|[6][0-9]|[8-9][0-9])?[0-9]{7}$/;

    const onTelChange = (e) => {
        const telInput = e.target.value;
        if (telInput !== null && !regexThaiTelNumberNoHyphen.test(telInput)) {
            setTel(null);
            setHelperText("กรอกเบอร์โทร 8-10 หลัก");
        } else {
            setTel(telInput);
            setHelperText(null);
        }
    };

    const updateUser = () => {
        if (tel !== null) {
            dispatchImport({ tel });
        }
        setOpenSnack(true);
    };

    const updateAllUsers = () => {
        dispatchImport({});
    };

    // const updateProduct = () => {
    //     dispatchImportProduct();
    // };

    const closeError = () => {
        setOpenSnack(false);
    };

    return (
        <Wrapper>
            <h1>Import Tradesys Data</h1>
            <Div>
                <Grid container item>
                    <Background>
                        <Modal
                            open={loading}
                            noButton
                            heightAuto
                            style={{ backgroundColor: 'unset', boxShadow: 'unset' }}>
                            <Loading />
                        </Modal>
                        {/* <Div style={{ alignItems: 'baseline' }}>
                            <p style={{ marginRight: '1rem' }}>อัพเดตสินค้า:</p>
                            <Button className="update-product-btn" type="button" onClick={() => updateProduct()}>Update Product</Button>
                        </Div> */}
                        <Div style={{ marginTop: '1rem', alignItems: 'baseline' }}>
                            <p style={{ marginRight: '1rem' }}>อัพเดตสัญญาลูกค้า:</p>
                            <TextField placeholder="เบอร์โทรลูกค้า" onChange={onTelChange} error={helperText !== null} helperText={helperText} />
                            <Button className="update-btn" type="button" onClick={() => updateUser()} disabled={helperText !== null || tel === null}>Update</Button>
                        </Div>
                        {/* <Div style={{ marginTop: '1rem', alignItems: 'baseline' }}>
                            <p style={{ marginRight: '1rem' }}>อัพเดตลูกค้าทั้งหมด:</p>
                            <Button className="update-all-btn" type="button" onClick={() => updateAllUsers()}>Update All</Button>
                        </Div> */}
                        <Snackbar open={error !== null && openSnack} onClick={() => closeError()}>
                            <Alert>
                                <p>{error}</p>
                                <HighlightOff />
                            </Alert>
                        </Snackbar>
                    </Background>
                </Grid>
            </Div>
        </Wrapper>
    );
};

export default Import;