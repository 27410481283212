import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../buttons';
import { EditRemark } from '../../../constants/global/actionform';
import { GET, POST, PUT, EDIT_REMARK } from '../../../services/index';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { auth } from '../../../utils/firebase';
import { useAuthState } from '../../../utils/hook';
import moment from 'moment';
import { CallMerge } from '@material-ui/icons';
import { Icon } from '../../../resources/icon';

export const ViewIcloudPasswordModal = ({ title, visible, onClose, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
  } = useForm();

  const [record, setRecord] = useState({});

  useEffect(() => {
    if (JSON.stringify(data && data.record) !== "{}" && data.record) {
      const dataValue = Object.keys(data.record);
      dataValue.forEach((val) => setValue(val, data.record[val]));
      setRecord(data.record)
    }

  }, [data]);

  const onCloseMain = () => {
    clearErrors();
    onClose();
  };
  return (
    <Modal title={title || 'modal'} open={visible} width={480} onCancel={onCloseMain} footer={null} destroyOnClose={true} maskClosable={false}>
      อีเมล์ไอคลาวน์: <b>{data.record.icloudMail}</b><br />
      รหัสผ่านไอคลาวน์: <b>{data.record.deCryptPassword}</b><br />
      รหัสพิน: <b>{data.record.deCryptPin}</b>
      <ContainerButton right>
        <ButtonTheme useFor="CLOSE" onClick={() => onCloseMain()} />
      </ContainerButton>
    </Modal>
  );
};
