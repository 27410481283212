import { TrackingTaskProvider} from "./TrackingTaskProvider";
import TrackingTaskInfo from "./TrackingTaskScreen";


const TrackingTaskDashBoard = () => {
  return (
    <TrackingTaskProvider>
        <TrackingTaskInfo />
    </TrackingTaskProvider>
  )
}

export default TrackingTaskDashBoard;
