import * as TYPES from '../types/transaction';

const initialState = {
    loading: false,
    error: null,
    size: 10,
    page: 1,
    transactions: [],
    totalItem: 0,
    totalPage: 0,
};

const initialStateInfo = {
    loading: false,
    error: null,
    transactionInfo: null
}

export const transactionReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPES.LIST_TRANSACTION_REQ:
            return { ...state, loading: true };
        case TYPES.EXPORT_TRANSACTION_REQ:
            return { ...state, loading: true };
        case TYPES.LIST_TRANSACTION_SUCCESS:
            return { ...state, loading: false, transactions: payload.data.data.transactions, totalItem: payload.data.data.total_item, totalPage: payload.data.data.total_page };
        case TYPES.LIST_TRANSACTION_FAIL || TYPES.EXPORT_TRANSACTION_FAIL:
            return { ...state, loading: false, error: payload };
        case TYPES.EXPORT_TRANSACTION_SUCCESS:
            return { ...state, loading: false };
        default:
            return state;
    }
};

export const transactionInfoReducer = (state = initialStateInfo, { type, payload }) => {
    switch (type) {
        case TYPES.GET_TRANSACTION_REQ || TYPES.UPDATE_TRANSACTION_REQ:
            return { ...state, loading: true };
        case TYPES.GET_TRANSACTION_SUCCESS:
            return { ...state, loading: false, transactionInfo: payload.data.data, error: null };
        case TYPES.GET_TRANSACTION_FAIL:
            return { ...state, loading: false, error: payload };
        case TYPES.UPDATE_TRANSACTION_SUCCESS:
            return { ...state, loading: false, transactionInfo: { ...state.transactionInfo, status: payload.data.data.status, remark: payload.data.data.remark, discount: payload.data.data.discount }, error: null };
        case TYPES.UPDATE_TRANSACTION_FAIL:
            return { ...state, loading: false, error: payload };
        case TYPES.UPDATE_TRANSACTION_INFO_REQ:
            return { ...state, loading: true };
        case TYPES.UPDATE_TRANSACTION_INFO_SUCCESS:
            return { ...state, loading: false, transactionInfo: { ...state.transactionInfo, remark: payload.data.data.remark, discount: payload.data.data.discount }, error: null };
        case TYPES.UPDATE_TRANSACTION_INFO_FAIL:
            return { ...state, loading: false, error: payload };
        default:
            return state;
    }
};