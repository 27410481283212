// /* eslint no-use-before-define: 0 */
import React, { useState, useEffect } from 'react';
import Wrapper from '../../views/wrapper/Wrapper';
import { Modal, Card, Spin } from 'antd';
import { UnlockOutlined } from '@ant-design/icons';
import { PageLayout } from '../../components/pageLayout/PageLayout';
import { UnlockStatusCRM } from '../../components/modal/UnlockiCloud/UnlockStatusCRM';
import { EditRemarkUnlockIcloudModal } from '../../components/modal/UnlockiCloud/EditRemarkUnlockIcloudModal';
import { UnlockIcloudCRMColumn } from '../../constants/global/columnTableForm';
import { UnlockIcloudCRMSearchForm } from '../../constants/global/SearchForm';
import { auth } from '../../utils/firebase';
import { useCurrentState } from '../../utils/hook';
import { GET, GET_UNLOCK_ICLOUD_CRM } from '../../services';

const UnlockIcloudCRM = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [visibleStatusModal, setVisibleStatusModal] = useState(false);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [defaultValue, setDefaultValue] = useState({});
  const [contractNo, setContractNo] = useState('');
  const { user } = useCurrentState(auth);

  useEffect(() => {
    getDataUnlockiCloudCRM({ unlock_status: '', user_name: '' });
  }, []);

  const openModal = (record, name) => {
    if (record) {
      setDefaultValue(record);
      setContractNo(record.contract_no);
      if (name === 'CHANGE_STATUS') {
        setVisibleStatusModal(true);
      } else if (name === 'REMARK_UNLOCK') {
        setVisibleEditModal(true)
      }
    }
  };

  const getDataUnlockiCloudCRM = async (data) => {
    data.user_name = data.user_name || '';
    data.unlock_status = data.unlock_status || '';
    //remark

    const { unlock_status, user_name } = data;
    setLoading(true);
    try {
      const res = await GET(GET_UNLOCK_ICLOUD_CRM(unlock_status, user_name));
      const { success, data } = res;
      if (success) {
        let sortByClosedContractDate = data.sort((a, b) => new Date(b.closed_contract_date) - new Date(a.closed_contract_date))
        setDataSource(sortByClosedContractDate);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const optionsCRM = [
    { value: 'NO_STATUS', label: 'ไม่ระบุ' },
    { value: 'CONTACT_ADMIN', label: 'ติดต่อเจ้าหน้าที่' },
    { value: 'NEW_CONTRACT', label: 'สร้างสัญญาใหม่' },
    { value: 'CONTRACT_COMPLETED', label: 'ทำสัญญาใหม่เสร็จสิ้น' },
  ];

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'ปลดล็อกรหัส iCloud ฝ่าย CRM',
              icon: <UnlockOutlined />,
              spanSearch: 24,
              formSearch: UnlockIcloudCRMSearchForm({ options: { optionsCRM } }),
              onSearch: (data) => getDataUnlockiCloudCRM(data),
            }}
            tableLayout={{
              columns: UnlockIcloudCRMColumn({ options: { optionsCRM }, openModal, user }),
              dataSource: dataSource,
            }}
          />
        </Spin>
      </Card>
      <UnlockStatusCRM
        visible={visibleStatusModal}
        title={'เปลี่ยนสถานะของลูกค้า'}
        onClose={() => setVisibleStatusModal(false)}
        data={{ reApi: getDataUnlockiCloudCRM, record: defaultValue, user }}
      />
      <EditRemarkUnlockIcloudModal
        visible={visibleEditModal}
        title={'แก้ไขหมายเหตุ'}
        onClose={() => setVisibleEditModal(false)}
        data={{ reApi: getDataUnlockiCloudCRM, record: defaultValue, contractNo }}
      />
    </Wrapper>
  );
};

export default UnlockIcloudCRM;
