import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../../components/buttons';
import { OTHER_PAYMENT_FORM } from '../form';
import { POST, CREATE_OTHER_FEE } from '../../../services';
import { Icon } from '../../../resources/icon';
import { optionOtherFee } from '../constant';
import moment from 'moment';
export const OtherFeeModal = ({ title, visible, onClose, data, reApi }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [typeOtherPayment, setTypeOtherPayment] = useState('');

  useEffect(() => { }, []);

  const openConfirm = (params) => {
    Modal.confirm({
      title: 'ยืนยัน',
      icon: <Icon.warningAlert />,
      content: 'ยืนยันบันทึกค่าใช้จ่ายอื่นๆ?',
      onOk() {
        onSubmit(params);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const onSubmit = async (params) => {
    setLoading(true);
    try {
      const payload = {
        contract_no: data?.contract_no,
        remark: params?.remark || '',
        type: params?.type,
        amount: params?.amount,
      };
      let res = await POST(CREATE_OTHER_FEE, payload);
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            onClose();
            reApi()
          },
        });
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const onChange = (value, name) => {
    setTypeOtherPayment(value);
  };
  return (
    <Modal title={`${title}` || 'modal'} open={visible} width={480} onCancel={onClose} footer={null} destroyOnClose={true} maskClosable={false}>
      <form onSubmit={handleSubmit(openConfirm)}>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          formList={OTHER_PAYMENT_FORM({ options: { otherFee: optionOtherFee }, onChange, data: { type: typeOtherPayment } })}
          renderButton={
            <>
              <ContainerButton right>
                <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" loading={loading} />
                <ButtonTheme useFor="CANCEL" onClick={() => onClose()} />
              </ContainerButton>
            </>
          }
        />
      </form>
    </Modal>
  );
};
