export const ADD_SPECIAL_TASK_FORM = ({ options }) => {
  return [
    {
      key: '1',
      span: 24,
      name: 'trackerID',
      label: 'ผู้ติดตาม',
      type: 'SELECT',
      placeholder: 'เลือกผู้ติดตาม',
      options: options.trackers,
      rules: {
        required: true,
      },
    },

    {
      key: '2',
      span: 24,
      name: 'trackingFee',
      label: 'ค่าติดตาม :',
      type: 'INPUT_PRICE',
      rules: {
        required: true,
      },
    },
  ];
};

