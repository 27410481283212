import * as TYPES from '../types/import';
import * as API from '../api/import';

export const importData = (body) => async (dispatch) => {
    dispatch({ type: TYPES.IMPORT_DATA_REQ });
    try {
        const response = await API.importData(body);
        if (response.status === 200) {
            dispatch({ type: TYPES.IMPORT_DATA_SUCCESS, payload: response });
        } else {
            dispatch({ type: TYPES.IMPORT_DATA_FAIL, payload: response.response.data.error });
        }
    } catch (error) {
        dispatch({ type: TYPES.IMPORT_DATA_FAIL, payload: error });
    }
};

export const importProduct = () => async (dispatch) => {
    dispatch({ type: TYPES.IMPORT_DATA_REQ });
    try {
        const response = await API.importProduct();
        if (response.status === 200) {
            dispatch({ type: TYPES.IMPORT_DATA_SUCCESS, payload: response });
        } else {
            dispatch({ type: TYPES.IMPORT_DATA_FAIL, payload: response.response.data.error });
        }
    } catch (error) {
        dispatch({ type: TYPES.IMPORT_DATA_FAIL, payload: error });
    }
};