/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { TableRow, TableCell, InputLabel, MenuItem, FormControl, Select } from '@material-ui/core';
import { Visibility, Pageview, Edit, CheckCircle, Cancel } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Button } from './styled-component';
import ChipStatus from '../../components/chip/ChipStatus';
import { useTransaction } from '../../hook/useTransaction';
import { toastContext } from '../../utils/toastProvider';
import { formatDate } from '../../utils/date';
import { updateTransactionStatus } from '../../redux/api/transaction';
import { createParams } from './utils';
import Modal from '../../components/modal';
import { Modal as ModalAntd, Button as ButtonAntd } from 'antd';
import { color } from '../../resources/color';
import { Icon } from '../../resources/icon';
import { ContainerButton } from '../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../components/buttons';

const useStyles = makeStyles({
  select: {
    '& > div:focus': {
      background: 'none',
    },
  },
  icon_btn: {
    cursor: 'pointer',
    '&.save': {
      color: '#01D827',
    },
    '&.save:hover': {
      color: '#01B927',
    },
    '&.cancel': {
      color: '#E01E01',
    },
    '&.edit': {
      color: '#999999',
    },
  },
  pagination_btn: {
    '& button:focus': {
      outline: 0,
    },
  },
  input_label: {
    fontSize: '8px',
  },
  icon_column: {
    padding: 0,
  },
});

const TransactionItem = (props) => {
  const { dispatchGet } = useTransaction();
  const {
    id,
    idCard,
    name,
    type,
    transactionDate,
    createdDate,
    totalAmount,
    slipUrl,
    canUpdate,
    onClickSlip,
    status,
    statusLabel,
    remark,
    onEditTable,
    page,
    size,
    updatedBy,
    statusSlip,
    // openModalError,
    filter
  } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [updateStatus, setUpdateStatus] = useState('DEFAULT');
  const [openModal, setOpenModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const classes = useStyles();
  const [config] = useContext(toastContext);

  const handleEdit = (edit) => {
    setIsEdit(edit);
    onEditTable(edit);
    if (!edit) {
      setUpdateStatus('DEFAULT');
    }
  };

  const showSlip = (url) => {
    onClickSlip(url);
  };

  const changeStatus = (e) => {
    setUpdateStatus(e.target.value);
  };

  // const updateTransaction = async (ignoreBlock) => {
  //   if (status === updateStatus || updateStatus === 'DEFAULT') {
  //     toast.success('update success', { ...config, onClose: setIsEdit(false) });
  //     onEditTable(false);
  //   } else {
  //     const data = {
  //       status: updateStatus,
  //       updated_by: updatedBy,
  //       ignore_block: ignoreBlock
  //     };
  //     const params = createParams(filter?.status, filter?.userIdCard, filter?.name, filter?.tel, page + 1, size, filter?.startDate, filter?.createdDate);
  //     const response = await updateTransactionStatus(id, data);
  //     if (response.status === 200) {
  //       toast.success('update success', { ...config, onClose: setIsEdit(false) });
  //       dispatchGet(params);
  //       // setIsEdit(false);
  //       onEditTable(false);
  //       setOpenModal(false);
  //     } else {
  //       if (response.response.data.error === "user is not blocked payment") {
  //         setOpenModal(true);
  //       } else {
  //         toast.error(response.response.data.error, { ...config, onClose: setIsEdit(false) });
  //         // setIsEdit(false);
  //         onEditTable(false);
  //         setOpenModal(false);
  //       }
  //     }
  //   }
  // };

  const onClose = () => {
    setOpenModal(false)
  }

  const onCloseMain = () => {
    onClose();
  };

  const openConfirm = (params) => {
    ModalAntd.confirm({
      title: "ยืนยัน",
      icon: <Icon.warningAlert />,
      content: "ยืนยันรายการชำระ?",
      onOk() {
        onSubmit(params);
        onCloseMain()
      },
      okText: "ยืนยัน",
      // onCancel() {
      //   setIsEdit(false)
      // },
      cancelText: "ยกเลิก",
    });
  };

  const onSubmit = async (ignoreBlock) => {

    if (ignoreBlock) {
      setIsDisabled(true)
    }

    setIsEdit(false);

    if (status === updateStatus || updateStatus === 'DEFAULT') {
      toast.success('update success', config);
      onEditTable(false);
    } else {
      const data = {
        status: updateStatus,
        updated_by: updatedBy,
        ignore_block: ignoreBlock
      };

      const params = createParams(filter?.status, filter?.userIdCard, filter?.name, filter?.tel, page + 1, size, filter?.startDate, filter?.createdDate);
      const response = await updateTransactionStatus(id, data);

      if (response.status === 200) {
        toast.success('update success', config);
        dispatchGet(params);
        // setIsEdit(false);
        onEditTable(false);
        setOpenModal(false);
      } else {
        if (response.response.data.error === "user is not blocked payment") {
          setOpenModal(true);
        } else {
          toast.error(response.response.data.error, config);
          // setIsEdit(false);
          onEditTable(false);
          setOpenModal(false);
        }
      }
    }
  };

  return (
    <>
      <TableRow key={id}>
        {openModal && (
          <TableCell style={{ display: "none" }}>
            {/* <Modal
              open={openModal}
              setOpen={setOpenModal}
              heightAuto
              labelSubmit='ดำเนินการต่อ'
              labelClose='ยกเลิก'
              // handleSubmit={() => updateTransaction(true)}
              handleSubmit={() => openConfirm(true)}
              actions={[
                (<Button key="cancel-to-customer" varint="contained" color="secondary" className={classes.button}>
                  <Link to={{
                    pathname: "/customer",
                    search: `?id_card=${idCard}`
                  }}>ไปหน้าลูกค้า</Link>
                </Button>)
              ]}
              style={{ padding: '1rem' }}>
              <p>ลูกค้ายังไม่ได้ถูกล็อคการชำระ ต้องการดำเนินการต่อหรือไม่?</p>
            </Modal> */}
            <ModalAntd title={'ลูกค้ายังไม่ได้ถูกล็อคการชำระ ต้องการดำเนินการต่อหรือไม่?'} open={openModal} width={550} onCancel={onCloseMain} footer={null} destroyOnClose={true} maskClosable={false}>
              <ContainerButton center>
                <ButtonAntd type="primary" disabled={isDisabled} onClick={() => onSubmit(true)} style={isDisabled ? { background: color.disabled, borderColor: color.disabled } : { background: color.create, borderColor: color.create }}>ดำเนินการต่อ</ButtonAntd>
                {/* <ButtonTheme useFor="CUSTOM" title="ดำเนินการต่อ" bgcolor={color.create} onClick={() => onSubmit(true)} /> */}
                <ButtonTheme useFor="CANCEL" bgcolor={color.danger} color={'white'} onClick={() => onCloseMain()} />
                <ButtonTheme useFor="CUSTOM" title="ไปหน้าลูกค้า" onClick={() => window.open(`/customer?id_card=${idCard}`)} />
              </ContainerButton>
            </ModalAntd>
          </TableCell>
        )}
        <TableCell>{idCard}</TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>{type}</TableCell>
        <TableCell align="left">{formatDate(new Date(transactionDate), { dateStyle: 'short', timeStyle: 'short' })}</TableCell>
        <TableCell align="left">{formatDate(new Date(createdDate))}</TableCell>
        <TableCell align="left">{new Intl.NumberFormat('th-TH').format(totalAmount)}</TableCell>
        <TableCell align="left">
          {slipUrl ? (
            <Button type="button" className="slip" onClick={() => showSlip(slipUrl)}>
              <Visibility className={classes.icon_btn} />
            </Button>
          ) : (
            <Visibility style={{ color: '#ccc' }} />
          )}
        </TableCell>
        <TableCell align="center">
            {statusSlip !== '-' ? <ChipStatus status={statusSlip} /> : "-"}
          </TableCell>
        {!isEdit ? (
          <TableCell align="center">
            <ChipStatus status={status} />
          </TableCell>
        ) : (
          <TableCell align="center" style={{ maxWidth: 200, padding: 0, width: 120 }}>
            <FormControl>
              <InputLabel className={classes.input_label} id={`transaction-status-label-${id}`}>
                {statusLabel}
              </InputLabel>
              <Select
                className={classes.select}
                labelId={`transaction-status-label-${id}`}
                id={`transaction-status-${id}`}
                value={updateStatus}
                onChange={changeStatus}>
                <MenuItem value="DEFAULT" disabled>
                  เลือก
                </MenuItem>
                <MenuItem value="CONFIRMED">ยืนยัน</MenuItem>
                <MenuItem value="CANCELED">ยกเลิก</MenuItem>
              </Select>
            </FormControl>
          </TableCell>
        )}

        <TableCell
          size="small"
          align={remark && remark !== '-' ? 'left' : 'center'}
          style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 150 }}>
          {remark || '-'}
        </TableCell>

        <TableCell className={classes.icon_column} style={{ maxWidth: 30 }}>
          <Link style={{ color: "#000" }} to={`/transaction/${id}`}><Pageview className={classes.icon_btn} /></Link>
        </TableCell>
        {!isEdit && canUpdate && ["PENDING", "CONFIRMED"].includes(status) && (
          <TableCell className={classes.icon_column} colSpan={2}>
            <Button onClick={() => handleEdit(true)}>
              <Edit className={`edit ${classes.icon_btn}`} />
            </Button>
          </TableCell>
        )}

        {!isEdit && (!canUpdate || !["PENDING", "CONFIRMED"].includes(status)) && (
          <TableCell className={classes.icon_column} colSpan={2}>
            <Button disabled style={{ opacity: 0, pointerEvents: "none" }}>
              <Edit className={`edit ${classes.icon_btn}`} />
            </Button>
          </TableCell>
        )}

        {isEdit && (
          <TableCell className={classes.icon_column} style={{ maxWidth: 30 }}>
            <Button onClick={() => handleEdit(false)}>
              <Cancel className={`cancel ${classes.icon_btn}`} />
            </Button>
          </TableCell>
        )}

        {isEdit && (
          <TableCell className={classes.icon_column} style={{ maxWidth: 30 }}>
            <Button onClick={() => openConfirm(false)}>
              <CheckCircle className={`save ${classes.icon_btn}`} />
            </Button>
            {/* <Button onClick={() => updateTransaction(false)}>
              <CheckCircle className={`save ${classes.icon_btn}`} />
            </Button> */}
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

TransactionItem.propTypes = {
  id: PropTypes.string,
  idCard: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  transactionDate: PropTypes.string,
  createdDate: PropTypes.string,
  totalAmount: PropTypes.number,
  slipUrl: PropTypes.string,
  onClickSlip: PropTypes.func,
  status: PropTypes.string,
  statusLabel: PropTypes.string,
  remark: PropTypes.string,
  onEditTable: PropTypes.func,
  page: PropTypes.number,
  size: PropTypes.number,
  canUpdate: PropTypes.bool,
  updatedBy: PropTypes.string,
  filter: PropTypes.object
};

TransactionItem.defaultProps = {
  id: '',
  idCard: '',
  name: '',
  type: '',
  transactionDate: '',
  createdDate: '',
  totalAmount: 0,
  slipUrl: null,
  onClickSlip: null,
  status: '',
  statusLabel: '',
  remark: null,
  onEditTable: null,
  page: 1,
  size: 10,
  canUpdate: false,
  updatedBy: '',
  filter: {}
};

export default TransactionItem;
