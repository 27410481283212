export const GET_MASTER_DATA = `
  query GetMasterData($input: MasterDataInp!) {
    getMasterData(input: $input) {
      code
      name
      is_active
      is_visible
    }
  }
`;

export const GET_PRODUCT_MASTER_DATA = `
query GetProductFeatureMaster($input: ProductFeatureMasterInput!) {
  getProductFeatureMaster(input: $input) {
    code
    name
    is_visible
    is_active
  }
}
`;
