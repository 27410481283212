import { Table } from 'antd';
import moment from 'moment';

export const BlacklistLinkModal = ({ data }) => {
    return <div>
        <Table
            scroll={{ x: 240 }}
            tableLayout="fixed"
            columns={[
                {
                    title: '#',
                    dataIndex: '',
                    width: "50px",
                    render: (text, record, index) => {
                        return index + 1;
                    },
                },
                {
                    title: 'ลิงก์',
                    dataIndex: 'url',
                    width: "50%",
                    render: (text, record) => {
                        const urlLink = record.source ? record.link : record.ref
                        if (Array.isArray(urlLink) && urlLink[0] && urlLink[0].url) {
                            return <div>{urlLink.map((l, i) => {
                                return l.url.map(linkR => {
                                    return <div key={`${linkR}_${i}`}><a target="_blank" rel="noreferrer" href={!linkR.includes('https') ? `https://${linkR.trim()}` : linkR} >{linkR}</a></div>
                                })
                            })}</div>
                        }

                        return <div>{urlLink.map((l, i) => {
                            let linkR = l
                            return linkR && <div key={`${linkR}_${i}`}><a target="_blank" rel="noreferrer" href={!linkR.includes('https') ? `https://${linkR.trim()}` : linkR} >{linkR}</a></div>
                        })}</div>
                    },
                },
                {
                    title: 'ประเภท',
                    dataIndex: 'source',
                    width: "150px",
                    render: (text) => {
                        return text || "-"
                    }
                },
                {
                    title: 'แหล่งที่มา',
                    dataIndex: 'runBy',
                    render: (text) => {
                        return text || "-"
                    }
                },
                {
                    title: 'วันที่ตรวจสอบ',
                    dataIndex: 'create_date',
                    render: (text, record) => {
                        return moment(record.create_date).format('YYYY-MM-DD HH:mm')
                    },
                },
            ]}
            dataSource={data}
        />
    </div>
}