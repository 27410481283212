import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../buttons';
import { CreatePickSocial } from '../../../constants/global/actionform';
import { POST, INSERT_JOURNAL_ITEM, INSERT_JOURNAL_ITEM_NON_SERIAL } from '../../../services/index';
import { auth } from '../../../utils/firebase';
import { useAuthState } from '../../../utils/hook';


export const CreatePickModal = ({ title, visible, onClose, options, data, onCallApi, type }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
  } = useForm();



  const [loading, setLoading] = useState(false)
  const { user } = useAuthState(auth);

  const onSubmit = async (params) => {
    setLoading(true)

    let obj = {
      branchFrom: params.branch_from,
      branchTo: params.branch_to,
      remark: ''
    }

    try {
      const api_path = type === 'non_serial' ? INSERT_JOURNAL_ITEM_NON_SERIAL : INSERT_JOURNAL_ITEM
      const res = await POST(api_path, type === 'non_serial' ? obj : { ...obj, userLogin: user.email })
      const { success, result } = res
      if (success) {
        onCallApi(result.document_no)
        onCloseMain();
      }


    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });

    } finally {
      setLoading(false)
    }

  };

  const onCloseMain = () => {
    clearErrors();
    onClose();
  };


  return (
    <Modal title={title} open={visible} width={350} onCancel={onCloseMain} footer={null} destroyOnClose={true} maskClosable={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          formList={CreatePickSocial({ options, data })}
          renderButton={
            <>
              <ContainerButton right>
                <ButtonTheme useFor="SUBMIT" title="สร้างใบจัดสินค้า" htmlType="submit" loading={loading} />
                <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
              </ContainerButton>
            </>
          }
        />
      </form>
    </Modal>
  );
};
