// /* eslint no-use-before-define: 0 */
import React, { useState, useEffect } from 'react';
import Wrapper from '../../views/wrapper/Wrapper';
import { Modal, Card, Button, Table, Spin } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { GET, GET_INTEREST_RATE_REFINANCE_DATA } from '../../services/index';
import { PageLayout } from '../../components/pageLayout/PageLayout';
import { InterestRateRefinanceTableColumn } from '../../constants/global/columnTableForm';

const InterestRateRefinanceTableScreen = () => {
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        getInterestRateRefinanceData()
    }, []);

    const validateEndDate = (value) => {
        let result = ''
        if (value === null || value === undefined || value === '') {
            result = ''
        } else {
            result = value
        }
        return result
    }

    const getInterestRateRefinanceData = async () => {
        setLoading(true)
        try {
            const res = await GET(GET_INTEREST_RATE_REFINANCE_DATA)
            const { success, data } = res

            if (success) {
                const result = data.map((el) => {
                    return {
                        end_date: validateEndDate(el?.end_date) || '',
                        ...el,
                    }
                })
                setDataSource(result)
            }
        } catch (err) {
            const { error } = err;
            Modal.error({
                title: error || '',
            });
        } finally {
            setLoading(false)
        }
    }


    return (
        <Wrapper>
            <Card>
                <Spin tip="Loading..." spinning={loading}>
                    <PageLayout
                        tableLayout={{
                            title: 'ตารางราคา และอัตราดอกเบี้ย ไอโฟนแลกเงิน',
                            icon: <SettingOutlined />,
                            columns: InterestRateRefinanceTableColumn(),
                            dataSource: dataSource,
                            rowKey: (record) => record.id,
                        }}
                    />
                </Spin>
            </Card>
        </Wrapper>
    );
};

export default InterestRateRefinanceTableScreen;