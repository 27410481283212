// /* eslint no-use-before-define: 0 */
import React, {useState, useEffect} from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import {Modal, Card, Spin} from 'antd';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../../components/buttons';
import {AuditOutlined} from '@ant-design/icons';
import {PageLayout} from '../../../components/pageLayout/PageLayout';
import {GET, GET_OPTION_BY_KEY, GET_PICK_REPORT, GET_BRANCH, POST, VIEW_PICK_DOC} from '../../../services/index';
import {CSVLink} from 'react-csv';
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import {PickReportColumn} from '../column';
import {ReportPickSearchForm} from '../form';
import {useAuthState} from '../../../utils/hook';
import {auth} from '../../../utils/firebase';
import {optionProductHandPick} from '../utils/constants';

const PickReport = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [optionJournalStatus, setOptionJournalStatus] = useState([]);
  const [optionBranch, setOptionBranch] = useState([]);
  const [optionProductHand, setOptionProductHand] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const history = useHistory();
  const {user} = useAuthState(auth);

  useEffect(() => {
    getReportPick({document_no: '', status: ''});
    getOptionStatus();
    getOptionBranch();
    getOptionProductHand();
  }, []);

  const getOptionStatus = async () => {
    try {
      const res = await GET(GET_OPTION_BY_KEY('journalHeadStatus'));
      const {success, result} = res;
      if (success) {
        const option = result.journalHeadStatus.map((el) => {
          return {...el, text: el.label};
        });
        setOptionJournalStatus(option);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  const getOptionBranch = async () => {
    try {
      const res = await GET(GET_BRANCH);
      const {success, result} = res;
      if (success) {
        const option = result.branch.map((el) => {
          return {
            text: el.label,
            value: el.value,
          };
        });
        setOptionBranch(option);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  const getOptionProductHand = async () => {
    try {
      const option = [...optionProductHandPick, {value: 'ALL', label: 'ทั้งหมด'}];
      setOptionProductHand(option);
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  const getReportPick = async (data) => {
    if (data.productHand === 'ALL') {
      data.productHand = '';
    }

    setLoading(true);
    try {
      let res = await GET(GET_PICK_REPORT(data.document_no || '', data.status || '', data.productHand || ''));
      const {success, result} = res;
      if (success) {
        const resultData = result.picks.map((val, i) => {
          return {
            no: i + 1,
            ...val,
          };
        });
        const dataEx = result.picks.map((el, i) => {
          return {
            เลขที่เอกสาร: el.document_no,
            ผู้สร้าง: el.user_create,
            วันที่สร้าง: moment(el.create_date).format('YYYY-MM-DD HH:mm'),
            ปลายทาง: el.branch_to_name.branch_name,
            ผู้รับ: el.user_update,
            วันที่รับ: moment(el.update_date).format('YYYY-MM-DD HH:mm'),
            สถานะ: el.status_name,
          };
        });
        setDataExport(dataEx);
        setDataSource(resultData);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const redirectToPrintPick = async (record) => {
    const data = {
      documentNo: record.document_no,
      userLogin: user.email,
    };

    try {
      const res = await POST(VIEW_PICK_DOC, data);
      const {success, result} = res;

      if (success) {
        history.push({
          pathname: '/print-pick',
          state: {data: {...result.export, page: 'printPick'}},
        });
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'รายงานจัดสินค้า',
              icon: <AuditOutlined />,
              spanSearch: 24,
              formJustify: 'left',
              formSearch: ReportPickSearchForm({option: {optionJournalStatus, optionProductHand}}),
              onSearch: (data) => getReportPick(data),
            }}
            tableLayout={{
              columns: PickReportColumn({options: {optionBranch, optionJournalStatus}, redirectToPrintPick}),
              dataSource: dataSource,
              extraContent: (
                <ContainerButton right>
                  <CSVLink data={dataExport} filename={'รายงานจัดสินค้า'}>
                    <ButtonTheme useFor="DOWNLOAD" title={'EXPORT'} />
                  </CSVLink>
                </ContainerButton>
              ),
            }}
          />
        </Spin>
      </Card>
    </Wrapper>
  );
};

export default PickReport;
