import styled from 'styled-components';

export const Table = styled.table`
  width: 100%
`;

export const Td = styled.td`
  border: 1px solid #888;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  padding: 5px;
`;

export const Th = styled.th`
  border: 1px solid #888;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  padding: 5px;
`;