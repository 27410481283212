/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
import firebase from 'firebase/app';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { currentUser } from '../redux/actions/auth';

export const useAuthState = (auth) => {
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        firebase.auth().currentUser.getIdToken(true).then(
          (idToken) => {
            setUser({
              uid: user?.uid,
              email: user?.email,
              photoURL: user?.photoURL,
              displayName: user?.displayName,
              token: user?.refreshToken,
              idToken: idToken
            })
          }
        );
      }

      if (!user) setUser(false);
      if (initializing) setInitializing(false);
    });

    // Cleanup subscription
    return unsubscribe;
  }, [auth, initializing]);

  return { user, initializing };
};

export const useCurrentState = (auth) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(() => auth.currentUser);
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        let currentRes = await dispatch(currentUser(user?.uid));
        setUser({uid: user?.uid, role: currentRes?.role, branch: currentRes?.branch,userId: currentRes?.userId });
        localStorage.setItem('admin_branch', currentRes?.branch);
      } else {
        setUser(null);
      }
      setInitializing(false);
    });
    return unsubscribe;
  }, [auth, dispatch]);

  return { user, initializing };
};
