import Wrapper from '../../views/wrapper/Wrapper';
import { Joi, AutoAdmin } from 'joi-auto-admin';
import { thaiqrLogs } from '../../utils/auto-admin/schema';
import { getThaiQRLogs } from '../../utils/auto-admin/api';

const ThaiQRLogs = () => {
    const props = {
        title: 'รายการชำระผ่าน Thai QR',
        getMany: getThaiQRLogs,
        updateOne: () => console.log(),
        schema: thaiqrLogs,
        disableExcelDownload: true,
        disableExcelUpload: true,
        querySchema: Joi.object({
            id_card: Joi.string().min(3).label('บัตรปชช'),
            name: Joi.string().min(3).label('ชื่อลูกค้า'),
            tel: Joi.string().min(3).label('เบอร์โทรศัพท์'),
        }),
    };

    return (
        <Wrapper>
            <center style={{ maxWidth: 1440, margin: 'auto' }}>
                <AutoAdmin {...props} />
            </center>
        </Wrapper>
    );
};

export default ThaiQRLogs;