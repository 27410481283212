import {useState} from 'react';
import axios from 'axios';
import {Modal} from 'antd';
import {API_CHATCONE_SERVICE} from '../../env';

export const useUpdateTagChatconeByProcess = () => {
  const [loading, setLoading] = useState(false);

  const updateTagChatconeByProcess = async (input) => {
    try {
      setLoading(true);

      const response = await axios.post(`${API_CHATCONE_SERVICE}/admin/update-tag-by-process`, input, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
          'content-type': 'application/json',
        },
      });

      if (response?.data?.success) {
        Modal.success({
          title: 'อัพเดทข้อมูลไปยัง chatcone สำเร็จ',
        });
        return true
      }

      return false;
    } catch (err) {
      let content = '';
      if (err?.message?.includes('Network Error')) content = 'ไม่สามารถเชื่อมต่อ Partner Api Service ได้';

      Modal.error({
        title: 'ไม่สามารถอัพเดทข้อมูลไปยัง chatcone ได้',
        content: content || err?.response?.data?.error?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return [updateTagChatconeByProcess, loading];
};
