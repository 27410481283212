import React from 'react';
import { Checkbox as CheckboxAnt } from 'antd';
import { CheckboxStyle } from './CheckboxStyled';

export const Checkbox = ({ children, ...otherProps }) => {
  return (
    <CheckboxStyle>
      <CheckboxAnt {...otherProps}>{children}</CheckboxAnt>
    </CheckboxStyle>
  );
};

export const CheckboxGroup = ({ children, ...otherProps }) => {
  return (
    <CheckboxStyle>
      <CheckboxAnt.Group {...otherProps}>{children}</CheckboxAnt.Group>
    </CheckboxStyle>
  );
};