export const columnsPick = [
  {
    title: 'ลำดับ',
    dataIndex: 'line_no',
    width: '30px',
    align: 'center',
  },
  {
    title: 'ประเภท',
    dataIndex: 'type',
    width: '100px',
    align: 'center',
  },
  {
    title: 'สินค้า',
    dataIndex: 'item_name',
    width: '150px',
    align: 'center',
  },
  {
    title: 'ซีเรียล',
    dataIndex: 'serial_number',
    width: '120px',
    align: 'center',
  },
  {
    title: 'Apple Status',
    dataIndex: 'appleStatus',
    width: '120px',
    align: 'center',
  },
  {
    title: 'Serial Status',
    dataIndex: 'serial_status',
    width: '120px',
    align: 'center',
    render: (text, record) => {
      return record?.serial_status === undefined || record?.serial_status === null || record?.serial_status === ''
        ? '-'
        : record?.serial_status === 'READY'
        ? 'พร้อมขาย'
        : record?.serial_status === 'NOT_READY'
        ? 'ไม่พร้อมขาย'
        : record?.serial_status;
    },
  },
  {
    title: 'หมายเหตุ',
    dataIndex: 'remark',
    width: '120px',
    align: 'center',
  },
];

export const columnsPick2 = [
  {
    title: 'ลำดับ',
    dataIndex: 'line_no',
    width: '30px',
    align: 'center',
  },
  {
    title: 'ประเภท',
    dataIndex: 'type',
    width: '100px',
    align: 'center',
  },
  {
    title: 'สินค้า',
    dataIndex: 'item_name',
    width: '150px',
    align: 'center',
  },
  {
    title: 'Apple Status',
    dataIndex: 'appleStatus',
    width: '120px',
    align: 'center',
  },
  {
    title: 'Serial Status',
    dataIndex: 'serial_status',
    width: '120px',
    align: 'center',
    render: (text, record) => {
      return record?.serial_status === undefined || record?.serial_status === null || record?.serial_status === ''
        ? '-'
        : record?.serial_status === 'READY'
        ? 'พร้อมขาย'
        : record?.serial_status === 'NOT_READY'
        ? 'ไม่พร้อมขาย'
        : record?.serial_status;
    },
  },
  {
    title: 'หมายเหตุ',
    dataIndex: 'remark',
    width: '120px',
    align: 'center',
  },
];

export const columnsPickNonSerial = [
  {
    title: 'ลำดับ',
    dataIndex: 'line_no',
    width: '30px',
    align: 'center',
  },
  {
    title: 'สินค้า',
    dataIndex: 'item_name',
    width: '300px',
    align: 'left',
  },
  {
    title: 'จำนวน',
    dataIndex: 'qty',
    width: '100px',
    align: 'right',
  },
];

export const columnsCheckStock = [
  {
    title: 'ลำดับ',
    dataIndex: 'line_no',
    width: '30px',
    align: 'center',
  },
  {
    title: 'สินค้า',
    dataIndex: 'item_name',
    width: '150px',
    align: 'center',
  },
  {
    title: 'ซีเรียล',
    dataIndex: 'serial_number',
    width: '120px',
    align: 'center',
  },
  {
    title: 'สถานะ',
    dataIndex: 'status_name',
    width: '100px',
    align: 'center',
  },
  {
    title: 'Serial Status',
    dataIndex: 'serial_status',
    width: '100px',
    align: 'center',
    render: (text, record) => {
      return record?.serial_status === undefined || record?.serial_status === null || record?.serial_status === ''
        ? '-'
        : record?.serial_status === 'READY'
        ? 'พร้อมขาย'
        : record?.serial_status === 'NOT_READY'
        ? 'ไม่พร้อมขาย'
        : record?.serial_status;
    },
  },
  {
    title: 'Apple Status',
    dataIndex: 'checkStockAppleStatus',
    width: '100px',
    align: 'center',
  },
  {
    title: 'เช็ค Apple Status',
    dataIndex: 'isSameAppleStatus',
    width: '100px',
    align: 'center',
    render: (text, record) => {
      let result = '';
      if (record.isSameAppleStatus === false) {
        result = 'ไม่ตรง';
      } else if (record.isSameAppleStatus === true) {
        result = 'ตรง';
      }
      return result;
    },
  },
];

export const columnsProductPickStock = [
  {
    title: 'ลำดับ',
    dataIndex: 'rowNo',
    width: '30px',
    align: 'center',
  },
  {
    title: 'สินค้า',
    dataIndex: 'product_name',
    width: '300px',
    align: 'center',
  },
  {
    title: 'จำนวนจอง',
    dataIndex: 'countReserve',
    width: '80px',
    align: 'center',
  },
  {
    title: 'จำนวนสต็อก',
    dataIndex: 'countStock',
    width: '80px',
    align: 'center',
  },
  {
    title: 'จำนวนที่ต้องหยิบ',
    dataIndex: 'countPick',
    width: '80px',
    align: 'center',
  },
];
