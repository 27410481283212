import { useEffect, useState } from 'react';
import { Table } from 'antd';
import { TextLarge, TextSmall } from '../forms/text';

const TableForm = ({ rowSelection, title, columns, dataSource, expandable, pagination, components, loading, style, scroll, rowClassName, rowKey, summary, onRow }) => {
  const [pageSize, setPageSize] = useState(10);

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };

  return (
    <div style={{ marginTop: '1%', ...style }}>
      <TextSmall text={title || ""} color={'#0052ba'} />
      <Table
        onRow={onRow}
        rowSelection={rowSelection}
        expandable={expandable}
        columns={columns}
        dataSource={dataSource || []}
        bordered
        components={components}
        summary={summary}
        size="small"
        loading={loading}
        rowClassName={rowClassName}
        rowKey={rowKey}
        scroll={ scroll || { x: 'max-content' } }
        pagination={
          !pagination ? {
            showSizeChanger: true,
            onShowSizeChange,
            pageSize,
            total: dataSource.length || 0,
            showTotal: (total, range) => `${total} รายการ`,
            defaultPageSize: 20,
            defaultCurrent: 1,
          } : pagination
        }
      />
    </div>
  );
};

export default TableForm;
