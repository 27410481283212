import React, { useState, useEffect } from 'react';
import Wrapper from '../../views/wrapper/Wrapper';
import { Modal, Card, Space, Row, Spin, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { PageLayout } from '../../components/pageLayout/PageLayout';
import { auth } from '../../utils/firebase';
import { useCurrentState } from './../../utils/hook';
import { DIFARMasterColumn } from './components/columnTableForm';
import { DIFARMasterSearchForm } from './components/searchForm';
import { Icon } from '../../resources/icon';
import { color } from '../../resources/color';
import { GET, GET_DIFARMASTER, PUT, EDIT_IMPORT_DIFARMASTER } from '../../services';

const DIFARMaster = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useCurrentState(auth);
  const [selected, setSelected] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    getDataDIFARMASTER({ Code: '', Telephone: '', PersonType: '', IsImport: '' });
  }, []);

  const optionPersonType = [
    { value: '0', label: 'บุคคลธรรมดา' },
    { value: '1', label: 'นิติบุคคล' },
  ];

  const optionImportStatus = [
    { value: '0', label: 'รอ Import' },
    { value: '1', label: 'Import เสร็จสิ้น' },
    { value: '2', label: 'Import ไม่สำเร็จ' },
  ];

  const getDataDIFARMASTER = async (data) => {
    data.Code = data?.Code || '';
    data.Telephone = data?.Telephone || '';
    data.PersonType = data?.PersonType || '';
    data.IsImport = data?.IsImport || '';

    const { Code, Telephone, PersonType, IsImport } = data;
    setLoading(true);
    try {
      const res = await GET(GET_DIFARMASTER(Code, Telephone, PersonType, IsImport));
      const { success, data } = res;
      if (success) {
        data.map((item, index) => {
          item.key = index + 1;
          item.IsImport.code = item.IsImport.code.toString();
        });
        setDataSource(data);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const openConfirm = () => {
    if (selected && selected.length !== 0) {
      Modal.confirm({
        title: 'ยืนยัน',
        icon: <Icon.warningAlert />,
        content: 'ยืนยันการเปลี่ยนสถานะเป็น รอ Import ข้อมูล',
        onOk() {
          reImportData();
        },
        okText: 'ยืนยัน',
        cancelText: 'ยกเลิก',
      });
    } else {
      Modal.warning({
        title: 'กรุณาเลือกข้อมูลที่ต้องการ Import',
      });
    }
  };

  const reImportData = async () => {
    setLoading(true);
    try {
      const res = await PUT(EDIT_IMPORT_DIFARMASTER, { idCards: selected });
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            getDataDIFARMASTER({});
            clearCheckbox()
          },
        });
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const clearCheckbox = () => {
    setLoading(true);
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };


  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'Master ลูกหนี้',
              icon: <UserOutlined />,
              spanSearch: 24,
              formSearch: DIFARMasterSearchForm({ options: { optionPersonType, optionImportStatus } }),
              onSearch: (data) => getDataDIFARMASTER(data),
            }}
            tableLayout={{
              columns: DIFARMasterColumn({ options: { optionPersonType, optionImportStatus } }),
              dataSource: dataSource,
              rowSelection: {
                selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelected(
                    selectedRows.map((row) => {
                      return row.Code;
                    }),
                  );
                  setSelectedRowKeys(selectedRowKeys)
                },
                getCheckboxProps: (record) => {
                  if (record.IsImport.code !== '2') {
                    return {
                      disabled: true,
                    };
                  }
                },
              },
              extraContent: (
                <Row
                  style={{
                    width: '100%',
                  }}
                  align="bottom"
                  justify="space-between">
                  <Space>
                    <Button
                      type="primary"
                      style={{ background: color.tranfer, borderColor: color.tranfer }}
                      size="large"
                      icon={<Icon.tranfer />}
                      onClick={() => openConfirm()}>
                      Import ข้อมูล
                    </Button>
                  </Space>
                </Row>
              ),
            }}
          />
        </Spin>
      </Card>
    </Wrapper>
  );
};

export default DIFARMaster;
