// /* eslint no-use-before-define: 0 */
import { BookOutlined } from '@ant-design/icons';
import { Card, Modal, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { DownPaymentVerifyModal } from '../../components/modal/DownPayment/DownPaymentVerify';
import { PageLayout } from '../../components/pageLayout/PageLayout';
import { DownPaymentFinanceSearchForm } from '../../constants/global/SearchForm';
import { DownPaymentFinanceColumn } from '../../constants/global/columnTableForm';
import { convertStrToFormat } from '../../functions/fn';
import { GET, GET_FINANCE_DOWN_PAYMENT, GET_FINANCE_DOWN_PAYMENT_VERIFY } from '../../services/index';
import Wrapper from '../../views/wrapper/Wrapper';

const FinanceDownPaymentScreen = () => {
    const [loading, setLoading] = useState(false);
    const [visibleVerifyModal, setVisibleVerifyModal] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const pageRef = useRef(1)
    const sizeRef = useRef(10)
    const [totalSize, setTotalSize] = useState(0)
    const [totalPage, setTotalPage] = useState(0)
    const [idBookState, setIdBookState] = useState(null)
    const [defaultValue, setDefaultValue] = useState({});
    const [valueSearch, setValueSearch] = useState({})

    useEffect(() => {
        getFinanceDownPaymentData({ page: pageRef.current, size: sizeRef.current, down_payment_id_book: '' })
    }, []);

    const createParams = (value) => {
        let params = [];
        let result = '';

        Object.keys(value).forEach((key) => {
            if (value[key]) {
                params.push(key + '=' + encodeURIComponent(value[key]));
            }
        });

        if (params.length !== 0) {
            result += params.join('&');
        }

        return result;
    }

    const getFinanceDownPaymentData = async (value) => {

        value.page = value?.page || 1
        value.size = value?.size || 10

        pageRef.current = value.page
        sizeRef.current = value.size

        value.down_payment_id_book = value.down_payment_id_book || ''
        let params = createParams(value);

        const { page, size, down_payment_id_book } = value;

        setLoading(true)
        setIdBookState(down_payment_id_book)
        setValueSearch(value)

        try {
            const res = await GET(GET_FINANCE_DOWN_PAYMENT(params))
            const { success, data, total_item, total_page } = res

            if (success) {

                setTotalSize(total_item)
                setTotalPage(total_page)
                const result = data.map((el, i) => {
                    return {
                        key: el?._id,
                        rowNo: i + 1,
                        name: el?.user?.general?.fullname,
                        ...el,
                    }
                })

                setDataSource(result);
            }

        } catch (err) {
            const { error } = err;
            Modal.error({
                title: error || '',
            });
        } finally {
            setLoading(false)
        }
    }

    const onPageSizeChangeCustom = (pageData, sizeData) => {
        const sizeChange = sizeRef.current !== sizeData;
        if (sizeChange) {
            pageRef.current = 1
        } else {
            pageRef.current = pageData
        }
        sizeRef.current = sizeData

        let id_book = idBookState

        const params = {
            page: pageRef.current,
            size: sizeRef.current,
            down_payment_id_book: id_book || '',
        };
        getFinanceDownPaymentData(params);
    };

    const openModal = (record, name) => {
        if (record) {
            setDefaultValue(record);
            if (name == 'verify') {
                getDataVerify(record.down_payment_id_book);
            }
        }
    };

    const getDataVerify = async (value) => {
        setLoading(true);
        if (value === undefined) {
            Modal.error({
                title: 'ไม่พบเลขที่ออมดาวน์จากรายงานฉบับนี้',
                content: 'ไม่สามารถดึงรายละเอียดข้อมูลมาแสดงได้',
            });
            setLoading(false);
        } else {
            try {
                const res = await GET(GET_FINANCE_DOWN_PAYMENT_VERIFY(value));
                const { success, other } = res;
                let data = res.data;

                if (success) {
                    let result = data.map((el) => {
                        return {
                            key: el?._id,
                            downpayment_no: el.downpayment_no,
                            user_id_card: el?.id_card,
                            tel: el?.tel,
                            name: el?.name,
                            product_name: el?.product_name,
                            remain_amount: el?.remain_amount ? convertStrToFormat(el?.remain_amount, 'money') : 0,
                            return_amount: el?.return_amount ? convertStrToFormat(el?.return_amount, 'money') : 0,
                            book_bank_no: el?.book_bank_no,
                            book_bank_name: el?.book_bank_name,
                            bank_name: el?.bank_name,
                            bank_branch: el?.bank_branch,
                            admin_cancel: el?.admin_cancel,
                            book_bank_image: el?.book_bank_image || "",
                            profile_image: el?.profile_image || "",
                            rename_image: el?.rename_image || "",
                            remark: el?.remark,
                        }
                    });
                    setDefaultValue(result);
                    setVisibleVerifyModal(true);
                }
            } catch (err) {
                const { message, error } = err;
                Modal.error({
                    title: message,
                    content: error || '',
                });
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Wrapper>
            <Card>
                <Spin tip="Loading..." spinning={loading}>
                    <PageLayout searchLayout={{
                        title: 'ยกเลิกออมดาวน์ ฝ่ายบัญชี',
                        icon: <BookOutlined />,
                        spanSearch: 24,
                        formSearch: DownPaymentFinanceSearchForm(),
                        onSearch: (data) => getFinanceDownPaymentData(data),
                    }}
                        tableLayout={{
                            columns: DownPaymentFinanceColumn({ openModal }),
                            dataSource: dataSource,
                            rowKey: (record) => record.key,
                            pagination: {
                                showSizeChanger: true,
                                current: pageRef.current,
                                pageSize: sizeRef.current,
                                onChange: (p, s) => onPageSizeChangeCustom(p, s),
                                pageSizeOptions: ['10', '20', '30', '40', '100', '200', '300'],
                                total: totalSize || 0,
                                showTotal: (totalSize, range) => `รายการทั้งหมด ${convertStrToFormat(totalSize, 'number-comma')} รายการ`,
                                defaultPageSize: sizeRef.current,
                                defaultCurrent: 1,
                            },
                        }}
                    />
                </Spin>
            </Card>
            <DownPaymentVerifyModal
                visible={visibleVerifyModal}
                title={'ตรวจสอบ'}
                onClose={() => setVisibleVerifyModal(false)}
                data={{ reApi: getFinanceDownPaymentData, record: defaultValue, valueSearch }}
            />

        </Wrapper>
    );
};

export default FinanceDownPaymentScreen;