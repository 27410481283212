import React, { useState, useRef, useEffect } from 'react';
import { Collapse, Table, Image } from 'antd';
import { OtherPaymentColumn, DetailOtherPaymentColumn } from '../columns';
import TableForm from '../../../components/table';


const OtherPaymentTable = ({ data }) => {
  const [dataSource, setDataSource] = useState(data);
  const [image, setImage] = useState('');
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (data.length > 0) {
      onSetData();
    }
  }, [data]);

  const onSetData = () => {
    const dataSet = data.map((el, i) => {
      return { no: i + 1, ...el, key: `key_${i + 1}` };
    });
    setDataSource(dataSet);
  };

  const onOpenImage = (record) => {
    setVisible(true);
    setImage(record?.slipUrl);
  };

  return (
    <div>
      <Collapse>
        <Collapse.Panel header="รายการค่าใช้จ่ายอื่นๆ" key="1">
          <div>
            <Table
              columns={OtherPaymentColumn()}
              dataSource={dataSource}
              expandable={{
                rowExpandable: (record) => record?.transactionOther.length !== 0,
                expandedRowRender: (record) => {
                  return (
                    <TableForm
                      title={'รายละเอียด'}
                      style={{ margin: '1vh' }}
                      noPagination={true}
                      columns={DetailOtherPaymentColumn({ onOpenImage })}
                      dataSource={record?.transactionOther}
                      scroll="100%"
                    />
                  );
                },
              }}
            />
          </div>
        </Collapse.Panel>
      </Collapse>
      {visible && (
        <Image
          width={200}
          style={{ display: 'none' }}
          preview={{
            visible: { visible },
            src: image,
            onVisibleChange: (value) => {
              setVisible(value);
            },
          }}
        />
      )}
    </div>
  );
};
export default OtherPaymentTable;
