import React, {useState, useEffect, useCallback} from 'react';
import {Modal} from 'antd';
import {useForm} from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../../components/buttons';
import {CHANGE_TRACKER_FORM} from '../form';
import {POST, CHANGE_TRACKING_FEE, GET_JOBTYPE, GET} from '../../../services';
import {Icon} from '../../../resources/icon';
import {useGetOptions} from '../hook/useGetOptions';

export const ChangeTrackerIDModal = ({title, visible, onClose, data, reApi, selectedRowKeys}) => {
  const {
    handleSubmit,
    formState: {errors},
    setValue,
    getValues,
    control,
    clearErrors,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [typeOtherJob, setTypeOtherJob] = useState("");
  const [jobType, setJobType] = useState([]);
  const {optionsTracker} = useGetOptions();

  const fetchJobType = useCallback(async () => {
    try {
      const res = await GET(GET_JOBTYPE);
      const {success, result} = res;
      if (success) {
        const option = result.map((item) => ({
          label: item.label,
          value: item.value,
        }));
        setJobType(option);
  }
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
    }
  }, []);

  useEffect(() => {
    setValue('tracker_id', data?.tracker_id);
    setValue('tracking_fee', data?.tracking_fee);
    setValue('jobType', data?.job_type);
    setValue('jobTypeRemark', data?.job_type_remark);
    fetchJobType()
  }, [data]);

  useEffect(() => {
    if(getValues('jobType') !== "OTHERS") setValue('jobTypeRemark',"")
  }, [getValues('jobType')]);

  const openConfirm = (params) => {
    Modal.confirm({
      title: 'ยืนยัน',
      icon: <Icon.warningAlert />,
      content: 'ยืนยันเปลี่ยนผู้ติดตาม',
      onOk() {
        onSubmit(params);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const onSubmit = async (params) => {
    setLoading(true);
    try {
      const payload = {
        terminate_id: selectedRowKeys.length === 0 ? [data?.terminate_id] : selectedRowKeys,
        trackerID: params?.tracker_id,
        tracking_fee: params?.tracking_fee,
        jobType: params?.jobType,
        jobTypeRemark: params?.jobTypeRemark
      };
      let res = await POST(CHANGE_TRACKING_FEE, payload);
      const {success, message} = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            reApi();
            onCloseMain();
          },
        });
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const onCloseMain = () => {
    clearErrors();
    setValue('tracker_id',null);
    setValue('tracking_fee', '');
    onClose();
  };
  const onChange = (value, name) => {
    setTypeOtherJob(value);
  };
  return (
    <Modal title={title || 'เปลี่ยนผู้ติดตามที่เลือกทั้งหมด'} open={visible} width={480} onCancel={onCloseMain} footer={null} destroyOnClose={true} maskClosable={false}>
      <form onSubmit={handleSubmit(openConfirm)}>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          formList={CHANGE_TRACKER_FORM({options: {trackers: optionsTracker, contractType: jobType}, onChange, data:{type: typeOtherJob}})}
          renderButton={
            <>
              <ContainerButton right>
                <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" loading={loading} />
                <ButtonTheme useFor="CANCEL" onClick={onCloseMain} />
              </ContainerButton>
            </>
          }
        />
      </form>
    </Modal>
  );
};
