// /* eslint no-use-before-define: 0 */
import React, { useState, useEffect } from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import { Modal, Card, Spin } from 'antd';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../../components/buttons';
import { AuditOutlined } from '@ant-design/icons';
import { PageLayout } from '../../../components/pageLayout/PageLayout';
import { ReportFollowTrackDetailSearchForm } from '../../../constants/global/SearchForm';
import { ReportFollowTrackDetailColumn } from '../../../constants/global/columnTableForm';
import { GET, GET_TRACK_DAILY_DETAIL } from '../../../services/index';
import { CSVLink } from "react-csv";
import { exportDefault } from "../../../functions/fn"
import moment from 'moment';


const FollowTrackDetail = () => {

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataExport, setDataExport] = useState([])
  const [dataSearch, setDataSearch] = useState({ action_by: "", startDate: "", endDate: "", customerName: "", stage: "" })

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const actionBy = params.get("action_by")
    const start_date = params.get("start_date")
    const end_date = params.get("end_date")
    const stage = params.get("stage")


    if (actionBy) {
      setDataSearch({ actionBy, startDate: start_date, endDate: end_date, customerName: '', stage: '' })
      getTrackDailyDetail({ actionBy, startDate: start_date, endDate: end_date, customerName: '', stage })
    } else {
      setDataSearch()
      getTrackDailyDetail({ action_by: "", startDate: "", endDate: "", customerName: "" })
    }


  }, []);

  const getTrackDailyDetail = async (value) => {
    setDataSearch({
      stage: value.stage || '',
      actionBy: value.actionBy || '',
      startDate: value.startDate && moment(value.startDate).format('YYYY-MM-DD') || '',
      endDate: value.endDate && moment(value.endDate).format('YYYY-MM-DD') || '',
      customerName: value.customerName || ''
    })
    setLoading(true);
    try {

      let params = `?`;
      if (value.stage) {
        params += `stage=${value.stage}&`;
      }

      if (value.actionBy) {
        params += `action_by=${value.actionBy}&`;
      }

      if (value.startDate) {
        params += `start_date=${moment(value.startDate).format('YYYY-MM-DD')}&`;
      }

      if (value.endDate) {
        params += `end_date=${moment(value.endDate).format('YYYY-MM-DD')}&`;
      }

      if (value.customerName) {
        params += `customer_name=${value.customerName}&`;
      }

      let res = await GET(`${GET_TRACK_DAILY_DETAIL}${params}`);
      const { status, tracklog } = res;
      if (status) {

        if (tracklog.length > 0) {

          const dataEx = tracklog.map((el, i) => {
            return {
              'ชื่อ-นามสกุล': el.user?.general?.fullname,
              'เบอร์โทร': el.user?.tel,
              'เลขที่สัญญา': el.track?.contract_no[0] || '',
              'เกินกำหนด': el.track?.stage,
              'ยอดรวมค้างชำระ': el.track?.amount,
              'บันทึกการติดตาม': el.remark,
              'แอดมินผู้ติดตาม': el.action_by,
            };
          });

          const report = tracklog.map((el, i) => {
            return {

              customerName: el.user?.general?.fullname || '',
              telephone: el.user?.tel || '',
              contractNo: el.track?.contract_no[0] || '',
              aging: el.track?.stage,
              amount: el.track?.amount,
              remark: el.remark || '',
              actionBy: el.action_by,
            };
          });
          const dataExportDefault = exportDefault(['ชื่อ-นามสกุล', 'เบอร์โทร', 'เลขที่สัญญา', 'เกินกำหนด', 'ยอดรวมค้างชำระ', 'บันทึกการติดตาม', 'แอดมินผู้ติด'])
          setDataExport(dataEx.length > 0 && dataEx || dataExportDefault)
          setDataSource(report);

        } else {
          setDataSource([])
        }

      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'รายละเอียดบันทึกติดตามงานรายคน',
              icon: <AuditOutlined />,
              spanSearch: 24,
              formSearch: ReportFollowTrackDetailSearchForm(dataSearch),
              onSearch: (data) => getTrackDailyDetail(data),
              dataSearchDefault: dataSearch
            }}
            tableLayout={{
              columns: ReportFollowTrackDetailColumn(),
              dataSource: dataSource,
              extraContent: (
                <ContainerButton right>
                  <CSVLink data={dataExport} filename={"รายงานรายละเอียดบันทึกติดตามงานรายคน"}><ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด'} /></CSVLink>
                </ContainerButton>
              ),
            }}
          />
        </Spin>
      </Card>
    </Wrapper>
  );
};

export default FollowTrackDetail;
