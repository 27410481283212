import {useEffect, useState, useReducer} from 'react';
import {getProductFeatureMasterDt} from '../../../components/inputfromapi/api';

export const useGetProductFeature = () => {
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [needRefresh, refresh] = useReducer((s) => !s, false);

  useEffect(() => {
    let abort = false;
    fetchReport();
    return () => {
      abort = true;
    };

    async function fetchReport() {
      setLoading(true);
      try {
        const response = await getProductFeatureMasterDt({input: {}});
        if (response) setResult(response);
      } catch (err) {
        if (!abort) setError(err);
      } finally {
        if (!abort) {
          setLoading(false);
        }
      }
    }
  }, [needRefresh]);

  return [result, loading, error, refresh];
};
