import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import RenderForm from '../../../../constants/global/RenderForm';
import { ContainerButton } from '../../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../../../components/buttons';
import { PUT, EDIT_REMARK } from '../../../../services/index';
import { Icon } from '../../../../resources/icon';
import { EditRemarkConsider } from '../form';

export const EditRemarkMonitorModal = ({ title, visible, onClose, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState({});

  useEffect(() => {

    if (JSON.stringify(data && data.record) !== "{}" && data.record) {
      setValue('remark', data.record['remark'] || '')
      setRecord(data.record)
    }

  }, [data]);

  const openConfirm = (params,) => {
    Modal.confirm({
      title: "ยืนยัน",
      icon: <Icon.warningAlert />,
      content: "ยืนยันการแก้ไข หมายเหตุ",
      onOk() {
        onSubmit(params);
      },
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
    });
  };


  const onSubmit = async (params) => {
    setLoading(true);


    try {
      let res = await PUT(EDIT_REMARK(record.registerId), { remark: params.remark });
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            data.reApi(data.valueSearch);
            onCloseMain();
          },
        });
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }

  };

  const onCloseMain = () => {
    clearErrors();
    setValue('remark', '')
    onClose();
  };
  return (
    <Modal title={title || 'modal'} open={visible} width={480} onCancel={onCloseMain} footer={null} destroyOnClose={true} maskClosable={false}>
      <form onSubmit={handleSubmit(openConfirm)}>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          formList={EditRemarkConsider()}
          renderButton={
            <>
              <ContainerButton right>
                <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" />
                <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
              </ContainerButton>
            </>
          }
        />
      </form>
    </Modal>
  );
};
