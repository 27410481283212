export const MockMenuAccount = [
  {
    head: 'Ufriend',
    menu: [
      {menu_code: 'M1009', menu_name: 'Refinance', head: 'Ufriend', path: '/Refinance'},
      {menu_code: 'M1010', menu_name: 'พิจารณาลูกค้า', head: 'Ufriend', path: '/monitor-customer'},
      {menu_code: 'M1011', menu_name: 'ปฎิทินนัดทำสัญญา', head: 'Ufriend', path: '/scheduler-dashboard'}
    ],
  },
];


/// path mี่ไม่เชคสิทธิ์/////
export const pathNameNoPermission = [
  {
    path: '/print-pick',
    name: '',
  },
  {
    path:'/manage-package',
    name:''
  },
  {
    path:'/add_trackingno',
    name:''
  },
  {
    path:'/tracking-no-report',
    name:''
  },
  {
    path:'/terminated-contract-report',
    name:''
  },
  {
    path:'/device-return',
    name:''
  },
  {
    path:'/mistake-terminate-contract',
    name:''
  },
  {
    path:'/add_AcknowledgementReceipt_tracking_no',
    name:''
  },
  {
    path:'/print-pick-non-serial',
    name:''
  },
  {
    path:'/tracking-task-manage',
    name:''
  },
  // {
  //   path: '/sign-second-hand',
  //   name: ''
  // },
]