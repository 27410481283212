import Wrapper from '../../views/wrapper/Wrapper';
import { Joi, AutoAdmin } from 'joi-auto-admin';
import { products } from '../../utils/auto-admin/schema';
import { getProductWithFilterList, updateProduct } from '../../utils/auto-admin/api';

const Product = () => {
   
    const props = {
        title: 'สินค้า',
        getMany: getProductWithFilterList,
        updateOne: updateProduct,
        schema: products,
        rowButtons: [
            // { label: 'แจ้งเตือนอีกครั้ง', onClick: () => alert(4) },
        ],
        disableExcelDownload: true,
        disableExcelUpload: true,
        querySchema: Joi.object({
            code: Joi.string().label('รหัสสินค้า'),
            name: Joi.string().label('ชื่อสินค้า'),
            plu: Joi.string().label('PLU'),
        }),
    };

    return (
        <Wrapper>
            <center style={{ maxWidth: 1024, margin: 'auto' }}>
                <AutoAdmin {...props} />
            </center>
        </Wrapper>
    );
};

export default Product;