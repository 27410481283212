import React, {useEffect, useState, useMemo} from 'react';
import {Modal} from 'antd';
import {DatatableLog, Spinners} from '../../../../components';
import {ContractHistoryTypeLockColumn} from './columns/ContractHistoryTypeLockColumn.js';
import {getContractHistoryTypeLock} from './api';

const ModalHistoryTypeLock = ({title, visible, onClose, modalData}) => {
  const page = 1;
  const limit = 10;
  const filters = {contract_no: modalData?.contract_no};
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const listOptions = useMemo(() => {
    return {
      filters,
      pagination: {
        limit: limit,
        page: page,
      },
    };
  }, [filters, page, limit]);

  useEffect(() => {
    (async () => {
      if (visible) {
        setLoading(true);
        const res = await getContractHistoryTypeLock(listOptions);

        if (res) {
          setData(res);
        }
        setLoading(false);
      }
    })();
  }, [visible]);

  return (
    <>
      {loading ? <Spinners /> : null}
      <Modal title={title} open={visible} width={1200} onCancel={onClose} footer={null} destroyOnClose={true} maskClosable={false}>
        <DatatableLog
          showDivider={false}
          columns={ContractHistoryTypeLockColumn()}
          data={data}
          pagination={{pageSize: 10}}
          pageSize={limit}
          page={page}
        />
      </Modal>
    </>
  );
};

export default ModalHistoryTypeLock;
