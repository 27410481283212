import axios from 'axios';
import { API_URL } from '../../env.js';

export const getTrackInfo = async (id) => {
    try {
        return await axios.get(`${API_URL}/admin/track/${id}`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
        });
    } catch (error) {
        return error;
    }
};

export const createTrackLog = async (id, body) => {
    try {
        return await axios.post(`${API_URL}/admin/track/${id}/create-log`, body, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
        });
    } catch (error) {
        return error;
    }
};

export const getTrackLog = async (id) => {
    try {
        return await axios.get(`${API_URL}/admin/track/${id}/log?page=1&size=30`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
        });
    } catch (error) {
        return error;
    }
};
