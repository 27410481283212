import {useState} from 'react';
import axios from 'axios';
import {Modal} from 'antd';
import {API_CHATCONE_SERVICE} from '../../env.js';

export const useSaveSocialId = () => {
  const [loading, setLoading] = useState(false);

  const saveSocialId = async (input) => {
    try {
      if (input?.refId) {
        setLoading(true);

        const response = await axios.post(`${API_CHATCONE_SERVICE}/admin/save-social-id`, input, {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
            'content-type': 'application/json',
          },
        });

        if (response?.data?.success) {
          return true;
        }
        
        throw new Error();
      }
    } catch (err) {
      let content = '';
      if (err?.message?.includes('Network Error')) content = 'ไม่สามารถเชื่อมต่อ Partner Api Service ได้';

      Modal.error({
        title: 'บันทึกข้อมูลไม่สำเร็จ',
        content: content || err?.response?.data?.error?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return [saveSocialId, loading];
};
