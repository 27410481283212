import React, {useEffect, useState} from 'react';
import {Input} from 'formik-antd';
import {useFormikContext} from 'formik';
import numeral from 'numeral';
import {decryptString} from '../../functions/fn';
import {getFGFData} from './api';

/* FGF: 2.ทำสัญญา FGFCodeInput */
const FGFCodeInput = (props) => {
  const [fgfCode, setFGFCode] = useState(null);
  const idCard = decryptString(localStorage.getItem('id_card')) || '';
  const ctx = useFormikContext();

  useEffect(async () => {
    const res = await getFGFData(idCard);
    const {friend_get_friend, success} = res;

    if (success) {
      setFGFCode(friend_get_friend?.code);
      const commission_amount = friend_get_friend?.commission_amount || 0;
      ctx.setFieldValue('contract.$discount', numeral(commission_amount).format('0,0'), false);
      ctx.setFieldValue('contract.$discountValue', commission_amount, false);
    }
  }, [idCard]);

  return (
    <>
      <Input {...props} value={fgfCode}></Input>
    </>
  );
};

export default FGFCodeInput;
