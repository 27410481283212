import {Checkbox} from 'formik-antd';
import {useFormikContext} from 'formik';
import _ from 'lodash';

const SameAddress = (props) => {
  const ctx = useFormikContext();
  const onChange = (e) => {
    setTimeout(() => {
      try {
        var newValue = '';
        if (e.target.checked) newValue = _.get(ctx.values, 'customer.address.full');
        ctx.setFieldValue('customer.address2.full', newValue);
      } catch (e) {}
    });
  };

  return <Checkbox {...props} onChange={onChange} />;
};

export default SameAddress;
