import {API_ADMIN_SERVICE, APP_API_KEY} from '../../../../../env';
import {axiosInstance, decodeUserAdmin} from '../../../../../oauth';
import {UPDATE_CONTRACT_TYPE_LOCK} from './graphql/Mutation';
import {GET_CONTRACT_HISTORY_TYPE_LOCK} from './graphql/Query';

export const updateContractTypeLock = async (input) => {
  try {
    const userAdminV2 = await decodeUserAdmin();
    if (!userAdminV2) return;

    const {accessToken, clientKey} = userAdminV2;

    const response = await axiosInstance.post(
      API_ADMIN_SERVICE,
      {
        query: UPDATE_CONTRACT_TYPE_LOCK,
        variables: {input: input},
      },
      {
        headers: {
          Authorization: accessToken,
          'x-api-key': APP_API_KEY,
          'client-key': clientKey,
        },
      },
    );

    if (response?.data?.data) {
      return response?.data?.data?.updateContractTypeLock;
    }

    if (response?.data?.errors) {
      throw new Error(response?.data?.errors[0]?.message);
    }
    return null;
  } catch (error) {
    throw error;
  }
};

export const getContractHistoryTypeLock = async (input) => {
  try {
    const userAdminV2 = await decodeUserAdmin();
    if (!userAdminV2) return;

    const {accessToken, clientKey} = userAdminV2;

    const response = await axiosInstance.post(
      API_ADMIN_SERVICE,
      {
        query: GET_CONTRACT_HISTORY_TYPE_LOCK,
        variables: {
          input: {...input},
        },
      },
      {
        headers: {
          Authorization: accessToken,
          'x-api-key': APP_API_KEY,
          'client-key': clientKey,
        },
      },
    );

    return response?.data?.data?.getHistoryTypeLock?.result;
  } catch (error) {
    return null;
  }
};
