import React, { useState } from 'react';
import { Select, TreeSelect, Tooltip, Typography, Input } from 'antd';
import { Controller } from 'react-hook-form';
import { SyncOutlined, CopyOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { compareRender } from '../../functions/fn';
const { SHOW_PARENT } = TreeSelect;
const { Text } = Typography
const { Option, OptGroup } = Select;

export const SelectDropdown = React.memo(
  ({ item, control, getValues, dropdownMatchSelectWidth = true }) => {

    const { name, rules, value, defaultValue, placeholder, disabled, allowClear, onSelect, onChangeCustom, mode, options, onClear } = item;

    const [copied, setCopied] = useState(false);
    const [text, setText] = useState('copy');
    const [textRandom, setTextRandom] = useState('สุ่มใหม่');

    const onCopy = () => {
      setCopied(true);
      setText('copied!');
    };

    const onUnCopy = () => {
      setCopied(false);
      setText('copy');
    };

    return (
      <>
        <Controller
          name={name}
          control={control}
          rules={rules}
          value={value}
          defaultValue={defaultValue}
          render={({ field: { onChange, onFocus, onBlur, onSearch, value, ref } }) => (
            <>
              <Select
                ref={ref}
                showSearch
                showArrow
                mode={mode}
                placeholder={placeholder}
                optionFilterProp="children"
                onSearch={onSearch}
                onFocus={onFocus}
                defaultValue={defaultValue}
                value={value || item.value || defaultValue}
                disabled={disabled}
                allowClear={allowClear}
                onBlur={onBlur}
                style={{ width: '100%', margin: '5px 0px', borderRadius: '10px' }}
                onSelect={onSelect ? (e) => onSelect(e, name) : onChange}
                onChange={onChangeCustom ? onChangeCustom : onChange}
                onClear={onClear}
                dropdownMatchSelectWidth={dropdownMatchSelectWidth}
                notFoundContent={null}>
                {options.length > 0 &&
                  options.map((_option, _idxO) => {
                    return (
                      <Select.Option key={Number(_idxO)} others={_option.others || null} value={_option.value}>
                        {_option.label}
                      </Select.Option>
                    );
                  })}
              </Select>
              {item.copied ? (
                <CopyToClipboard
                  text={item.name === 'domainName' ? `${getValues('mail')}${value}` : value}
                  onCopy={() => onCopy()}
                  onMouseLeave={() => onUnCopy()}>
                  <Tooltip title={text}>
                    {copied ? (
                      <CheckCircleOutlined
                        style={{
                          position: 'absolute',
                          marginTop: '15px',
                          marginLeft: item.random ? '-50px' : '-46px',
                          color: '#009000',
                          fontSize: '16px',
                        }}
                      />
                    ) : (
                      <CopyOutlined
                        style={{
                          position: 'absolute',
                          marginTop: '15px',
                          marginLeft: item.random ? '-50px' : '-46px',
                          color: '#007bff',
                          fontSize: '16px',
                        }}
                      />
                    )}
                  </Tooltip>
                </CopyToClipboard>
              ) : (
                ''
              )}
              {item.random ? (
                <Tooltip title={textRandom}>
                  <SyncOutlined
                    // spin={item.spinRandom}
                    style={{ position: 'absolute', marginTop: '15px', marginLeft: '-24px', color: '#fd7e14' }}
                    onClick={() => item.onRandom(item.name)}
                  />
                </Tooltip>
              ) : (
                ''
              )}
            </>
          )}
        />
      </>
    );
  },
  (prev, next) =>
    compareRender(prev, next) && JSON.parse(JSON.stringify(prev.item)) === JSON.parse(JSON.stringify(next.item)) && compareRender(prev, next, 'item'),
);

export const SelectDropdownBanner = React.memo(
  ({ item, onSelect, control, setValue }) => {
    return (
      <>
        <Controller
          name={item.name}
          control={control}
          rules={item.rules}
          value={item.value}
          //defaultValue={item.defaultValue}
          render={({ onChange, onFocus, onBlur, onSearch, value }) => (
            <Select
              showSearch
              showArrow
              placeholder={item.placeholder}
              optionFilterProp="children"
              onSearch={onSearch}
              onFocus={onFocus}
              defaultValue={item.defaultValue}
              value={value || item.defaultValue}
              disabled={item.disabled}
              allowClear={item.allowClear}
              onBlur={onBlur}
              style={{ width: '100%', margin: '5px 0px', borderRadius: '10px' }}
              onChange={item.onSelect ? (e) => item.onSelect(e, item.name) : onChange}
              onSelect={(val, option) => onSelect && onSelect({ name: item.name, option })}
              // filterOption={(input, option) =>
              // 	option.children.indexOf(input.toLowerCase()) >= 0
              // }
              notFoundContent={null}>
              {!!item.options &&
                item.options.map((_option, _idxO) => {
                  return (
                    <Select.Option key={Number(_idxO)} others={_option.others || null} value={_option.value}>
                      {_option.label}
                    </Select.Option>
                  );
                })}
            </Select>
          )}
        />
      </>
    );
  },
  (prevProps, nextProps) => {
    return false;
  },
  //  [prevProps.value[prevProps.item.options] !== nextProps.value[nextProps.item.options]]
);

export const TreeSelectDropdown = React.memo(
  ({ item, onSelect, control, setValue, options, onChangeCustom }) => {
    return (
      <>
        <Controller
          name={item.name}
          control={control}
          rules={item.rules}
          // value={item.value}
          defaultValue={item.defaultValue}
          render={({ onChange, onFocus, onBlur, onSearch, value }) => (
            <TreeSelect
              treeData={item.options}
              value={item.value}
              onChange={item.onChangeCustom}
              treeCheckable={true}
              showCheckedStrategy={SHOW_PARENT}
              placeholder={`กรุณาเลือก${item.label}`}
              style={{
                width: '100%',
                margin: '5px 0px',
              }}
            />
          )}
        />
      </>
    );
  },
  (prevProps, nextProps) => {
    return false;
  },
  //  [prevProps.value[prevProps.item.options] !== nextProps.value[nextProps.item.options]]
);

export const SelectDropdownDefault = React.memo(
  ({ item, control, getValues }) => {
    const { name, rules, value, defaultValue, placeholder, disabled, allowClear, onSelect, onChangeCustom, mode, options, onClear } = item;

    const [copied, setCopied] = useState(false);
    const [text, setText] = useState('copy');
    const [textRandom, setTextRandom] = useState('สุ่มใหม่');

    const onCopy = () => {
      setCopied(true);
      setText('copied!');
    };

    const onUnCopy = () => {
      setCopied(false);
      setText('copy');
    };

    return (
      <>
        <Controller
          name={name}
          control={control}
          rules={rules}
          value={value}
          defaultValue={defaultValue}
          render={({ field: { onChange, onFocus, onBlur, onSearch, value } }) => (
            <>
              <Select
                showSearch
                showArrow
                mode={mode}
                placeholder={placeholder}
                optionFilterProp="children"
                onSearch={onSearch}
                onFocus={onFocus}
                defaultValue={defaultValue}
                value={item.value || defaultValue}
                disabled={disabled}
                allowClear={allowClear}
                onBlur={onBlur}
                style={{ width: '100%', margin: '5px 0px', borderRadius: '10px' }}
                onSelect={onSelect ? (e) => onSelect(e, name) : onChange}
                onChange={onChangeCustom ? onChangeCustom : onChange}
                onClear={onClear}
                notFoundContent={null}>
                {options.length > 0 &&
                  options.map((_option, _idxO) => {
                    return (
                      <Select.Option key={Number(_idxO)} others={_option.others || null} value={_option.value}>
                        {_option.label}
                      </Select.Option>
                    );
                  })}
              </Select>
              {item.copied ? (
                <CopyToClipboard
                  text={item.name === 'domainName' ? `${getValues('mail')}${value}` : value}
                  onCopy={() => onCopy()}
                  onMouseLeave={() => onUnCopy()}>
                  <Tooltip title={text}>
                    {copied ? (
                      <CheckCircleOutlined
                        style={{
                          position: 'absolute',
                          marginTop: '15px',
                          marginLeft: item.random ? '-50px' : '-46px',
                          color: '#009000',
                          fontSize: '16px',
                        }}
                      />
                    ) : (
                      <CopyOutlined
                        style={{
                          position: 'absolute',
                          marginTop: '15px',
                          marginLeft: item.random ? '-50px' : '-46px',
                          color: '#007bff',
                          fontSize: '16px',
                        }}
                      />
                    )}
                  </Tooltip>
                </CopyToClipboard>
              ) : (
                ''
              )}
              {item.random ? (
                <Tooltip title={textRandom}>
                  <SyncOutlined
                    // spin={item.spinRandom}
                    style={{ position: 'absolute', marginTop: '15px', marginLeft: '-24px', color: '#fd7e14' }}
                    onClick={() => item.onRandom(item.name)}
                  />
                </Tooltip>
              ) : (
                ''
              )}
            </>
          )}
        />
      </>
    );
  },
  (prev, next) =>
    compareRender(prev, next) && JSON.parse(JSON.stringify(prev.item)) === JSON.parse(JSON.stringify(next.item)) && compareRender(prev, next, 'item'),
);

// Select with the most basic antd
export const SelectGroup = React.memo(
  ({ item, control, getValues, dropdownMatchSelectWidth = true }) => {

    const { name, rules, value, defaultValue, placeholder, disabled, allowClear, onSelect, onChangeCustom, mode, options, onClear } = item;

    const [copied, setCopied] = useState(false);
    const [text, setText] = useState('copy');
    const [textRandom, setTextRandom] = useState('สุ่มใหม่');

    const onCopy = () => {
      setCopied(true);
      setText('copied!');
    };

    const onUnCopy = () => {
      setCopied(false);
      setText('copy');
    };

    return (
      <>
        <Controller
          name={name}
          control={control}
          rules={rules}
          value={value}
          defaultValue={defaultValue}
          render={({ field: { onChange, onFocus, onBlur, onSearch, value, ref } }) => (
            <>
              <Select
                ref={ref}
                showSearch
                showArrow
                mode={mode}
                placeholder={placeholder}
                optionFilterProp="children"
                onSearch={onSearch}
                onFocus={onFocus}
                defaultValue={defaultValue}
                value={value || item.value || defaultValue}
                disabled={disabled}
                allowClear={allowClear}
                onBlur={onBlur}
                style={{ width: '100%', margin: '5px 0px', borderRadius: '10px' }}
                onSelect={onSelect ? (e) => onSelect(e, name) : onChange}
                onChange={onChangeCustom ? onChangeCustom : onChange}
                onClear={onClear}
                dropdownMatchSelectWidth={dropdownMatchSelectWidth}
                notFoundContent={null}
              >
                {options.length > 0 &&
                  options.map((_option, _idxO) => (
                    <Select.OptGroup key={_idxO} label={<Text type="secondary">{_option.label}</Text>}>
                      {_option.options.map((el, idx) => (
                        <Select.Option key={el.value} value={el.value}>
                          {el.label}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  ))}
              </Select>
              {item.copied ? (
                <CopyToClipboard
                  text={item.name === 'domainName' ? `${getValues('mail')}${value}` : value}
                  onCopy={() => onCopy()}
                  onMouseLeave={() => onUnCopy()}>
                  <Tooltip title={text}>
                    {copied ? (
                      <CheckCircleOutlined
                        style={{
                          position: 'absolute',
                          marginTop: '15px',
                          marginLeft: item.random ? '-50px' : '-46px',
                          color: '#009000',
                          fontSize: '16px',
                        }}
                      />
                    ) : (
                      <CopyOutlined
                        style={{
                          position: 'absolute',
                          marginTop: '15px',
                          marginLeft: item.random ? '-50px' : '-46px',
                          color: '#007bff',
                          fontSize: '16px',
                        }}
                      />
                    )}
                  </Tooltip>
                </CopyToClipboard>
              ) : (
                ''
              )}
              {item.random ? (
                <Tooltip title={textRandom}>
                  <SyncOutlined
                    // spin={item.spinRandom}
                    style={{ position: 'absolute', marginTop: '15px', marginLeft: '-24px', color: '#fd7e14' }}
                    onClick={() => item.onRandom(item.name)}
                  />
                </Tooltip>
              ) : (
                ''
              )}
            </>
          )}
        />
      </>
    );
  },
  (prev, next) =>
    compareRender(prev, next) && JSON.parse(JSON.stringify(prev.item)) === JSON.parse(JSON.stringify(next.item)) && compareRender(prev, next, 'item'),
);

export const SelectOptionGroup = (props) => {
  const { item, ...stuff } = props

  const { name, rules, value, defaultValue, placeholder, disabled, allowClear, onSelect, onChangeCustom, mode, options, onClear, onSearch, onFocus, onBlur, onChange } = item;
  const [searchValue, setSearchValue] = useState(item?.value);

  const filteredOptions = options.map(group => ({
    ...group,
    options: group.options.filter(option =>
      option.label.toLowerCase().includes(searchValue.toLowerCase())
    ),
  }));
  return (
    <>
      <Input
        type="text"
        placeholder="Search options..."
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
      />
      <Select
        showSearch
        showArrow
        mode={mode}
        placeholder={placeholder}
        optionFilterProp="children"
        onSearch={onSearch}
        onFocus={onFocus}
        defaultValue={defaultValue}
        value={value || item.value || defaultValue}
        disabled={disabled}
        allowClear={allowClear}
        onBlur={onBlur}
        style={{ width: '100%', margin: '5px 0px', borderRadius: '10px' }}
        onSelect={onSelect ? (e) => onSelect(e, name) : onChange}
        onChange={onChangeCustom ? onChangeCustom : onChange}
        onClear={onClear}
        notFoundContent={null}
        dropdownStyle={{ height: 'auto' }}
      >
        {filteredOptions.map(group => (
          <OptGroup label={group.label} key={group.label}>
            {group.options.map(option => (
              <Option value={option.value} key={option.value}>
                {option.label}
              </Option>
            ))}
          </OptGroup>
        ))}
      </Select>
    </>

  )

}

// export const SelectMultiple = React.memo(
//   ({item, onSelect, control}) => {
//     return (
//       <>
//         <Controller
//           name={item.name}
//           control={control}
//           rules={item.rules}
//           value={item.value}
//           render={({field: {onChange, onFocus, onBlur, onSearch, value}}) => (
//             <Select
//               mode="multiple"
//               showArrow={item.disabled || false}
//               defaultValue={item.defaultValue}
//               options={item.options}
//               value={value}
//               disabled={item.disabled}
//               placeholder={item.placeholder}
//               maxTagCount={item.maxTagCount}
//               onChange={(e) => onSelect && onSelect( e,item.name)}
//               //onChange={(val) => item.onChange && item.onChange(val) || onChange}
//               style={{width: '100%', margin: '5px 0px', borderRadius: 4}}
//             />
//           )}
//         />
//       </>
//     );
//   },
//   (prevProps, nextProps) => {
//     return false;
//   },
// );
