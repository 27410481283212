// /* eslint no-use-before-define: 0 */
import React, { useEffect, useState } from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import { Modal, Card, Spin, Row, Col, Input } from 'antd';
import { SwapOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { SearchLayout } from '../../../components/pageLayout/SearchLayout';
import { TableLayout } from '../../../components/pageLayout/TableLayout'
import { PickNoRecieveSearchForm } from '../../../constants/global/SearchForm'
import { LineItemsNonSerialRecieveColumn } from '../../../constants/global/columnTableForm'
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../../components/buttons';
import { POST, GET, GET_JOURNAL_ITEM_NON_SERIAL, RECHECK_ITEMS_NON_SERIAL, CONFIRM_RECIEVE_ITEMS_NON_SERIAL, } from '../../../services';
import moment from 'moment';
import { EditableCellReciveStockNonSerial } from '../form/editCellReciveStockNonSerial'

const RecieveItemsNonSerial = () => {
  const [loading, setLoading] = useState(false)
  const [dataSearch, setDataSearch] = useState([])
  const [dataSource, setDataSource] = useState([])
  const [remarkDefault, setRemark] = useState("")
  const [userCreate, setUserCreate] = useState("")
  const [userUpdate, setUserUpdate] = useState("")
  const [docNo, setDocNo] = useState("")

  useEffect(() => {

  }, [remarkDefault])

  const getJournalItems = async (data) => {
    setLoading(true)
    try {
      const res = await GET(`${GET_JOURNAL_ITEM_NON_SERIAL}?documentNo=${data.document_no}&page=receive`)
      const { success, result } = res
      if (success) {
        const { branch_from_name, branch_to_name, document_no, remark, user_create, user_update, create_date, update_date, journal_line } = result.journalItems
        const dataSetSearch = [
          { label: "Pick No.", value: document_no },
          { label: "สาขาต้นทาง", value: branch_from_name },
          { label: "สาขาปลายทาง", value: branch_to_name },
        ]

        const data = journal_line.map((el, i) => {
          const { item_code, line_no, item_name } = el
          return {
            key: i + 1,
            item_code,
            item_name,
            line_no,
            qty: 0,
            status: ''
          }
        })
        setRemark(remark)
        setDocNo(document_no)
        setUserCreate(`${user_create.name} ${moment(create_date).format("YYYY-MM-DD HH:mm")}`)
        setUserUpdate(`${user_update.name || ""} ${update_date ? moment(update_date).format("YYYY-MM-DD HH:mm") : ""}`)
        setDataSearch(dataSetSearch)
        setDataSource(data)

      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false)
    }
  }

  const onChange = (value, ref, key) => {

    if (ref === "remark") {
      setRemark(value)
    } else if (ref === "document_no") {
      setDocNo(value)
      if (value.split("").length === 9) {
        getJournalItems(value)
      }

    } else {
      dataSource.forEach((el) => {
        if (el.key === key) {
          if (ref === "qty") {
            el[ref] = value
          }

        }
      })
    }
  }




  const reCheckItems = async () => {
    const items = dataSource.map((el) => {
      const { item_code, qty } = el
      return { item_code, qty: +qty }
    })

    try {
      const res = await POST(RECHECK_ITEMS_NON_SERIAL, { documentNo: docNo, items })
      const { success, result } = res
      if (success) {
        const data = result.items.map((val, k) => {
          return {
            ...dataSource[k],
            _id: val._id,
            status: val.status
          }
        })
        setDataSource(data)
      }

    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  }

  const confirmRecieveItems = async () => {
    setLoading(true)

    const dataSend = {
      documentNo: docNo,
      remark: remarkDefault,
      items: dataSource.map((el) => {
        const { _id, line_no, item_code, item_name, qty, status } = el
        return { _id, line_no, item_code, item_name, qty, status }
      })
    }

    try {
      const res = await POST(CONFIRM_RECIEVE_ITEMS_NON_SERIAL, dataSend)
      const { success, message } = res;
      if (success) {

        Modal.success({
          title: message,
          afterClose: () => {
            window.location.reload();
          },
        });

      }

    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false)
    }
  }

  const validateData = (ref) => {
    let status = []
    let qty = []

    dataSource.forEach((el) => {
      if (!el.qty || el.qty === 0) {
        qty.push({ key: el.key })
      }

      if (el.status === 'NOT MATCH' || el.status === '') {
        status.push({ key: el.key })
      }

    })


    if (ref === 'confirm') {
      if (status.length > 0) {
        Modal.error({
          title: "สถานะต้องเป็น RECEIVE เท่านั้น"
        })
      } else {
        onConfirm()
      }
    } else {
      if (qty.length > 0) {
        Modal.error({
          title: "กรุณากรอกจำนวนสินค้าที่ท่านได้รับ แล้วกดตรวจสอบ"
        })
      } else {
        reCheckItems()
      }
    }
  }


  const onConfirm = () => {
    Modal.confirm({
      title: 'ยืนยันการรับสินค้าเข้าสต็อก',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        confirmRecieveItems();
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const detailUser = [{ label: "ผู้สร้างเอกสาร", value: userCreate }, { label: "แก้ไขล่าสุดโดย", value: userUpdate }]
  return (
    <Wrapper>
      <Card>
        <SearchLayout
          props={{
            title: 'ตรวจสอบสินค้าที่รับ',
            icon: <SwapOutlined />,
            spanSearch: 24,
            formSearch: PickNoRecieveSearchForm({ data: docNo || "", fn: { onChange } }),
            onSearch: (data) => getJournalItems(data),
          }}
        />
        <Spin tip="Loading..." spinning={loading}>
          {dataSearch.length > 0 ?
            <div style={{ marginTop: "20px", padding: "12px" }}>
              <Row gutter={[16, 16]} style={{ marginBottom: "12px", fontSize: "16px" }} justify="left">
                {
                  dataSearch.map((el, k) => {
                    return <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: k === 3 ? 8 : 4 }} lg={{ span: k === 3 ? 8 : 4 }} key={k}><span style={{ fontWeight: "800" }}>{`${el.label} : `}</span><br />{`${el.value}`}</Col>
                  })
                }
              </Row>
              <Row gutter={[16, 16]} justify="left">
                <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }}>
                  <Input.TextArea maxLength={500} rows={4} placeholder="ระบุข้อความไม่เกิน 500 ตัวอักษร" value={remarkDefault} onChange={(e) => onChange(e.target.value, "remark")} />
                </Col>
              </Row>
              <Row gutter={[16, 16]} justify="left" style={{ marginTop: "12px" }}>
                {
                  detailUser.map((val, i) => {
                    return (
                      <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 8 }} lg={{ span: 8 }} key={i}>
                        <span style={{ fontWeight: "800" }}>{`${val.label} : `}</span>{`${val.value}`}
                      </Col>
                    )
                  })
                }
              </Row>
              <TableLayout
                props={{
                  title: 'รายการสินค้า',
                  columns: LineItemsNonSerialRecieveColumn(onChange),
                  dataSource: dataSource,
                  components: { body: { cell: EditableCellReciveStockNonSerial } },
                  footerContent: (
                    <ContainerButton center>
                      <ButtonTheme useFor="RE_CHECK" title={'ตรวจสอบ'} loading={loading} onClick={() => validateData('recheck')} />
                      <ButtonTheme useFor="CONFIRM" title={'คอนเฟิร์ม'} onClick={() => validateData('confirm')} />
                    </ContainerButton>
                  )
                }}
              />
            </div> : null
          }
        </Spin>
      </Card>

    </Wrapper>
  );
};

export default RecieveItemsNonSerial
