import {Checkbox} from 'formik-antd';
import {useFormikContext} from 'formik';
import {useState} from 'react';
import { POST, RAMDOM_MAIL} from '../../services/index';
import {decryptString} from '../../functions/fn';

const MailIcloudOld = (props) => {
  const ctx = useFormikContext();
  const [messageError, setMessageError] = useState('');
  const onChange = async (e) => {
    // setTimeout(() => {
    if (e.target.checked) {
      const id_card = decryptString(localStorage.getItem('id_card'));
     
      const obj = {
        useOldMail: true,
        idCard: id_card,
      };
      try {
        let res = await POST(RAMDOM_MAIL, obj);
        const {success, result} = res;
        if (success) {
          ctx.setFieldValue('contract.contract.mail', result.mail);
          // setMessageError(' ( ไม่มีบัญชีนี้ )');
        }
      } catch (err) {
        const {message} = err;
        setMessageError(message);
   
        // Modal.error({
        //   title: message,
        //   content: err || '',
        // });
      }
    } else {
      setMessageError('');
      ctx.setFieldValue('contract.contract.mail', '');
    }
  };

  return (
    <><Checkbox {...props} onChange={onChange}>
    {props.label}
     
   
  </Checkbox>
  <div><span style={{color: 'red'}}>{messageError}</span></div> </>
    
    
  );
};

export default MailIcloudOld;
