import { auth } from "./firebase";
import { AUTH_ERROR } from "../redux/types/auth";

export const verifyEmail = (email) => {
	const isDplusEmail = /^[\w-.]+@dplusonline\.net$/g.test(email);
	const isDigitalHomeEmail = /^[\w-.]+@digital2home\.com$/g.test(email);
	const isAllowedEmail = ["dappmaker01@gmail.com", "dappmaker02@gmail.com", "dappmaker03@gmail.com", "newnwan@gmail.com"].includes(email);
	const isAllow = isDplusEmail || isDigitalHomeEmail || isAllowedEmail;
	return isAllow
};

export const accessDenied = async (dispatch, { error, errorStatus }) => {
	await auth.signOut();
	localStorage.removeItem("token");
	return dispatch({ type: AUTH_ERROR, payload: { error, errorStatus } });
};
