import PropTypes from 'prop-types';
import { Table, Td, Th } from './styled-component';

const DownPaymentRemarkLogTable = (props) => {
  const { logs } = props;
  logs.sort((a, b) => Number(new Date(b.created_date)) - Number(new Date(a.created_date)))
  return (
    <>
      <Table style={{ width: '100%', alignSelf: 'center', marginBlock: '3%' }}>
        <thead>
          <tr>
            <Th>วันที่บันทึก</Th>
            <Th>หมายเหตุ</Th>
            <Th>บันทึกโดย</Th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log) => (
            <tr key={log?._id}>
              <Td style={{ width: '15%' }}>{new Intl.DateTimeFormat('th-TH', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(log?.created_date))}</Td>
              <Td>{log?.remark}</Td>
              <Td style={{ width: '30%' }}>{log?.user_update}</Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

DownPaymentRemarkLogTable.propTypes = {
  logs: PropTypes.array,
};

DownPaymentRemarkLogTable.defaultProps = {
  logs: [],
};

export default DownPaymentRemarkLogTable;
