import styled from 'styled-components';

export const Div = styled.div`
  display: flex;
  min-width: 1000px;
  // height: calc(100vh - 105px);
`;

export const Background = styled.div`
  background-color: ${(props) => (props.color ? props.color : 'white')};
  padding: 25px;
  border-radius: 0;
  width: 100%;
  height: 100%
`;

export const Table = styled.table`
  width: 100%;
  & tr {
    height: 3rem;
  }
`;

export const Th = styled.th`
  text-align: ${(props) => (props.left ? 'left' : 'center')};
  border-right: ${(props) => (props.border_right ? '1px solid black' : '0')};
  border-bottom: ${(props) => (props.border_bottom ? '1px solid black' : '0')};
  background-color: ${(props) => (props.highlight ? '#ddd' : 'none')};
`
export const Td = styled.td`
  text-align: ${(props) => (props.left ? 'left' : 'center')};
  border-right: ${(props) => (props.border_right ? '1px solid black' : '0')};
  background-color: ${(props) => (props.highlight ? '#ddd' : 'none')};
`

export const WrapTable = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

export const HeaderInfo = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const FormUpdateWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Button = styled.button`
  padding: 5px 15px 5px 15px;
  border: 0;
  border-radius: 3px;
  margin-right:10px;
  background-color: transparent;
  &:focus {
    outline: 0;
  }

  &.slip {
    padding: 0;
    margin: 0;
    cursor: pointer;
  }

  &.search {
    background-color: #3443eb;
    color: #fff;
    height: 80%;
  }

  &.search:hover {
    background-color: #303ed9;
  }

  &.clear {
    border: 1px solid #3443eb;
    color: #3443eb;
    height: 80%;
  }

  &.clear:disabled {
    background-color: #ddd;
    border: 0;
    color: #999;
  }

  &.edit {
    background-color: #3443eb;
    color: #fff;
  }

  &.cancel-edit {
    border: 1px solid #E01E01;
    color: #E01E01;
  }

  &.save {
    background-color: #01D827;
    color: #fff;
  }

`
export const ImagePlaceholder = styled.div`
  height: 100%;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #ddd;
`