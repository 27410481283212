import {render} from 'enzyme';
import {ButtonTheme} from '../../../components/buttons';

export const OtherInfoColumn = (onPreview) => {
  return [
    {
      title: 'วันที่บันทึก',
      dataIndex: 'createDate',
      key: 'createDate',
      width: '150px',
    },
    {
      title: 'รูปภาพ',
      dataIndex: 'image',
      key: 'image',
      width: '150px',
      render: (_text, record) => {
        return record?.image.length > 0 ? <ButtonTheme useFor="VIEW" title="Preview" onClick={() => onPreview(record)} /> : '-';
      },
    },
    {
      title: 'ประเภท',
      dataIndex: 'type',
      key: 'type',
      width: '200px',
    },
    {
      title: 'ข้อมูลอื่นๆ',
      dataIndex: 'remark',
      key: 'remark',
      width: '300px',
      render: (text, _record) => {
        if (text.includes('http')) {
          return (
            <a href={text} target="_blank" rel="noreferrer">
              {text}
            </a>
          );
        }
        return text;
      },
    },
    {
      title: 'บันทึกโดย',
      dataIndex: 'createBy',
      key: 'createBy',
      width: '150px',
    },
  ];
};
