import React from 'react'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { SpinnersStyled } from './SpinnersStyled'

const Spinners = (props) => {
  const { showIcon, children, text, size, otherProps } = props

  const antIcon2 = <Loading3QuartersOutlined style={{ fontSize: size }} spin />
  return (
    <SpinnersStyled>
      <Spin
        indicator={antIcon2}
        spinning={showIcon}
        wrapperClassName="spinner-title"
        tip={text || 'กำลังโหลด...'}
        {...otherProps}
      >
        <div className="content">{children}</div>
      </Spin>
    </SpinnersStyled>
  )
}

export default Spinners

Spinners.propTypes = {
  showIcon: PropTypes.bool,
  size: PropTypes.number,
  text: PropTypes.string
}

Spinners.defaultProps = {
  showIcon: true,
  size: 50
}
