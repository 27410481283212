import * as TYPES from '../types/contract';
import _ from 'lodash'

const contractState = {
    loading: false,
    error: null,
    contracts: []
};

const periodsState = {
    loading: false,
    error: null,
    periods: []
}

const exportState = {
    loading: false,
    error: null,
    file: null
};

const listState = {
    loading: false,
    error: null,
    size: 10,
    page: 1,
    contracts: [],
    totalItem: 0,
    totalPage: 0
};

const infoState = {
    loading: false,
    error: null,
    info: null
};

const updateState = {
    loading: false,
    error: null,
    message: null
};

export const contractListReducer = (state = contractState, { type, payload }) => {
    switch (type) {
        case TYPES.CONTRACT_LIST_REQ:
            return { ...state, loading: true };
        case TYPES.CONTRACT_LIST_SUCCESS:
            return { ...state, loading: false, error: null, contracts: payload.data.data };
        case TYPES.CONTRACT_LIST_FAIL:
            return { ...state, loading: false, error: payload, contracts: [] };
        default:
            return state;
    }
}

export const periodListReducer = (state = periodsState, { type, payload }) => {
    switch (type) {
        case TYPES.CONTRACT_PERIOD_LIST_REQ:
            return { ...state, loading: true };
        case TYPES.CONTRACT_PERIOD_LIST_SUCCESS:
            return { ...state, loading: false, error: null, periods: payload.data.data.data };
        case TYPES.CONTRACT_PERIOD_LIST_FAIL:
            return { ...state, loading: false, error: payload, periods: [] };
        case TYPES.CONTRACT_PERIOD_LIST_CLEAR:
            return { ...state, loading: false, error: null, periods: [] };
        default:
            return state;
    }
}


export const exportReducer = (state = exportState, { type, payload }) => {
    switch (type) {
        case TYPES.CONTRACT_EXPORT_REQ:
            return { ...state, loading: true };
        case TYPES.CONTRACT_EXPORT_SUCCESS:
            return { ...state, loading: false, error: null, file: payload };
        case TYPES.CONTRACT_EXPORT_FAIL:
            return { ...state, loading: false, error: payload, file: null };
        default:
            return state;
    }
};

export const exportReciveReducer = (state = exportState, { type, payload }) => {
    switch (type) {
        case TYPES.CONTRACT_RECIVE_EXPORT_REQ:
            return { ...state, loading: true };
        case TYPES.CONTRACT_RECIVE_EXPORT_SUCCESS:
            return { ...state, loading: false, error: null, file: payload };
        case TYPES.CONTRACT_RECIVE_EXPORT_FAIL:
            return { ...state, loading: false, error: payload, file: null };
        default:
            return state;
    }
};

export const contractListTradesysReducer = (state = listState, { type, payload }) => {
    switch (type) {
        case TYPES.CONTRACT_LIST_TRADESYS_REQ:
            return { ...state, loading: true };
        case TYPES.CONTRACT_LIST_TRADESYS_SUCCESS:
            // return { ...state, loading: false, error: null, contracts: payload.data.data.contracts, totalItem: payload.data.data.total_item, totalPage: payload.data.data.total_page, contractInfo: _.keyBy(payload.data.data.contract_info,'contract_no') };
            return { ...state, loading: false, error: null, totalItem: payload.data.data.total_item, totalPage: payload.data.data.total_page, contractInfo: payload.data.data.contract_info };
        case TYPES.CONTRACT_LIST_TRADESYS_FAIL:
            return { ...state, loading: false, error: payload };
        default:
            return state;
    }
};

export const contractInfoTradesysReducer = (state = infoState, { type, payload }) => {
    switch (type) {
        case TYPES.CONTRACT_INFO_REQ:
            return { ...state, loading: true };
        case TYPES.CONTRACT_INFO_SUCCESS:
            return { ...state, loading: false, error: null, info: payload.data.data };
        case TYPES.CONTRACT_INFO_FAIL:
            return { ...state, loading: false, error: payload };
        default:
            return state;
    }
};

export const contractUpdateTradesysReducer = (state = updateState, { type, payload }) => {
    switch (type) {
        case TYPES.CONTRACT_UPDATE_REQ:
            return { ...state, loading: true };
        case TYPES.CONTRACT_TERMINATED_REQ:
            return { ...state, loading: true };
        case TYPES.CONTRACT_IMPOUNDED_REQ:
            return { ...state, loading: true };
        case TYPES.CONTRACT_GRADE_UPDATE_REQ:
            return { ...state, loading: true };
        case TYPES.CONTRACT_UPDATE_SUCCESS:
            return { ...state, loading: false, error: null, message: payload.data.message };
        case TYPES.CONTRACT_TERMINATED_SUCCESS:
            return { ...state, loading: false, error: null, message: payload.data.message };
        case TYPES.CONTRACT_IMPOUNDED_SUCCESS:
            return { ...state, loading: false, error: null, message: payload.data.message };
        case TYPES.CONTRACT_GRADE_UPDATE_SUCCESS:
            return { ...state, loading: false, error: null, message: payload.data.message };
        case TYPES.CONTRACT_UPDATE_FAIL:
            return { ...state, loading: false, error: payload };
        case TYPES.CONTRACT_TERMINATED_FAIL:
            return { ...state, loading: false, error: payload };
        case TYPES.CONTRACT_IMPOUNDED_FAIL:
            return { ...state, loading: false, error: payload };
        case TYPES.CONTRACT_GRADE_UPDATE_FAIL:
            return { ...state, loading: false, error: payload };
        default:
            return state;
    }
};

export const exportTerminateReducer = (state = exportState, { type, payload }) => {
    switch (type) {
        case TYPES.CONTRACT_EXPORT_TERMINATED_REQ:
            return { ...state, loading: true };
        case TYPES.CONTRACT_EXPORT_TERMINATED_SUCCESS:
            return { ...state, loading: false, error: null, file: payload };
        case TYPES.CONTRACT_EXPORT_TERMINATED_FAIL:
            return { ...state, loading: false, error: payload, file: null };
        default:
            return state;
    }
};