import React from 'react';
import { DatePicker } from 'antd';
import { Controller } from 'react-hook-form';
import { compareRender } from '../../functions/fn';

const { RangePicker } = DatePicker;

export const DatePickerForm = React.memo(({ item, control, handleChange }) => {
  return (
    <>
      <Controller
        name={item.name}
        control={control}
        rules={item.rules}
        value={item.value}
        defaultValue={item.defaultValue}
        // render={(props) => (
        render={({ field: { value } }) => (

          <DatePicker
            allowClear={item.allowClear}
            placeholder={item.placeholder || ''}
            disabledDate={item.disabledDate}
            disabled={item.disabled}
            value={item.value ? item.value : value}
            defaultValue={item.defaultValue}
            style={{ width: '100%', marginTop: 5 }}
            onChange={item.onChange ? (e) => item.onChange(e, item.name) : (e) => handleChange(e, item)}
            format={item.format}
          // onClear={item.onClear}
          // onChange={(errors[item.name]) ? clearErrors(item.name) && onChange : onChange}
          />
        )}
      />
    </>
  );
}, (prev, next) =>
  compareRender(prev, next) &&
  JSON.parse(JSON.stringify(prev.item)) ===
  JSON.parse(JSON.stringify(next.item)) &&
  compareRender(prev, next, 'item')
);


export const RangPickerTheme = React.memo(({ item, control, handleChange }) => {
  const { showTime, format, disabled, disabledTime, placeholder, onChange, name, rules, defaultValue } = item

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={(props) => (
          <RangePicker
            allowClear={false}
            showTime={showTime || true}
            format={format || "YYYY-MM-DD HH:mm:ss"}
            disabled={disabled || false}
            disabledTime={disabledTime || false}
            onChange={onChange ? (e) => item.onChange(e, item.name) : (e) => handleChange(e, item)}
            value={props.field.value}
            placeholder={placeholder || []}
            style={{ width: '100%', marginTop: 5 }}
          />

        )}
      />
    </>
  );
}, compareRender);
