/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import LoginScreen from './screens/login/Login';
import Routes from './routes/Route';
import store from './redux';
import { ToastProvider } from './utils/toastProvider';
// import { SocketProvider } from './utils/socketProvider';
import ErrorBoundary from './components/error-boundaries';

const App = () => {

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router>
            <ToastContainer />
            <Switch>
              <ToastProvider>
                {/* <SocketProvider> */}
                <Route exact path="/" component={LoginScreen} />
                <Routes component={Routes} />
                {/* </SocketProvider> */}
              </ToastProvider>
            </Switch>
          </Router>
        </MuiPickersUtilsProvider>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
