import React, {useState, useEffect, useRef} from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import * as _ from 'lodash';
import {Modal, Card, Spin} from 'antd';
import {BookOutlined} from '@ant-design/icons';
import {PageLayout} from '../../../components/pageLayout/PageLayout';
import {EditSecondHandPriceSearchForm} from '../../../constants/global/SearchForm';
import {EditSecondHandPriceColumn} from '../../../constants/global/columnTableForm';
import {
  GET,
  GET_SECOND_HAND_PRODUCT_OPTION,
  GET_SECOND_HAND_PRICE_DATA,
  PUT,
  EDIT_SECOND_HAND_PRICE,
  GET_PRODUCT_MODEL_ALL,
  GET_PRODUCT_STOARGE_ALL,
  GET_PRODUCT_COLOR_ALL,
} from '../../../services/index';
import {convertStrToFormat} from '../../../functions/fn';
import {ButtonTheme} from '../../../components/buttons';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {color} from '../../../resources/color';
import {AddPriceSecondHandModal} from '../modal/AddPriceSecondHandModal';
import {useGetReportEditSecondHandExcel} from './api';

const EditSecondHandPriceScreen = () => {
  const pageRef = useRef(1);
  const sizeRef = useRef(10);

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [tempDataSource, setTempDataSource] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [, setTotalPage] = useState(0);
  const [productNameState, setProductNameState] = useState(null);
  const [defaultValue] = useState({});
  const [optionProduct, setOptionProduct] = useState([]);
  const [optionCondition] = useState([]);
  const [optionProductModelAll, setOptionProductModelAll] = useState([]);
  const [optionProductStorageAll, setOptionProductStorageAll] = useState([]);
  const [optionProductColorAll, setOptionProductColorAll] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleAddModal, setVisibleAddModal] = useState(false);
  const [valueSearch, setValueSearch] = useState({});
  const [enableEdit, setEnableEdit] = useState(false);
  const [editData, setEditData] = useState([]);

  const [resultReport, loadingReport, errorReport, setInputReport] = useGetReportEditSecondHandExcel();

  useEffect(() => {
    if (resultReport?.message === 'success' && resultReport?.url) {
      setTimeout(() => {
        window.open(resultReport?.url, '_blank', 'noopener,noreferrer');
        onClose();
      }, 1000);
    }
  }, [resultReport]);

  useEffect(() => {
    if (errorReport) {
      const message = errorReport?.response?.data?.errors;

      Modal.error({title: 'ดึงข้อมูลไม่สำเร็จ', content: message});
      onClose();
    }
  }, [errorReport]);

  useEffect(async () => {
    await getOptionSecndHandProduct();
    await getOptionProductModelAll();
    await getOptionStorageAll();
    await getOptionColorAll();
    await getDataEditSecondHandPrice({
      page: pageRef.current,
      size: sizeRef.current,
      product: '',
    });
  }, []);

  const createParams = (value) => {
    let params = [];
    let result = '';

    Object.keys(value).forEach((key) => {
      if (value[key]) params.push(key + '=' + value[key]);
    });

    if (params.length !== 0) result += params.join('&');

    return result;
  };

  const getOptionProductModelAll = async () => {
    try {
      let res = await GET(GET_PRODUCT_MODEL_ALL);
      const {success, result} = res;
      if (success) {
        setOptionProductModelAll(result);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({title: message, content: error || ''});
    } finally {
      setLoading(false);
    }
  };

  const getOptionStorageAll = async () => {
    try {
      let res = await GET(GET_PRODUCT_STOARGE_ALL);
      const {success, result} = res;
      if (success) {
        const data = result.filter((el) => el.value !== 'None');
        setOptionProductStorageAll(data);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({title: message, content: error || ''});
    } finally {
      setLoading(false);
    }
  };

  const getOptionColorAll = async () => {
    try {
      let res = await GET(GET_PRODUCT_COLOR_ALL);
      const {success, result} = res;
      if (success) {
        const data = result.filter((el) => el.value !== 'None');
        setOptionProductColorAll(data);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({title: message, content: error || ''});
    } finally {
      setLoading(false);
    }
  };

  const getOptionSecndHandProduct = async () => {
    try {
      let res = await GET(GET_SECOND_HAND_PRODUCT_OPTION);
      const {success, result} = res;
      if (success) {
        setOptionProduct(result);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({title: message, content: error || ''});
    } finally {
      setLoading(false);
    }
  };

  const getDataEditSecondHandPrice = async (value) => {
    value.page = value?.page || 1;
    value.size = value?.size || 10;

    pageRef.current = value.page;
    sizeRef.current = value.size;

    value.product = value.product || '';

    const {product} = value;
    setLoading(true);
    setProductNameState(product);
    setValueSearch(value);

    let params = createParams(value);

    try {
      const res = await GET(GET_SECOND_HAND_PRICE_DATA(params));
      const {success, data} = res;

      if (success) {
        setTotalPage(data.totalPage);
        setTotalSize(data.totalItem);

        const result = data.productSecondHandList.map((el) => {
          return {key: el._id, ...el};
        });

        const prepareDataToEdit = result.map((el) => {
          return {
            _id: el?._id,
            product_condition: {
              product_condition_1: el?.product_condition_1 || '',
              product_condition_2: el?.product_condition_2 || '',
              product_condition_3: el?.product_condition_3 || '',
              product_condition_4: el?.product_condition_4 || '',
            },
          };
        });
        setDataSource(result);
        // temp.current = _.cloneDeep(result)
        setTempDataSource(_.cloneDeep(result));

        setEditData(prepareDataToEdit);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({title: message, content: error || ''});
    } finally {
      setLoading(false);
    }
  };

  const onPageSizeChangeCustom = (pageData, sizeData) => {
    const sizeChange = sizeRef.current !== sizeData;
    if (enableEdit === true && pageRef.current !== pageData) {
      Modal.confirm({
        title: 'คำเตือน!!',
        content: 'ข้อมูลมีการแก้ไข ต้องการบันทึกข้อมูลก่อนเปลี่ยนหน้าหรือไม่',
        onOk() {
          updateSecondHandPrice(pageData);
          getDataEditSecondHandPrice({
            page: pageData,
            size: sizeRef.current,
            product: productNameState || '',
          });
        },
        okText: 'บันทึก',
        cancelText: 'ยกเลิก',
      });
    } else if (enableEdit === false) {
      if (sizeChange) {
        pageRef.current = 1;
      } else {
        pageRef.current = pageData;
      }
      sizeRef.current = sizeData;

      let product = productNameState;

      const params = {
        page: pageRef.current,
        size: sizeRef.current,
        product: product || '',
      };

      getDataEditSecondHandPrice(params);
    }
  };

  const onClearCustom = (setValue, formSearch) => {
    formSearch.map((val) => setValue(val.name, null));

    setProductNameState('');
    pageRef.current = 1;
    sizeRef.current = 10;
  };

  const onExportExcelReport = async () => {
    let params = null;

    if (productNameState === '') {
      params = ``;
    } else {
      if (productNameState) params += `&product=${productNameState}`;
      params = `?` + params.substring(1);
    }

    await setInputReport(params);
  };

  const validateEditData = (record) => {
    if (
      isValidText(record.product_condition_1) &&
      isValidText(record.product_condition_2) &&
      isValidText(record.product_condition_3) &&
      isValidText(record.product_condition_4)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isValidText = (text) => {
    if (text.toString().match(/^[a-zA-Z\s]+$/) || /^[ก-๏\s]+$/.test(text)) {
      return false;
    }
    return true;
  };

  const onClose = () => setVisibleModal(false);

  const onEditOnRow = () => setEnableEdit(true);

  const onConfirm = (ref) => {
    if (ref === 'CANCEL_EDIT') {
      Modal.confirm({
        title: 'ต้องการยกเลิกการแก้ไขราคา?',
        onOk() {
          onCancelEditOnRow();
        },
      });
    } else if (ref === 'EDIT') {
      let validateArr = [];

      editData.forEach((el) => {
        let validateValue = validateEditData(el.product_condition);
        validateArr.push(validateValue);
      });

      let finalValidate = validateArr.filter((el) => el === false);
      if (finalValidate.length > 0) {
        Modal.warning({
          title: 'กรุณากรอกเฉพาะตัวเลข',
        });
      } else if (finalValidate.length === 0) {
        Modal.confirm({
          title: 'ยืนยันการแก้ไข ใช่หรือไม่',
          onOk() {
            updateSecondHandPrice();
          },
          okText: 'บันทึก',
          cancelText: 'ยกเลิก',
        });
      }
    }
  };

  const onCancelEditOnRow = () => {
    setEnableEdit(false);
    setDataSource(tempDataSource);
  };

  const handleChange = (e, record, ref) => {
    let data = _.cloneDeep(dataSource);
    let selectedIndex = data.findIndex((el) => el.key === record.key);

    let index = editData.findIndex((el) => {
      return el._id === record.key;
    });
    let items = [...editData];
    let item = {...editData[index]};

    switch (ref) {
      case 'condition_1':
        if (e.target.value.match(/^[0-9]+$/)) {
          data[selectedIndex].product_condition_1 = parseInt(e.target.value);
          item.product_condition.product_condition_1 = parseInt(e.target.value);
          items[index] = item;
        } else {
          data[selectedIndex].product_condition_1 = e.target.value;
          item.product_condition.product_condition_1 = e.target.value;
          items[index] = item;
        }
        break;
      case 'condition_2':
        if (e.target.value.match(/^[0-9]+$/)) {
          data[selectedIndex].product_condition_2 = parseInt(e.target.value);
          item.product_condition.product_condition_2 = parseInt(e.target.value);
          items[index] = item;
        } else {
          data[selectedIndex].product_condition_2 = e.target.value;
          item.product_condition.product_condition_2 = e.target.value;
          items[index] = item;
        }

        break;
      case 'condition_3':
        if (e.target.value.match(/^[0-9]+$/)) {
          data[selectedIndex].product_condition_3 = parseInt(e.target.value);
          item.product_condition.product_condition_3 = parseInt(e.target.value);
          items[index] = item;
        } else {
          data[selectedIndex].product_condition_3 = e.target.value;
          item.product_condition.product_condition_3 = e.target.value;
          items[index] = item;
        }
        break;
      case 'condition_4':
        if (e.target.value.match(/^[0-9]+$/)) {
          data[selectedIndex].product_condition_4 = parseInt(e.target.value);
          item.product_condition.product_condition_4 = parseInt(e.target.value);
          items[index] = item;
        } else {
          data[selectedIndex].product_condition_4 = e.target.value;
          item.product_condition.product_condition_4 = e.target.value;
          items[index] = item;
        }
        break;
      default:
        break;
    }

    setEditData([...items]);
    setDataSource(data);
  };

  const updateSecondHandPrice = async () => {
    setLoading(true);

    try {
      const res = await PUT(EDIT_SECOND_HAND_PRICE, editData);
      const {success} = res;

      if (success) {
        setEnableEdit(false);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading || loadingReport}>
          <PageLayout
            searchLayout={{
              title: 'แก้ไขราคาสินค้ารับซื้อมือสอง',
              icon: <BookOutlined />,
              spanSearch: 24,
              formSearch: EditSecondHandPriceSearchForm({options: {optionProduct}}),
              onSearch: (data) => getDataEditSecondHandPrice(data),
              onClearCustom,
            }}
            tableLayout={{
              columns: EditSecondHandPriceColumn({optionCondition, data: {enableEdit}, fn: {handleChange}}),
              dataSource: dataSource,
              rowKey: (record) => record?.key,
              pagination: {
                showSizeChanger: true,
                current: pageRef.current,
                pageSize: sizeRef.current,
                onChange: (p, s) => onPageSizeChangeCustom(p, s),
                pageSizeOptions: ['10', '20', '30', '40', '100', '200', '300'],
                total: totalSize || 0,
                showTotal: (totalSize, range) => `รายการทั้งหมด ${convertStrToFormat(totalSize, 'number-comma')} รายการ`,
                defaultPageSize: sizeRef.current,
                defaultCurrent: 1,
              },
              extraContent: (
                <ContainerButton right>
                  <ButtonTheme useFor="CREATE" title={'เพิ่มสินค้า'} onClick={() => setVisibleAddModal(true)} />
                  {!enableEdit && (
                    <ButtonTheme
                      useFor="EDIT"
                      title={'แก้ไขทั้งหมด'}
                      style={{backgroundColor: color.manage, color: color.white}}
                      onClick={() => onEditOnRow()}
                    />
                  )}
                  {enableEdit && (
                    <>
                      <ButtonTheme useFor="REJECT" title={'ยกเลิก'} onClick={() => onConfirm('CANCEL_EDIT')} />
                      <ButtonTheme useFor="SUBMIT" onClick={() => onConfirm('EDIT')} />
                    </>
                  )}
                  <ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด Excel'} onClick={() => setVisibleModal(true)} />
                </ContainerButton>
              ),
            }}
          />
        </Spin>
      </Card>
      <Modal
        title={'ต้องการดาวน์โหลด Excel?'}
        open={visibleModal}
        width={550}
        onCancel={onClose}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}>
        <ContainerButton right>
          <ButtonTheme useFor="CUSTOM" title={'ตกลง'} onClick={async () => await onExportExcelReport()} />
          <ButtonTheme useFor="CANCEL" onClick={() => onClose()} />
        </ContainerButton>
      </Modal>
      <AddPriceSecondHandModal
        visible={visibleAddModal}
        title={'เพิ่มสินค้าและราคารับซื้อสินค้ามือสอง'}
        onClose={() => setVisibleAddModal(false)}
        data={{
          reApi: getDataEditSecondHandPrice,
          reApi2: getOptionSecndHandProduct,
          record: defaultValue,
          valueSearch,
          option: {optionProductModelAll, optionProductStorageAll, optionProductColorAll},
        }}
      />
    </Wrapper>
  );
};

export default EditSecondHandPriceScreen;
