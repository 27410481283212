import React, { useState, useEffect, useRef } from 'react';
import { Modal, Row, Col, Typography, Button, Input, Select, Spin } from 'antd';
import { useForm } from 'react-hook-form';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../buttons';
import { POST, EDIT_CANCEL_DOWN_PAYMENT, GET, GET_BANK_OPTION, GET_DATA_OCR } from '../../../services/index';
import { Icon } from '../../../resources/icon';
import RenderForm from '../../../constants/global/RenderForm';
import { CancelDownPaymentForm, CancelDownPaymentForm2 } from '../../../constants/global/actionform';
import { checkSameNameWithPrefix, convertStrToFormat, isNegative } from '../../../functions/fn';
import { EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import { TextXSMall } from '../../forms/text';

const { Text } = Typography;

export const CancelDownPaymentModal = ({ title, visible, onClose, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    setError,
    clearErrors,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState({});
  const [cancelFee, setCancelFee] = useState('2000');
  const [editCancelFee, setEditCancelFee] = useState(false);
  const [bankOptions, setBankOptions] = useState([]);
  const [idCardImageList, setIdCardImageList] = useState([]);
  const [changeNameImageList, setChangeNameImageList] = useState([]);
  const [bookBankImage, setBookBankImage] = useState([]);
  const [bookBankBranch, setBookBankBranch] = useState('');
  const [bookBankCode, setBookBankCode] = useState(null);
  const [bookBankName, setBookBankName] = useState('');
  const [bookBankNumber, setBookBankNumber] = useState('');
  const [returnAmount, setReturnAmount] = useState(0);
  const [checkNegative, setCheckNegative] = useState(false);
  const [enabledChangeName, setEnabledChangeName] = useState(false);
  const [visibleError, setVisibleError] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  useEffect(() => {
    if (JSON.stringify(data && data.record) !== '{}' && data.record) {
      setRecord(data.record);
      setValue('remark', data?.record?.remark)
      initialCalculate();
      onFetchBankOption();
    }
  }, [data]);

  useEffect(() => {
    if (bookBankImage && bookBankImage[0]?.url) {
      const spliceUrl = bookBankImage[0]?.url.split('?');
      getDataOCR(spliceUrl[0]);
    }
  }, [bookBankImage]);

  const initialCalculate = () => {
    let initialReturnAmount = parseInt(data.record.paid_amount) - 2000;
    if (initialReturnAmount <= 0) {
      setCheckNegative(false);
    } else if (initialReturnAmount > 0) {
      setCheckNegative(true);
    }
    setReturnAmount(parseInt(data.record.paid_amount) - 2000);
  };

  const onConfirm = (params) => {
    if (checkNegative) {
      if (bookBankCode === null || bookBankName === '' || bookBankBranch === '' || bookBankNumber === '') {
        setVisibleError(true);
      } else if (bookBankCode !== null && bookBankName !== '' && bookBankBranch !== '' && bookBankNumber !== '') {
        setVisibleError(false);
      }
      if (enabledChangeName) {

        if (JSON.stringify(idCardImageList) === '[]') {
          setError('id_card_image', { type: 'required', message: 'โปรดอัพโหลดรูปบัตรประชาชน' });
        }
        if (JSON.stringify(bookBankImage) === '[]') {
          setError('book_bank_image', { type: 'required', message: 'โปรดอัพโหลดรูปหน้าบัญชีธนาคาร' });
        }
        if (JSON.stringify(changeNameImageList) === '[]') {
          setError('change_name_image', { type: 'required', message: 'โปรดอัพโหลดรูปใบเปลี่ยนชื่อ' });
        }
        if (bookBankCode !== null && bookBankName !== '' && bookBankBranch !== '' && bookBankNumber !== '' && JSON.stringify(idCardImageList) !== '[]' && JSON.stringify(bookBankImage) !== '[]' && JSON.stringify(changeNameImageList) !== '[]') {
          Modal.confirm({
            title: 'ยืนยัน',
            icon: <Icon.warningAlert />,
            content: 'ยืนยันการยกเลิกออมดาวน์',
            onOk() {
              onSubmit(params);
            },
            okText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
          });
        }

      } else {

        if (JSON.stringify(idCardImageList) === '[]') {
          setError('id_card_image', { type: 'required', message: 'โปรดอัพโหลดรูปบัตรประชาชน' });
        }
        if (JSON.stringify(bookBankImage) === '[]') {
          setError('book_bank_image', { type: 'required', message: 'โปรดอัพโหลดรูปหน้าบัญชีธนาคาร' });
        }

        if (bookBankCode !== null && bookBankName !== '' && bookBankBranch !== '' && bookBankNumber !== '' && JSON.stringify(idCardImageList) !== '[]' && JSON.stringify(bookBankImage) !== '[]') {
          Modal.confirm({
            title: 'ยืนยัน',
            icon: <Icon.warningAlert />,
            content: 'ยืนยันการยกเลิกออมดาวน์',
            onOk() {
              onSubmit(params);
            },
            okText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
          });
        }
      }
    } else {
      Modal.confirm({
        title: 'ยืนยัน',
        icon: <Icon.warningAlert />,
        content: 'ยืนยันการยกเลิกออมดาวน์',
        onOk() {
          onSubmit(params);
        },
        okText: 'ยืนยัน',
        cancelText: 'ยกเลิก',
      });
    }
  };

  const onFetchBankOption = async () => {
    try {
      const res = await GET(GET_BANK_OPTION);
      const { message, data } = res;
      if (message === 'get bank options success') {
        const result = data?.bank?.bank.map((el) => {
          return {
            label: el.bankName,
            value: el.bankCode,
          };
        });

        setBankOptions(result);
      }
    } catch (err) {
      const { error } = err;
      Modal.error({
        title: error,
      });
    }
  };

  const onSubmit = async (params) => {
    setLoading(true);

    try {
      const negativeValue = isNegative(returnAmount)

      const bankName = bankOptions.filter((el) => el.value === bookBankCode)

      let obj = {
        id_card: record.user_id_card,
        id_card_image: idCardImageList[0]?.url || '',
        book_bank_imge: bookBankImage[0]?.url || '',
        bank_name: bankName[0]?.label || '',
        bank_code: bookBankCode || '',
        bank_branch: bookBankBranch || '',
        back_account_name: bookBankName || "",
        bank_number: bookBankNumber || '',
        remark: params.remark || '',
        rename_image: changeNameImageList[0]?.url || '',
        return_amount: negativeValue ? 0 : returnAmount,
      }

      let res = await POST(EDIT_CANCEL_DOWN_PAYMENT, obj);
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            data.reApi(data.valueSearch);
            onCloseMain();
          },
        });
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const onEditCancelFee = () => {
    setEditCancelFee(!editCancelFee);
  };

  const saveImage = (e, ref) => {
    e.fileList.map(async (el) => {
      if (el?.response?.message === 'get url upload success') {
        await axios.put(el?.response?.data?.url, e.file.originFileObj, { headers: { 'Content-Type': e.file.originFileObj.type } });
      }
    });

    switch (ref) {
      case 'id_card_image':
        const objFile = e.fileList.map((val) => {
          if (val?.response?.message === 'get url upload success') {
            return {
              mimeType: val.response && val.type,
              url: val.response && val?.response?.data?.url,
            };
          }
        });
        setIdCardImageList(objFile);
        break;
      case 'book_bank_image':
        const objFile2 = e.fileList.map((val) => {
          if (val?.response?.message === 'get url upload success') {
            return {
              mimeType: val.response && val.type,
              url: val.response && val?.response?.data?.url,
            };
          }
        });

        setBookBankImage(objFile2);
        break;
      case 'change_name_image':
        const objFile3 = e.fileList.map((val) => {
          if (val?.response?.message === 'get url upload success') {
            return {
              mimeType: val.response && val.type,
              url: val.response && val?.response?.data?.url,
            };
          }
        });
        setChangeNameImageList(objFile3);
        break;
    }
  };

  const getDataOCR = async (url) => {
    setLoading(true);
    try {
      const res = await POST(GET_DATA_OCR, { link: url });
      const { message, data } = res;

      if (message === 'get ocr success') {
        if (JSON.stringify(data.ocr) !== '{}') {
          setBookBankCode(data?.ocr.bankCode);
          setBookBankName(data?.ocr.accountName);
          setBookBankNumber(data?.ocr.accountNo);
          if (data?.ocr.accountName !== null) {
            let checkName = checkSameNameWithPrefix(record.name, data?.ocr.accountName)
            if (checkName === false) {
              setEnabledChangeName(true);
            } else if (checkName === true) {
              setEnabledChangeName(false)
            }
          }
        }
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message || '',
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = async (e, ref) => {
    switch (ref) {
      case 'edit_cancel_fee':
        setCancelFee(e.target.value);
        let amount = parseInt(record?.paid_amount) - parseInt(e.target.value);
        if (e.target.value.includes('-')) {
          Modal.error({
            title: 'ห้ามกรอกค่าธรรมเนียมติดลบ',
          });
        } else {
          if (amount <= 0) {
            setCheckNegative(false);
          } else {
            setCheckNegative(true);
          }
        }
        setReturnAmount(amount);
        break;
      case 'id_card_image':
        setValue(ref, e.fileList);
        saveImage(e, 'id_card_image');
        setError('id_card_image', { type: 'custom', message: '' });
        clearErrors(ref)
        break;
      case 'book_bank_image':
        setValue(ref, e.fileList);
        saveImage(e, 'book_bank_image');
        setError('book_bank_image', { type: 'custom', message: '' });
        clearErrors(ref)
        break;
      case 'change_name_image':
        setValue(ref, e.fileList);
        saveImage(e, 'change_name_image');
        setError('change_name_image', { type: 'custom', message: '' });
        clearErrors(ref)
        break;
      case 'book_bank_code':
        setBookBankCode(e);
        break;
      case 'book_bank_branch':
        setBookBankBranch(e.target.value);
        break;
      case 'book_bank_name':
        let checkName = checkSameNameWithPrefix(record.name, e.target.value)
        if (checkName === false) {
          setEnabledChangeName(true);
        } else if (checkName === true) {
          setEnabledChangeName(false)
        }
        setBookBankName(e.target.value);
        break;
      case 'book_bank_number':
        setBookBankNumber(e.target.value);
        break;
    }
    clearErrors()
  };

  const onCloseMain = () => {
    clearErrors();
    setIdCardImageList([])
    setBookBankImage([])
    setChangeNameImageList([])
    setBookBankBranch(null)
    setBookBankCode(null)
    setBookBankName(null)
    setBookBankNumber(null)
    setCheckNegative(false)
    setEnabledChangeName(false)
    setEditCancelFee(false)
    setCancelFee('2000')
    setVisibleError(false)
    setReturnAmount(0)
    setValue('remark', '')
    onClose();
  };

  const getBase64 = (file) => {
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const onPreview = async (file) => {
    if (file.response) {
      if (!file.response.file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
    }

    if (file.mimeType === 'application/pdf' || file.type === 'application/pdf') {
      window.open(file.url || file.preview || (file.response && file.response.file.url), '_blank', 'noopener,noreferrer');
    } else {
      setPreviewVisible(true);
      setPreviewImage((file.response && file.response.file.url) || file.url || file.preview);
      setPreviewTitle(file.fileName || file.name);
    }
  };

  return (
    <Modal title={title || 'modal'} open={visible} width={800} onCancel={onCloseMain} footer={null} destroyOnClose={true} maskClosable={false}>
      <form onSubmit={handleSubmit(onConfirm)}>
        <Row style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
          <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
            <Text strong>ยอดออมดาวน์ทั้งหมด</Text>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
            {convertStrToFormat(record?.paid_amount?.toString(), 'money')}
          </Col>
        </Row>
        <Row style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
          <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
            <Text strong>ค่าธรรมเนียม</Text>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ marginTop: 10 }}>
              {editCancelFee ? (
                <Input
                  style={{ width: '40%' }}
                  placeholder="กรุณากรอกค่าธรรมเนียม"
                  value={cancelFee}
                  onChange={(e) => handleChange(e, 'edit_cancel_fee')}
                />
              ) : (
                <Text>{convertStrToFormat(cancelFee, 'money')}</Text>
              )}
              <Button
                className="ant-btn-customize"
                icon={<EditOutlined />}
                onClick={() => onEditCancelFee()}></Button>
            </div>
          </Col>
        </Row>
        <hr style={{ width: '70%', alignItems: 'center', justifyContent: 'center' }} color="black" />
        <Row style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
          <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
            <Text strong>คงเหลือ</Text>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
            {convertStrToFormat(returnAmount.toString(), 'money')}
          </Col>
        </Row>
        <hr style={{ width: '70%', alignItems: 'center', justifyContent: 'center' }} color="black" />
        <hr style={{ width: '70%', alignItems: 'center', justifyContent: 'center', marginTop: -10, marginBottom: 50 }} color="black" />
        {
          checkNegative ?
            <>
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                setError={setError}
                clearErrors={clearErrors}
                formList={CancelDownPaymentForm({ data: { idCardImageList, bookBankImage, checkNegative }, handleChange, onPreview })}
              />
              <Row style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: 10, paddingInline: 20 }}>
                <Spin spinning={loading} tip={'กำลังดึงข้อมูล'}></Spin>
              </Row>
              <Row style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: 10, paddingInline: 20 }}>
                <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Select
                    style={{ width: '70%' }}
                    placeholder={'กรุณาเลือกธนาคาร'}
                    onChange={(e) => handleChange(e, 'book_bank_code')}
                    options={bankOptions}
                    value={bookBankCode}
                  />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 24 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  style={{ display: 'flex', justifyContent: 'center', marginLeft: -40 }}>
                  <Input
                    style={{ width: '70%', borderRadius: '10px' }}
                    placeholder="สาขา"
                    value={bookBankBranch}
                    onChange={(e) => handleChange(e, 'book_bank_branch')}
                  />
                </Col>
              </Row>
              <Row style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: 20, paddingInline: 20, marginBottom: 30 }}>
                <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Input
                    style={{ width: '70%', borderRadius: '10px' }}
                    placeholder="ชื่อบัญชี"
                    value={bookBankName}
                    onChange={(e) => handleChange(e, 'book_bank_name')}
                  />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 24 }}
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  style={{ display: 'flex', justifyContent: 'center', marginLeft: -40 }}>
                  <Input
                    style={{ width: '70%', borderRadius: '10px' }}
                    placeholder="เลขที่บัญชี"
                    value={bookBankNumber}
                    onChange={(e) => handleChange(e, 'book_bank_number')}
                  />
                </Col>
              </Row>
              {visibleError && (
                <Row>
                  <Col
                    xs={{ span: 24 }}
                    md={{ span: 24 }}
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    style={{ display: 'flex', justifyContent: 'center', marginLeft: -40, marginBottom: 40 }}>
                    <TextXSMall color={'red'} text={'กรุณากรอกข้อมูลให้ครบ'} />
                  </Col>
                </Row>
              )}
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                setError={setError}
                clearErrors={clearErrors}
                formList={CancelDownPaymentForm2({ data: { changeNameImageList, enabledChangeName }, handleChange, onPreview })}
              />
            </>
            : <></>
        }
        <ContainerButton center>
          <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" />
          <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
        </ContainerButton>
      </form>
    </Modal>
  );
};
