import React from 'react';
import TableForm from '../table';
import { CardTheme } from '../card/CardTheme';
import { TextLarge } from '../forms/text'

export const TableLayout = ({ props }) => {
  const { style, columns, dataSource, icon, rowSelection, extraContent, expandable, components, loading, scroll, rowClassName, title, rowKey, footerContent, pagination, onRow, hidden, summary } = props;
  return (
    <>
      {!hidden && <CardTheme
        style={{ marginTop: '2%', ...style }}
        title={title && (
          <div style={{ display: 'flex', alignItems: 'center', color: '#0052BA' }}>
            {icon}
            &nbsp;
            <TextLarge text={title} color={'#0052BA'} />
          </div>
        )}
        content={
          <>
            {extraContent}
            <TableForm
              loading={loading}
              columns={columns}
              components={components}
              dataSource={dataSource}
              expandable={expandable}
              rowSelection={rowSelection}
              rowClassName={rowClassName}
              rowKey={rowKey}
              scroll={scroll}
              onRow={onRow}
              summary={summary}
              pagination={pagination}
            />
            {footerContent}
          </>
        }
      />}
    </>
  );
};
