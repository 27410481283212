export const optionProductHandPick = [
  {value: 'NEW', label: 'สินค้ามือหนึ่ง'},
  {value: 'SPECIAL', label: 'สินค้าพิเศษ'},
];

export const optionProductHand = [
  {value: 'NEW', label: 'สินค้ามือหนึ่ง'},
  {value: 'SECOND', label: 'สินค้ามือสอง'},
  {value: 'SPECIAL', label: 'สินค้าพิเศษ'},
];

export const mockData = {
  CENTER: [
    {
      item_code: 'G02101030433',
      product_name: 'Apple iPad Air5 Wifi+Cellular 256GB Purple',
      productHand: 'มือหนึ่ง',
      branch: 'CENTER',
      countReserve: 1,
      countStock: 0,
      countPick: 1,
    },
    {
      item_code: 'G05101020350',
      product_name: 'Apple Pencil 2nd generate  White',
      productHand: 'มือหนึ่ง',
      branch: 'CENTER',
      countReserve: 1,
      countStock: 0,
      countPick: 1,
    },
    {
      item_code: 'G01101160502',
      product_name: 'Apple iPhone 14 128GB Blue',
      productHand: 'มือหนึ่ง',
      branch: 'CENTER',
      countReserve: 1,
      countStock: 0,
      countPick: 1,
    },
    {
      item_code: 'G01101010882',
      product_name: 'Apple iPhone 15 Plus 128GB Black',
      productHand: 'มือหนึ่ง',
      branch: 'CENTER',
      countReserve: 1,
      countStock: 0,
      countPick: 1,
    },
    {
      item_code: 'G01101030513',
      product_name: 'Apple iPhone 14 Plus 256GB Purple',
      productHand: 'มือหนึ่ง',
      branch: 'CENTER',
      countReserve: 1,
      countStock: 0,
      countPick: 1,
    },
    {
      item_code: 'G01101300893',
      product_name: 'Apple iPhone 15 Pro 256GB Natural Titanium',
      productHand: 'มือหนึ่ง',
      branch: 'CENTER',
      countReserve: 1,
      countStock: 0,
      countPick: 1,
    },
  ],
};
