export const getTrackStatus = (status) => {
    switch (status) {
      case 'PENDING':
        return 'รอการติดตาม';
      case 'TRACKED':
        return 'ติดตามแล้ว';
      case 'NO_OVERDUED':
        return 'ไม่มีงวดค้าง';
      default:
        return 'ผิดพลาด';
    }
  };

export const getStageRange = (stage) => {
    switch (stage) {
      case 5:
        return '60+';
      case 4:
        return '46-60';
      case 3:
        return '31-45';
      case 2:
        return '15-30';
      case 1:
        return '1-14.';
      default:
        return '-';
    }
  }


  export const getContactStatus = (status) => {
    switch (status) {
      case 'CAN_CONTACT':
        return 'ติดต่อได้';
      case 'CAN_NOT_CONTACT':
        return 'ติดต่อไม่ได้';
      case 'OTHERS':
        return 'อื่นๆ';
      default:
        return '';
    }
  };