export const GET_CONTRACT_HISTORY_TYPE_LOCK = `
  query GetHistoryTypeLock($input: ContractHistoryTypeLockListInp!) {
    getHistoryTypeLock(input: $input) {
      result {
        no
        type_lock_name
        mdm_provider_name
        mdm_site_name
        created_name
        created_date
      }
      count
    }
  }
`;