import * as TYPES from '../types/statement';

const initStatement = {
    loading: false,
    error: null,
    statementInfo: null
};

export const statementInfoReducer = (state = initStatement, { type, payload }) => {
    switch (type) {
        case TYPES.EXPORT_STATEMENT_REQ:
            return { ...state, loading: true };
        case TYPES.EXPORT_STATEMENT_SUCCESS:
            return { ...state, loading: false, error: null };
        case TYPES.EXPORT_STATEMENT_FAIL:
            return { ...state, loading: false, error: payload };
        default:
            return state;
    }
};