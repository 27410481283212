// /* eslint no-use-before-define: 0 */
import React, { useState, useEffect, useContext, useRef } from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import { Modal, Card, Spin } from 'antd';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../../components/buttons';
import { dataManageIcloudAccount } from '../../../constants/global/mockData';
import { SettingOutlined } from '@ant-design/icons';
import { PageLayout } from '../../../components/pageLayout/PageLayout';
import { ManageTelephoneSearchForm } from '../../../constants/global/SearchForm';
import { ManageTelephoneColumn } from '../../../constants/global/columnTableForm';
import { GET, POST, GET_TELEPHONE } from '../../../services/index';
import { CreateTelephoneModal } from '../../../components/modal/ManageTelephone/CreateTelephoneModal';
import { EditTelephoneModal } from '../../../components/modal/ManageTelephone/EditTelephoneModal';

const ManageTelephone = () => {


  const [loading, setLoading] = useState(false);
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [valueDefault, setValueDefault] = useState({});
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    getDataTelephone({});
  }, []);

  const getDataTelephone = async (data) => {
    data.tel = data.tel || '';

    try {
      setLoading(true);
      let res = await POST(GET_TELEPHONE, data);
      const { success, result } = res;
      if (success) {
        const resultData = result.map((val, i) => {
          return {
            rowNo: i + 1,
            ...val,
          };
        });
        setDataSource(resultData);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const openModal = (record) => {
    setValueDefault(record);
    setVisibleEdit(true);
  };

  const closeModal = () => {
    setVisibleEdit(false);
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'จัดการข้อมูลเบอร์โทร',
              icon: <SettingOutlined />,
              spanSearch: 24,
              formSearch: ManageTelephoneSearchForm(),
              onSearch: (data) => getDataTelephone(data),
            }}
            tableLayout={{
              columns: ManageTelephoneColumn({ openModal }),
              dataSource: dataSource,
              extraContent: (
                <ContainerButton right>
                  <ButtonTheme useFor="CREATE" title={'เพิ่มเบอร์'} onClick={() => setVisibleCreate(!visibleCreate)} />
                </ContainerButton>
              ),
            }}
          />
        </Spin>
      </Card>
      <CreateTelephoneModal
        visible={visibleCreate}
        title={'เพิ่มเบอร์โทรศัพท์'}
        onClose={() => setVisibleCreate(!visibleCreate)}
        data={{ reApi: getDataTelephone }}
      />
      <EditTelephoneModal
        visible={visibleEdit}
        title={'แก้ไขเบอร์โทรศัพท์'}
        onClose={closeModal}
        data={{ reApi: getDataTelephone, record: valueDefault }}
      />
    </Wrapper>
  );
};

export default ManageTelephone;
