import {ButtonTheme} from '../../../../components/buttons';
import moment from 'moment/moment';
import {Popconfirm} from 'antd';
import {convertFormatToStr} from '../../../../functions/fn';
import {useContract} from '../../../../hook/useContract';

export const TrackingNoReportColumn = () => {
  return [
    {
      title: '#',
      dataIndex: 'no',
      width: '50px',
      align: 'center',
    },
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contract_no',
      width: '120px',
      align: 'left',
    },
    {
      title: 'ชื่อ-นามสกุล',
      dataIndex: 'name',
      width: '150px',
      align: 'left',
    },
    {
      title: 'Tracking No.',
      dataIndex: 'tracking_no',
      width: '120px',
      align: 'center',
    },
    {
      title: 'วันที่จัดส่ง',
      dataIndex: 'sending_date',
      width: '120px',
      align: 'center',
      render: (text, _record) => {
        return text ? moment(text).format('DD/MM/YYYY') : '';
      },
    },
    {
      title: 'สถานะจัดส่ง',
      dataIndex: 'thaipost_status_thai',
      width: '100px',
      align: 'center',
    },
    {
      title: 'วันที่รับใบตอบรับ',
      dataIndex: 'acknowledgment_date',
      width: '120px',
      align: 'center',
      render: (text, _record) => {
        return text !== '-' ? moment(text).format('DD/MM/YYYY') : text;
      },
    },
    {
      title: 'บันทึกเลข Tracking',
      dataIndex: 'tracking_upload_user',
      width: '150px',
      align: 'left',
    },
    {
      title: 'บันทึกใบตอบรับ',
      dataIndex: 'acknowledgment_user',
      width: '150px',
      align: 'left',
    },
  ];
};

export const TrackingNoColumn = (onConfirmRemove) => {
  return [
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'ContractNo',
      width: '120px',
      align: 'center',
    },
    {
      title: 'ชื่อ-นามสกุล',
      dataIndex: 'fullName',
      width: '200px',
      align: 'left',
    },
    {
      title: 'Tracking No.',
      dataIndex: 'TrackingNo',
      width: '120px',
      align: 'center',
    },
    {
      title: 'จัดการ',
      dataIndex: 'action',
      width: '80px',
      align: 'center',
      render: (_text, record) => {
        return <ButtonTheme useFor="REMOVE" onClick={() => onConfirmRemove(record)} />;
      },
    },
  ];
};

export const ResultTrackingColumn = () => {
  return [
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contractNo',
      width: '120px',
      align: 'center',
    },
    {
      title: 'Tracking No,',
      dataIndex: 'trackingNo',
      width: '150px',
      align: 'left',
    },
    {
      title: 'ผลลัพธ์',
      dataIndex: 'result',
      width: '250px',
      align: 'center',
    },
  ];
};

export const TerminatedReportColumn = () => {
  const {dispatchExportContractTerminated} = useContract();
  return [
    {
      title: '#',
      dataIndex: 'no',
      width: '50px',
      align: 'center',
    },
    {
      title: 'สถานะการออกหนังสือยกเลิกสัญญา',
      dataIndex: 'contract_status',
      width: '250px',
      align: 'center',
      render: (_text, record) => {
        if (record?.contract_status === 'YES') {
          return 'ออกหนังสือแล้ว';
        }
        return record?.contract_status;
      },
    },
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contract_no',
      width: '120px',
      align: 'left',
    },
    {
      title: 'ชื่อ-นามสกุล',
      dataIndex: 'user_name',
      width: '150px',
      align: 'left',
    },
    {
      title: 'ประเภทหนังสือ',
      dataIndex: 'type',
      width: '200px',
      align: 'left',
    },
    {
      title: 'ยอดชำระ',
      dataIndex: 'paid_amount',
      width: '120px',
      align: 'right',
      render: (text) => {
        return text.toLocaleString('en-US');
      },
    },
    {
      title: 'เกินกำหนด (วัน)',
      dataIndex: 'overdued_date',
      width: '120px',
      align: 'center',
    },
    {
      title: 'วันที่ออกหนังสือยกเลิกสัญญา',
      dataIndex: 'terminated_date',
      width: '150px',
      align: 'center',
      render: (text, _record) => {
        return text !== '-' ? moment(text, 'YYYY-MM-DD').format('DD/MM/YYYY') : text;
      },
    },
    {
      title: 'ผู้กดออกหนังสือยกเลิกสัญญา',
      dataIndex: 'user_create_terminated',
      width: '150px',
      align: 'center',
    },
    {
      title: 'สถานะส่งหนังสือเลขที่ไปรษณีย์',
      dataIndex: 'thaipost_status_thai',
      width: '200px',
      align: 'center',
    },
    {
      title: '',
      dataIndex: 'action',
      width: '200px',
      align: 'center',
      render: (text, record) => {
        if (record?.contract_status === 'YES') {
          return (
            <ButtonTheme
              useFor="DOWNLOAD"
              title="ดาวน์โหลดหนังสือ"
              onClick={() =>
                dispatchExportContractTerminated(record?.contract_no, record?.contract_pdf_mistake_id !== '-' ? record?.contract_pdf_mistake_id : '')
              }
            />
          );
        }
        return '-';
      },
    },
  ];
};
