import * as TYPES from '../types/down-payment';
import * as API from '../api/down-payment';

export const exportDownPayment = (downPaymentNumber) => async (dispatch) => {
    dispatch({ type: TYPES.DOWN_PAYMENT_EXPORT_REQ });
    try {
        const response = await API.exportDownPayment(downPaymentNumber);
        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement("a");
            a.href = url;
            a.download = `รายงานออมดาวน์เลขที่ ${downPaymentNumber}.pdf`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            dispatch({ type: TYPES.DOWN_PAYMENT_EXPORT_SUCCESS, payload: url });
        } else {
            dispatch({ type: TYPES.DOWN_PAYMENT_EXPORT_FAIL, payload: response.response.data.error });
        }
    } catch (error) {
        dispatch({ type: TYPES.DOWN_PAYMENT_EXPORT_FAIL, payload: error });
    }
};