import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useContract } from '../../hook/useContract';
import { useBranch } from '../../hook/useBranch';
import Wrapper from '../../views/wrapper/Wrapper';
import { FileSearchOutlined } from '@ant-design/icons';
import { ContractListSearchForm } from '../../constants/global/SearchForm';
import { ContractListColumn } from '../../constants/global/columnTableForm';
import { Card, Spin, Modal } from 'antd';
import { PageLayout } from '../../components/pageLayout/PageLayout';
import { convertStrToFormat } from '../../functions/fn';
import { ContainerButton } from '../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../components/buttons';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { getContractListExport } from '../../redux/api/contract';
import { GET, GET_DATA_EXPORT_CONTRACT } from '../../services';

const useStyles = makeStyles({
    pagination_btn: {
        '& button:focus': {
            outline: 0,
        },
    }
});

// const optionBranch = [{value:'CENTER',label:"CENTER: วงเวียนใหญ่"},{value:'RS',label:"RS: รังสิต"},
// {value:'KHONKAEN',label:"KHONKAEN: ขอนแก่น"},
// {value:'CHONBURI',label:"CHONBURI: ชลบุรี"},
// {value:'PHITSANULOK',label:"PHITSANULOK: พิษณุโลก"},
// {value:'CHIANGMAI',label:"CM: เชียงใหม่"},
// {value:'FWG',label:"FWG: โฟกัส เซ็นทรัลเวสเกต"},
// {value:'FBKP',label:"FBKP: โฟกัส บางกะปิ"},
// ]
const optionStatus = [{ value: 'ACTIVE', label: "กำลังใช้งาน" }, { value: 'TERMINATED', label: "สัญญาถูกยกเลิก" },
{ value: 'COMPLETED', label: "ปิดสัญญา" }, { value: 'IMPOUNDED', label: "สัญญาถูกยึด" }, { value: 'WAIT_SIGNATURE', label: "รอเซ็นสัญญา" },
{ value: 'WAIT_ADMIN_CONFIRM', label: "รอเจ้าหน้าที่อนุมัติ" }, { value: 'WAIT_FINANCE_APPROVE', label: "รอเจ้าหน้าที่การเงินอนุมัติ" }]

const createParams = (branch, status, name, tel, contractNumber, pageParam, sizeParam, startDate, endDate) => {
    let params = `?size=${sizeParam}&page=${pageParam}`;
    if (branch) {
        params += `&branch=${branch}`;
    }
    if (status) {
        params += `&status=${status}`;
    }
    if (name) {
        params += `&name=${name}`;
    }
    if (tel) {
        params += `&tel=${tel}`;
    }
    if (contractNumber) {
        params += `&contract_number=${contractNumber}`;
    }
    if (startDate) {
        params += `&startDate=${startDate}`;
    }
    if (endDate) {
        params += `&endDate=${endDate}`;
    }
    return params;
};

const COLUMNS = 7;
const Contract = () => {
    const { dispatchGetContractList, contractListTradesys, dispatchGetContractListExport, contractListTradesysExport } = useContract();
    const [nameState, setNameState] = useState("");
    const [telState, setTelState] = useState("");
    const [branchState, setBranchState] = useState('');
    const [statusState, setStatusState] = useState('');
    const [contractNumberState, setContractNumberState] = useState("");
    const [startDateState, setStartDateState] = useState("");
    const [endDateState, setEndDateState] = useState("");
    const pageRef = useRef(1)
    const sizeRef = useRef(10)
    const classes = useStyles();
    const { branchList } = useBranch()
    const optionBranch = branchList
    const [dataSource, setDataSource] = useState([])
    const [dataExport, setDataExport] = useState([])
    const [totalSize, setTotalSize] = useState(0)
    const [totalPage, setTotalPage] = useState(0)
    const [visibleModal, setVisibleModal] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(async () => {
        await dispatchGetContractList(`?size=${contractListTradesys.size}&page=${contractListTradesys.page}`);
        sizeRef.current = contractListTradesys.size
        pageRef.current = contractListTradesys.page
    }, []);

    useEffect(() => {
        getContractListData()
    }, [contractListTradesys.contractInfo, contractListTradesys.size, contractListTradesys.page])

    const getContractListData = () => {
        try {
            if (contractListTradesys?.contractInfo?.length !== 0 && contractListTradesys?.contractInfo !== undefined) {
                setTotalSize(contractListTradesys.totalItem)
                setTotalPage(contractListTradesys.totalPage)
                setDataSource(contractListTradesys.contractInfo)
                // setDataExport(dataEx)
            } else if ((contractListTradesys?.contractInfo?.length === 0 || contractListTradesys?.contractInfo !== undefined)) {
                setTotalSize(contractListTradesys.totalItem)
                setTotalPage(contractListTradesys.totalPage)
                setDataSource([])
                // setDataExport([])
            }
        } catch (err) {
            const { message, error } = err;

            Modal.error({
                title: message,
                content: error || contractListTradesys.error || ''
            })
        }

    }

    const search = (data) => {

        data.name = data.name ? data.name : ''
        data.tel = data.tel ? data.tel : ''
        data.contractNumber = data.contractNumber ? data.contractNumber : ''
        data.status = data.status ? data.status : ''
        data.branch = data.branch ? data.branch : ''

        data.startDate = data.startDate ? moment(data?.startDate).format('YYYY-MM-DD') : ''
        data.endDate = data.endDate ? moment(data?.endDate).format('YYYY-MM-DD') : ''

        const { name, tel, contractNumber, branch, status, startDate, endDate } = data

        pageRef.current = 1
        sizeRef.current = 10

        setNameState(name)
        setTelState(tel)
        setContractNumberState(contractNumber)
        setBranchState(branch)
        setStatusState(status)
        setStartDateState(startDate)
        setEndDateState(endDate)
        const params = createParams(branch || '', status || '', name || '', tel || '', contractNumber || '', pageRef.current, sizeRef.current, startDate || '', endDate || '');
        dispatchGetContractList(params);
    };

    const onPageSizeChangeCustom = (pageData, sizeData) => {
        const sizeChange = sizeRef.current !== sizeData;
        if (sizeChange) {
            pageRef.current = 1
        } else {
            pageRef.current = pageData
        }
        sizeRef.current = sizeData
        let branch = branchState
        let status = statusState
        let name = nameState
        let tel = telState
        let contractNumber = contractNumberState
        let page = pageRef.current
        let size = sizeRef.current
        let startDate = startDateState
        let endDate = endDateState
        const params = createParams(branch || '', status || '', name || '', tel || '', contractNumber || '', page || 1, size || 10, startDate || '', endDate || '');
        dispatchGetContractList(params);
    };

    const onClearCustom = (setValue, formSearch) => {
        formSearch.forEach((val) => setValue(val.name, null));
        setBranchState('')
        setNameState('')
        setTelState('')
        setContractNumberState('')
        setStatusState('')
        setStartDateState('')
        setEndDateState('')
        pageRef.current = 1
        sizeRef.current = 10
    }

    const fetchDataForExport = async () => {
        setLoading(true)

        let params = ``

        if (branchState === '' && statusState === '' && nameState === '' && telState === '' && contractNumberState === '' && startDateState === '' && endDateState === '') {
            params = ``
        } else {
            // params += `?`
            if (branchState) {
                params += `&branch=${branchState}`;
            }
            if (statusState) {
                params += `&status=${statusState}`;
            }
            if (nameState) {
                params += `&name=${nameState}`;
            }
            if (telState) {
                params += `&tel=${telState}`;
            }
            if (contractNumberState) {
                params += `&contract_number=${contractNumberState}`;
            }
            if (startDateState) {
                params += `&startDate=${startDateState}`;
            }
            if (endDateState) {
                params += `&endDate=${endDateState}`;
            }
            params = `?` + params.substring(1);
        }

        try {
            let res = await GET(GET_DATA_EXPORT_CONTRACT(params));
            const { success, data } = res

            if (success) {
                let twoProductContract = data.contract_info.filter((el) => el.product.length > 1)
                let arr = []

                if (twoProductContract.length !== 0) {
                    twoProductContract.map((el) => {
                        for (let i = 0; i < el.product.length; i++) {
                            arr.push({
                                'เลขที่สัญญา': el?.contract_no,
                                'ชื่อ-นามสกุล': el?.user?.general.fullname.trim(),
                                'เกรด': el?.grade,
                                'วันที่ทำสัญญา': el?.transaction_date,
                                'ประเภทสัญญา': el?.type === 'REFINANCE' ? 'ไอโฟนแลกเงิน' : el.type === 'INSTALLMENT' ? 'ผ่อนดาวน์' : el.type === 'REFINPACKAGE' ? 'ไอโฟนแลกเงิน + แพ็คเกจ' : '',
                                'รูปแบบสัญญา': el?.contract_type === 'E_CONTRACT' ? 'E-Contract' : 'Paper',
                                'รุ่น': el.product[i].name || '',
                                'สี': el.product[i].color || '',
                                'ความจุ': el.product[i].size || '',
                                'วงเงินที่ได้รับ': el?.lease_amount && convertStrToFormat(el.lease_amount.toString(), 'money'),
                                'เงินดาวน์': el?.down_amount && convertStrToFormat(el.down_amount.toString(), 'money'),
                                'สาขา': el?.branchName,
                                'สถานะสัญญา': el?.statusName,
                                'ชื่อ-นามสกุล(ผู้สร้างสัญญา)': el?.admin_created?.general.fullname,
                                'ชื่อ-นามสกุล(ผู้ยกเลิกสัญญา)': el?.admin_terminated?.general.fullname,
                                'เหตุผลที่กดยกเลิกสัญญา': el?.remark_terminated,
                            })
                        }
                    })

                }

                const oneProductContract = data.contract_info.filter((el) => el.product.length <= 1)
                const prepareData = oneProductContract.map((el) => {
                    return {
                        'เลขที่สัญญา': el?.contract_no,
                        'ชื่อ-นามสกุล': el?.user?.general.fullname.trim(),
                        'เกรด': el?.grade,
                        'วันที่ทำสัญญา': el?.transaction_date,
                        'ประเภทสัญญา': el?.type === 'REFINANCE' ? 'ไอโฟนแลกเงิน' : el.type === 'INSTALLMENT' ? 'ผ่อนดาวน์' : el.type === 'REFINPACKAGE' ? 'ไอโฟนแลกเงิน + แพ็คเกจ' : '',
                        'รูปแบบสัญญา': el?.contract_type === 'E_CONTRACT' ? 'E-Contract' : 'Paper',
                        'รุ่น': el?.product.length === 1 ? el.product[0].name : '',
                        'สี': el?.product.length === 1 ? el.product[0].color : '',
                        'ความจุ': el?.product.length === 1 ? el.product[0].size : '',
                        'วงเงินที่ได้รับ': el?.lease_amount && convertStrToFormat(el.lease_amount.toString(), 'money'),
                        'เงินดาวน์': el?.down_amount && convertStrToFormat(el.down_amount.toString(), 'money'),
                        'สาขา': el?.branchName,
                        'สถานะสัญญา': el?.statusName,
                        'ชื่อ-นามสกุล(ผู้สร้างสัญญา)': el?.admin_created?.general.fullname,
                        'ชื่อ-นามสกุล(ผู้ยกเลิกสัญญา)': el?.admin_terminated?.general.fullname,
                        'เหตุผลที่กดยกเลิกสัญญา': el?.remark_terminated,
                    }
                })
                let dataEx = [...prepareData, ...arr]
                dataEx.sort((a, b) => {
                    return a.contract_no = b.contract_no
                })
                setDataExport(dataEx)
            }

        } catch (err) {
            const { message, error } = err;

            Modal.error({
                title: message || '',
                content: error || '',
            });
        } finally {
            setLoading(false)
            if (params !== '') {
                setVisibleModal(true)
            }
        }
    }

    const onClose = () => {
        setVisibleModal(false)
    }

    return (
        <Wrapper>
            <Card>
                <Spin tip="Loading..." spinning={contractListTradesys.loading || loading}>
                    <PageLayout
                        searchLayout={{
                            title: 'สัญญาซื้อขาย',
                            icon: <FileSearchOutlined />,
                            spanSearch: 24,
                            formSearch: ContractListSearchForm({ option: { optionBranch, optionStatus } }),
                            onSearch: (data) => search(data),
                            onClearCustom: onClearCustom,
                        }}
                        tableLayout={{
                            columns: ContractListColumn({ option: { optionBranch, optionStatus } }),
                            dataSource: dataSource,
                            pagination: {
                                showSizeChanger: true,
                                current: pageRef.current,
                                pageSize: sizeRef.current,
                                onChange: (p, s) => onPageSizeChangeCustom(p, s),
                                pageSizeOptions: ['10', '20', '30', '40', '100', '200', '300'],
                                total: totalSize || 0,
                                showTotal: (totalSize, range) => `รายการทั้งหมด ${convertStrToFormat(totalSize, 'number-comma')} รายการ`,
                                defaultPageSize: sizeRef.current,
                                defaultCurrent: 1,
                            },
                            extraContent: (
                                <ContainerButton right>
                                    <ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด Excel'} onClick={() => fetchDataForExport()} />
                                    {/* <CSVLink data={dataExport} filename={"สัญญาซื้อขาย"}><ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด Excel'} /></CSVLink> */}
                                </ContainerButton>
                            )
                        }}
                    />
                </Spin>
            </Card>
            <Modal title={'ต้องการดาวน์โหลด Excel?'} open={visibleModal} width={550} onCancel={onClose} footer={null} destroyOnClose={true} maskClosable={false}>
                <ContainerButton right>
                    <CSVLink data={dataExport} filename={"สัญญาซื้อขาย"}><ButtonTheme useFor="CUSTOM" title={'ตกลง'} onClick={() => onClose()} /></CSVLink>
                    <ButtonTheme useFor="CANCEL" onClick={() => onClose()} />
                </ContainerButton>
            </Modal>
        </Wrapper>
    )
};

export default Contract;