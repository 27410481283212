export const API_URL = process.env.REACT_APP_API_URL;
export const API_SOCKET_URL = process.env.REACT_APP_API_SOCKET_URL;
export const API_URL_RESEVRE = process.env.REACT_APP_API_URL_RESEVRE;
export const LINK = process.env.REACT_APP_LINK;
export const V2_URL = process.env.REACT_APP_V2_URL;
export const APP_URL = process.env.REACT_APP_APP_URL;
export const API_CHATCONE_SERVICE = process.env.REACT_APP_API_CHATCONE_SERVICE;
export const SYS_DISPLAY = process.env.REACT_APP_SYS_DISPLAY;
export const NODE_ENV = process.env.REACT_APP_NODE_ENV;
export const IS_PRODUCTION = process.env.REACT_APP_IS_PRODUCTION;
export const ENCRYPT_KEY = process.env.REACT_APP_ENCRYPT_KEY;
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const API_AUTHEN_SERVICE = process.env.REACT_APP_AUTHEN_SERVICE;
export const API_REPORT_SERVICE = process.env.REACT_APP_REPORT_SERVICE;
export const API_ADMIN_SERVICE = process.env.REACT_APP_ADMIN_SERVICE;
export const API_PRODUCT_SERVICE = process.env.REACT_APP_PRODUCT_SERVICE;
export const APP_API_KEY = process.env.REACT_APP_API_KEY;
export const API_ICLOUD = process.env.REACT_APP_ICLOUD_API_URL;
export const API_ICLOUD_KEY = process.env.REACT_APP_ICLOUD_API_KEY;
