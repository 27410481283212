import * as TYPES from '../types/statement';
import * as API from '../api/statement';

export const exportStatement = (data) => async (dispatch) => {
    dispatch({ type: TYPES.EXPORT_STATEMENT_REQ });
    try {
        const response = await API.readStatement(data);
        if (response.status === 200) {
            dispatch({ type: TYPES.EXPORT_STATEMENT_SUCCESS, payload: response });
        } else {
            dispatch({ type: TYPES.EXPORT_STATEMENT_FAIL, payload: response });
        }
    } catch (error) {
        dispatch({ type: TYPES.EXPORT_STATEMENT_FAIL, payload: error });
    }
};